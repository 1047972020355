import { Pipe, PipeTransform } from '@angular/core';
import * as sanitizeHtml from 'sanitize-html';

@Pipe({
    name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
    transform(value, sanitizeConfig) {
        return sanitizeHtml(value, sanitizeConfig);
    }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { MemoizedSelector, select, Store } from '@ngrx/store';

import { HttpUtilsService } from '@core/services/http-utils.service';
import { getLocation, IConnection } from '@store/common';
import {
    getLineageConnectionsList,
    getLineageCount,
    getLineageSelectedConnectionsIDs,
    getLineageSelectedConnectionsObjs,
    selectLineageConnections
} from '@store/lineage';
import {
    getDiscoveryConnectionsList,
    getDiscoveryConnectionsListBG,
    getDiscoveryCount,
    getDiscoverySelectedConnectionsIDs,
    getDiscoverySelectedConnectionsObjs,
    selectDiscoveryConnections
} from '@store/discovery';
import {
    getCompareETLConnectionsList,
    getCompareETLCount,
    getCompareETLSelectedConnectionsIDs,
    getCompareETLSelectedConnectionsObjs,
    selectCompareETLConnections
} from '@core/states/compare-etl.state';
import {
    getCompareREPORTConnectionsList,
    getCompareREPORTCount,
    getCompareREPORTSelectedConnectionsIDs,
    getCompareREPORTSelectedConnectionsObjs,
    selectCompareREPORTConnections
} from '@core/states/compare-report.state';
import { UtilsService } from '@core/services/utils.service';
import * as DiscoveryActions from '@store/discovery/actions';
import { SetDiscoveryConnections } from '@store/discovery/actions';
import * as LineageActions from '@store/lineage/actions';
import { SetLineageConnections, SetLineageFlatConnections } from '@store/lineage/actions';
import * as CommonActions from '@store/common/actions';
import { MenuAsideService } from '@core/services/layout/menu-aside.service';
import { ModuleUtilsService } from './module-utils.service';
import { Locations } from '@shared/enums/locations.enum';
import { KeyValue } from '@angular/common';
import { IConnectionsCollections } from '@main/discovery/discovery.types';
import { StoreUtilsService } from '@store/services/store-utils.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ConnectionsList {
    API_ENDPOINT: any = 'clientQueries/getConnectionsForTables';
    public locationSubString: string;
    public connectionsListArray: Array<object> = [];
    public connectionsListArrayBG: Array<object> = [];
    public totalCount: number = 0;
    public totalSelected: number = 0;
    public count: string;
    public selectedConnectionsIDs = [];
    public moduleCounter = {};
    private location: Locations;
    private selectedConnectionsObjs = [];

    constructor(
        private menuAsideService: MenuAsideService,
        private httpUtilsService: HttpUtilsService,
        private store: Store<any>,
        private utilsService: UtilsService,
        private moduleUtilsService: ModuleUtilsService,
        private storeUtilsService: StoreUtilsService,
        private toasterService: ToastrService,
    ) {
        this.store
            .pipe(
                select(getLocation),
            )
            .subscribe(location => {
                this.location = <Locations>this.moduleUtilsService.getLocationPreferredKey(location);

                // reset the local values
                this.resetValues();

                // get all data from server after reset the service values
                this.getFromStore();
            });
    }

    public updateConnectionsStorage() {
        const connections = this.getSelectedConnections();
        localStorage.setItem(`${this.location}_connections`, JSON.stringify(connections));
    }

    public getSelectedConnections() {
        function getSelector(): MemoizedSelector<any, any> {
            switch (location) {
                case Locations.lineage:
                    return selectLineageConnections;
                case Locations.discovery:
                    return selectDiscoveryConnections;
                case Locations.compareReports:
                    return selectCompareREPORTConnections;
                case Locations.compareEtls:
                    return selectCompareETLConnections;
                default:
                    return null;
            }
        }

        let location;
        this.store
            .pipe(
                select(getLocation),
                take(1),
            )
            .subscribe(loc => location = loc);

        let connections = [];
        let connectionsBG = [];
        this.store
            .pipe(
                select(getSelector()),
                filter(conn => !!conn),
                take(1),
            )
            .subscribe((conn: any) => {
                connections = conn.connections;
                connectionsBG = conn.connectionsBG;
            });

        return {
            connections,
            connectionsBG
        };
    }

    public getSelectedConnectionsObj(externalConnections?: any) {
        const arr = [];
        const addConnections = (connections) => {
            if (connections) {
                connections.forEach((a: { key: string, value: any[] }) => {
                    a.value.forEach((b: { key: string, value: any[] }) => {
                        b.value.forEach((c: IConnection) => {
                            if (c.is_checked) {
                                arr.push(c);
                            }
                        });
                    });
                });
            }
        };

        const connectionsCollections: IConnectionsCollections = externalConnections ? externalConnections : this.getSelectedConnections();
        addConnections(connectionsCollections.connections);
        addConnections(connectionsCollections.connectionsBG);
        return arr;
    }

    public checkIfExistsConnectionsFromStorage(module: string): boolean {
        const all = Object.keys(localStorage);
        for (let i = 0; i < all.length; i++) {
            if (all[i].startsWith(module)) {
                return true;
            }
        }
        return false;
    }

  // get metadata-sources list
    public getConnectionsFromServer(location?: Locations) {
        return this.httpUtilsService.postData(this.API_ENDPOINT)
            .pipe(
                map((result: any) => {
                  // update all metadata-sources to store (to use for all the app, like in admin - connection parameters)
                    const res = JSON.parse(result.res);
                    this.updateAllConnectionsInStore(res);
                    return res;
                })
            );
    }

    public getConnections(onFirstLoad?: boolean, location?: Locations): Observable<any> {
        this.location = location ? location : this.location;

        this.connectionsListArray = [];
        this.connectionsListArrayBG = [];
        this.totalCount = 0;
        this.totalSelected = 0;
        this.locationSubString = this.moduleUtilsService.getLocationSubString(location);

        return this.getConnectionsFromServer(location)
            .pipe(
                tap(response => {
                    this.buildConnectionsLists(response, onFirstLoad);
                    this.updateStore();
                    this.menuAsideService.updateBadgeValue();
                }),
            );
    }

    public getConnectionsIDs(data: Array<any>): string {
        let _connections_ids: string;
        const _connection_ids_arr: Array<any> = [];

        data.forEach(function (value) {
            _connection_ids_arr.push(value.CONNID);
        });
        _connections_ids = _connection_ids_arr.toString();
        return _connections_ids;
    }

  // update metadata-sources cookies
    public updateStorage(connection): void {
        const _connection_name = this.locationSubString + '_' + connection.tool + (connection.isBG ? '_BG_' : '_') + connection.connection_name + '_' + connection.connection_id;
        this.setStorageConnection(_connection_name, connection.is_checked);
    }

  // set to store the metadata-sources ids and the count
    public updateStore(connection?): void {
        if (connection) {
            this.getAllStoreSelectedConnectionsObjs()
                .subscribe(selected_connections_objs => this.selectedConnectionsObjs = selected_connections_objs);
            this.selectedConnectionsObjs = [...this.selectedConnectionsObjs, connection];
        }

        switch (this.location) {
            case Locations.lineage:
            case Locations.e2eColumnDashboard:
            case Locations.e2eColumn:
                this.store.dispatch(new LineageActions.UpdateLineageSelectedConnectionsIDs({selected_connections_ids: this.selectedConnectionsIDs.join(',')}));
                this.store.dispatch(new LineageActions.UpdateLineageCount({count: this.count}));
                this.store.dispatch(new LineageActions.UpdateLineageSelectedConnectionsObjs({selected_connections_objs: this.selectedConnectionsObjs}));
                this.store.dispatch(new LineageActions.UpdateLineageConnectionsList({connections_list: this.connectionsListArray}));
                break;
            case Locations.discovery:
                this.store.dispatch(new DiscoveryActions.UpdateDiscoverySelectedConnectionsIDs({selected_connections_ids: this.selectedConnectionsIDs.toString()}));
                this.store.dispatch(new DiscoveryActions.UpdateDiscoveryCount({count: this.count}));
                this.store.dispatch(new DiscoveryActions.UpdateDiscoverySelectedConnectionsObjs({selected_connections_objs: this.selectedConnectionsObjs}));
                this.store.dispatch(new DiscoveryActions.UpdateDiscoveryConnectionsList({connections_list: this.connectionsListArray}));
                this.store.dispatch(new DiscoveryActions.UpdateDiscoveryConnectionsListBG({connections_list_bg: this.connectionsListArrayBG}));
                break;
        }
    }

    updateModuleCounter() {
        if (this.connectionsListArray == undefined) {
            console.log('Connection list is empty.');
            return;
        }

        Object.keys(this.connectionsListArray).forEach((module) => {
            this.moduleCounter[module] = {'total': 0, 'checked': 0};
            Object.keys(this.connectionsListArray[module]).forEach((tool) => {
                this.connectionsListArray[module][tool].forEach((connection) => {
                    this.moduleCounter[module].total++;
                    if (connection['is_checked']) {
                        this.moduleCounter[module].checked++;
                    }

                });
            });
        });
    }

  // calculate the count of selected metadata-sources
    public calculateCount(isChecked, fromUpdate?): void {
        if (isChecked) {
            this.totalSelected++;
        } else if (fromUpdate && !isChecked) {
            this.totalSelected--;
        }

        this.count = this.totalSelected + '/' + this.totalCount;
    }

    public updateConnectionsIDsList(connectionsCollection?): void {
        this.totalSelected = 0;
        this.totalCount = 0;

        const a = this.getConnectionIds(connectionsCollection.connections);
        const b = this.getConnectionIds(connectionsCollection.connectionsBG);
        this.selectedConnectionsIDs = [...a.connectionsIds, ...b.connectionsIds];
        const connectionsFlat = [...a.flatConnections, ...b.flatConnections];
        this.store.dispatch(new SetLineageFlatConnections(connectionsFlat));
        this.count = this.totalSelected + '/' + this.totalCount;

        if (this.selectedConnectionsIDs.length === 0) {
            this.toasterService.error('Please choose at least one connection', 'Connections error');
        }
    }

    public getAllStoreSelectedConnectionsObjs() {
        switch (this.location) {
            case Locations.lineage:
                return this.store
                    .pipe(
                        select(getLineageSelectedConnectionsObjs),
                    );
            case Locations.discovery:
                return this.store
                    .pipe(
                        select(getDiscoverySelectedConnectionsObjs),
                    );
            case Locations.compareEtls:
                return this.store
                    .pipe(
                        select(getCompareETLSelectedConnectionsObjs),
                    );
            case Locations.compareReports:
                return this.store
                    .pipe(
                        select(getCompareREPORTSelectedConnectionsObjs),
                    );
            default:
                return of([]);
        }
    }

    public getAllStoreConnectionsList(): Observable<Array<any>> {
        let _connections_list;
        switch (this.location) {
            case Locations.lineage:
                this.store
                    .pipe(
                        select(getLineageConnectionsList),
                    )
                    .subscribe(connections_list => {
                        _connections_list = connections_list;
                    });
                break;
            case Locations.discovery:
                this.store
                    .pipe(
                        select(getDiscoveryConnectionsList),
                    )
                    .subscribe(connections_list => {
                        _connections_list = connections_list;
                    });
                break;
            case Locations.compareEtls:
                this.store
                    .pipe(
                        select(getCompareETLConnectionsList),
                    )
                    .subscribe(connections_list => {
                        _connections_list = connections_list;
                    });
                break;
            case Locations.compareReports:
                this.store
                    .pipe(
                        select(getCompareREPORTConnectionsList),
                    )
                    .subscribe(connections_list => {
                        _connections_list = connections_list;
                    });
                break;
        }
        return of(_connections_list);
    }

    public getAllStoreConnectionsListBG(): Observable<Array<any>> {
        let _connections_list_bg;
        if (this.location === Locations.discovery) {
            this.store
                .pipe(
                    select(getDiscoveryConnectionsListBG)
                )
                .subscribe(connections_list_bg => {
                    _connections_list_bg = connections_list_bg;
                });
        }
        return of(_connections_list_bg);
    }

    // get count from store
    public getCountFromStore(): void {
        switch (this.location) {
            case Locations.lineage:
                this.store
                    .pipe(
                        select(getLineageCount),
                    )
                    .subscribe(count => {
                        this.count = count;
                    });
                break;
            case Locations.discovery:
                this.store
                    .pipe(
                        select(getDiscoveryCount),
                    )
                    .subscribe(count => {
                        this.count = count;
                    });
                break;
            case Locations.compareEtls:
                this.store
                    .pipe(
                        select(getCompareETLCount),
                    )
                    .subscribe(count => {
                        this.count = count;
                    });
                break;
            case Locations.compareReports:
                this.store
                    .pipe(
                        select(getCompareREPORTCount),
                    )
                    .subscribe(count => {
                        this.count = count;
                    });
                break;
        }

        if (this.count !== undefined && this.count !== '0') {
            this.totalSelected = Number(this.count.substr(0, this.count.indexOf('/')));
            this.totalCount = Number(this.count.substr(this.count.indexOf('/') + 1));
        }
    }

    public getStorageConnection(item: string): Observable<string> {
        const connection: string = <string>localStorage.getItem(item);
        return of(connection);
    }

    public setStorageConnection(item: string, value: string): ConnectionsList {
        localStorage.setItem(item, value);
        return this;
    }

    public getFromStorage(): string {
        return '-1';
    }

    private resetValues(): void {
        this.count = undefined;
        this.selectedConnectionsObjs = [];
        this.selectedConnectionsIDs = [];
        this.connectionsListArray = [];
        this.connectionsListArrayBG = [];
        this.totalCount = 0;
        this.totalSelected = 0;
    }

    private indexOrderByModule(akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number {
        const a = this.moduleUtilsService.getOrderByType(akv.key);
        const b = this.moduleUtilsService.getOrderByType(bkv.key);
        return a > b ? 1 : (b > a ? -1 : 0);
    }

    private createConnectionsArr(payload) {
        const arr = [];
        for (const key1 in payload) {
            const b = [];
            for (const key2 in payload[key1]) {
                b.push({
                    key: key2,
                    value: payload[key1][key2]
                });
            }
            const a = {
                key: key1,
                value: b
            };
            arr.push(a);
        }
        return arr.sort(this.indexOrderByModule.bind(this));
    }

    private buildConnectionsLists(data: Array<any>, onFirstLoad?: boolean): void {

        data.forEach(value => {
            const _module_type = value.ID.substr(value.ID, value.ID.indexOf('_'));
            const _tool = value.ID.substr(value.ID.indexOf('_') + 1);
            const _connection_name = value.NAME;
            const _connection_id = value.CONNID;
            let isChecked;
            let notExistInStorage = false;

            this.getStorageConnection(`${this.locationSubString}_${_tool}_${_connection_name}_${_connection_id}`)
                .subscribe(val => {
                    // means that connection not exist on the local storage
                    if (val == null) {
                        notExistInStorage = true;
                    }
                    isChecked = (val == 'true' || val === null ? 'true' : false);
                });

            const _obj: IConnection = {
                module_type: _module_type,
                tool: _tool,
                connection_name: _connection_name,
                connection_id: _connection_id,
                is_checked: Boolean(isChecked),
                isBG: false,
            };
            let connection_bg = {..._obj};

            if (this.connectionsListArray) {
                if (!this.connectionsListArray[_module_type]) {
                    this.connectionsListArray[_module_type] = [];
                }
                if (!this.connectionsListArray[_module_type][_tool]) {
                    this.connectionsListArray[_module_type][_tool] = [];
                }
                this.connectionsListArray[_module_type][_tool].push(_obj);

                this.updateStorage(_obj);

              // count the metadata-sources
                this.totalCount++;
                this.calculateCount(isChecked);
                // set connection id to list if checked
                // this.updateConnectionsIDsList(_is_checked || connection_bg.is_checked, _connection_id);
            } else {
              console.log('vm.connectionsListArray was undefined!! [ metadata-sources-list.service.ts - line 124 ]');
            }

            // bg
            if (this.location === Locations.discovery && _module_type === 'REPORT' && this.toolForBG(_tool)) {
                connection_bg = {..._obj};
                connection_bg.isBG = true;

                this.getStorageConnection(this.locationSubString + '_' + _tool + (connection_bg.isBG ? '_BG_' : '_') + _connection_name + '_' + _connection_id).subscribe(val => {
                    // means that connection not exist on the local storage
                    if (val == null) {
                        notExistInStorage = true;
                    }
                    connection_bg.is_checked = (val == 'true' || val == null ? 'true' : false);
                });

                if (!this.connectionsListArrayBG[_module_type]) {
                    this.connectionsListArrayBG[_module_type] = [];
                }
                if (!this.connectionsListArrayBG[_module_type][_tool]) {
                    this.connectionsListArrayBG[_module_type][_tool] = [];
                }

                this.connectionsListArrayBG[_module_type][_tool].push(connection_bg);

                // update the local storage with this spesific connection just if this connection un\checked by user or if this connection not exist in the local storage
                if (notExistInStorage || !onFirstLoad) {
                    this.updateStorage(connection_bg);
                }

              // count the metadata-sources
                this.totalCount++;
                this.calculateCount(connection_bg.is_checked);
            }

            // update the local storage with this spesific connection just if this connection un\checked by user or if this connection not exist in the local storage
            // if (notExistInStorage || !onFirstLoad && (vm.location != "compare-etls" && vm.location != "compare-reports")) { vm.updateStorage(_obj); }
        });

        if (this.connectionsListArray) {
            Object.keys(this.connectionsListArray).forEach((module) => {
                this.moduleCounter[module] = {'total': 0, 'checked': 0};
                Object.keys(this.connectionsListArray[module]).forEach((tool) => {
                    this.connectionsListArray[module][tool].forEach((connection) => {
                        this.moduleCounter[module].total++;
                        if (connection['is_checked']) {
                            this.selectedConnectionsObjs = [...this.selectedConnectionsObjs, connection];
                            this.moduleCounter[module].checked++;
                        }
                    });
                });
            });
        } else {
          console.log('vm.connectionsListArray was undefined!! [ metadata-sources-list.service.ts - line 147 ]');
        }

        if (this.location === Locations.discovery) {
            Object.keys(this.connectionsListArrayBG).forEach((module) => {
                Object.keys(this.connectionsListArrayBG[module]).forEach((tool) => {
                    this.connectionsListArrayBG[module][tool].forEach((connection) => {
                        if (connection['is_checked']) {
                            this.selectedConnectionsObjs = [...this.selectedConnectionsObjs, connection];
                        }
                    });
                });
            });
        }

        const connections = [...this.createConnectionsArr(this.connectionsListArray)];
        const connectionsBG = [...this.createConnectionsArr(this.connectionsListArrayBG)];

        const a = this.getConnectionIds(connections);
        const b = this.getConnectionIds(connectionsBG);
        this.selectedConnectionsIDs = [...a.connectionsIds, ...b.connectionsIds];
        const connectionsFlat = [...a.flatConnections, ...b.flatConnections];

        switch (this.location) {
            case Locations.admin:
                this.store.dispatch(new SetLineageFlatConnections(connectionsFlat));
                break;
            case Locations.e2eColumn:
            case Locations.e2eColumnDashboard:
            case Locations.lineage:
            case Locations.map:
            case Locations.lineageSchema:
                this.store.dispatch(new LineageActions.UpdateLineageCount({count: this.count}));
                this.store.dispatch(new SetLineageConnections({connections, connectionsBG}));
                this.store.dispatch(new SetLineageFlatConnections(connectionsFlat));
                break;
            case Locations.discovery:
                this.store.dispatch(new DiscoveryActions.UpdateDiscoveryCount({count: this.count}));
                this.store.dispatch(new SetDiscoveryConnections({connections, connectionsBG}));
                this.store.dispatch(new SetLineageFlatConnections(connectionsFlat));
                break;
        }
    }

    private updateAllConnectionsInStore(response) {
        this.store.dispatch(new CommonActions.UpdateAllConnections(response));
    }

    // check if this tool should be in BUSINESS GLOSSARY \ DATA DICTIONARY list
    private toolForBG(tool: string): boolean {
        if (tool === 'BO' || tool === 'Cognos' || tool.toUpperCase() === 'COGNOS' || tool === 'MICROSTRATEGY' || tool == 'OBIEE') {
            return true;
        }
        return false;
    }

    private getConnectionIds(connections): { connectionsIds: number[], flatConnections: IConnection[] } {
        const flatConnections: IConnection[] = [];
        const connectionsIds: number[] = [];

        connections.forEach((a: { key: string, value: any[] }) => {
            a.value.forEach((b: { key: string, value: any[] }) => {
                b.value.forEach((connection: IConnection) => {

                    const flatItem: IConnection = {
                        module_type: connection.module_type,
                        tool: connection.tool,
                        connection_id: connection.connection_id,
                        toolLongName: this.moduleUtilsService.shortToLongToolNameToDisplay(connection.tool),
                        is_checked: Boolean(connection.is_checked),
                    };
                    flatConnections.push(flatItem);

                    this.totalCount++;
                    if (connection.is_checked) {
                        connectionsIds.push(connection.connection_id);
                        this.totalSelected++;
                    }
                });
            });
        });

        return {connectionsIds, flatConnections};
    }

    private getAllStoreSelectedConnectionsIDs(): Observable<any> {
        let _connections_ids;
        switch (this.location) {
            case Locations.lineage:
                this.store
                    .pipe(select(getLineageSelectedConnectionsIDs))
                    .subscribe(selected_connections_ids => {
                        _connections_ids = selected_connections_ids;
                    });
                break;
            case Locations.discovery:
                this.store
                    .pipe(select(getDiscoverySelectedConnectionsIDs))
                    .subscribe(selected_connections_ids => {
                        _connections_ids = selected_connections_ids;
                    });
                break;
            case Locations.compareEtls:
                this.store
                    .pipe(
                        select(getCompareETLSelectedConnectionsIDs),
                    )
                    .subscribe(selected_connections_ids => {
                        _connections_ids = selected_connections_ids;
                    });
                break;
            case Locations.compareReports:
                this.store
                    .pipe(
                        select(getCompareREPORTSelectedConnectionsIDs),
                    )
                    .subscribe(selected_connections_ids => {
                        _connections_ids = selected_connections_ids;
                    });
        }
        return of(_connections_ids);
    }

    private getFromStore(): void {
      // get list of the metadata-sources list from the store
        this.getAllStoreConnectionsList()
            .subscribe(val => {
                this.connectionsListArray = val;
            });

      // get list of the metadata-sources list bg from the store
        this.getAllStoreConnectionsListBG()
            .subscribe(val => {
                this.connectionsListArrayBG = val || [];
            });

      // get list of the selected metadata-sources from the store
        this.getAllStoreSelectedConnectionsObjs()
            .subscribe(selected_connections_objs => {
                this.selectedConnectionsObjs = selected_connections_objs;
            });

      // get list of the selected metadata-sources ids from the store
        this.getAllStoreSelectedConnectionsIDs()
            .subscribe(selected_connections_ids => {
                if (selected_connections_ids) {
                    if (selected_connections_ids.toString() === '-1') {
                        this.selectedConnectionsIDs = [];
                    } else {
                        this.selectedConnectionsIDs = selected_connections_ids.split(',');
                    }
                }
            });

        this.getCountFromStore();
    }

    // public clear(item: string) {
    // 	localStorage.removeItem(item);
    // }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'oct-menu-toolbar-button',
	templateUrl: './menu-toolbar-button.component.html',
	styleUrls: ['./menu-toolbar-button.component.scss']
})
export class MenuToolbarButtonComponent implements OnInit {
	@Input() item: any;

	constructor() {
	}

	ngOnInit(): void {
	}
}

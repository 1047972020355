import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[octTextDirection]'
})
export class TextDirectionDirective implements AfterViewInit {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngAfterViewInit(): void {
    fromEvent(this.el.nativeElement, 'keyup')
      .subscribe(() => this.setTextDirection());
  }

  private isRtl(text: string): boolean {
    // Simple check for Arabic or Hebrew characters
    const rtlChars = /[\u0590-\u05FF\uFB1D-\uFB4F]/;
    return rtlChars.test(text);
  }

  private setTextDirection() {
    const text = this.el.nativeElement.textContent;
    const direction = this.isRtl(text) ? 'text-direction-rtl' : 'text-direction-ltr';
    this.renderer.addClass(this.el.nativeElement, direction);
  }
}

<span class="m-list-timeline__badge m-list-timeline__badge--state-warning"></span>

<div class="selected-item" [ngClass]="{'hidden-item': !isDeleteIcon, 'back-from-hidden-item': isDeleteIcon}">
    <div (click)="startAction(item)" [ngClass]="{'disabled-row': !isDeleteIcon}" class="timeline-item"
         id="recent-selected-item">
        <div [matTooltip]="item.timestamp | date:'dd/MM/yyyy, h:mm:ss a'" class="flex first-row"
             matTooltipClass="my-tooltip"
             matTooltipPosition="left">
            <div class="recent-activities-icon-wrapper">
                <img style="max-width: 40px;" [matTooltip]="showModuleInTooltip(item)" alt="" matTooltipClass="my-tooltip"
                     matTooltipPosition="above"
                     src="assets/{{item.icon}}">
            </div>
            <div [ngClass]="{'disabled-row': !isDeleteIcon}" class="bold elipsiss"
                 octRtlView>{{item.search_value === 'UNKNOWN' ? '' : item.search_value}}</div>
            <div *ngIf="item.module === 'Discovery' && item.activity === 'Show Button Data'" class="bullet"></div>
            <div *ngIf="item.module === 'Discovery' && item.activity === 'Show Button Data'">{{item.search_value_from_url}}</div>
        </div>
        <div [ngClass]="{'disabled-row': !isDeleteIcon}" class="flex">
            <div>{{item.activity}}</div>
            <div *ngIf="item.tool_name && item.tool_name != 'UNKNOWN'" class="bullet"></div>
            <div *ngIf="item.tool_name && item.tool_name != 'UNKNOWN'">{{item.tool_name === 'MISSING' ? 'MISSING DESCRIPTION' : item.tool_name }}</div>
            <div *ngIf="item.tool_type && item.tool_type != 'UNKNOWN'" class="bullet"></div>
            <div *ngIf="item.tool_type && item.tool_type != 'UNKNOWN'" [matTooltip]="item.filter"
                 [ngStyle]="{'background-color':item.color}" class="m-badge m-badge--wide"
                 matTooltipPosition="right">{{item.tool_type}}</div>
            <div *ngIf="item.filtered && item.filtered != false" class="bullet"></div>
            <div>
                <img #p="ngbPopover" (click)="openCloseFilterPopup($event, p)"
                     *ngIf="item.filtered && item.filtered != false"
                     [autoClose]="'outside'" [ngbPopover]="popContent"
                     alt="Filter" container="body"
                     id="recent-filter-icon"
                     placement="left" popoverClass="recent-activities-pop-over"
                     src="../../../../../../../../assets/filter-gray.svg"
                     triggers="manual">
            </div>
        </div>
    </div>

    <span *ngIf="!isDeleteIcon" [ngStyle]="{'width': !isDeleteIcon ? '100%' : 0}"
          class="overflow-btns hidden-span">DELETED</span>

    <div [ngStyle]="{'width': !isDeleteIcon ? '100%' : 0}"
         class="overflow-btns hidden-btns hidden-item-exclude-div">
        <img (click)="deleteItem($event)" *ngIf="isDeleteIcon"
             [matTooltip]="'Permanently Remove'"
             alt="Delete"
             class="delete-icon"
             id="recent-delete-item"
             matTooltipClass="my-tooltip"
             matTooltipPosition="left"
             src="../../../../../../../../assets/app/img/icons/small_x.svg">
        <mat-icon (click)="undoItem($event)" *ngIf="!isDeleteIcon"
                  [matTooltip]="'Undo'"
                  class="recent-activity-undo"
                  id="recent-undo-item"
                  matTooltipClass="my-tooltip"
                  matTooltipPosition="left"
                  svgIcon="redo"></mat-icon>
    </div>
</div>

<ng-template #popContent>
    <perfect-scrollbar>
        <oct-adc-filter [data]="onFilterPopup()" [readOnlyData]="true"
                        class="pop-over-filters"></oct-adc-filter>
    </perfect-scrollbar>
</ng-template>

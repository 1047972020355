export enum SearchType {
	default = 1,
	advanced,
	byTag,
	filter,
	dateRange,
}

export enum ButtonsTheme {
  none = 'none',
	redTheme = 'red-theme',
	whiteTheme = 'white-theme'
}

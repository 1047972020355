<div class="toolbar">
    <mat-icon (click)="exportGridToExcel()" class="download-excel-icon" matTooltip="Download as Excel (.xlsx)"
              svgIcon='download'></mat-icon>
</div>

<ng-template #template let-tooltip>
    <span>{{ tooltip.nativeElement.innerText }}</span>
</ng-template>

<div (mouseover)="showTooltip($event)" [tooltipTemplate]="template"
     class="grid-container" filter=".k-grid td"
     kendoTooltip
     showOn="none">
    <kendo-grid #grid="kendoGrid"
                [columnMenu]="true"
                [filter]="state.filter"
                [kendoGridBinding]="gridView"
                [ngStyle]="{'height': data.height || 'auto'}"
                [reorderable]="true"
                [resizable]="true"
                [sort]="state.sort"
                [sortable]="true"
                filterable="menu"
                scrollable="scrollable">
        <kendo-grid-column *ngFor="let field of fieldsMap; let i=index"
                           [field]="field.name"
                           [headerStyle]="{'color': '#365B89', 'background-color': 'rgb(243 245 247 / 30%)', 'font-weight': '400'}"
                           [style]="{'color': '#365B89', 'background-color': '#FFFFFF'}"
                           [title]="field.title"
                           [width]="field.width">
            <ng-template *ngIf="field.clickable" kendoGridCellTemplate let-dataItem>
                <a (click)="openField(dataItem)"
                   [innerHTML]="dataItem[field.name]"
                   class="clickable-item">
                </a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-excel fileName="{{data.excelName}}.xlsx"></kendo-grid-excel>
    </kendo-grid>
</div>

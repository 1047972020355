import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';

import { DiscoveryData } from '@core/interfaces/discovery-data';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { ConnectionsList } from '@core/services/connections-list.service';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { DiscoveryState, getDiscoverySelectedConnectionsIDs } from '@store/discovery';
import * as DiscoveryActions from '@store/discovery/actions';
import { SubheaderService } from '@core/services/layout/subheader.service';
import { filterBy } from '@progress/kendo-data-query';
import { IAdvancedSearchValue, ISearchParams } from '@store/search';
import { SearchFacadeService } from '@store/services/search-facade.service';
import { Locations } from '@shared/enums/locations.enum';
import { LogsService } from '@core/services/logs.service';
import { ErrorService } from '@shared/services/error.service';

@Injectable()
export class DiscoveryService implements OnDestroy {
    public dataObjs: Array<object> = [];
    public selectedObject: any = null;
    public selectedTool: string;
    public searchVal: string;
    public connectionIds: string;

    public lastUrl: string;
    public toggleGrid: boolean;
    public toggleGridSubject = new Subject<boolean>();
    public list;
    public lastSearchValueDiscovery: string = '';
    public inChange = 0;
    public innerSearchVal = '';
    public gridskip: number = 0;
    public gridDataSubject = new Subject();
    public gridData: any;
    // tiny modal
    public tinyModalMode: string = 'close';
    public autoRunSearch: boolean = false;
    // advanced search params
    public searchObjType: string;
    public filter1: string = 'Contains';
    public filter1val: string = '';
    public operator1: string = 'or';
    public filter2: string = 'Contains';
    public filter2val: string = '';
    public operator2: string = '';
    public filter3: string = 'Contains';
    public filter3val: string = '';
    public brackets: number = 0;
    public FilterTypes = {
        'Contains': 'Contains',
        'Not_contains': 'Not contains',
        'Equal': 'Equal',
        'Not_equal': 'Not equal',
        'Starts_with': 'Starts with',
        'Ends_with': 'Ends with'
    };
    public discoveryHistoryList = [];
    public advancedParam: ISearchParams;
    private connectionId: string;
    private searchObjectAsStringToShow: string;
    private serviceDestroy$ = new Subject();

    constructor(
            private http: HttpClient,
            private httpUtilsService: HttpUtilsService,
            private router: Router,
            private connectionsList: ConnectionsList,
            private moduleUtilsService: ModuleUtilsService,
            private toasterService: ToastrService,
            private storeDiscovery: Store<DiscoveryState>,
            private subheaderService: SubheaderService,
            private searchFacade: SearchFacadeService,
            private logsService: LogsService,
            private errorService: ErrorService
    ) {
        this.storeDiscovery
                .pipe(
                        select(getDiscoverySelectedConnectionsIDs),
                )
                .subscribe(selected_connections_ids => {
                    this.connectionIds = selected_connections_ids;
                });
    }

    public closeTable(): void {
        this.toggleGrid = false;
        this.toggleGridSubject.next(false);
        this.selectedObject = null;
        this.resetDataListValues();
    }

    // those values are reset the table results of spesific green button
    public resetDataListValues() {
        this.list = {
            data: [],
            columns: [],
            collapse: []
        };
    }

    // public searchDiscovery(externalConnections?: IConnectionsCollections): Observable<any> {
    // 	debugger;
    // 	this.closeTable();
    //
    // 	return this.getQueriesNames(externalConnections)
    // 		.pipe(
    // 			filter(res => !!res.length),
    // 			tap(this.setBreadCrumbs.bind(this)),
    // 			take(1),
    // 		);
    // }

    public getDiscoveryData(discoveryData: DiscoveryData): Observable<any> {
        return this.httpUtilsService.postData('generUI/GENER_UI_Results', discoveryData)
                .pipe(
                        map((result: any) => JSON.parse(result.res)),
                        catchError(error => {
                            this.errorService.failureCounter++;
                            this.errorService.failureCounterSubject$.next();
                            return throwError({
                                tool: discoveryData.tool,
                                error
                            });
                        })
                );
    }

    public exportCsv(discoveryData: any): Observable<any> {
        return this.httpUtilsService.post('generUI/export', discoveryData, {
            responseType: 'blob',
        });
    }

    public getQueryNames(data: object): Observable<any> {
        return this.httpUtilsService.postData('generUI/getAllQueriesNames', data).pipe(
                map((result: any) => JSON.parse(result.res)),
        );
    }

    public innerSearch(searchedColumns: any[]) {
        this.gridData = filterBy(this.gridData, {logic: 'or', filters: searchedColumns});
        this.innerSearchVal = searchedColumns[0].value;
    }

    public addDiscoveryObjToHistoryList(obj) {
        let searchObjType: 'quick' | 'short' | 'full';

        searchObjType = 'quick';

        const searchValues: IAdvancedSearchValue = {
            // The reason that there is no default values is because this is hoe the server expect to get it,
            // any default values will corrupt the request
            filter1: obj.Filter_1,
            filter1val: obj.Filter_1_val,
            operator1: obj.Operator_1,
            filter2: obj.Filter_2,
            filter2val: obj.Filter_2_val,
            operator2: obj.Operator_2,
            filter3: obj.Filter_3,
            filter3val: obj.Filter_3_val,
            bracketsCondition: obj.Brackets || 0
        };

        if (searchValues.filter2val && searchValues.filter2val.length > 2) {
            searchObjType = 'short';
        }

        if (searchValues.filter2val && searchValues.filter3val && searchValues.filter3val.length > 2) {
            searchObjType = 'full';
        }

        this.advancedParam = {
            searchObjType: searchObjType,
            searchValues: searchValues
        };

        this.advancedParam['displayedValue'] = this.getSearchObjectAsStringToShow(true);

        const found = this.discoveryHistoryList.find(historyItem => JSON.stringify(historyItem) === JSON.stringify(this.advancedParam));
        if (!found) {
            this.discoveryHistoryList = [...this.discoveryHistoryList, this.advancedParam];
            this.storeDiscovery.dispatch(new DiscoveryActions.UpdateDiscoveryHistoryList({history_list: this.getDiscoveryHistoryList()}));
        }
    }

    public getDiscoveryHistoryList() {
        return this.discoveryHistoryList;
    }

    public displayHistoryItem(item: ISearchParams) {
        this.searchFacade.setDefaultSearch(item.searchValues.filter1val, Locations.discovery);
        this.searchFacade.setAutoCompleteSearchValue(item.searchValues.filter1val, Locations.discovery);
    }

    ngOnDestroy(): void {
        this.serviceDestroy$.next();
        this.serviceDestroy$.unsubscribe();
    }

    public getTools(externalConnections?) {
        const connectionsObjs = this.connectionsList.getSelectedConnectionsObj(externalConnections);
        if (!connectionsObjs.length) {
            return [];
        }

        let tools = [];
        for (const obj of connectionsObjs) {
            if (obj.is_checked) {

                // Because lake of time and a lot of DB tables that are not impossible to change tool name.
                // I made a special condition for Microstategy tool that is not changing to the short name.
                if (obj.tool === 'MICROSTRATEGY') {
                    if (obj.isBG) {
                        tools.push(obj.tool + '_BG');
                    } else {
                        tools.push(obj.tool);
                    }
                } else {
                    if (obj.isBG) {
                        tools.push(this.moduleUtilsService.longToShortToolName(obj.tool) + '_BG');
                    } else {
                        tools.push(this.moduleUtilsService.longToShortToolName(obj.tool));
                    }
                }
            }
        }
        tools = _.uniq(tools);

      // user don't choose any connection from the metadata-sources list in the right menu
        if (tools.length === 0) {
            this.toasterService.error('Please choose at least one connection', 'Connections error');
            return tools;
        }

        return tools;
    }

    public getSearchObject() {
        let _obj = {};

        switch (this.searchObjType) {
            case 'quick':
                if (!!this.filter1val || this.filter1val === '') {
                    _obj = {
                        'Filter_1': this.filter1,
                        'Filter_1_val': this.filter1val,
                        'Brackets': this.brackets
                    };
                }
                break;
            case 'short':
                _obj = {
                    'Filter_1': this.filter1,
                    'Filter_1_val': this.filter1val,

                    'Operator_1': this.operator1,

                    'Filter_2': this.filter2,
                    'Filter_2_val': this.filter2val,

                    'Brackets': this.brackets
                };
                break;
            case 'full':
                _obj = {
                    'Filter_1': this.filter1,
                    'Filter_1_val': this.filter1val,

                    'Operator_1': this.operator1,

                    'Filter_2': this.filter2,
                    'Filter_2_val': this.filter2val,

                    'Operator_2': this.operator2,

                    'Filter_3': this.filter3,
                    'Filter_3_val': this.filter3val,

                    'Brackets': this.brackets
                };
                break;
        }

        this.storeDiscovery.dispatch(new DiscoveryActions.UpdateDiscoverySearchObj(_obj));
        return _obj;
    }

    public setAdvancedSearchParams(advancedSearchValue: IAdvancedSearchValue) {
        this.filter1 = advancedSearchValue.filter1;
        this.filter1val = advancedSearchValue.filter1val;
        this.operator1 = advancedSearchValue.operator1;
        this.filter2 = advancedSearchValue.filter2;
        this.filter2val = advancedSearchValue.filter2val;
        this.operator2 = advancedSearchValue.operator2;
        this.filter3 = advancedSearchValue.filter3;
        this.filter3val = advancedSearchValue.filter3val;
        this.brackets = advancedSearchValue.bracketsCondition;
    }

    public getSearchObjectAsStringToShow(isAdv?: boolean): string {
        switch (this.searchObjType) {
            case 'quick':
                this.searchObjectAsStringToShow = `${this.filter1} ${this.filter1val}`;
                break;
            case 'short':
                this.searchObjectAsStringToShow = (isAdv ? this.getFilterTypeText(this.filter1) : 'Contains') + ' ' + this.filter1val + ' ' + this.operator1.toUpperCase() + ' ' + this.getFilterTypeText(this.filter2) + ' ' + this.filter2val;
                break;
            case 'full':
                // This should be left == because the comparison should be done by value only
                switch (this.brackets.toString()) {
                    case '1':
                        // tslint:disable-next-line:max-line-length
                        this.searchObjectAsStringToShow = '(' + (isAdv ? this.getFilterTypeText(this.filter1) : 'Contains') + ' ' + this.filter1val + ' ' + this.operator1.toUpperCase() + ' ' + this.getFilterTypeText(this.filter2) + ' ' + this.filter2val + ') ' + this.operator2.toUpperCase() + ' ' + this.getFilterTypeText(this.filter3) + ' ' + this.filter3val;
                        break;
                    case '2':
                        // tslint:disable-next-line:max-line-length
                        this.searchObjectAsStringToShow = (isAdv ? this.getFilterTypeText(this.filter1) : 'Contains') + ' ' + this.filter1val + ' ' + this.operator1.toUpperCase() + ' (' + this.getFilterTypeText(this.filter2) + ' ' + this.filter2val + ' ' + this.operator2.toUpperCase() + ' ' + this.getFilterTypeText(this.filter3) + ' ' + this.filter3val + ')';
                        break;
                    default:
                        // tslint:disable-next-line:max-line-length
                        this.searchObjectAsStringToShow = (isAdv ? this.getFilterTypeText(this.filter1) : 'Contains') + ' ' + this.filter1val + ' ' + this.operator1.toUpperCase() + ' ' + this.getFilterTypeText(this.filter2) + ' ' + this.filter2val + ' ' + this.operator2.toUpperCase() + ' ' + this.getFilterTypeText(this.filter3) + ' ' + this.filter3val;
                }
                break;
            default:
                this.searchObjectAsStringToShow = (isAdv ? this.getFilterTypeText(this.filter1) : 'Contains') + ' ' + this.filter1val;
                break;
        }
        return this.searchObjectAsStringToShow;
    }

    private setBreadCrumbs(isAdv?: boolean): void {
        this.subheaderService.deleteBreadcrumbs();
        const _dataToBreadcrumbs = {
            name: this.getSearchObjectAsStringToShow(isAdv)
        };
        _dataToBreadcrumbs['url'] = '/discovery';
        this.subheaderService.appendBreadcrumbs(_dataToBreadcrumbs);
    }

    /**
     * /@description those functions are to load green buttons
     */
    // private getQueriesNames(externalConnections?: IConnectionsCollections): Observable<any[]> {
    // 	debugger;
    // 	const tools = this.getTools(externalConnections);
    //
    // 	const data = {
    // 		tools_names: tools
    // 	};
    //
    // 	return new Observable(observer => {
    // 		this.getQueryNames(data)
    // 			.pipe(
    // 				take(1),
    // 			)
    // 			.subscribe(response => {
    // 				response = _.orderBy(response, 'QUERYNUMBER');
    // 				let _obj = {};
    // 				let tools = [];
    // 				// add type to each row in data array
    // 				response.forEach(val => {
    // 					val.TYPE = this.moduleUtilsService.getToolType(this.moduleUtilsService.getToolName(val.TOOL_NAME));
    // 					val.COUNT = undefined;
    //
    // 					const _found = _.find(tools, function (value, key) {
    // 						if (value.tool === val.TOOL_NAME) {
    // 							value.data.push(val);
    // 							return val;
    // 						}
    // 					});
    //
    // 					if (!_found) {
    // 						_obj = {
    // 							tool: val.TOOL_NAME,
    // 							type: val.TYPE,
    // 							data: [val],
    // 							orderId: val.TOOL_NAME.includes('_BG') ? 1 : 0,
    // 						};
    // 						tools.push(_obj);
    // 					}
    // 				});
    // 				tools = _.orderBy(tools, ['tool', 'type', 'orderId'], ['asc']);
    //
    // 				this.startSearching(tools)
    // 					.pipe(
    // 						take(1),
    // 					)
    // 					.subscribe(dataObjs => {
    // 						observer.next(dataObjs);
    // 					});
    // 			});
    // 	});
    // }

    private getFilterTypeText(selectedFilter: string) {
        return this.FilterTypes[selectedFilter];
    }

    // get the counter of each button
    // public startSearching(tools): Observable<any> {
    // 	const searchObj = this.getSearchObject();
    // 	const searchValue = JSON.stringify(searchObj);
    //
    // 	debugger;
    //
    // 	// empty search will not insert to Recent Searches
    // 	if (searchObj['Filter_1_val'].trim().length > 0) {
    // 		this.addDiscoveryObjToHistoryList(searchObj);
    // 		const log: ILogData = {
    // 			search_value: this.advancedParam['displayedValue'],
    // 			module: 'Discovery',
    // 			// 10 build of : 9 char of word 'discovery' + 1 char of word '/'
    // 			// url: this.router.url.substr(this.router.url.indexOf('discovery') + 10, this.router.url.length)
    // 			url: this.lastUrl
    // 		};
    // 		this.logsService.writeToUsage(log);
    // 	}
    //
    // 	return new Observable(observer => {
    // 		let loadingCounter = 0;
    // 		for (const tool of tools) {
    // 			tool.loading = true;
    // 			tool.loadingCounter = tool.data.length;
    // 			tool.name = tool.tool;
    //
    // 			for (const toolData of tool.data) {
    // 				this.selectedTool = toolData.TOOL_NAME;
    //
    // 				const payload = {
    // 					connIDs: this.connectionIds,
    // 					searchValue,
    // 					tool: this.selectedTool,
    // 					query_number: toolData.QUERYNUMBER,
    // 					makeOnlyCount: 1
    // 				};
    // 				// To fix erros with '\\','//','&','^' (OCT-4664)
    // 				payload.searchValue = escape(payload.searchValue);
    //
    // 				loadingCounter++;
    //
    // 				this.getDiscoveryData(payload)
    // 					.pipe(
    // 						take(1),
    // 					)
    // 					.subscribe(response => {
    //
    // 						const _toolName = response[0].ToolName;
    // 						const _tool = tools.find(item => item.name === _toolName);
    // 						if (_tool) {
    // 							_tool.loadingCounter--;
    //
    // 							if (_tool.loadingCounter === 0) {
    // 								_tool.loading = false;
    // 							}
    // 						}
    //
    // 						if (response !== 'Exception of type \'System.OutOfMemoryException\' was thrown.') {
    // 							toolData.COUNT = response[0].NumRows;
    // 							toolData.TITLE = response[0].Tooltip;
    // 						} else {
    // 							toolData.COUNT = ' - Error';
    // 							toolData.TITLE = response[0];
    // 						}
    //
    // 						loadingCounter--;
    //
    // 						if (loadingCounter === 0) {
    // 							observer.next(tools);
    // 						}
    // 					});
    // 			}
    // 		}
    //
    // 		this.dataObjs = tools;
    // 	});
    // }

    private resetBasicSearch() {
        this.filter1 = 'Contains';
        this.brackets = 0;
    }
}

import { Component, ViewEncapsulation } from '@angular/core';
import { DATE_FORMATS } from '@main/adc/adc-shared/types/dateFormats';

@Component({
    selector: 'oct-date-range-toolbar-item',
    templateUrl: './date-range-toolbar-item.component.html',
    styleUrls: ['./date-range-toolbar-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DateRangeToolbarItemComponent {
    public name: string;
    public toolTip: string;
    public startDate;
    public endDate;
    public click;

    set data(data: { startDate: any, endDate: any }) {
        if (data) {
            this.startDate = data.startDate.format(DATE_FORMATS[1]);
            this.endDate = data.endDate.format(DATE_FORMATS[1]);
        }
    }

    constructor() {
    }

    onDateRangeToolBarClick(el) {
        this.click(el);
    }
}

<div [ngClass]="{disabledMe: formControl.disabled, 'on-hover-btns': hoverMode}"
     class="padding-wrapper" id="text-form-control">
    <div [ngClass]="{edit: editMode}" class="row item-row">
        <div class="flex" style="margin: 5px 0">
            <div [ngClass]="{'on-hover-btns': hoverMode}" class="row-label">
                <label [ngClass]="{edit: editMode}"
                       class="row-label-body" matTooltip="{{title}}">{{ title }}</label>
            </div>

            <div class="actions">
<!--                <button (click)="onEditClick()" *ngIf="!readonly"-->
<!--                        [ngClass]="{edit: editMode, 'disabledMe': isUserRole}"-->
<!--                        class="action-btn"-->
<!--                        id="Data_Catalog_Edit_{{formControl.value}}"-->
<!--                        mat-icon-button-->
<!--                        matTooltip="Edit">-->
<!--                    <mat-icon [ngClass]="{edit: editMode, 'on-hover-icons': hoverMode, 'edit-icon': !hoverMode}"-->
<!--                              svgIcon="edit-property"></mat-icon>-->
<!--                </button>-->
                <button #copyBtn (click)="onCopyClick($event)"
                        class="action-btn" mat-icon-button matTooltip="Copy">
                    <mat-icon *ngIf="!editMode" [ngClass]="{'on-hover-icons': hoverMode}"
                              class="copy-icon"
                              svgIcon="copy-property"></mat-icon>
                </button>
            </div>
        </div>

        <div class="row-content">
            <angular-editor #editor [config]="editorConfig" [formControl]="formControl"
                            [ngClass]="{edit: editMode, rtl: isRtl}"
                            id="editor"></angular-editor>

        </div>

        <div *ngIf="editMode" class="flex btns-form-action" space-between>
			<div class="flex" style="flex: 1;">
				<mat-label *ngIf="characterLimit">{{ characterCount }}/{{ characterLimit }}</mat-label>
				<div *ngIf="formControl.errors?.plainTextMaxLength" class="control-error-message">
					<mat-label style="color: red; position: absolute; margin-left: 10px;">
						Up to {{ formControl.errors.plainTextMaxLength.requiredLength + 1 }} characters
					</mat-label>
				</div>
			</div>
            <div class="flex" style="align-items: center;">
<!--                <oct-underline-button (clicked)="onCancel()" fontSize="0.85rem" matTooltip="Cancel"-->
<!--                                      text="Cancel"></oct-underline-button>-->
<!--                <oct-round-button (clicked)="onSave()" class="submit-btn" fontSize="0.9rem"-->
<!--                                  matTooltip="Save" text="Save"></oct-round-button>-->
            </div>
        </div>
    </div>
</div>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserRole } from '@shared/enums/user-role.enum';

export interface UserState {
	id: number;
	email: string;
	first_name: string;
	last_name: string;
	full_name: string;
	company: string;
	about_me: string;
	job_title: string;
	user_active: string;
	user_auth_level: string;
	image: string;
	user_permissions: string;
}

export const initialUserState: UserState = {
	id: 0,
	image: './assets/app/img/logos/Octopai_Logo.png',
	full_name: 'Guest',
	company: 'Octopai',
	email: 'user@octopai.com',
	first_name: 'Guest',
	last_name: 'Guest',
	about_me: null,
	job_title: null,
	user_active: 'NO',
	user_auth_level: UserRole.User,
	user_permissions: null,
};

export const selectUserState = createFeatureSelector<UserState>('user');
export const getUser = createSelector(selectUserState, state => state);
export const selectUserId = createSelector(selectUserState, state => state.id);
export const getEmail = createSelector(selectUserState, state => state.email);
export const getFullName = createSelector(selectUserState, state => state.full_name);
export const getAuthLevel = createSelector(selectUserState, state => state.user_auth_level);
export const getUserPermissions = createSelector(selectUserState, state => state.user_permissions);

import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getAllConnections } from '@store/common';
import { Subject } from 'rxjs';
import { deleteActivity } from '@store/activities/actions';
import { selectActivities } from '@store/activities';

@Component({
	selector: 'oct-quick-action',
	styleUrls: ['./quick-action.component.scss'],
	templateUrl: './quick-action.component.html',
})
export class QuickActionComponent implements OnInit {
	@HostBinding('class') classes = 'm-topbar__quick-actions m-topbar__quick-actions--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push m-dropdown--mobile-full-width m-dropdown--skin-light';
	public modalClosed: boolean = true;
	public connections_list: Array<object>;
	public filterIsOpen: boolean = false;
	isFocus$ = new Subject<boolean>();
	private activities;


	constructor(
		private store: Store<any>
	) {
	}

	ngOnInit(): void {
		this.store
			.pipe(
				select(selectActivities),
			)
			.subscribe(results => this.activities = results);
	}

	public open() {
		this.isFocus$.next(true);
		this.toggleQuickAction();
		this.getAllConnections();
	}

	public closeQuickAction() {
		this.isFocus$.next(false);
		this.modalClosed = true;
		this.activities.filter(item => item.to_delete).forEach(item => {
			this.store.dispatch(deleteActivity({payload: item.usage_id}));
		});

		// this.store.dispatch(recentActivitiesToggle({payload: !this.modalClosed}));
	}

	public changeBoxSize(e) {
		this.filterIsOpen = e;
	}

	private toggleQuickAction() {
		this.modalClosed = !this.modalClosed;
		// this.store.dispatch(recentActivitiesToggle({payload: !this.modalClosed}));
	}

	private getAllConnections() {
		this.store
			.pipe(
				select(getAllConnections),
			)
			.subscribe(connections_list => {
				this.connections_list = connections_list;
			});
	}
}

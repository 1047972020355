import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';

import { AppModule } from '@app/app.module';

// import * as Sentry from '@sentry/browser';

// const _tracesSampleRate = (() => {
//     if (location.href.includes('.dev.')) {
//         return 1.0;
//     }
//     return 0.2;
// })();

const _environment = (() => {
  if (location.href.includes('.dev.')) {
    return 'dev';
  }
  if (location.href.includes('.qa.')) {
    return 'qa';
  }
  if (location.href.includes('.stage.')) {
    return 'stage';
  }
  return 'production';
})();

// Sentry.init({
//     dsn: 'https://29f350d7d7704bec9d7ca304457cd0f7@o454599.ingest.sentry.io/5444798',
//     tracesSampleRate: _tracesSampleRate,
//     environment: _environment,
// });

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {
    };
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((ref: NgModuleRef<AppModule>) => {
  })
  .catch(err => console.log(err));

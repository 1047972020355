import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { UtilsService } from '@core/services/utils.service';
import { SplashScreenService } from '@core/services/splash-screen.service';
import { SubheaderService } from '@core/services/layout/subheader.service';
import { PageConfigService } from '@core/services/page-config.service';
import { SideBarService } from '@core/services/layout/side-bar.service';
import { MenuConfigService } from '@core/services/menu-config.service';
import * as LineageActions from '@store/lineage/actions';
import { select, Store } from '@ngrx/store';
import { ReferenceTableService } from '@core/services/reference-table.service';
import { SideBarTypes } from '@store/layout';
import { LineageIdObj } from '@core/interfaces/lineage-data';
import { PropertiesObj } from '@core/interfaces/properties-btns-data';
import { BrowserService } from '@core/services/browser.service';
import { ToolbarService } from '@shared/components/toolbar/toolbar.service';
import { Locations } from '@shared/enums/locations.enum';
import { LogsService } from '@core/services/logs.service';
import { ILogData } from '@core/interfaces/log-data';
import { getLineageSelectedConnectionsIDs } from '@store/lineage';
import * as _ from 'lodash';
import { MenuAsideService } from '@core/services/layout/menu-aside.service';
import { MapIDService } from '@main/maps/map-id/map-id.service';

@Injectable()
export class LineageIDService {
  API_SEARCH = 'lineage/search';
  arrayOfUrl: any;
  url: string;
  module: string;
  notExistObj: object;
  showErrorMsg: boolean;
  data: any;
  data_max_count: number;
  dataToBreadcrumbs: any = {};
  selectedObj = undefined;
  svg;
  diagramData = null;
  killRequest$ = new Subject();
  isSaveState = false;
  lineageData$ = new Subject();
  private lastTrigger: string;
  private lineageRequestTimeout: any;
  private lineagRequestPending: boolean = false;

  constructor(
    private store: Store<any>,
    private httpUtilsService: HttpUtilsService,
    private splashScreenService: SplashScreenService,
    private subheaderService: SubheaderService,
    private pageConfigService: PageConfigService,
    private menuConfigService: MenuConfigService,
    private utilsService: UtilsService,
    private sideBarService: SideBarService,
    private referenceTableService: ReferenceTableService,
    private router: Router,
    private route: ActivatedRoute,
    private browserService: BrowserService,
    private toolBarService: ToolbarService,
    private logsService: LogsService,
    private menuAsideService: MenuAsideService,
    private mapIDService: MapIDService,
  ) {
    this.initVals();
  }

  getRid(payload): Observable<any> {
    return this.httpUtilsService.postData(this.API_SEARCH, payload, true);
  }

  findAsFullText(properties) {
    const key = Object.values(properties).find(listVal => listVal['as_full_text'] === 1);
    if (key) {
      return key['value'];
    }
    return '';
  }

  getLineageSchemaByIdData(lineageIdObj, disableLoader?: boolean): Observable<any> {
    if (!disableLoader) {
      this.splashScreenService.setLoaderOn();
    }
    return this.httpUtilsService.post('lineage/GetLinage', lineageIdObj, true)
      .pipe(
        takeUntil(this.killRequest$),
        map((result: any) => {
          return result;
        })
      );
  }

  // TODO: Should not be called directly. only by route.
  buildParamsToGetLineageSchema(): void {
    this.lineagRequestPending = true;
    this.arrayOfUrl = JSON.parse(this.pageConfigService.decodeBtoa(this.url));
    this.data = null;
    this.module = this.arrayOfUrl.module;
    this.killRequest$.next();
    this.isSaveState = false;

    this.store
      .pipe(
        select(getLineageSelectedConnectionsIDs),
        filter(connectionId => !!connectionId && connectionId !== '-1'),
        take(1),
      )
      .subscribe(connectionId => {
        const a = this.arrayOfUrl.connIDs?.split(',');
        const b = connectionId.split(',');
        const c = _.difference(a, b);
        const d = _.difference(b, a);

        this.isSaveState = this.diagramData
          && this.diagramData.graph.mainNode.ObjectGUID === this.arrayOfUrl.ObjectGUID
          && c.length === 0 && d.length === 0;

        if (!this.isSaveState) {
          this.arrayOfUrl.connIDs = connectionId;
          this.url = this.pageConfigService.encodeBtoa(JSON.stringify(this.arrayOfUrl));
          this.menuAsideService.updateMenuItemRoute(Locations.lineageSchema, `lineageSchema/${this.url}`);
        }
      });

    if (this.isSaveState) {
      this.lineagRequestPending = false;
      this.arrayOfUrl.rID = this.diagramData.graph.mainNode.Rid;
      this.getLineageSchema();
    } else {
      const payload = {
        outputFields: '@rid',
        keyValues:
          [
            {
              txtSearch: this.arrayOfUrl.ObjectGUID || '',
              operator: 4, // 1- startWith	 2- endWith		3- contains		4-equal
              txtfield: 'ObjectGUID',
              connector: ''
            }
          ]
      };

      this.getRid(payload)
        .pipe(
          takeUntil(this.killRequest$),
          filter(postData => !!postData),
        )
        .subscribe(postData => {
          if (postData.length > 0) {
            this.arrayOfUrl.rID = postData[0]['@rid'];
          }
          this.setBreadCrumbs();
          this.getLineageSchema();
        });
      this.lineagRequestPending = false;
    }
  }

  getLineageSchema(): void {
    const data: LineageIdObj = {
      rid: this.arrayOfUrl.rID,
      connections: this.arrayOfUrl.connIDs.split(',')
    };

    // reset the svg from the screen
    this.resetSvg();

    if (!data.rid) {
      this.subheaderService.appendBreadcrumbs(this.dataToBreadcrumbs);
      this.buildNotExistObj('lineage', false, false);
    }

    if (this.isSaveState) {
      const log: ILogData = {
        usage_id: '-1',
        search_value: this.diagramData.data.mainNode.name,
        module: 'Cross System Lineage',
        activity: 'Show Cross System Lineage',
        url: this.url.substr(this.url.indexOf('/') + 1, this.url.length),
        tool_type: this.diagramData.data.mainNode.type,
        tool_name: this.diagramData.data.mainNode.tool
      };
      this.logsService.writeToUsage(log);
      this.createLineageSchema(this.diagramData.data);
    } else {
      this.getLineageSchemaByIdData(data)
        .subscribe(response => {
          const log: ILogData = {
            usage_id: '-1',
            search_value: response.mainNode?.name,
            module: 'Cross System Lineage',
            activity: 'Show Cross System Lineage',
            url: this.arrayOfUrl.url.substr(this.arrayOfUrl.url.indexOf('/') + 1, this.arrayOfUrl.url.length),
            tool_type: response.mainNode?.type,
            tool_name: response.mainNode?.tool
          };
          this.logsService.writeToUsage(log);

          this.diagramData = {
            name: response.mainNode?.name,
            graph: JSON.parse(JSON.stringify(response)),
            data: response,
          };

          // lineage not exist
          if (!response || !response.mainNode || (response.links?.length === 0 && response.nodes?.length === 0)) {
            this.subheaderService.appendBreadcrumbs(this.dataToBreadcrumbs);
            this.buildNotExistObj('lineage', false, false);
            this.toolBarService.schemaLoad$.next(false);
            this.diagramData = null;
          } else {
            this.createLineageSchema(response);
          }

        });
    }
  }

  isJson(str) { // in case we get err msg that lineage too big
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  buildNotExistObj(page: string, isTooBig: boolean, isValid: boolean): void {
    this.notExistObj = {
      page: page,
      tooBig: isTooBig,
      valid: isValid
    };
    this.showErrorMsg = true;
    this.notExistObj = Object.assign(this.notExistObj, this.arrayOfUrl);
  }

  createLineageSchema(results: any) {
    results = this.removeNullObj(results);

    // no full diagram to show
    if (results.length === 0 || (results.links.length === 0 && results.nodes.length === 0)) {
      this.subheaderService.appendBreadcrumbs(this.dataToBreadcrumbs);
      this.toolBarService.schemaLoad$.next(false);
      this.buildNotExistObj('lineage', false, false);
      return;
    }
    this.data = results;

    this.lineageData$.next(results);
  }

  checkIfLineageIsTooBig(data): boolean {
    const str = JSON.stringify(data);
    const str_size = encodeURI(str).split(/%..|./).length - 1;
    if (str_size >= this.data_max_count) {
      return true;
    }
    return false;
  }

  removeNullObj(results): object {
    // TODO: get from OR - isFake=1 -> is fake node, isFake=0 -> it's real node
    const withoutNulls = {...results, nodes: []};
    for (const obj of results.nodes) {
      if (obj.properties.isFake === 1) {
        // if (obj.properties.Table == null && obj.DB == null && obj.JOBNAME == null && obj.REPORT_NAME == null) { /////////////?????
      } else {
        withoutNulls.nodes.push(obj);
      }
    }
    return withoutNulls;
  }

  initVals() {
    this.module = null;
    this.notExistObj = null;
    this.showErrorMsg = null;
    this.data = null;
  }

  getTextTopCircle(d): string {
    if (d.type === 'ETL') {
      return d.name;
    } else if (d.type === 'DATABASE') {
      if (d.dbOwner) {
        return d.dbOwner + '.' + d.name;
      } else {
        return d.name;
      }
    } else if (d.type === 'REPORT') {
      return d.name;
    }
  }

  makeEllipsisText(text) {
    text.each(function () {
      const text = d3.select(this);
      const words = text.text().split('');

      const ellipsis = text.text('').append('tspan').attr('class', 'elip').text('...');
      const width = parseFloat(text.attr('width')) - ellipsis.node().getComputedTextLength();
      const numWords = words.length;

      const tspan = text.insert('tspan', ':first-child').text(words.join(''));

      // Try the whole line
      // While it's too long, and we have words left, keep removing words

      while (tspan.node().getComputedTextLength() > width && words.length) {
        words.pop();
        tspan.text(words.join(''));
      }

      if (words.length === numWords) {
        ellipsis.remove();
      }
    });
  }

  pulsate(selection) {
    if (!this.browserService.isIE()) {
      this.removeAllPulse();
      this.recursive_transitions(selection);
    }
  }

  recursive_transitions(selection) {
    const vm = this;
    if (selection.data()[0] !== undefined) {
      if (selection.data()[0].r !== undefined) {
        selection.transition()
          .duration(400)
          .attr('stroke-width', 2)
          .attr('r', selection.data()[0].r)
          .ease(d3.easeSinIn)
          .transition()
          .duration(800)
          .attr('stroke-width', 3)
          .attr('r', selection.data()[0].r + 4)
          .ease(d3.easeBounceIn)
          .on('end', function () {
            vm.recursive_transitions(selection);
          });
      }
    }
  }

  removeAllPulse() {
    const circles = d3.selectAll('circle')._groups;
    circles.forEach(function (val) {
      val.forEach(function (val) {
        if (d3.select(val).data()[0] !== undefined) {
          d3.select(val).transition()
            .duration(200)
            .attr('r', d3.select(val).data()[0].r)
            .attr('stroke-width', 2)
            .attr('stroke-dasharray', '1, 0');
        }
      });
    });
  }

  // reset the d3 svg
  resetSvg(): void {
    d3.select('#svgDiagramRegular').remove();
  }

  updateStoreActions(location, _obj, d) {
    let propertiesObj: PropertiesObj;
    // build object properties of 'list-properties' interface
    if (d.LinkType) { // link
      propertiesObj = this.buildLinkObjectPropertiesInterface(d);
    } else { // node
      propertiesObj = this.buildObjectPropertiesInterface(d);
    }
    if (propertiesObj.connection_logic_name) {
      this.mapIDService.propertiesObjSubject.next(propertiesObj);
    }
    this.store.dispatch(new LineageActions.UpdateLineageProperties({properties: _obj}));
    this.store.dispatch(new LineageActions.UpdateLineagePropertiesObj({properties_obj: propertiesObj}));

    return propertiesObj;
  }

  openPropertiesMenu() {
    this.sideBarService.open(SideBarTypes.properties);
  }

  // TODO: can to remove after make generic compare
  buildPropertiesMenuForFakeObject(d: any) {
    return {
      'obj_1': 'Object name',
      'val_1': {
        'as_full_text': '',
        'as_multi_values': '',
        'as_url': '',
        'value': d.name
      }
    };
  }

  setBreadCrumbsToDiagram(dataRes, query_obj) {
    let infoItem = this.referenceTableService.getTooltipInformation([dataRes.mainNode], query_obj, true);
    infoItem = this.referenceTableService.buildInfoData(infoItem);
    const data = Object.assign(infoItem, this.dataToBreadcrumbs);
    this.subheaderService.appendBreadcrumbs(data);
  }

  killRequests() {
    this.killRequest$.next();
  }

  setBreadCrumbs(): void {
    this.dataToBreadcrumbs = this.arrayOfUrl;
    this.dataToBreadcrumbs.url = `${Locations.lineageSchema}/${this.url}`;
  }

  private buildObjectPropertiesInterface(obj) {
    const propertiesObj: PropertiesObj = {
      module: undefined,
      tool: undefined,
      name: undefined,
      data_right_click: undefined,
      connection_logic_name: undefined,
      main_circle: undefined,
      db_type: undefined,
      db_name: undefined,
      schema: undefined,
      path_folder: undefined,
      rID: undefined,
      serverName: undefined,
      ObjectGUID: null,
      subModule: undefined,
      connectionID: null,
    };

    propertiesObj.subModule = obj.properties.ModuleType;
    propertiesObj.module = obj.type;
    propertiesObj.tool = obj.properties.ToolName;
    propertiesObj.serverName = obj.properties.Server;
    propertiesObj.name = obj.name;
    propertiesObj.data_right_click = obj.data_right_click;
    propertiesObj.connection_logic_name = obj.properties.ConnectionName;
    propertiesObj.main_circle = obj.main_circle;
    propertiesObj.db_type = obj.properties.ObjectType;
    propertiesObj.db_name = obj.properties.DB;
    propertiesObj.schema = obj.properties.Schema;
    propertiesObj.path_folder = obj.properties.Folder;
    propertiesObj.rID = obj.properties.rid;
    propertiesObj.ObjectGUID = obj.ObjectGUID;
    propertiesObj.in_count = obj.properties.in_count;
    propertiesObj.out_count = obj.properties.out_count;
    propertiesObj.connectionID = obj.properties.ConnectionID;
    return propertiesObj;
  }

  private buildLinkObjectPropertiesInterface(obj) {
    const propertiesObj: PropertiesObj = {
      source: undefined,
      target: undefined,
      linkType: undefined,
      data_right_click: undefined,
      connectionID: null
      // linkDis: undefined
    };

    propertiesObj.source = obj.source;
    propertiesObj.target = obj.target;
    propertiesObj.linkType = obj.LinkType;
    propertiesObj.data_right_click = obj.data_right_click;
    // propertiesObj.linkDis = obj.linkDis;
    propertiesObj.connectionID = obj.ConnectionID;
    return propertiesObj;
  }

  private createRightClickData(dataRightClick: any, propArr: any[], isSource: boolean) {
    let properties_obj = {};
    let indexDataLink = 1;

    if (dataRightClick) {
      propArr.forEach(item => {
        for (const [key, value] of Object.entries(dataRightClick)) {
          let tmp = {};
          if (!isSource) {
            if (key === item) {
              tmp = {
                ['val_' + indexDataLink]: {
                  'as_full_text': '',
                  'as_multi_values': '',
                  'as_url': '',
                  'value': value,
                  'isSource': true
                },
                ['obj_' + indexDataLink]: item,
              };
              properties_obj = {...properties_obj, ...tmp};
              indexDataLink++;
            }
          } else {
            if (value === item) {
              const indexArr = key.split('_');
              const index = indexArr[indexArr.length - 1];
              tmp = {
                ['val_' + indexDataLink]: {
                  'as_full_text': '',
                  'as_multi_values': '',
                  'as_url': '',
                  'value': dataRightClick['val_' + index].value,
                  'isSource': true
                },
                ['obj_' + indexDataLink]: item,
              };
              properties_obj = {...properties_obj, ...tmp};
              indexDataLink++;
            }
          }
        }
      });
    }
    return properties_obj;
  }
}

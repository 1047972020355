import * as CodePreviewActions from './actions';
import { initialCodePreviewState } from './index';

export const initialState = initialCodePreviewState;

export function CodePreviewReducer(state = initialState, action: CodePreviewActions.Actions) {
	switch (action.type) {
		case CodePreviewActions.SET_SCRIPT_ON_LIST_PROPERTIES:
			return {
				...state,
				scriptOnListProperties: action.payload
			};
		case CodePreviewActions.SET_SCRIPT_ON_TEXT_TAB_OF_MAP:
			return {
				...state,
				scriptOnTextTabOfMap: action.payload
			};
		default:
			return state;
	}
}

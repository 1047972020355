import { Injectable } from '@angular/core';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpUtilsService,
    private store: Store<any>,
  ) {
  }

  getAll() {
    return this.http.get('openai/usage-message', {});
  }

  markRead(id: string) {
    return this.http.put(`openai/mark-read/${id}`);
  }
}

<div class="row-title">
    {{ title }}
    <span *ngIf="formControl.hasError('required')">*</span>
</div>
<mat-form-field
        [ngStyle]="{'margin-bottom': formControl.invalid && (formControl.dirty || formControl.touched) ? '0' : '20px'}"
        [style.width.%]="100" appearance="outline"
        id="list-form-{{key}}">
    <mat-select (selectionChange)="onSelectionChange($event)" [formControl]="formControl"
                placeholder="{{placeholder}}">
        <mat-option *ngFor="let option of listOptions"
                    [value]="option.name"
                    id="drop-list-{{option.name}}">{{ option.name }}
        </mat-option>
    </mat-select>
</mat-form-field>

<div *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)"
     [ngClass]="{'has-danger': formControl.invalid && (formControl.dirty || formControl.touched)}"
     class="error-msg-container">
    <div *ngIf="formControl.hasError('required')" class="form-control-feedback">
        {{ title }} is required
    </div>
    <div *ngIf="formControl.hasError('minlength')" class="form-control-feedback">
        {{ title }} must be at least 2 characters long
    </div>
</div>

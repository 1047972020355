<!-- <li class="m-nav__item m-dropdown m-dropdown--large m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width m-dropdown--skin-light m-list-search m-list-search--skin-light" m-dropdown-toggle="click" m-dropdown-persistent="true" id="m_quicksearch" m-quicksearch-mode="dropdown"> -->
<a href="javascript:;" class="m-nav__link m-dropdown__toggle">
	<span class="m-nav__link-icon">
		<span class="m-nav__link-icon-wrapper">
			<i class="flaticon-search-1"></i>
		</span>
	</span>
</a>
<div class="m-dropdown__wrapper">
	<span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
	<div class="m-dropdown__inner ">
		<div class="m-dropdown__header">
			<form class="m-list-search__form" name="form" #f="ngForm">
				<div class="m-list-search__form-wrapper">
					<span class="m-list-search__form-input-wrapper">
						<input id="m_quicksearch_input" autocomplete="off" type="text" name="q" class="m-list-search__form-input"
							   value="" placeholder="Search..." [(ngModel)]="model.value">
					</span>
					<span class="m-list-search__form-icon-close" id="m_quicksearch_close">
						<i class="la la-remove"></i>
					</span>
				</div>
			</form>
		</div>
		<div class="m-dropdown__body">
			<div class="m-dropdown__scrollable m-scrollable" data-scrollable="true" data-max-height="300" data-mobile-max-height="200">
				<perfect-scrollbar style="max-height: 35vh;">
					<div class="m-dropdown__content"></div>
				</perfect-scrollbar>
			</div>
		</div>
	</div>
</div>
<!-- </li> -->

import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'oct-avatar-item',
	templateUrl: './avatar-item.component.html',
	styleUrls: ['./avatar-item.component.scss']
})
export class AvatarItemComponent implements OnInit {
	@Input() option: any;
	@Input() hide: any;

	constructor() {
	}

	ngOnInit(): void {
	}

}

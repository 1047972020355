import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { INotification, selectNotifications } from '@store/notifications';
import { filter } from 'rxjs/operators';
import { updateNotification } from '@store/notifications/actions';

@Component({
  selector: 'oct-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})

export class NotificationsComponent implements OnInit {
  notifications: INotification[] = [];
  hideBadge = true;
  unreadCount = 0;

  constructor(
    private store: Store<any>,
  ) {
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectNotifications),
        filter((notifications: INotification[]) => notifications.length > 0),
      )
      .subscribe((results: INotification[]) => {
        this.unreadCount = results.filter(n => n.isRead === false).length;
        this.hideBadge = this.unreadCount === 0;
        this.notifications = results;
      });
  }

  onOpen() {
    this.hideBadge = true;
    const ids = this.notifications.map(n => n.id);
    ids.forEach(id => this.store.dispatch(updateNotification({id, payload: {isRead: true}})));
  }
}

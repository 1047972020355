import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'objectName'
})
export class ObjectNamePipe implements PipeTransform {

	transform(title: any, objectName: any): any {
		const startIndex = title.indexOf(objectName);
		if (startIndex > -1) {
			const matchStr = title.substr(startIndex, objectName.length);
			const word = title.replace(matchStr, `<b class="bold">${matchStr}</b>`);
			return word;
		} else {
			return title;
		}
	}
}

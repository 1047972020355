import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kUnit'
})
export class KUnitPipe implements PipeTransform {

	transform(value: any, ...args: any[]): any {
		if (value > 1000) {
			return Math.round(parseFloat(value) / 1000).toString() + 'K';
		} else {
			return value;
		}
	}

}

<div class="flex context-wrapper" flex-center flex-dircetion-col
     style="background-color: white; box-shadow: 0 0 12px #365B8933; border-radius: 12px;padding: 5px">
    <button (click)="menuItem.click()" *ngFor="let menuItem of menu" [disableRipple]="true"
            [disabled]="menuItem.disabled" class="transparent-button"
            mat-flat-button>
        <mat-icon [ngClass]="{'discovery-icon' : menuItem.name === 'discovery'}"
                  id="DATA_CATALOG_HOP_TO_{{menuItem.name}}" matTooltip="{{menuItem.title}}"
                  svgIcon="{{menuItem.name}}"></mat-icon>
    </button>
</div>

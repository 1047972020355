<h2 mat-dialog-title>
    <div [style.width.%]="100" class="flex" space-between>
        <div>{{data.modalProperties.header.title}}</div>
        <div (click)="close()" class="close-button">
            <img alt="close" src="../../../../assets/app/img/icons/small_x.svg"/>
        </div>
    </div>
</h2>
<mat-dialog-content>
    <form [formGroup]="form" class="m-form form-modal" id="generic-modal">
        <div class="modal-body add-new-form-body">
            <ng-container #genericFormModalComponentPropertiesContainer></ng-container>
            <ng-container #genericFormModalAddDataComponentPropertiesContainer></ng-container>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <oct-round-button (clicked)="onSubmit()" [disabled]="form.invalid"
                      fontSize="1.2rem" matTooltip="{{data.modalProperties.footer.title}}"
                      text="{{data.modalProperties.footer.title}}"
                      width="10.7vw">
    </oct-round-button>
</mat-dialog-actions>

import { Pipe, PipeTransform } from '@angular/core';
import { SearchType } from '@shared/enums/searchType';

@Pipe({
	name: 'highlight'
})

export class HighlightPipe implements PipeTransform {
	transform(value: any, search: any): any {
		if (!value) {
			return;
		}

		if (!search) {
			return value;
		}

		switch (search.searchType) {
			case SearchType.advanced:
				return this.advancedSearchHighlight(value, search);
			default:
				return this.defaultSearchHighlight(value, search);
		}
	}

	private defaultSearchHighlight(value, search) {
		if (typeof search === 'string') {
			if (search === '') {
				return value;
			} else {
				return this.highlight(value, search.trim());
			}
		}

		if (typeof search.searchValue === 'object' || !search.searchValue || !search.searchValue.trim()) {
			return value;
		}

		return this.highlight(value, search.searchValue.trim());
	}

	private advancedSearchHighlight(value, search) {
		let searchValues = [];
		if (search) {
			searchValues = [
				{
					key: search.searchValue.filter1,
					value: search.searchValue.filter1val,
				},
				{
					key: search.searchValue.filter2,
					value: search.searchValue.filter2val,
				},
				{
					key: search.searchValue.filter3,
					value: search.searchValue.filter3val,
				},
			];
		}

		searchValues
			.forEach(item => {
				if (item.value) {
					value = this.highlight(value, item.value);
				}
			});

		return value;
	}

	private escapeRegExp(str) {
		const specials = ['-', '[', ']', '/', '{', '}', '(', ')', '*', '+', '?', '.', '\\', '^', '$', '|'];
		const regex = RegExp(`[${specials.join('\\')}]`);
		return str.replace(regex, '\\$&');
	}

	private replaceAll(str: string, find, replace) {
		return str.replace(new RegExp(this.escapeRegExp(find)), replace);
	}

	private highlight(value: string, searchTerm: string) {
		let result = [];

		// let searchTermFix;
		// searchTermFix = this.replaceAll(searchTerm.toLowerCase(), '\.', '\\.');
		// searchTermFix = this.replaceAll(searchTerm.toLowerCase(), '\$', '\\$');
		// searchTermFix = this.replaceAll(searchTerm.toLowerCase(), '\\', '\\\\');
		// searchTermFix = this.replaceAll(searchTerm.toLowerCase(), '\[', '\\[');
		//
		// searchTermFix = searchTermFix.replace(/\(/g, '\\(');
		// searchTermFix = searchTermFix.replace(/\)/g, '\\)');

		let searchTermFix = searchTerm;
		searchTermFix = searchTermFix.replace(/\\/g, '\\\\');
		searchTermFix = searchTermFix.replace(/-/g, '\\-');
		searchTermFix = searchTermFix.replace(/\[/g, '\\[');
		searchTermFix = searchTermFix.replace(/]/g, '\\]');
		searchTermFix = searchTermFix.replace(/\//g, '\\/');
		searchTermFix = searchTermFix.replace(/\{/g, '\\{');
		searchTermFix = searchTermFix.replace(/}/g, '\\}');
		searchTermFix = searchTermFix.replace(/\(/g, '\\(');
		searchTermFix = searchTermFix.replace(/\)/g, '\\)');
		searchTermFix = searchTermFix.replace(/\*/g, '\\*');
		searchTermFix = searchTermFix.replace(/\+/g, '\\+');
		searchTermFix = searchTermFix.replace(/\?/g, '\\?');
		searchTermFix = searchTermFix.replace(/\./g, '\\.');
		searchTermFix = searchTermFix.replace(/\^/g, '\\^');
		searchTermFix = searchTermFix.replace(/\$/g, '\\$');
		searchTermFix = searchTermFix.replace(/\|/g, '\\|');

		const regexp = new RegExp(searchTermFix.toLowerCase(), 'g');

		const indexList = [];

		const _word = value.trim();
		while ((result = regexp.exec(_word.toLowerCase())) !== null) {
			const index = result['index'];
			indexList.push(index);
		}

		indexList.reverse().forEach(index => {
			const _value = value.trim();
			const a = index === 0 ? '' : _value.slice(0, index);
			const b = _value.slice(index, index + searchTerm.length);
			const c = index + searchTerm.length === _value.length ? '' : _value.slice(index + searchTerm.length);
			value = a + `<mark>${b}</mark>` + c;
		});

		return value;
	}

}

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getAuthLevel } from '@core/states/user.state';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { filter, map, take } from 'rxjs/operators';
import { selectUserById } from '@store/users';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { UserProfileService } from '@content/layout/header/topbar/user-profile/user-profile.service';
import { ILinks } from '@main/adc/adc-shared/interfaces/graphQL';
import { AbgSource } from '@main/adc/adc-shared/types/abgSource';
import { AddLinkService } from '@main/adc/adc-home/add-link/add-link.service';
import { AbgService } from '@main/adc/adc.service';
import { ToastrService } from 'ngx-toastr';
import { selectStatusList } from '@main/adc/adc-store';

@Injectable()
export class AssetDetailsService {
  public currentUserAuthLevel: any;
  linkedAssetsSubject$ = new BehaviorSubject<any>([]);
  linkedAssets$ = this.linkedAssetsSubject$.asObservable();
  editMode$ = new BehaviorSubject<any>({editMode: false, cancelOrSaveAll: ''});

  constructor(
    private store: Store<any>,
    private http: HttpUtilsService,
    private userProfileService: UserProfileService,
    private addLinkService: AddLinkService,
    private adcService: AbgService,
    private toastrService: ToastrService,
  ) {
    this.getCurrentUser();
  }

  getCurrentUser(): any {
    this.store
      .pipe(
        select(getAuthLevel),
      )
      .subscribe(AuthLevel => {
        this.currentUserAuthLevel = AuthLevel;
      });
  }

  addLink() {
    const payload = {
      operationName: null,
      query: 'mutation ($linksInput: [LinksInputType!]) { obgObjectLinksMutations(linksInput: $linksInput) { linkObjects(linksInput: $linksInput) { sOURCE_OBJECT_ID, tARGET_OBJECT_ID, uPDATED_BY, eRROR_MSG, eRROR_NUM }}}',
      variables: {
        'linksInput': this.linksInputArray()
      }
    };

    return this.http.post('graphql', payload)
      .pipe(
        map(results => {
          this.adcService.lists[this.adcService.fromPage].results = [];
          this.adcService.fromPage = AbgSource.listResultsContainer;

          let msgText = '';
          if (results.data.obgObjectLinksMutations.linkObjects.length === 0) {
            if (this.addLinkService.totalLink > 0) {
              msgText = this.addLinkService.totalLink + ' link/s were added successfully';
              this.toastrService.success(msgText, 'New Link');
            }
          } else {
            let existLinks = 0;
            let errorLinks = 0;

            if (this.addLinkService.totalLink > results.data.obgObjectLinksMutations.linkObjects.length) {
              msgText = (this.addLinkService.totalLink - results.data.obgObjectLinksMutations.linkObjects.length) + ' link/s were added successfully';
            }

            results.data.obgObjectLinksMutations.linkObjects.forEach(item => {
              if (item.eRROR_NUM === 2) {
                existLinks++;
              } else {
                errorLinks++;
              }
            });
            msgText += (msgText !== '' && existLinks > 0) ? ',<br>' : '';
            msgText += existLinks > 0 ? existLinks + ' link/s already exist </br>' : '';
            msgText += (existLinks > 0 && errorLinks > 0) ? ',<br>' : '';
            msgText += errorLinks > 0 ? +errorLinks + ' link/s failed and returned with an error </br>' : '';

            this.toastrService.error(msgText, 'New Link', {enableHtml: true});
          }
          this.adcService.fromPage = AbgSource.listResultsContainer;
        }),
      );
  }

  getLinkedAssets(dataCatalogItemId: string) {
    const payload = {
      operationName: null,
      query: `{ oBG { getLinks (oBJECT_ID: "${dataCatalogItemId}") { oBJECT_ID, oBJECT_NAME, oBJECT_TYPE, oBJECT_DESCRIPTION, oCT_CALCULATION_DESCRIPTION, oCT_SHORT_DESCRIPTION, dATA_TYPE, oBJECT_PATH, lAYER, tAG_NAME, lINK_TYPE, aBG_STATUS, tOOL_TYPE, sOURCE_SYSTEM, dATA_STEWARD, oWNER, uPDATED_BY, lAST_UPDATEDATE }}}`,
      variables: null
    };
    return this.http.post('graphql', payload)
      .pipe(
        map((response: ILinks) => response.data.oBG.getLinks),
      );
  }

  getAttributes(adcAssetId: string, adcAssetType): Observable<any> {
    const payload = {
      operationName: null,
      query: `{ oBG { getObjectCustomAttributes(oBJECT_ID: "${adcAssetId}", oBJECT_TYPE: "${adcAssetType}") { oBJECT_ID, cUSTOM_ATTRIBUTES  }}}`,
      variables: null
    };
    return this.http.post('graphql', payload);
  }

  unlinkObjects(selectedAdcItemId, targetObjectId) {
    const payload = {
      operationName: null,
      query: 'mutation ($unlinkInput: LinksInputType!) { obgObjectLinksMutations(unlinkInput: $unlinkInput) { unlinkObjects (unlinkInput: $unlinkInput) { sOURCE_OBJECT_ID, tARGET_OBJECT_ID, uPDATED_BY} } }',
      variables: {
        'unlinkInput': {
          sOURCE_OBJECT_ID: selectedAdcItemId,
          tARGET_OBJECT_ID: targetObjectId,
          uPDATED_BY: (this.userProfileService.user_obj.id ? this.userProfileService.user_obj.id : 1),
        }
      }
    };

    return this.http.post(`graphql`, payload);
  }

  setAssetUserNames(asset: any) {
    return new Promise((resolve, reject) => {
      const a$ = this.store
        .pipe(
          select(selectUserById(asset.uPDATED_BY as number)),
        );
      const b$ = this.store
        .pipe(
          select(selectUserById(asset.oWNER as number)),
        );
      const c$ = this.store
        .pipe(
          select(selectUserById(asset.dATA_STEWARD as number)),
        );

      combineLatest([a$, b$, c$])
        .subscribe(([a, b, c]) => {
          asset.updateBy = a ? `${a.useR_FIRST_NAME} ${a.useR_LAST_NAME}` : '';
          asset.userOwner = b ? `${b.useR_FIRST_NAME} ${b.useR_LAST_NAME}` : '';
          asset.userDataSteward = c ? `${c.useR_FIRST_NAME} ${c.useR_LAST_NAME}` : '';
          resolve(asset);
        }, error => {
          reject(error);
        });
    });
  }

  setAssetStatus(asset): Promise<any> {
    return new Promise((resolve, reject) => {
      this.store
        .pipe(
          select(selectStatusList),
          filter(list => list && !!list.length),
          take(1),
        )
        .subscribe((statusList) => {
          const assetStatus = statusList.find(item => item.sTATUS_ID === asset.aBG_STATUS);
          asset.colorStatus = assetStatus ? assetStatus.sTATUS_COLOR : '#ffffff';
          asset.statusName = assetStatus?.sTATUS_NAME;
          resolve(asset);
        });
    });
  }

  private linksInputArray(): any[] {
    const resultArr = [];
    let itemToSend;

    for (const key in this.addLinkService.selectedNewLink) {
      itemToSend = {
        sOURCE_OBJECT_ID: this.addLinkService.sourceObjectID,
        tARGET_OBJECT_ID: key,
        uPDATED_BY: (this.userProfileService.user_obj.id ? this.userProfileService.user_obj.id : 1)
      };
      resultArr.push(itemToSend);
    }
    this.addLinkService.totalLink = resultArr.length;
    return resultArr;
  }
}

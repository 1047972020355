import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AccessData } from '@core/auth/access-data';

@Injectable()
export class TokenStorage {
	public loggingoutStatus: string;

	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		const token: string = localStorage.getItem('accessToken');
		return of(token);
	}

	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
	public getRefreshToken(): Observable<string> {
		return of(localStorage.getItem('refreshToken'));
	}

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		const roles: any = localStorage.getItem('userRoles');
		try {
			return of(JSON.parse(roles));
		} catch (e) { }
	}

	/**
	 * Get username
	 * @returns {Observable<string>}
	 */
	public getUsername(): Observable<string> {
		const token: string = <string>localStorage.getItem('username');
		return of(token);
	}

	/**
	 * Get status if user click on log out now, or not (we need it for the loader text)
	 * @returns {Observable<string>}
	 */
	public getLoggingout(): Observable<string> {
		const token: string = <string>localStorage.getItem('loggingout');
		return of(token);
	}

	public setLoggingoutStatus(): void {
		this.getLoggingout().subscribe(value => {
			this.loggingoutStatus = value;
		});
	}

	/**
	 * Set status if user click on log out now, or not (we need it for the loader text)
	 * @returns {Observable<string>}
	 */
	public setLoggingout(token: string): TokenStorage {
		localStorage.setItem('loggingout', token);

		return this;
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessToken(accessData: AccessData): TokenStorage {
		localStorage.setItem('accessToken', accessData.accessToken);
		localStorage.setItem('accessTokenExpiration', accessData.expiration);
		return this;
	}

	/**
	 * Set refresh token
	 * @returns {TokenStorage}
	 */
	public setRefreshToken(refreshToken: any): TokenStorage {
		localStorage.setItem('refreshToken', refreshToken?.token);
		localStorage.setItem('refreshTokenExpiration', refreshToken?.expiration);
		return this;
	}

	/**
	 * Set user roles
	 * @param roles
	 * @returns {TokenStorage}
	 */
	public setUserRoles(roles: any): any {
		if (roles != null) {
			localStorage.setItem('userRoles', JSON.stringify(roles));
		}

		return this;
	}

	/**
	 * Set username
	 * @returns {TokenStorage}
	 */
	public setUsername(username: string): TokenStorage {
		localStorage.setItem('username', username);

		return this;
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		localStorage.removeItem('ssoToken');
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('userRoles');
		localStorage.removeItem('username');
		// localStorage.removeItem('refreshTokenExpiration');
		// localStorage.removeItem('accessTokenExpiration');
	}

	/**
 * Logout
 */
	public logout(refresh?: boolean): void {
		this.setLoggingout('true');
		this.clear();
	}
}

import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'oct-big-frame-text-form-control',
	templateUrl: './big-frame-text-form-control.component.html',
	styleUrls: ['./big-frame-text-form-control.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => BigFrameTextFormControlComponent),
			multi: true
		}
	],
})
export class BigFrameTextFormControlComponent implements OnInit, ControlValueAccessor {
	@ViewChild('textArea') textArea: ElementRef;
	@Input() title: string;
	@Input() data: any;
	@Input() key: string;
	@Input() form: FormGroup;
	@Input() formControl: FormControl;
	public val: string;
	public isFocused = false;

	constructor() {
	}

	ngOnInit(): void {
	}

	onChange: any = () => {
	}

	onTouch: any = () => {
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	writeValue(value): void {
	}

	onFocusOut() {
		this.isFocused = false;
		this.val = this.textArea.nativeElement.value;
		this.onChange({key: this.key, value: this.formControl.value, data: this.data});
		this.formControl.patchValue({key: this.key, value: this.val, data: this.data});
	}
}

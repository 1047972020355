import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowMoreComponent } from '@shared/oct-components/show-more/show-more.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [ShowMoreComponent],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [ShowMoreComponent]
})
export class ShowMoreModule {
}

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { QuickSearchModel } from '@core/models/quick-search.model';

@Injectable()
export class QuickSearchService {
	API_ENDPOINT: any = 'clientQueries/getSearchRes';

	constructor(
		private http: HttpClient,
		private httpUtilsService: HttpUtilsService
	) { }

	search(data_req: QuickSearchModel): Observable<any> {
		// return this.httpUtilsService.postData(this.API_ENDPOINT, data_req);
		return this.httpUtilsService.postData(this.API_ENDPOINT, data_req).pipe(
			map((result: any) => {
				result = JSON.parse(result.res);
				return result;
			})
		);
	}

	searchInObg(data_req): Observable<any> {
		return this.httpUtilsService.postDataOBG(data_req);
	}
}

import { abgActionTypes } from '@main/adc/adc-store/adc/actions';
import { AdcState } from '@main/adc/adc-store/adc';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { searchActionsTypes } from '@store/search/actions';
import { abgCommonActionsTypes } from '@main/adc/adc-store/actions';
import { IPost, IPostState } from '@main/adc/adc-store';

const initialState: AdcState = {
	selected_obj_id: null,
	selected_obj: null,
	results: [],
	linkSearchValue: '',
	sort: 'Asc',
	filtered: false,
	filterOpen: localStorage.getItem('adcFilter') ? localStorage.getItem('adcFilter') === 'true' : true,
};

export function reducer(state: AdcState = initialState, action) {
	const _state = {...state};
	switch (action.type) {
		case abgActionTypes.LOADING:
			return {..._state, loading: action.payload};
		case abgActionTypes.RESET_SELECTED:
			return {...initialState};
		case abgActionTypes.RESET_ABG_RESULTS:
			return {..._state, results: [], total: 0};
		case searchActionsTypes.SET_ADVANCED_SEARCH:
			return {..._state, results: [], total: 0};

		case abgActionTypes.UPDATE_SELECTED_ABG:
			return {..._state, selected_obj: action.payload};

		case abgActionTypes.UPDATE_ABG_ITEM_SUCCESS:
			return {..._state, selected_obj: {...action.payload}};

		case abgActionTypes.UPDATE_SELECTED_ABG_ID:
			return {..._state, selected_obj_id: action.payload};

		case abgActionTypes.GET_ABG_DATA_AND_TOTAL_SUCCESS:
			return {
				..._state,
				results: action.payload.data,
				total: action.payload.total,
			};
		case abgActionTypes.GET_ABG_DATA_SUCCESS:
			return {..._state, results: action.payload};
		case abgActionTypes.GET_ABG_TOTAL_SUCCESS:
			return {..._state, total: action.payload};
		case abgActionTypes.SET_ABG_FILTER:
			const filters = {..._state, ...action.payload};
			return {..._state, filters};

		case abgActionTypes.SET_ABG_TAGS:
			return {
				..._state,
				tags: action.payload,
			};

		case abgActionTypes.SET_LINK_SEARCH_VALUE:
			return {..._state, linkSearchValue: action.payload};
		case abgActionTypes.SET_ABG_TAG:
			_state[action.payload.source] = {..._state[action.payload.source]};
			return _state;
		case abgActionTypes.SET_ABG_ITEM_PROPERTY:
			_state.selected_obj[action.payload.key] = action.payload.value;
			return _state;

		case abgCommonActionsTypes.SUSPEND_SUCCESS:
			return {..._state, selected_obj: {..._state.selected_obj, sTATUS: action.payload.suspend}};

		case abgActionTypes.GET_ABG_ITEM_SUCCESS:
			return {
				..._state,
				selected_obj_id: action.payload.item ? action.payload.item.oBJECT_ID : null,
				selected_obj: {
					...action.payload.item,
					aVG: action.payload.aVG,
					rATE_COUNT: action.payload.rATE_COUNT,
					rATES: action.payload.rATES,
				}
			};

		case abgActionTypes.SET_ABG_ITEM_RATE_SUCCESS:
			return {
				..._state,
				selected_obj: {
					...state.selected_obj,
					rATE: action.payload,
				}
			};

		case abgActionTypes.GET_RATES_SUCCESS:
			return {..._state, selected_obj: {...state.selected_obj, rATES: action.payload}};

		case abgActionTypes.GET_ABG_ITEM_AVG_RATE_SUCCESS:
			return {
				..._state,
				selected_obj: {...state.selected_obj, aVG: action.payload.aVG, rATE_COUNT: action.payload.rATE_COUNT}
			};

		case abgCommonActionsTypes.ADD_STATUS_SUCCESS:
			return {..._state, selected_obj: {..._state.selected_obj, aBG_STATUS: action.payload}};

		case abgActionTypes.SET_SENSITIVE_SUCCESS:
			return {..._state, selected_obj: {..._state.selected_obj, iS_SENSITIVE: action.payload}};

		case abgActionTypes.SET_SORT:
			return {..._state, sort: action.payload};

		case abgActionTypes.SET_SEARCH_FILTERS:
			return {
				...state,
				searchFilters: {
					...state.searchFilters,
					...action.payload,
				}
			};

		case abgActionTypes.SET_FILTERED:
			return {...state, filtered: action.payload};

		case abgActionTypes.SET_DETAILS_LOADER:
			return {...state, detailLoader: action.payload};

		case abgActionTypes.SET_FILTER_STATE:
			return {...state, filterOpen: action.payload};

		default:
			return _state;
	}
}

// Posts
export const postAdapter: EntityAdapter<IPost> = createEntityAdapter<IPost>({
	selectId: (model: IPost) => model.cOMMENT_ID,
});

const postInitialState: IPostState = postAdapter.getInitialState({
	active: false,
});

export function postsReducer(state: IPostState = postInitialState, action) {
	const _state = {...state};

	switch (action.type) {
		case abgActionTypes.SET_POSTS_DRAWER_STATUS:
			return {..._state, active: action.payload};
		case abgActionTypes.GET_POSTS_SUCCESS:
			return postAdapter.addAll(action.payload, _state);
		default:
			return _state;
	}
}

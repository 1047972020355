import { Action } from '@ngrx/store';

export enum abgCommonActionsTypes {
    ENABLE_SEARCH_FILTERS = '[ABG-COMMON] show search filters panel',

    SET_ABG_COMMON_FILTERS = '[ABG-COMMON] set abg common filter',
    SET_ABG_FILTERS_STATUS = '[ABG-COMMON] set abg filter status open/close',

    SET_ABG_ITEM = '[ABG-COMMON] set abg item object',
    SET_ABG_ITEM_SUCCESS = '[ABG-COMMON] set abg item was a success',
    SET_ABG_ITEM_FAILURE = '[ABG-COMMON] set abg item was failed',

    GET_STATUS_LIST = '[ABG-COMMON] get status list',
    GET_STATUS_LIST_SUCCESS = '[ABG-COMMON] get status list success',
    GET_STATUS_LIST_FAILURE = '[ABG-COMMON] get status list failure',

    ADD_STATUS = '[ABG] add status',
    ADD_STATUS_SUCCESS = '[ABG] add status success',
    ADD_STATUS_FAILURE = '[ABG] add status failure',

    UPDATE_STATUS = '[ABG] update status item',
    UPDATE_STATUS_SUCCESS = '[ABG] update status item success',
    UPDATE_STATUS_FAILURE = '[ABG] update status item failure',

    DELETE_STATUS = '[ABG] delete status item',
    DELETE_STATUS_SUCCESS = '[ABG] delete status item success',
    DELETE_STATUS_FAILURE = '[ABG] delete status item failure',

    SUSPEND = '[ABG-COMMON] suspend abg item',
    SUSPEND_SUCCESS = '[ABG-COMMON] suspend abg item success',
    SUSPEND_FAILURE = '[ABG-COMMON] suspend abg item failure',

    SET_ABG_ITEM_SUSPEND = '[ABG-COMMON] set abg suspend',
    SET_ABG_ITEM_SUSPEND_SUCCESS = '[ABG-COMMON] set abg suspend success',
    SET_ABG_ITEM_SUSPEND_FAILURE = '[ABG-COMMON] set abg suspend failure',

    POST = '[ABG-COMMON] post',
    POST_SUCCESS = '[ABG-COMMON] post success',
    POST_FAILURE = '[ABG-COMMON] post failure',

    GET_TAGS = '[ABG-COMMON] get tags',
    GET_TAGS_SUCCESS = '[ABG-COMMON] get tags success',
    GET_TAGS_FAILURE = '[ABG-COMMON] get tags failure',

    GET_ASSET_TYPES = '[ABG-COMMON] get asset types',
    GET_ASSET_TYPES_SUCCESS = '[ABG-COMMON] get asset types success',
    GET_ASSET_TYPES_FAILURE = '[ABG-COMMON] get asset types failure',

    GET_CUSTOM_ASSET_TYPES = '[ABG-COMMON] get custom asset types',
    GET_CUSTOM_ASSET_TYPES_SUCCESS = '[ABG-COMMON] get custom asset types success',
    GET_CUSTOM_ASSET_TYPES_FAILURE = '[ABG-COMMON] get custom asset types failure',
}

export class GetCustomAssetTypes implements Action {
    readonly type = abgCommonActionsTypes.GET_CUSTOM_ASSET_TYPES;
}

export class GetCustomAssetTypesSuccess implements Action {
    readonly type = abgCommonActionsTypes.GET_CUSTOM_ASSET_TYPES_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetCustomAssetTypesFailure implements Action {
    readonly type = abgCommonActionsTypes.GET_CUSTOM_ASSET_TYPES_FAILURE;

    constructor(public payload: any) {
    }
}

export class GetAssetTypes implements Action {
    readonly type = abgCommonActionsTypes.GET_ASSET_TYPES;
}

export class GetAssetTypesSuccess implements Action {
    readonly type = abgCommonActionsTypes.GET_ASSET_TYPES_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetAssetTypesFailure implements Action {
    readonly type = abgCommonActionsTypes.GET_ASSET_TYPES_FAILURE;

    constructor(public payload: any) {
    }
}

export class EnableSearchFilters implements Action {
    readonly type = abgCommonActionsTypes.ENABLE_SEARCH_FILTERS;

    constructor(public payload: any) {
    }
}

export class GetTags implements Action {
    readonly type = abgCommonActionsTypes.GET_TAGS;

    constructor(public payload: any) {
    }
}

export class GetTagsSuccess implements Action {
    readonly type = abgCommonActionsTypes.GET_TAGS_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetTagsFailure implements Action {
    readonly type = abgCommonActionsTypes.GET_TAGS_FAILURE;

    constructor(public payload: any) {
    }
}

export class SetAbgItem implements Action {
    readonly type = abgCommonActionsTypes.SET_ABG_ITEM;

    constructor(public payload: any) {
    }
}

export class SetAbgItemSuccess implements Action {
    readonly type = abgCommonActionsTypes.SET_ABG_ITEM_SUCCESS;

    constructor(public payload: any) {
    }
}

export class SetAbgItemFailure implements Action {
    readonly type = abgCommonActionsTypes.SET_ABG_ITEM_FAILURE;

    constructor(public payload: any) {
    }
}

export class Post implements Action {
    readonly type = abgCommonActionsTypes.POST;

    constructor(public payload: any) {
    }
}

export class PostSuccess implements Action {
    readonly type = abgCommonActionsTypes.POST_SUCCESS;

    constructor(public payload: any) {
    }
}

export class PostFailure implements Action {
    readonly type = abgCommonActionsTypes.POST_FAILURE;

    constructor(public payload: any) {
    }
}

export class SetAdcFilter implements Action {
    readonly type = abgCommonActionsTypes.SET_ABG_COMMON_FILTERS;

    constructor(public payload: any) {
    }
}

export class SetAbgFilterStatus implements Action {
    readonly type = abgCommonActionsTypes.SET_ABG_FILTERS_STATUS;

    constructor(public payload: any) {
    }
}

export class SetSuspend implements Action {
    readonly type = abgCommonActionsTypes.SUSPEND;

    constructor(public payload: any) {
    }
}

export class SetSuspendSuccess implements Action {
    readonly type = abgCommonActionsTypes.SUSPEND_SUCCESS;

    constructor(public payload: any) {
    }
}

export class SetSuspendFailure implements Action {
    readonly type = abgCommonActionsTypes.SUSPEND_FAILURE;

    constructor(public payload: any) {
    }
}

export class SetAbgItemSuspend implements Action {
    readonly type = abgCommonActionsTypes.SET_ABG_ITEM_SUSPEND;

    constructor(public payload: any) {
    }
}

export class SetAbgItemSuspendSuccess implements Action {
    readonly type = abgCommonActionsTypes.SET_ABG_ITEM_SUSPEND_SUCCESS;

    constructor(public payload: any) {
    }
}

export class SetAbgItemSuspendFailure implements Action {
    readonly type = abgCommonActionsTypes.SET_ABG_ITEM_SUSPEND_FAILURE;

    constructor(public payload: any) {
    }
}

export class GetStatusList implements Action {
    readonly type = abgCommonActionsTypes.GET_STATUS_LIST;
}

export class GetStatusListSuccess implements Action {
    readonly type = abgCommonActionsTypes.GET_STATUS_LIST_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetStatusListFailure implements Action {
    readonly type = abgCommonActionsTypes.GET_STATUS_LIST_FAILURE;

    constructor(public payload: any) {
    }
}

export class AddStatus implements Action {
    readonly type = abgCommonActionsTypes.ADD_STATUS;

    constructor(public payload: any) {
    }
}

export class AddStatusSuccess implements Action {
    readonly type = abgCommonActionsTypes.ADD_STATUS_SUCCESS;

    constructor(public payload: any) {
    }
}

export class AddStatusFailure implements Action {
    readonly type = abgCommonActionsTypes.ADD_STATUS_FAILURE;

    constructor(public payload: any) {
    }
}

export class UpdateStatus implements Action {
    readonly type = abgCommonActionsTypes.UPDATE_STATUS;

    constructor(public payload: any) {
    }
}

export class UpdateStatusSuccess implements Action {
    readonly type = abgCommonActionsTypes.UPDATE_STATUS_SUCCESS;

    constructor(public payload: any) {
    }
}

export class UpdateStatusFailure implements Action {
    readonly type = abgCommonActionsTypes.UPDATE_STATUS_FAILURE;

    constructor(public payload: any) {
    }
}

export class DeleteStatus implements Action {
    readonly type = abgCommonActionsTypes.DELETE_STATUS;

    constructor(public payload: any) {
    }
}

export class DeleteStatusSuccess implements Action {
    readonly type = abgCommonActionsTypes.DELETE_STATUS_SUCCESS;

    constructor(public payload: any) {
    }
}

export class DeleteStatusFailure implements Action {
    readonly type = abgCommonActionsTypes.DELETE_STATUS_FAILURE;

    constructor(public payload: any) {
    }
}

export type abgCommonActions =
    GetAssetTypes |
    GetAssetTypesSuccess |
    EnableSearchFilters |
    GetTags |
    GetTagsSuccess |
    GetTagsFailure |
    SetAbgItem |
    SetAbgItemSuccess |
    SetAbgItemFailure |
    Post |
    PostSuccess |
    PostFailure |
    SetSuspend |
    SetSuspendSuccess |
    SetSuspendFailure |
    SetAbgItemSuspend |
    SetAbgItemSuspendSuccess |
    SetAbgItemSuspendFailure |
    GetStatusList |
    GetStatusListSuccess |
    GetStatusListFailure |
    AddStatus |
    AddStatusSuccess |
    AddStatusFailure |
    UpdateStatus |
    UpdateStatusSuccess |
    UpdateStatusFailure |
    DeleteStatus |
    DeleteStatusSuccess |
    DeleteStatusFailure |
    SetAdcFilter |
    SetAbgFilterStatus |
    GetCustomAssetTypes |
    GetCustomAssetTypesSuccess |
    GetCustomAssetTypesFailure;

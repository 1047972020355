<div class="row-title">
	{{title}}
	<span *ngIf="formControl.hasError('required')">*</span>
</div>
<mat-form-field [style.width.%]="100" appearance="outline" id="list-form-{{key}}">
	<mat-select panelClass="item-row" [formControl]="formControl">
		<mat-select-trigger>
			{{formControl.value?.value?.FULL_NAME}}
		</mat-select-trigger>
		<mat-option *ngFor="let option of listOptions; let i = index"
					class="row-option"
					id="avatar-{{i}}"
					(click)="onSelected(option)"
					[value]="{key: this.key, value: option, data: this.data}">
			<oct-avatar-item [option]="option" [hide]="false"></oct-avatar-item>
		</mat-option>
	</mat-select>
</mat-form-field>

<div *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)"
	 [ngClass]="{'has-danger': formControl.invalid && (formControl.dirty || formControl.touched)}"
	 class="error-msg-container">
	<div *ngIf="formControl.hasError('required')" class="form-control-feedback">
		{{title}} is required
	</div>
	<div *ngIf="formControl.hasError('minlength')" class="form-control-feedback">
		{{title}} must be at least 2 characters long
	</div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonsTheme } from '@shared/enums/searchType';

@Component({
	selector: 'oct-round-button',
	templateUrl: './round-button.component.html',
	styleUrls: ['./round-button.component.scss']
})
export class RoundButtonComponent implements OnInit {
	@Input() id: string;
	@Input() text: string;
	@Input() fontSize: string;
	@Input() disabled: boolean = false;
	@Input() theme: ButtonsTheme = ButtonsTheme.redTheme;
	@Output() clicked = new EventEmitter<boolean>();

	constructor() {
	}

	ngOnInit(): void {
	}

	public onClick(e) {
		this.clicked.emit(true);
	}
}

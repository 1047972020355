import { ILayoutState, Status } from '@store/layout/index';
import { layoutActions } from '@store/layout/actions';

const initialState: ILayoutState = {
  quickSideBar: {
    status: Status.close,
  },
  navStatus: Status.close,
};

export function layoutReducer(state: ILayoutState = initialState, action): ILayoutState {
  switch (action.type) {
    case layoutActions.RESET_SIDE_BAR:
      return {...initialState};
    case layoutActions.UPDATE_SIDE_BAR:
      return {...state, quickSideBar: action.payload};
    case layoutActions.UPDATE_NAV_MENU_STATUS:
      return {...state, navStatus: action.payload};
    case layoutActions.UPDATE_TOP_HEADER:
      return {...state, topHeader: action.payload};
    default:
      return state;
  }
}

<div mPortlet #mPortlet [options]="options" class="m-portlet m-portlet--rounded-force height"
	 [ngClass]="{'m-portlet__head--discovery--ETL' : module == 'ETL',
		 'm-portlet__head--discovery--DATABASE' : module === 'DB' || module == 'FILES',
		 'm-portlet__head--discovery--REPORT' : module == 'REPORT',
		 'm-portlet__head--discovery--ANALYSIS' : module == 'ANALYSIS',
		 'm-portlet__head--OBG' : location === 'adc'}">
	<div class="m-portlet__head m-portlet__head--fit" #mPortletHead>
        <div class="main-header">
            <div class="m-portlet__head-progress">
                <mat-progress-bar *ngIf="(loader.progress$ | async) === 0 && (loading$ | async)"
                                  mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="m-portlet__head-caption">
                <ng-content select="[mPortletHeadTitle]"></ng-content>
            </div>
            <div #mPortletHeadTools class="m-portlet__head-tools">
                <ng-content select="[mPortletHeadTools]"></ng-content>
            </div>
        </div>
        <div class="sub-header">
            <ng-content select="[mPortletSubHead]"></ng-content>
        </div>
    </div>
    <div #mPortletBody class="m-portlet__body bodyHeight">
        <ng-content select="[mPortletBody]"></ng-content>
    </div>
    <div #mPortletFooter class="m-portlet__foot">
        <ng-content select="[mPortletFooter]"></ng-content>
    </div>
</div>

<ng-container *ngIf="!!toolbarItems">
    <div (clickOutside)="close()" *ngIf="toolbarItems.length !== 0" class="tool-item-container">
        <div *ngFor="let item of toolbarItems" [ngStyle]="{margin: item.title === 'Search' ? '0px' : '0px 7px'}"
             [ngSwitch]="item.buttonType"
             class="tool-item" matTooltip="{{item.toolTip}}">
            <oct-menu-overlay *ngSwitchCase="buttonType.menuOverlay"
                              [contentComponent]="item.component"
                              [contentData]="item.componentData"></oct-menu-overlay>
            <oct-menu-toolbar-button *ngSwitchCase="buttonType.menu" [item]="item"></oct-menu-toolbar-button>
            <oct-simple-toolbar-button *ngSwitchDefault [item]="item"></oct-simple-toolbar-button>
        </div>
    </div>
</ng-container>

<ng-container #componentsContainer></ng-container>

<!--slot for slider template-->
<ng-content></ng-content>

import { Pipe, PipeTransform } from '@angular/core';
import { filterFunctions } from '@shared/types/advanced-search';
import { select, Store } from '@ngrx/store';
import { SearchFacadeService } from '@store/services/search-facade.service';
import { SearchType } from '@shared/enums/searchType';
import { selectSearchType, selectSearchValue } from '@store/search';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

@Pipe({
  name: 'searchHighlight'
})

export class SearchHighlightPipe implements PipeTransform {
  private searchType;
  private searchValue;

  constructor(private store: Store<any>, private searchFacade: SearchFacadeService) {
    const a = this.store.pipe(select(selectSearchType));
    const b = this.store.pipe(select(selectSearchValue));

    combineLatest([a, b])
      .pipe(
        take(1),
      )
      .subscribe(results => {
        this.searchType = results[0];
        this.searchValue = results[1];
      });
  }

  transform(value: string): any {
    if (!value) {
      return;
    }

    let searchValues = [];

    switch (this.searchType) {
      case SearchType.default:
        this.store
          .pipe(
            select(selectSearchValue)
          )
          .subscribe(searchValue => {
            searchValues = [{
              key: filterFunctions.contains,
              value: searchValue,
            }];
          });
        break;
      case SearchType.advanced:
        const advancedSearchValues = this.searchValue;
        searchValues = [
          {
            key: advancedSearchValues.filter1,
            value: advancedSearchValues.filter1val,
          },
          {
            key: advancedSearchValues.filter2,
            value: advancedSearchValues.filter2val,
          },
          {
            key: advancedSearchValues.filter3,
            value: advancedSearchValues.filter3val,
          },
        ];
        break;
    }

    searchValues
      .forEach(item => {
        if (item.value) {
          value = this.highlight(value, item.value);
        }
      });

    return value.replace(/\n/g, '</br>');
  }

  private escapeRegExp(str) {
    const specials = ['-', '[', ']', '/', '{', '}', '(', ')', '*', '+', '?', '.', '\\', '^', '$', '|'];
    const regex = RegExp(`[${specials.join('\\')}]`);
    return str.replace(regex, '\\$&');
  }

  private replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find)), replace);
  }

  private highlight(value: string, searchTerm: string) {
    let result = [];

    const searchTermOriginal = searchTerm.trim().slice(0);
    let searchTermFix = searchTerm;
    searchTermFix = searchTermFix.replace(/\\/g, '\\\\');
    searchTermFix = searchTermFix.replace(/-/g, '\\-');
    searchTermFix = searchTermFix.replace(/\[/g, '\\[');
    searchTermFix = searchTermFix.replace(/]/g, '\\]');
    searchTermFix = searchTermFix.replace(/\//g, '\\/');
    searchTermFix = searchTermFix.replace(/\{/g, '\\{');
    searchTermFix = searchTermFix.replace(/}/g, '\\}');
    searchTermFix = searchTermFix.replace(/\(/g, '\\(');
    searchTermFix = searchTermFix.replace(/\)/g, '\\)');
    searchTermFix = searchTermFix.replace(/\*/g, '\\*');
    searchTermFix = searchTermFix.replace(/\+/g, '\\+');
    searchTermFix = searchTermFix.replace(/\?/g, '\\?');
    searchTermFix = searchTermFix.replace(/\./g, '\\.');
    searchTermFix = searchTermFix.replace(/\^/g, '\\^');
    searchTermFix = searchTermFix.replace(/\$/g, '\\$');
    searchTermFix = searchTermFix.replace(/\|/g, '\\|');

    const regexp = new RegExp(searchTermFix.toLowerCase(), 'g');

    const indexList = [];

    const _word = value.trim();
    while ((result = regexp.exec(_word.toLowerCase())) !== null) {
      const index = result['index'];
      indexList.push(index);
    }

    indexList.reverse().forEach(index => {
      const a = index === 0 ? '' : value.slice(0, index);
      const b = value.slice(index, index + searchTerm.length);
      const c = index + searchTerm.length === value.length ? '' : value.slice(index + searchTerm.length);
      value = a + `<mark>${b}</mark>` + c;
    });

    return value;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'thousandsComma'
})
export class ThousandsCommaPipe implements PipeTransform {

	transform(value: any, ...args: any[]): any {
		return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

}

import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpUtilsService } from '@core/services/http-utils.service';

@Injectable({
	providedIn: 'root'
})
export class DrillBetweenMapsService {
	private API_ENDPOINT = 'lineageGener/runMapQuery';
	public dialogRef: any;

	constructor(
		private httpUtilsService: HttpUtilsService
	) { }

	public getMapDrillData(colToColMapDrill: any): Observable<any> {
		if (typeof colToColMapDrill.containerObjName == 'string') {
			colToColMapDrill.containerObjName = colToColMapDrill.containerObjName.replace(/'/g, '\'\'').replace(/&/g, '%26');
		}
		if (typeof colToColMapDrill.containerObjPath == 'string') {
			colToColMapDrill.containerObjPath = colToColMapDrill.containerObjPath.replace(/'/g, '\'\'').replace(/&/g, '%26');
		}

		return this.httpUtilsService.postData(this.API_ENDPOINT, colToColMapDrill, true).pipe(
			map((result: any) => {
				return result.res;
			})
		)
	}
}

<div class="checkbox-list-order">
    <div *ngFor="let permission of permissions; let i = index" class="checkbox-field">
        <input (click)="onClick(permission)" [attr.disabled]="permission.disabled ? 'disabled' : null"
               [checked]="permission.checked"
               [value]="permission.title"
               class="form-control m-input oct-input" id="permissionCheck_{{i}}"
               type="checkbox">
        {{permission.title === 'Data Catalog Insights' ? 'Insights' : (permission.title === 'Automated Data Catalog' ? 'Knowledge Hub' : permission.title) }}
    </div>
</div>

<div class="modal-header">
	<span class="modal-title">{{data.dialogHeader}}</span>
	<mat-icon svgIcon="close-icon" (click)="drillBetweenMapsService.dialogRef.close()" class="close-icon"></mat-icon>
</div>
<div class="search-wrapper flex" flex-end>
	<m-search-input (clear)="filterResult('')"
					(keyPress)="filterResult($event)"
					tooltip="{{'GENERAL.TOOLTIP_MSG' | translate}}">
	</m-search-input>
</div>
<div class="modal-body">
	<mat-radio-group (change)="onChange($event)" [value]="selectedIndex">
		<div *ngFor="let result of filterResults; let i = index"
			 [ngClass]="{'selected-content': selectedIndex === i}"
			 class="m--padding-10">
			<mat-radio-button [style.width.%]="100" [value]="i">
				<div *ngFor="let item of result.infoData | keyvalue; let j = index" class="flex content-text">
					<b>{{item.value.key}}:</b>
					<div
						[innerHTML]="moduleUtilsService.shortToLongToolNameToDisplay(item.value.value) | highlight: searchTerm"
						class="no-spaces elipsiss"
						matTooltip="{{item.value.value}}"
						matTooltipClass="extend-tooltip"></div>
				</div>
			</mat-radio-button>
		</div>
	</mat-radio-group>
</div>
<div class="modal-footer">
	<oct-round-button (clicked)="submit()" id="drill-between-maps" fontSize="1.2rem"
					  class="drill-btn" matTooltip="Drill" text="Drill"></oct-round-button>
</div>

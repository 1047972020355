<!-- BEGIN: Brand -->
<!--<div class="m-stack__item m-brand">-->
<div class="m-stack m-stack--ver m-stack--general">
    <!--    <div class="m-stack__item m-stack__item&#45;&#45;middle m-brand__logo">-->
    <!--        <oct-logo></oct-logo>-->
    <!--    </div>-->
    <div class="m-stack__item m-stack__item--middle m-brand__tools">
        <!-- BEGIN: Left Aside Minimize Toggle -->
        <!--		<a (click)="asideToggle()" *ngIf="menuAsideMinimizToggle"-->

        <div class="brand-wrapper">
            <div (click)="asideToggle()" [ngClass]="{'menu-open': layoutConfigService.isOpen}" class="toggle-wrapper">
                <div class="toggle-line"></div>
                <div class="toggle-line"></div>
                <div class="toggle-line"></div>
            </div>

            <!--			<a (click)="asideToggle()"-->
            <!--			   class="brand-icon m-brand__toggler m-brand__toggler&#45;&#45;left m&#45;&#45;visible-desktop-inline-block"-->
            <!--			   clickOutSideExclude-->
            <!--			   id="m_aside_left_minimize_toggle" mMenuAsideToggle>-->
            <!--				<span></span>-->
            <!--			</a>-->

            <div [ngClass]="{'menu-open': layoutConfigService.isOpen}" class="logo-wrapper">
                <img alt="" src="assets/app/img/logos/Octopai_Logo.png">
                <div *ngIf="layoutConfigService.isOpen" class="brand-title">OCTOPAI</div>
            </div>
        </div>


        <!-- END -->

        <!-- BEGIN: Responsive Aside Left Menu Toggler -->
        <!--		<a (click)="asideToggle()" *ngIf="menuAsideDisplay"-->
        <!--		   class="m-brand__icon m-brand__toggler m-brand__toggler&#45;&#45;left m&#45;&#45;visible-tablet-and-mobile-inline-block"-->
        <!--		   id="m_aside_left_offcanvas_toggle">-->
        <!--			<span></span>-->
        <!--		</a>-->
        <!-- END -->

        <!-- BEGIN: Responsive Header Menu Toggler -->
        <!-- [BUG] issue with *ngIf="menuHeaderDisplay", mOffcanvas trigger first before this DOM exist -->
        <!--<a *ngIf="menuHeaderDisplay" (click)="asideToggle()" id="m_aside_header_menu_mobile_toggle" href="javascript:;" class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
            <span></span>
        </a>-->
        <!-- END -->

        <!-- BEGIN: Topbar Toggler -->
        <!--		<a (click)="clickTopbarToggle($event); asideToggle();"-->
        <!--		   class="m-brand__icon m&#45;&#45;visible-tablet-and-mobile-inline-block"-->
        <!--		   id="m_aside_header_topbar_mobile_toggle">-->
        <!--			<i class="flaticon-more"></i>-->
        <!--		</a>-->
        <!-- BEGIN: Topbar Toggler -->
    </div>
</div>
<!--</div>-->
<!-- END: Brand -->

import { Observable } from 'rxjs';

export interface IToolBarItem {
	id?: string;
	name: string;
	nameInActive?: string;
	iconSize?: number;
	title?: string;
	text?: string;
	click?: (el, toolBarItem) => void;
	active?: boolean;
	toolTip?: string;
	badge?: boolean;
	width?: string;
	height?: string;
	notification$?: Observable<any>;
	disabled?: boolean;
	buttonType: ButtonType;
	items?: any[];
	icon?: any;
	component?: any;
	componentData?: any;
	visible?: boolean;
}

export interface IToolBarItemComponent {
	name: string;
	component: any;
	icon?: any;
	ref?: any;
	data?: any;
	click?: (el) => void;
	toolTip?: string;
}

export enum ButtonType {
	regular,
	menu,
	menuOverlay,
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByIndex'
})
export class SortByIndexPipe implements PipeTransform {

	transform(array: any, field: string): any[] {
		if (!Array.isArray(array)) {
			return;
		}
		return array.sort((a: any, b: any) => {
			if (a[field] < b[field]) {
				return -1;
			} else if (a[field] > b[field]) {
				return 1;
			} else {
				return 0;
			}
		});
	}
}

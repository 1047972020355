import { Component, OnDestroy, OnInit } from '@angular/core';
import { SliderOptions } from '@shared/enums/slider-options.enum';

@Component({
	selector: 'oct-zoom-slider',
	templateUrl: './zoom-slider.component.html',
	styleUrls: ['./zoom-slider.component.scss']
})
export class ZoomSliderComponent  {

	public click;
	public sliderOptions: typeof SliderOptions = SliderOptions;

	constructor() {
	}
}

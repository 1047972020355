import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { TokenStorage } from '@core/auth/token-storage.service';
import { CommonState } from '@store/common';
import * as CommonActions from '@store/common/actions';
import { Store } from '@ngrx/store';
import { Locations } from '@shared/enums/locations.enum';
import { environment } from '@environments/environment';

@Injectable()
export class HttpUtilsService {
  url: string;
  controller: any;

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorage,
    private router: Router,
    private storeCommon: Store<CommonState>,
  ) {
  }

  public isAuthorized(): boolean {
    const token: string = <string>localStorage.getItem('accessToken');
    return token != null;
  }

  getData(url: string, body?: any, contentType?: boolean) {
    const is_auth: boolean = this.isAuthorized();

    if (!contentType && body) {
      body = this.formatToUrlencoded(body);
    }

    return this.http.get(`${environment.apiUrl}${url}`, {
      headers: {
        'Content-Type': contentType ? 'application/json' : 'application/x-www-form-urlencoded',
      }
    })
      .pipe(
        catchError(err => of(this.handleError('ERROR http GET: ', err))) ///
      );
  }

  postData(url: string, body?: any, contentType?: boolean): Observable<any> {
    if (!contentType && body) {
      body = this.formatToUrlencoded(body);
    }

    return this.http.post(`${environment.apiUrl}${url}`, body, {
      headers: {
        'Content-Type': contentType ? 'application/json' : 'application/x-www-form-urlencoded',
      }
    })
      .pipe(
        catchError(err => of(this.handleError('ERROR http GET: ', err))));
  }

  postDataOBG(payload?: any): Observable<any> {
    return this.post(`graphql`, payload)
      .pipe(
        tap(response => {
          if (response.Error) {
            this.tokenStorage.logout(true);
            throw new Error('Method not implemented.');
          }
        }),
        catchError(error => {
          console.error(error.error);
          if (error._body?.indexOf('token') > -1) {
            this.tokenStorage.logout(true);
          }
          throw new Error('Method not implemented.');
        })
      );
  }

  // change data format - to sending as string and not as json
  formatToUrlencoded(data) {
    let obj: string = '';
    Object.entries(data).forEach(
      ([key, value]) => obj += (key + '=' + value + '&')
    );
    obj = obj.slice(0, -1);
    return obj;
  }

  handleError(message: string, err: HttpErrorResponse): any {
    if (err.status === 401 || err.statusText.indexOf('token') > -1) {
      this.tokenStorage.logout(true);
    }
    // throw new Error('Method not implemented.');
  }

  public setLocationAndSubLocation() {
    let _location = 'lineage';
    let _subLocation;

    this.url = (this.router.url.substr(1) === '' ? 'lineage' : this.router.url.substr(1));
    _location = this.url;

    if (this.url.toLowerCase().startsWith(Locations.map)) {
      // _subLocation = 'Map';
      _location = Locations.map;
    } else if (this.url.toLowerCase().startsWith('lineageschema')) {
      // _subLocation = 'LineageSchema';
      _location = Locations.lineageSchema;
    } else if (this.url.toLowerCase().startsWith(Locations.adcDashboard)) {
      _location = Locations.adcDashboard;
    } else if (this.url.toLowerCase().startsWith(Locations.adc)) {
      // _subLocation = 'AbgID';
      _location = Locations.adc;
    } else if (this.url.toLowerCase().startsWith(Locations.discovery)) {
      _location = Locations.discovery;
    } else if (this.url.toLowerCase().startsWith('compare')) {
      if (this.url.toLowerCase().indexOf('compare-etls') > -1) {
        _location = 'compare-etls';
      } else {
        _location = 'compare-reports';
      }
      if (document.location.hash.indexOf('vs%') > -1 || document.location.hash.indexOf('[vs]') > -1) {
        _subLocation = 'CompareID';
      }
    }

    this.storeCommon.dispatch(new CommonActions.UpdateLocation(_location));

    return {
      location: _location,
    };
  }

  public errorSimulation(): Observable<any> {
    return throwError(new Error('failed'));
  }

  public postWithStreaming(url: string, body: any) {
    const accessToken = localStorage.getItem('accessToken');
    this.controller = new AbortController();
    const signal = this.controller.signal;

    return new Observable((observer) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        signal,
      };

      fetch(`${environment.apiUrl}${url}`, requestOptions)
        .then(async (response) => {
          const reader = response.body.getReader();
          while (true) {
            const {done, value} = await reader.read();

            if (done) {
              observer.complete();
              break;
            }

            observer.next(new TextDecoder().decode(value));
          }
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            console.log('Fetch aborted');
            observer.error('ABORTED');
          } else {
            console.error('Fetch error:', error);
            observer.error(error);
          }
        });
    });
  }

  delete(url: string, options?: any) {
    return this.http.delete(`${environment.apiUrl}${url}`, options);
  }

  public put(url: string, body?: any, options?: any) {
    return this.http.put(`${environment.apiUrl}${url}`, body, options);
  }

  public post(url: string, body?: any, options?: any) {
    // return this.http.post(`${environment.apiUrl}${url}`, body, options);
    return this.request(url, 'POST', body, options);
  }

  public get(url: string, options?: any) {
    // return this.get(`${environment.apiUrl}${url}`, options);
    return this.request(url, 'GET', null, options);
  }

  public request(url, method: string, body, options?) {
    options = {
      ...options,
      reportProgress: true,
    };

    if (options.params) {
      let params = new HttpParams();
      for (const key in options?.params) {
        const value = options?.params[key];
        params = params.set(key, value);
      }

      options = {
        ...options,
        params,
      };
    }

    if (options.headers) {
      let headers = new HttpHeaders();
      for (const key in options.headers) {
        const value = options.headers[key];
        headers = headers.set(key, value);
      }

      options = {
        ...options,
        headers,
      };
    }

    const req = new HttpRequest(method, `${environment.apiUrl}${url}`, body, options);

    return this.http.request(req)
      .pipe(
        filter(event => event instanceof HttpResponse),
        map((event: HttpResponse<any>) => event.body),
      );
  }
}

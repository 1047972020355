import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { State } from '@store/users/index';
import { Action, createReducer, on } from '@ngrx/store';
import { getNotificationsSuccess, removeNotifications, updateNotificationSuccess } from '@store/notifications/actions';

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (notification: any) => notification?.id,
  sortComparer: false,
});
export const initialState: State = adapter.getInitialState();
const notificationsReducer = createReducer(
  initialState,
  on(getNotificationsSuccess, (state, {result}) => {
    return adapter.addOne(result, state);
  }),
  on(removeNotifications, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(updateNotificationSuccess, (state, {updates}) => {
    return adapter.updateOne(updates, state);
  }),
);

export function reducers(state: State, action: Action) {
  return notificationsReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

<ng-container *ngIf="formControl">
	<div class="row-title">
		{{title}}
		<span *ngIf="formControl.hasError('required')">*</span>
	</div>

	<div class="post-form-field">
	<textarea #textArea (focusin)="isFocused = true" (keyup)="onFocusOut()"
			  [ngClass]="{expand: isFocused, 'input-error': formControl.invalid && (formControl.dirty || formControl.touched)}"
			  matInput octRtl
			  id="textarea-{{key}}">{{formControl.value.value}}</textarea>
	</div>

	<div *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)"
		 [ngClass]="{'has-danger': formControl.invalid && (formControl.dirty || formControl.touched)}"
		 class="error-msg-container">
		<div *ngIf="formControl.hasError('required')" class="form-control-feedback">
			{{title}} is required
		</div>
		<div *ngIf="formControl.hasError('minlength')" class="form-control-feedback">
			{{title}} must be at least 2 characters long
		</div>
	</div>
</ng-container>

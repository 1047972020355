import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { getPublicSettings } from '@store/common';
import { UserRole, UserRoles } from '@shared/enums/user-role.enum';
import { SplashScreenService } from '@core/services/splash-screen.service';
import { UtilsService } from '@core/services/utils.service';
import * as _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { LocationsCollapse } from '@shared/enums/locations.enum';
import { getUser } from '@core/states/user.state';
import { UsersService } from '@main/admin/users/users.service';
import { NgxRolesService } from 'ngx-permissions';

@Component({
  selector: 'oct-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})

export class UserDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('firstInput', {static: true}) firstInput: ElementRef;

  form: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  email: FormControl;
  userRole: FormControl;
  jobTitles: FormControl;
  permissions: FormControl;
  userRoles: UserRoles = UserRole;
  userRoleOptions: string[] = [UserRole.Viewer, UserRole.Editor, UserRole.Admin];
  userPermissions: any[] = [];
  jobTitle: any[] = [];
  loggedInUser: any;
  isAdmin: boolean;
  isNewUser = false;
  formDisabled = false;
  private maxLengthForObjectName: number = 150;
  octomizeChecked: boolean;

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private store: Store<any>,
    private splashScreenService: SplashScreenService,
    private utilsService: UtilsService,
    private usersService: UsersService,
    private rolesService: NgxRolesService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.isAdmin = !!this.rolesService.getRole('ADMIN');
    this.isNewUser = !this.data;
    this.formDisabled = this.data?.STATUS === 'NO';
    this.store
      .pipe(
        select(getPublicSettings),
        take(1),
      )
      .subscribe(publicSettings => {
        this.jobTitle = publicSettings.jobTitle
          .split(';')
          .sort((a, b) => (a.trim() > b.trim() ? 1 : -1))
          .map((item, index) => {
            return {
              id: index,
              name: item.trim(),
              checked: false
            };
          });

        this.userPermissions = publicSettings.showModules
          .split(';')
          .filter(a => a)
          .map(item => {
            return {
              id: 1,
              title: LocationsCollapse[item],
              value: item,
              checked: false
            };
          });
      });
  }

  ngAfterViewInit(): void {
    this.store
      .pipe(
        select(getUser),
        filter(user => !!user),
        take(1),
      )
      .subscribe(user => {
        this.loggedInUser = user;
        this.createFormControls();
        this.createForm();
      });

    this.form.controls.userPermissions?.valueChanges.subscribe((value) => {
      this.octomizeChecked = value.find(item => item.value === 'octomize').checked;
    });
  }

  public setDisabled() {
    if (this.data?.ID) {
      return 'disabled';
    }
    return null;
  }

  public setDisabledAfterLogin() {
    if (this.data?.ID) {
      return 'disabled';
    }
    return null;
  }

  public onSubmit(): void {
    this.splashScreenService.setLoaderOn();
    this.dialogRef.close({
      form: this.form.getRawValue(),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  onActivateUser() {
    this.formDisabled = false;
    this.form.controls.firstName.enable();
    this.form.controls.lastName.enable();
    this.form.controls.userRole.enable();
  }

  private createFormControls() {
    this.firstName = new FormControl({
      value: this.data?.FIRST_NAME ? this.data?.FIRST_NAME : '',
      disabled: this.formDisabled
    }, [
      Validators.required,
      Validators.maxLength(this.maxLengthForObjectName)
    ]);

    this.lastName = new FormControl({
      value: this.data?.LAST_NAME ? this.data?.LAST_NAME : '',
      disabled: this.formDisabled
    }, [
      Validators.required,
      Validators.maxLength(this.maxLengthForObjectName)
    ]);

    this.email = new FormControl({
      value: this.data?.EMAIL ? this.data?.EMAIL : '',
      disabled: !this.isNewUser || this.formDisabled
    }, [
      Validators.required,
      Validators.maxLength(this.maxLengthForObjectName),
      Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ]);

    if (this.isAdmin) {
      this.userRole = new FormControl({
        value: this.data?.USER_ROLE ? this.data?.USER_ROLE : '',
        disabled: this.formDisabled
      }, [
        Validators.required,
      ]);
    }

    const jobTitleArray = _.uniq(this.jobTitle.map((titleItem: any) => {
      titleItem.checked = this.data?.JOB_TITLE ? this.data?.JOB_TITLE.includes(titleItem.name) : false;
      return titleItem;
    }));

    this.jobTitles = new FormControl(jobTitleArray, (a) => {
      if (a.value.filter(item => item.checked).length !== 0) {
        return null;
      } else {
        return {
          error: 'Job title is required'
        };
      }
    });

    if (this.isAdmin) {
      const dataPermissions = this.data?.USER_PERMISSIONS ? this.data?.USER_PERMISSIONS.map(item => item.toLowerCase()) : [];
      const userPermissionsArray = _.uniq(this.userPermissions.map((permissionItem: any) => {
        permissionItem.checked = dataPermissions ? dataPermissions.includes(permissionItem.value) : false;
        if (permissionItem.value === 'octomize') {
          this.octomizeChecked = permissionItem.checked;
        }
        return permissionItem;
      }));
      this.permissions = new FormControl(userPermissionsArray, (a) => {
        if (a.value.filter(item => item.checked).length !== 0) {
          return null;
        } else {
          return {
            error: 'User permission is required'
          };
        }
      });
    }
  }

  private createForm() {
    let formControls;
    if (this.isAdmin) {
      formControls = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        userRole: this.userRole,
        jobTitles: this.jobTitles,
        userPermissions: this.permissions,
      };
    } else {
      formControls = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        jobTitles: this.jobTitles,
      };
    }

    this.form = new FormGroup({...formControls});
  }
}

<div [hidden]="readOnlyData" class="adc-filter-header">
    <mat-icon [ngClass]="{'filtered': filtered}" class="filter" svgIcon="filter_frame"></mat-icon>
    <div class="adc-filter-title">Filters</div>
    <mat-icon (click)="onSettingsToolbar()" *ngIf="showSettingsToolbar" class="settings" id="layers-filter-toggle"
              svgIcon="settings"></mat-icon>
</div>
<div *ngIf="form; else loadingContent" [formGroup]="form"
     class="adc-filter-content">
    <div [hidden]="readOnlyData && !dataToShow['statusList']" class="adc-filter-element">
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Status</div>
        <div class="flex adc-filter-sub-title-container" flex-dircetion-col>
            <oct-check-box-list
                    *ngIf="form.controls.statusList.value.length < showMoreDisplayCounter; else elseBlockStatusList"
                    [selectAllOption]="true"
                    checkBoxListName="statusList"
                    formControlName="statusList"></oct-check-box-list>
            <ng-template #elseBlockStatusList>
                <oct-show-more fontSize="0.9rem">
                    <oct-check-box-list [selectAllOption]="true"
                                        checkBoxListName="statusList"
                                        formControlName="statusList"></oct-check-box-list>
                </oct-show-more>
            </ng-template>

        </div>
    </div>
    <div [hidden]="readOnlyData && (!dataToShow['rate'] || !dataToShow['statusList'])"
         class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['rate']" class="adc-filter-element">
        <div class="flex" flex-align-items-center>
            <div class="adc-filter-sub-title">Rating</div>
            <div [style.width.%]="75">
                <oct-rating-slider [options]="ratingOptions" formControlName="rate"></oct-rating-slider>
            </div>
        </div>
    </div>
    <div [hidden]="readOnlyData && (!dataToShow['sensitivity'] || !dataToShow['rate'])"
         class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['sensitivity']" class="adc-filter-element">
        <div class="flex">
            <div class="adc-filter-sub-title">Sensitive</div>
            <oct-button-toggle [listOfButtons]="sensitivity.buttons"
                               buttonToggleName="sensitivity"
                               formControlName="sensitivity"
                               height="24px"></oct-button-toggle>
        </div>
    </div>
    <div [hidden]="readOnlyData && (!dataToShow['assetTypes'] || !dataToShow['sensitivity'])"
         class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['assetTypes']" class="adc-filter-element">
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Asset Type</div>
        <div class="flex adc-filter-sub-title-container" flex-dircetion-col>
            <oct-check-box-list
                    *ngIf="form.controls.assetTypes.value.length < showMoreDisplayCounter; else elseBlock"
                    [selectAllOption]="true"
                    checkBoxListName="assetTypes"
                    formControlName="assetTypes"></oct-check-box-list>
            <ng-template #elseBlock>
                <oct-show-more fontSize="0.9rem">
                    <oct-check-box-list [selectAllOption]="true" checkBoxListName="assetTypes"
                                        formControlName="assetTypes"></oct-check-box-list>
                </oct-show-more>
            </ng-template>
        </div>
    </div>
    <div [hidden]="readOnlyData && (!dataToShow['tags'] || !dataToShow['assetTypes'])"
         class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['tags']" class="adc-filter-element">
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Tags</div>
        <m-search-input #tagSearchInput
                        (keyPress)="subscribeToTagSearch($event)"
                        [applySearchOnEnter]="true"
                        [autoComplete]="tags"
                        [style.width.%]="100"
                        class="search-round-wrapper"
                        placeholder="Search"
                        tooltip="{{'GENERAL.TOOLTIP_MSG' | translate}}">
            <mat-autocomplete #tags='matAutocomplete' (optionSelected)="tagSelected($event)" [displayWith]="displayFn"
                              class="new" slot2>
                <mat-option *ngFor="let option of tags$ | async; let i = index" [value]="option"
                            id="tagsAutoComplete-{{i}}"
                            matTooltip="{{option.tAG_NAME}}">
                    {{ option.tAG_NAME }}
                </mat-option>
            </mat-autocomplete>
        </m-search-input>
        <div *ngIf="tagsList.length" class="m--padding-top-10">
            <mat-chip-list class="new">
                <mat-chip *ngFor="let selectedTag of tagsList" class="flex new">
                    <oct-chip-content (tag)="onRemoveTag($event)"
                                      [selectedTag]="selectedTag"></oct-chip-content>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div [hidden]="readOnlyData && (!dataToShow['dataOwnersIds'] || !dataToShow['tags'])"
         class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['dataOwnersIds']" class="adc-filter-element">
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Data Owner</div>
        <m-search-input #dataOwnerSearchInput
                        (keyPress)="subscribeToDataOwnerSearch($event)"
                        [applySearchOnEnter]="true"
                        [autoComplete]="dataOwner"
                        [style.width.%]="100"
                        class="search-round-wrapper"
                        placeholder="Search"
                        tooltip="{{'GENERAL.TOOLTIP_MSG' | translate}}">

            <mat-autocomplete #dataOwner (optionSelected)="dataOwnerSelected($event)" [displayWith]="userDisplayFn"
                              class="new"
                              slot2>
                <mat-option *ngFor="let option of dataOwners$ | async; let i = index" [value]="option"
                            id="dataOwnerAutocomplete-{{i}}">
                    <oct-avatar-item [option]="option"></oct-avatar-item>
                </mat-option>
            </mat-autocomplete>
        </m-search-input>
        <div *ngIf="dataOwnersList.length" class="m--padding-top-10">
            <mat-chip-list class="new">
                <mat-chip *ngFor="let selectedUser of dataOwnersList" class="flex new">
                    <oct-chip-content (deleteAllList)="deleteAllDataOwnerList($event)"
                                      (deleteSpecialOption)="deleteSpecialOptionDataOwnerList($event)"
                                      (tag)="onRemoveDataOwner($event)"
                                      [selectedTag]="selectedUser"></oct-chip-content>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div [hidden]="readOnlyData && (!dataToShow['dataStewardsIds'] || !dataToShow['dataOwnersIds'])"
         class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['dataStewardsIds']" class="adc-filter-element">
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Data Steward</div>
        <m-search-input #dataStewardSearchInput
                        (keyPress)="subscribeToDataStewardSearch($event)"
                        [applySearchOnEnter]="true"
                        [autoComplete]="dataSteward"
                        [style.width.%]="100"
                        class="search-round-wrapper"
                        placeholder="Search"
                        tooltip="{{'GENERAL.TOOLTIP_MSG' | translate}}">
            <mat-autocomplete #dataSteward (optionSelected)="dataStewardSelected($event)" [displayWith]="userDisplayFn"
                              class="new" id="dataStewardAutocomplete"
                              slot2>
                <mat-option *ngFor="let option of dataStewards$  | async; let i = index" [value]="option"
                            id="dataStewardAutocomplete-{{i}}">
                    <oct-avatar-item [option]="option"></oct-avatar-item>
                </mat-option>
            </mat-autocomplete>
        </m-search-input>
        <div *ngIf="dataStewardsList.length" class="m--padding-top-10">
            <mat-chip-list class="new">
                <mat-chip *ngFor="let selectedUser of dataStewardsList" class="flex new">
                    <oct-chip-content (deleteAllList)="deleteAllDataStewardList($event)"
                                      (deleteSpecialOption)="deleteSpecialOptionDataStewardList($event)"
                                      (tag)="onRemoveDataSteward($event)"
                                      [selectedTag]="selectedUser"></oct-chip-content>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div [hidden]="readOnlyData && (!dataToShow['customAttributesIds'] || !dataToShow['dataStewardsIds'])"
         class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['customAttributesIds']" class="adc-filter-element">
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Custom Attribute</div>
        <m-search-input #customAttributeSearchInput
                        (keyPress)="subscribeToCustomAttributeSearch($event)"
                        [applySearchOnEnter]="true"
                        [autoComplete]="customAttribute"
                        [style.width.%]="100"
                        class="search-round-wrapper"
                        placeholder="Search"
                        tooltip="{{'GENERAL.TOOLTIP_MSG' | translate}}">

            <mat-autocomplete #customAttribute (optionSelected)="customAttributeSelected($event)"
                              [displayWith]="customAttributeSelectedDisplayFn"
                              class="new"
                              slot2>
                <mat-option *ngFor="let option of customAttributes$ | async; let i = index" [value]="option"
                            id="customAttributeAutocomplete-{{i}}">
                    {{ option.aTTRIBUTE_NAME }}
                </mat-option>
            </mat-autocomplete>
        </m-search-input>
        <div *ngIf="customAttributesList.length" class="m--padding-top-10">
            <mat-chip-list class="new">
                <mat-chip *ngFor="let selectedCustom of customAttributesList" class="flex new">
                    <oct-chip-content (tag)="onRemoveCustomAttribute($event)"
                                      [selectedTag]="selectedCustom"></oct-chip-content>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>

    <div
            [hidden]="readOnlyData && (!dataToShow['entryFrom'] || !dataToShow['entryTo'] || !dataToShow['dataStewardsIds'])"
            class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['entryFrom'] && !dataToShow['entryTo']"
         class="adc-filter-element">
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Entry Date</div>
        <div class="flex">
            <div (click)="entryFrom.open()" [hidden]="readOnlyData && !dataToShow['entryFrom']"
                 [ngClass]="{'clear_hover' : form.controls.entryFrom.value }"
                 class="search-round-wrapper date">
                <mat-icon *ngIf="!form.controls.entryFrom.value" class="calendar" svgIcon="calendar"></mat-icon>
                <input [matDatepicker]="entryFrom" class="search-input" formControlName="entryFrom"
                       placeholder="From" readonly>
                <mat-datepicker #entryFrom></mat-datepicker>
                <mat-icon (click)="onClearDate('entryFrom')"
                          class="clear"
                          svgIcon="close-icon"></mat-icon>
            </div>
            <div (click)="entryTo.open()" [hidden]="readOnlyData && !dataToShow['entryTo']"
                 [ngClass]="{'clear_hover' : form.controls.entryTo.value }"
                 class="search-round-wrapper date flex">
                <mat-icon *ngIf="!form.controls.entryTo.value" class="calendar" svgIcon="calendar"></mat-icon>
                <input [matDatepicker]="entryTo" class="search-input" formControlName="entryTo" placeholder="To"
                       readonly>
                <mat-datepicker #entryTo></mat-datepicker>
                <mat-icon (click)="onClearDate('entryTo')" *ngIf="!!form.controls.entryTo.value"
                          class="clear"
                          svgIcon="close-icon"></mat-icon>
            </div>
        </div>
    </div>
    <div
            [hidden]="readOnlyData && (!dataToShow['entryFrom'] || !dataToShow['entryTo'] || !dataToShow['lastUpdateFrom'] || !dataToShow['lastUpdateTo'])"
            class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['lastUpdateFrom'] && !dataToShow['lastUpdateTo']"
         class="adc-filter-element">
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Last Update</div>
        <div class="flex">
            <div (click)="lastUpdateFrom.open()" [hidden]="readOnlyData && !dataToShow['lastUpdateFrom']"
                 [ngClass]="{'clear_hover' : form.controls.lastUpdateFrom.value }"
                 class="search-round-wrapper date">
                <mat-icon *ngIf="!form.controls.lastUpdateFrom.value" class="calendar" svgIcon="calendar"></mat-icon>
                <input [matDatepicker]="lastUpdateFrom" class="search-input" formControlName="lastUpdateFrom"
                       placeholder="From" readonly>
                <mat-datepicker #lastUpdateFrom></mat-datepicker>
                <mat-icon (click)="onClearDate('lastUpdateFrom')" class="clear"
                          class="clear" svgIcon="close-icon"></mat-icon>
            </div>
            <div (click)="lastUpdateTo.open()" [hidden]="readOnlyData && !dataToShow['lastUpdateTo']"
                 [ngClass]="{'clear_hover' : form.controls.lastUpdateTo.value }"
                 class="search-round-wrapper date flex">
                <mat-icon *ngIf="!form.controls.lastUpdateTo.value" class="calendar" svgIcon="calendar"></mat-icon>
                <input [matDatepicker]="lastUpdateTo" class="search-input" formControlName="lastUpdateTo"
                       placeholder="To" readonly>
                <mat-datepicker #lastUpdateTo></mat-datepicker>
                <mat-icon (click)="onClearDate('lastUpdateTo')" class="clear"
                          class="clear" svgIcon="close-icon"></mat-icon>
            </div>
        </div>
    </div>
    <div [hidden]="readOnlyData && (!dataToShow['lastUpdateFrom'] || !dataToShow['lastUpdateTo'] || !dataToShow['Properties'])"
         class="adc-filter-separator"></div>

    <div [hidden]="readOnlyData && !dataToShow['Properties']"
         class="adc-filter-element flex adc-filter-sub-title-container" flex-dircetion-col>
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Properties</div>

        <oct-properties-check-box-list
                [selectAllOption]="true"
                [toggleValues]="toggleValues"
                checkBoxListName="propertiesList"
                formControlName="propertiesList"></oct-properties-check-box-list>

        <div [hidden]="form.controls.propertiesList.value[form.controls.propertiesList.value.length - 1].toggleValue == 'true' ||
			form.controls.propertiesList.value[form.controls.propertiesList.value.length - 1].checked === false"
             class="adc-filter-element">
            <m-search-input #dataTypeSearchInput
                            (keyPress)="subscribeToDataTypeSearch($event)"
                            [applySearchOnEnter]="true"
                            [autoComplete]="dataType"
                            [style.width.%]="100"
                            class="search-round-wrapper"
                            placeholder="Search Data Type"
                            tooltip="{{'GENERAL.TOOLTIP_MSG' | translate}}">
                <mat-autocomplete #dataType (optionSelected)="dataTypeSelected($event)"
                                  [displayWith]="dataTypeDisplayFn"
                                  class="new" id="dataTypeSearchInput"
                                  slot2>
                    <mat-option *ngFor="let option of dataTypes$  | async; let i = index" [value]="option"
                                id="dataType-{{i}}">
                        <oct-avatar-item [option]="option"></oct-avatar-item>
                    </mat-option>
                </mat-autocomplete>
            </m-search-input>
            <div *ngIf="dataTypes.length" class="m--padding-top-10">
                <mat-chip-list class="new">
                    <mat-chip *ngFor="let selectedDataType of dataTypes" class="flex new">
                        <oct-chip-content (deleteAllList)="deleteAllDataTypes($event)"
                                          (deleteSpecialOption)="deleteSpecialOptionDatatype($event)"
                                          (tag)="onRemoveDataType($event)"
                                          [selectedTag]="selectedDataType"></oct-chip-content>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>


    <div [hidden]="readOnlyData && (!dataToShow['Properties'] || !dataToShow['UpdatedByIds'])"
         class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['UpdatedByIds']" class="adc-filter-element">
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Updated by</div>
        <m-search-input #dataUpdatedBySearchInput
                        (keyPress)="subscribeToUpdatedBySearch($event)"
                        [applySearchOnEnter]="true"
                        [autoComplete]="dataUpdatedBy"
                        [style.width.%]="100"
                        class="search-round-wrapper"
                        placeholder="Search"
                        tooltip="{{'GENERAL.TOOLTIP_MSG' | translate}}">
            <mat-autocomplete #dataUpdatedBy (optionSelected)="dataUpdaterSelected($event)"
                              [displayWith]="userDisplayFn"
                              class="new" id="dataUpdatedBySearchInput"
                              slot2>
                <mat-option *ngFor="let option of dataUpdatedBy$  | async; let i = index" [value]="option"
                            id="dataUpdatedByAutocomplete-{{i}}">
                    <oct-avatar-item [option]="option"></oct-avatar-item>
                </mat-option>
            </mat-autocomplete>
        </m-search-input>
        <div *ngIf="dataUpdatedByList.length" class="m--padding-top-10">
            <mat-chip-list class="new">
                <mat-chip *ngFor="let selectedUser of dataUpdatedByList" class="flex new">
                    <oct-chip-content (deleteAllList)="deleteAllDataUpdatedByList($event)"
                                      (deleteSpecialOption)="deleteSpecialOptionDataUpdatedByList($event)"
                                      (tag)="onRemoveDataUpdater($event)"
                                      [selectedTag]="selectedUser"></oct-chip-content>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>


    <div [hidden]="readOnlyData && (!dataToShow['UpdatedByIds'] || !dataToShow['suspended'])"
         class="adc-filter-separator"></div>
    <div [hidden]="readOnlyData && !dataToShow['suspended']" class="adc-filter-element">
        <div class="adc-filter-sub-title adc-filter-sub-title-specific">Suspended</div>
        <oct-button-toggle [listOfButtons]="suspended.buttons"
                           buttonToggleName="suspended"
                           formControlName="suspended"
                           height="24px"></oct-button-toggle>
    </div>
</div>
<div [hidden]="readOnlyData" class="adc-filter-footer">
    <oct-underline-button (clicked)="onResetFilter()" class="adc-filter-reset-btn" fontSize="1.1rem"
                          id="filter-reset" matTooltip="Reset Search Filter"
                          text="Reset"></oct-underline-button>
    <oct-round-button (clicked)="onApply()" class="adc-filter-apply-btn" fontSize="1.2rem" id="filter-apply"
                      matTooltip="Apply Search Filter" text="Apply"></oct-round-button>
</div>

<ng-template #loadingContent>
    <div class="skeleton-container">
        <div class="width-present-100">
            <ngx-skeleton borderRadius="10px" height="3%" margin="0 0 8px 0" width="80%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>

            <ngx-skeleton borderRadius="10px" height="3%" margin="0 0 8px 0" width="80%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
            <ngx-skeleton borderRadius="10px" height="2%" margin="0 0 8px 0" width="50%"></ngx-skeleton>
        </div>
    </div>
</ng-template>

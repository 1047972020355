import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectQuickSideBarStatus, SideBarTypes, Status } from '@store/layout';
import { UpdateNavMenuStatus, UpdateSideBarState } from '@store/layout/actions';
import { Observable } from 'rxjs';

@Directive()
@Injectable()
export class SideBarService {
    public isOpen = false;
    public contentName = 'properties';
    public isNodeSelected = false;

    @Output() change: EventEmitter<any> = new EventEmitter();

    constructor(
        private store: Store<any>,
    ) {
    }

    getSideBarOpenState(): Observable<Status> {
        return this.store
            .pipe(
                select(selectQuickSideBarStatus),
            );
    }

    toggle(contentName) {
        this.isNodeSelected = false;
        this.isOpen = !this.isOpen;
        this.change.emit({isOpen: this.isOpen, activeTabId: contentName});

        this.store.dispatch(new UpdateSideBarState({
            status: this.isOpen ? Status.open : Status.close,
        }));
    }

    open(contentName?: string) {
        this.isOpen = true;
        this.change.emit({isOpen: this.isOpen, activeTabId: contentName});

        this.store.dispatch(new UpdateSideBarState({
            status: Status.open,
            type: contentName,
        }));
        this.store.dispatch(new UpdateNavMenuStatus(Status.close));
    }

    close(contentName?: string) {
        this.isNodeSelected = false;
        this.store.dispatch(new UpdateSideBarState({
            status: Status.close,
            type: contentName,
        }));
    }

    setIsNodeSelectedFlag(value: boolean) {
        this.isNodeSelected = value;
    }

    getIsNodeSelectedValue(): boolean {
        return this.isNodeSelected;
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterArr',
	pure: false,
})
export class FilterArrPipe implements PipeTransform {

	transform(items: any[], keyToFilter: string, term = ''): any {
		if (term === '') {
			return items;
		}

		if (items) {
			return items.filter(item => (item[keyToFilter]).toLowerCase().includes(term.toLowerCase()));
		}
	}

}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogState } from '@angular/material/dialog';

@Component({
  selector: 'oct-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  constructor(
	  public dialogRef: MatDialogRef<ErrorDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

	public close(): void {
		this.dialogRef.close();
	}



}

import { Injectable } from '@angular/core';

@Injectable()
export class AddLinkService {
    public dialogRef: any;
    public selectedNewLink: any = {};
    public sourceObjectID: string;
    public totalLink: number = 0;

    constructor() {
    }
}

<div class="generic-modal">
    <div class="generic-modal-header">
        <span (click)="close()" *ngIf="data.enableClose" class="generic-modal-close">
            <img alt="" src="./assets/app/img/icons/small_x.svg"/>
        </span>
        <img [ngStyle]="{width: data.headerIcon.width}" alt="" class="generic-modal-icon"
             src="{{data.headerIcon.name}}">
        <h4 class="general-modal-title">{{data.title}}</h4>
        <h6 class="general-modal-sub-title">{{data.subTitle}}</h6>
    </div>

    <div class="generic-modal-content">
        {{data.content}}
    </div>

    <div class="flex generic-modal-footer">
        <oct-round-button (clicked)="btn.click()" *ngFor="let btn of data.buttons" fontSize="1.2rem"
                          text="{{btn.text}}"></oct-round-button>
    </div>
</div>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ToolbarService } from '@shared/components/toolbar/toolbar.service';
import { filter, map } from 'rxjs/operators';
import { ButtonType, IToolBarItem, IToolBarItemComponent } from '@shared/components/toolbar/toolbar.interfaces';

@Component({
  selector: 'oct-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements OnInit, AfterViewInit {
  @ViewChild('componentsContainer', {static: true, read: ViewContainerRef}) container;
  @Output() saveAsPng = new EventEmitter();
  public toolbarItems: IToolBarItem[] = [];
  public buttonType: typeof ButtonType = ButtonType;


  constructor(
    public toolBarService: ToolbarService,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
  }

  public close() {
    return;
  }

  ngAfterViewInit(): void {
    this.toolBarService.componentsItems$
      .subscribe((componentsItems: Array<IToolBarItemComponent>) => {
        this.container.clear();
        componentsItems.forEach((componentItem: IToolBarItemComponent) => {
          const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(componentItem.component);
          const componentRef: ComponentRef<any> = this.container.createComponent(factory);
          componentRef.instance.name = componentItem.name;
          componentRef.instance.data = componentItem.data;
          componentRef.instance.toolTip = componentItem.toolTip;
          componentRef.instance.click = componentItem.click;
          componentItem.ref = componentRef.instance;
        });
      });

    this.toolBarService.items$
      .pipe(
        filter(items => !!items?.length),
        map((items: Array<any>) => items.filter((item: any) => item.visible)),
      )
      .subscribe(items => {
        this.toolbarItems = items;
        this.cdr.detectChanges();
      });

    this.toolBarService.resetToolbar$.subscribe(() => {
      this.toolbarItems = [];
      this.container.clear();
      this.toolBarService.componentsItems$.next([]);
      this.toolBarService.items$.next([]);
    });
  }
}

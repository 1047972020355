import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'bghighlight'
})

export class BgHighlightPipe implements PipeTransform {
    transform(value: string, args: any | any[]): any {
        if (!value) {
            return;
        }

        let _value = value;
        if (args) {
            args.forEach((val) => {
                if (typeof val === 'string') {
                    // in the UI there's no need to do encode/decode or escape/unescape
                    const _val = val.toLowerCase().trim();
                    const argStartIndex = _value.toLowerCase().indexOf(_val);

                    if (_val.length !== 0 && argStartIndex > -1) {
                        let argEndIndex;
                        if (_val.length < val.length) { // last char is space
                            argEndIndex = val.length - 1;
                        } else {
                            argEndIndex = val.length;
                        }

                        const matchingString = _value.substr(argStartIndex, argEndIndex);
                        _value = _value.replace(matchingString, `<span class='gbg'>${matchingString}</span>`);
                    }
                }
            });
            return _value;
        } else if (typeof args !== 'object') {
            const argStartIndex = _value.indexOf(args.toLowerCase());
            if (argStartIndex > -1) {
                const argEndIndex = args.length;
                const matchingString = _value.substr(argStartIndex, argEndIndex);
                return _value.replace(matchingString, `<span class='gbg'>${matchingString}</span>`);
            }
        }

        return value;
    }
}


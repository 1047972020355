import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface CodePreviewState {
	scriptOnListProperties: string;
	scriptOnTextTabOfMap: string;
}

export const initialCodePreviewState: CodePreviewState = {
	scriptOnListProperties: 'Loading...',
	scriptOnTextTabOfMap: 'Loading...'
}

export const selectCodePreviewState = createFeatureSelector<CodePreviewState>('code_preview');
export const getScriptOnListProperties = createSelector(selectCodePreviewState, state => state.scriptOnListProperties);
export const getScriptOnTextTabOfMap = createSelector(selectCodePreviewState, state => state.scriptOnTextTabOfMap);

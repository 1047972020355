import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'codemirror/mode/sql/sql';
import { select, Store } from '@ngrx/store';

import { CodePreviewService } from './code-preview.service';
import { URLforFullText } from '@core/enum/full-text';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { PageConfigService } from '@core/services/page-config.service';
import { getLocation } from '@store/common';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { UtilsService } from '@core/services/utils.service';
import { ToastrService } from 'ngx-toastr';
import * as CodeMirror from 'codemirror';
import { on } from 'codemirror';
import { Locations } from '@shared/enums/locations.enum';
import { ButtonsTheme } from '@shared/enums/searchType';
import 'codemirror/addon/display/placeholder.js';
import 'codemirror/addon/selection/mark-selection';
import { SetLineageSearch } from '@store/lineage/actions';
import { permissions } from '@shared/enums/permissions';
import { NgxPermissionsService } from 'ngx-permissions';
import { SideBySideService } from '@shared/components/side-by-side/side-by-side.service';
import { SideBarService } from '@core/services/layout/side-bar.service';

declare var Clipboard: any;

enum MarkerType {
  search = 'search-mark',
  gsp = 'gsp-mark',
}

@Component({
  selector: 'oct-code-preview',
  templateUrl: './code-preview.component.html',
  styleUrls: ['./code-preview.component.scss'],
})
export class CodePreviewComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('editorHolder', {static: true}) editorHolder: ElementRef;
  @ViewChild(SearchInputComponent, {static: true}) public searchInput: SearchInputComponent;
  @Input() url: URLforFullText;
  @Input() tool: string;
  @Input() id: string;
  @Input() search: string;
  @Input() isFocus: boolean;
  @Input() placeholder = '';
  @Output() coordinateHover = new EventEmitter();
  @Output() textChanged = new EventEmitter();
  doc: CodeMirror.Doc;
  public locations: typeof Locations = Locations;
  public copyMessage = 'Copy to clipboard';
  public location: string;
  buttonText = 'Select Vendor';
  editor: CodeMirror.EditorFromTextArea;
  applyLiveLineage: boolean;
  dirty = false;
  private searchMarkers = [];
  private searchValue: string;
  private componentDestroy$ = new Subject();
  private scrolledMarker = -1;
  private editorContent;

  constructor(
    public codePreviewService: CodePreviewService,
    public sideBySideService: SideBySideService,
    private route: ActivatedRoute,
    private pageConfigService: PageConfigService,
    private store: Store<any>,
    private moduleUtilsService: ModuleUtilsService,
    private utilsService: UtilsService,
    private toastrService: ToastrService,
    private router: Router,
    private ngxPermissions: NgxPermissionsService,
    private sideBarService: SideBarService,
  ) {
    this.codePreviewService.params = this.route.snapshot.params.id;
  }

  get content() {
    return this.editorContent;
  }

  @Input()
  set content(value) {
    if (value || value === '') {
      this.editorContent = value;
      this.editor?.setValue(value);
    }
  }

  get buttonThemeColor(): typeof ButtonsTheme {
    return ButtonsTheme;
  }

  ngOnInit() {
    this.applyLiveLineage = !!this.ngxPermissions.getPermission(permissions.liveLineage);
    this.codePreviewService.id = this.id;
    this.registerCopyCodeToClipboard();
    this.editorHolder.nativeElement.placeholder = this.placeholder;

    this.store
      .pipe(
        takeUntil(this.componentDestroy$),
        select(getLocation),
      )
      .subscribe(location => {
        this.location = location;
      });

    if ([Locations.liveVisualizer, Locations.liveLineage].includes(<Locations>this.location)) {
      this.codePreviewService.config = {
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/x-mysql',
        readOnly: false
      };
    } else {
      this.codePreviewService.config = {
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/x-mysql',
        readOnly: true
      };
    }

    this.initCodeMirror('');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.searchInput.isFocus = changes.isFocus?.currentValue;
    if (changes.search) {
      if (changes.search.currentValue === '') {
        this.clearSearchMarks();
      }
      this.searchValue = changes.search.currentValue ? changes.search.currentValue.trim() : changes.search.currentValue;
      this.searchInput.value$.next(this.searchValue || '');
    }
    this.buttonText = changes.isFocus?.currentValue || 'SELECT';
  }

  registerCopyCodeToClipboard() {
    new Clipboard('.copyCodeToClipboardClass', {
      text: (trigger: any) => {
        this.copyMessage = 'Copied!';
        this.toastrService.success('Value Copied!', 'Clipboard');
        setTimeout(() => {
          this.copyMessage = 'Copy to clipboard';
        }, 1500);
        return this.doc.getValue();
      }
    });
  }

  onSearch(e: string) {
    this.clearSearchMarks();
    if (e) {
      this.searchValue = e.toLowerCase();
    }
    this.markSearchTerm();
  }

  onNext() {
    this.next();
  }

  onPrev() {
    this.prev();
  }

  onClearSearch() {
    this.clearSearchMarks();
    this.searchValue = '';
  }

  next() {
    if (this.scrolledMarker + 1 < this.searchMarkers.length) {
      const nextScrolledMarker = ++this.scrolledMarker;
      this.scrollTo(nextScrolledMarker);
      this.searchInput.index = nextScrolledMarker + 1;
    }
  }

  prev() {
    if (this.scrolledMarker - 1 > -1) {
      const nextScrolledMarker = --this.scrolledMarker;
      this.scrollTo(nextScrolledMarker);
      this.searchInput.index = nextScrolledMarker + 1;
    }
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.unsubscribe();
  }

  navigateToLiveVisualize() {
    this.sideBarService.close('properties');
    this.sideBySideService.selectedTabIndex = 0;
    this.sideBySideService.scriptFromAnalysis = this.content;
    this.sideBySideService.vendorFromAnalysis = this.tool;
    this.sideBySideService.data = null;
    this.router.navigate([Locations.liveVisualizer]);
  }

  CreateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  clearGSPMarkers() {
    const marks = this.getMarks(MarkerType.gsp);
    marks
      .filter(marker => marker.attributes.type === MarkerType.gsp)
      .forEach(marker => {
        marker.clear();
      });
  }

  setGspTextMark(coordinates, text) {
    const id = this.CreateUUID();
    this.editor.markText(
      {line: coordinates[0][0] - 1, ch: coordinates[0][1] - 1},
      {line: coordinates[1][0] - 1, ch: coordinates[1][1] - 1},
      {
        className: `cm-mark-${id}`,
        attributes: {
          'data-id': id,
          'data-text': text,
          type: MarkerType.gsp,
        },
      });
    return id;
  }

  setGspMarksEvents() {
    const gspMarks = this.getMarks(MarkerType.gsp);
    gspMarks.forEach(mark => {
      const markId = mark.attributes['data-id'];
      const markText = mark.attributes['data-text'];
      const elArr = Array.from(document.getElementsByClassName(`cm-mark-${markId}`));
      elArr.forEach((el: any) => {
        on(el, 'mouseover', () => {
          const _elArr = Array.from(document.getElementsByClassName(`cm-mark-${markId}`));
          _elArr.forEach((_el: any) => {
            _el.style.backgroundColor = '#c8c8cd';
          });
          this.store.dispatch(new SetLineageSearch(markText));
        });
        on(el, 'mouseout', () => {
          const _elArr = Array.from(document.getElementsByClassName(`cm-mark-${markId}`));
          _elArr.forEach((_el: any) => {
            _el.style.backgroundColor = 'transparent';
          });
          this.store.dispatch(new SetLineageSearch(''));
        });
      });
    });
  }

  private getMarks(type: MarkerType) {
    const marks = this.editor.getAllMarks();
    return marks
      .filter(mark => mark.attributes.type === type);
  }

  private clearSearchMarks() {
    const marks = this.getMarks(MarkerType.search);
    marks
      .forEach(marker => {
        marker.clear();
      });

    this.setGspMarksEvents();
  }

  private markSearchTerm() {
    if (!this.searchValue) {
      this.onClearSearch();
      return;
    }

    let regExPattern = this.searchValue;
    regExPattern = regExPattern.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    const regex = RegExp(regExPattern.toLowerCase(), 'g');
    let match;

    for (let lineIndex = 0; lineIndex < this.editor.lineCount(); lineIndex++) {
      const line = this.editor.getLine(lineIndex);
      if (line) {
        while ((match = regex.exec(line.toLowerCase())) !== null) {
          const from = {line: lineIndex, ch: match.index};
          const to = {line: lineIndex, ch: match.index + this.searchValue.length};
          this.editor.markText(
            from,
            to,
            {
              className: 'code-mirror-marker',
              attributes: {
                type: MarkerType.search,
                line,
                from: from.ch.toString(),
                to: to.ch.toString(),
              }
            },
          );
        }
      }
    }

    this.searchMarkers = this.getMarks(MarkerType.search);
    this.scrolledMarker = this.searchMarkers.length >= 1 ? 0 : -1;
    this.scrollTo(0);
    this.searchInput.index = this.searchMarkers.length >= 1 ? 1 : -1;
    this.searchInput.total = this.searchMarkers.length;
  }

  private scrollTo(nextScrolledMarker: number) {
    const marks = this.searchMarkers
      .map((item, index) => {
        const lineHandle = this.editor.lineInfo(item.lines[0]);
        const lineIndex = lineHandle.line;
        const from = lineHandle.handle.markedSpans.filter(a => a.marker.attributes.type === MarkerType.search)[0].from;
        const to = lineHandle.handle.markedSpans.filter(a => a.marker.attributes.type === MarkerType.search)[0].to;
        let className = 'code-mirror-marker';

        if (index === nextScrolledMarker) {
          this.editor.scrollIntoView({line: lineIndex, ch: to});
          className = 'search-marker';
        }

        item.clear();

        return this.editor.markText(
          {line: lineIndex, ch: from},
          {line: lineIndex, ch: to},
          {
            className,
            attributes: {
              type: MarkerType.search,
              line: lineIndex,
              from,
              to,
            },
          }
        );
      });

    this.searchMarkers = [...marks];
    this.setGspMarksEvents();
  }

  private initCodeMirror(value) {
    this.editor = CodeMirror.fromTextArea(this.editorHolder.nativeElement, {
      ...this.codePreviewService.config,
    });
    this.editor.setValue(value);
    this.doc = this.editor.getDoc();
    this.editor.on('change', () => {
      this.editorContent = this.editor.getValue();
      if (this.dirty) {
        this.textChanged.emit();
      }
    });
    this.editor.on('keypress', () => {
      // this.textChanged.emit();
      this.dirty = true;
    });
  }

  private isMarkExists(line: string) {
    const marks = this.getMarks(MarkerType.search);
    return marks.find((mark: any) => mark.lines[0].text === line);
  }
}

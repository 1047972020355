import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface DiscoveryState {
	selected_connections_ids: string;
	count: string;
	selected_connections_objs: any[];
	properties: object;
	properties_obj: object;
	search_obj: any;
	connections_list: any[];
	connections_list_bg: any[];
	history_list: any[];
	connections?: any[];
	connectionsBG?: any[];
	url?: string;
	selectedObject: object;
}

export const selectDiscoveryState = createFeatureSelector<DiscoveryState>('discovery');
export const getDiscoverySelectedConnectionsIDs = createSelector(selectDiscoveryState, state => state.selected_connections_ids);
export const getDiscoverySelectedConnectionsObjs = createSelector(selectDiscoveryState, state => state.selected_connections_objs);
export const getDiscoveryProperties = createSelector(selectDiscoveryState, state => state.properties);
export const getDiscoveryPropertiesObj = createSelector(selectDiscoveryState, state => state.properties_obj);
export const getDiscoveryCount = createSelector(selectDiscoveryState, state => state.count);
export const getDiscoverySearchObj = createSelector(selectDiscoveryState, state => state.search_obj);
export const getDiscoveryConnectionsList = createSelector(selectDiscoveryState, state => state.connections_list);
export const getDiscoveryConnectionsListBG = createSelector(selectDiscoveryState, state => state.connections_list_bg);
export const getDiscoveryHistoryList = createSelector(selectDiscoveryState, state => state.history_list);
export const selectDiscoveryUrl = createSelector(selectDiscoveryState, state => state.url);
export const selectSelectedObject = createSelector(selectDiscoveryState, state => state.selectedObject);
export const selectDiscoveryConnections = createSelector(
	selectDiscoveryState,
	state => {
		return {
			connections: state.connections,
			connectionsBG: state.connectionsBG,
		};
	});



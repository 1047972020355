import { PageConfigService } from '@core/services/page-config.service';
import { LogsService } from '@core/services/logs.service';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DecimalPipe, Location } from '@angular/common';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { PostsDrawerStatus, selectAdcSearchFilters, selectFiltered, selectSort } from '@main/adc/adc-store/adc';
import { SubheaderService } from '@core/services/layout/subheader.service';
import { UserProfileService } from '@content/layout/header/topbar/user-profile/user-profile.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject, throwError } from 'rxjs';
import { SearchType } from '@shared/enums/searchType';
import { SearchFacadeService } from '@store/services/search-facade.service';
import { GetAbgDataAndTotal, GetAbgItem, SetPostsDrawerStatus } from '@main/adc/adc-store/adc/actions';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AbgUrlHelper, SearchResultsItem } from '@main/adc/adc-data';
import { Loading } from '@store/common/actions';
import { IABGItem, IABGTag, selectFilters, selectStatusList } from '@main/adc/adc-store';
import { GraphQLResult } from '@main/adc/adc-shared/interfaces/graphQL';
import { ISearchParams, selectAdvancedSearch, selectSearchType, selectSearchValue } from '@store/search';
import { filterFunctions, filterOperatorMap, filtersMap } from '@shared/types/advanced-search';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ILogData } from '@core/interfaces/log-data';
import { AbgSource } from '@main/adc/adc-shared/types/abgSource';
import { selectUserId } from '@core/states/user.state';
import { ADC_TYPE_NAME_MAPPING } from '@main/adc/adc-shared/types/adcTypeIcon.mapping';
import { FilterState } from '@shared/enums/filter-state.enum';
import { selectSearchObjType } from '@store/search/index';
import { ExcelUtilsService, IExcelTemplate } from '@shared/services/excel-utils.service';
import moment from 'moment/moment';
import { ErrorService } from '@shared/services/error.service';
import { Locations } from '@shared/enums/locations.enum';
import { getLocation } from '@store/common';
import { selectAdcDashboardSearchFilters } from '@main/adc/adc-store/adc-dashboard';
import { CATALOG_EXCEL_TEMPLATE } from '@shared/types/adcMappings';

@Injectable({
    providedIn: 'root',
})
export class AbgService {
    searchTagVal = '';
    lists: SearchResultsItem;
    isObjectSuspend: boolean = false;
    fromPage: AbgSource;
    isLoading: boolean = true;
    isFetching: boolean = false;
    totalLoading$ = new BehaviorSubject<boolean>(true);
    items: any[];
    totalCount;
    url = '';
    comeFromBackBrowser = false;
    total = 0;
    statusList;
    searchResultsSuccess$ = new Subject();
    homeFilterCollapse$ = new BehaviorSubject<boolean>(true);
    dashboardFilterCollapse$ = new BehaviorSubject<boolean>(false);
    selectedAbgItem: IABGItem;
    filterMode = FilterState.Standard;
    initialFilterValues;
    filterState: typeof FilterState = FilterState;
    setActiveTab$ = new Subject<number>();
    exportAssetsPending = false;
    updateLayerSetting$ = new Subject<boolean>();
    selectedToolBySelectBox: any;
    smartSearchType: string;
    private isResetSelectedBySearchPagination: boolean;
    private requestCancel$ = new Subject();
    private baseUrl = environment.apiUrl;
    private searchFilters: boolean = false;
    private searchType: number;
    private filtered: boolean = false;

    constructor(
        private http: HttpClient,
        private store: Store<any>,
        private location: Location,
        private toastrService: ToastrService,
        private pageConfigService: PageConfigService,
        private httpService: HttpUtilsService,
        private userProfileService: UserProfileService,
        private subheaderService: SubheaderService,
        private router: Router,
        private route: ActivatedRoute,
        private logsService: LogsService,
        private searchService: SearchFacadeService,
        public abgUrlHelper: AbgUrlHelper,
        private excelUtilsService: ExcelUtilsService,
        private errorService: ErrorService,
        private httpUtilsService: HttpUtilsService,
    ) {
        this.abgUrlHelper.abgUrlData = {};
        this.comeFromBackBrowser = false;
        this.isResetSelectedBySearchPagination = false;
        this.registerBackNavigation();
        this.initFromPageAsStartup();
        this.lists = {};
        this.initList();

        this.store
            .pipe(
                select(selectFiltered),
                filter(filtered => filtered !== undefined),
            )
            .subscribe(res => {
                this.filtered = res;
            });

        this.store
            .pipe(
                select(selectAdcSearchFilters(this.fromPage)),
                filter(filters => filters !== undefined),
            )
            .subscribe(res => {
                this.searchFilters = res;
            });

        this.store
            .pipe(
                select(selectSearchType),
                filter(type => !!type)
                // take(1),
            )
            .subscribe(searchType => {
                this.searchType = searchType;
            });
    }

    public getAbgItem(id: string) {
        const payload = {
            operationName: null,
            query: `{ oBG { getObjectDetails(oBJECT_ID: "${id}", uSER_ID: "${this.userProfileService.user_obj.id}") { oBJECT_ID, oBJECT_NAME, tOOL_TYPE, lAYER, uPDATED_BY,oCT_SHORT_DESCRIPTION, oBJECT_DESCRIPTION,	iS_OBG_OBJECT, oCT_CALCULATION_DESCRIPTION, oCT_OBJECT_DESCRIPTION, oBJECT_TYPE, dATA_TYPE,	oBJECT_PATH, sOURCE_SYSTEM, oWNER, uSER_NAME, sTATUS, lAST_UPDATEDATE, eXPRESSION, dATA_STEWARD, iS_BOOKMARK, aBG_STATUS  }}}`,
            variables: null
        };
        return this.httpService.post(`graphql`, payload)
            .pipe(
                map((result: GraphQLResult) => result.data.oBG.getObjectDetails[0]),
            );
    }

    public setSelectedObjectOnListResults(abgItem: IABGItem, fromPage): void {
        if (abgItem) {
            const fItem: IABGItem = this.lists[fromPage].results.find((item: IABGItem) => item.oBJECT_ID === abgItem.oBJECT_ID);
            if (fItem) {
                fItem.selected = true;
                this.lists[this.fromPage].selectedObject = fItem;
            }
        }
    }

    public setSelectedObjectOnListResultsById(objectId: string, fromPage?): void {
        const fItem: IABGItem = this.lists[this.fromPage].results.find((item: IABGItem) => item.oBJECT_ID === objectId);
        if (fItem) {
            fItem.selected = true;
            this.lists[this.fromPage].selectedObject = fItem;
        }
    }

    public openSelectedObjectProperties(adcItem: IABGItem) {
        this.resetSelected();
        adcItem.selected = true;
        this.setSelectedObjectOnListResultsById(adcItem.oBJECT_ID);

        this.abgUrlHelper.abgUrlData.selectedItemId = adcItem.oBJECT_ID;
        this.abgUrlHelper.abgUrlData.selectedItem = adcItem;
        this.abgUrlHelper.setUrl();
        this.url = this.abgUrlHelper.url;

        this.selectedAbgItem = adcItem;
        this.store.dispatch(new GetAbgItem(adcItem.oBJECT_ID));

        // TODO: Future refactoring
        // const id = this.pageConfigService.encodeBtoa(JSON.stringify({
        //     selectedItemId: adcItem.oBJECT_ID,
        //     selectedItem: adcItem,
        // }));
        // this.router.navigate(['/adc', id]);
    }

    public resetSelected(fromPage?: AbgSource) {
        this.fromPage = fromPage ? fromPage : this.fromPage;
        this.lists[this.fromPage].results.forEach((item: IABGItem) => item.selected = false);
        this.lists[this.fromPage].selectedObject = null;
    }

    public initFromPageAsStartup(): void {
        this.fromPage = AbgSource.listResultsContainer;
    }

    public initList() {
        this.lists[this.fromPage] = {
            enhancedCount: 0,
            standardCount: 0,
            selectedObject: undefined,
            results: [],
            standardResults: [],
            enhancedResults: [],
            totalCount: 0,
            totalMsg: '',
            endOfResults: false,
            selectedBySearch: {
                isSearching: false,
                selectedValue: undefined,
                selectedLayers: [],
                pageNumber: 1,
                rowsInPage: 50,
                tags: [],
            },
            isFetchMore: false,
            isNewSearching: false,
            noResults: false
        };
    }

    public registerBackNavigation() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event['navigationTrigger'] === 'popstate') {
                    this.subheaderService.removeLastItem();
                }
            }
        });
    }

    public getAllResults() {
        // if (!this.lists[this.fromPage]) {
        //     this.initList();
        // }
        //
        // this.getSelectedLayers();
        //
        // let typesToServer = this.getAllLayerTypes(this.lists[this.fromPage].selectedBySearch.selectedLayers);
        // if (typesToServer === '') {
        //     typesToServer = 'layerType1: "Presentation", layerType2:"Semantic", layerType3:"Physical", layerType4: "ABG", layerType5: "Report"';
        // }
        // const searchValue = this.lists[this.fromPage].selectedBySearch.selectedValue;
        //
        // const searchValueToServer = this.setSearchValueToServer(this.lists[this.fromPage].selectedBySearch.selectedValue);
        //
        // combineLatest([
        //     this.getTotal(typesToServer, searchValueToServer, searchValue),
        //     this.getData(typesToServer, searchValueToServer),
        // ])
        //     .pipe(
        //         take(1),
        //     )
        //     .subscribe((results: any[]) => {
        //         const totalResults = results[0].data;
        //         const dataResults = results[1].data;
        //
        //         this.lists[this.fromPage].totalCount = totalResults ? totalResults.oBG.getTotalObjectsCount[0] : 0;
        //
        //         this.resultsMsg = 'Loading...';
        //         if (dataResults) {
        //             this.setObgResults(dataResults.oBG.searchObject);
        //         }
        //
        //         const totalCount = this.validateTotalCount(this.lists[this.fromPage].totalCount);
        //         // this.lists[this.fromPage].totalMsg = `Showing ${this.lists[this.fromPage].results.length} from ${totalCount} total assets`;
        //
        //         const selectedItemId = sessionStorage.getItem('selectedObjectFromUrl');
        //         if (selectedItemId != null) {
        //             this.setSelectedObjectOnListResultsById(selectedItemId);
        //         }
        //     });
    }

    public getAllResultsAsync(fromPage?: AbgSource, isFetching = false): Observable<any> {
        this.fromPage = fromPage ? fromPage : this.fromPage;

        return new Observable(observer => {
            if (!this.lists[this.fromPage]) {
                this.initList();
            }

            const toolFiltering = this.getToolFilters(this.fromPage as AbgSource);
            if (toolFiltering.length === 0 && _.isEmpty(this.selectedToolBySelectBox)) {
                observer.error();
                return;
            }

            const typesToServer = toolFiltering.length ? `toolFiltering: ${JSON.stringify(toolFiltering)}` : '';
            const searchValueToServer = this.setSearchValueToServer(this.lists[this.fromPage].selectedBySearch.selectedValue);
            const searchFilters = this.searchService.searchFiltersToGraphQLParams(this.fromPage as AbgSource);

            // Kill previous requests for total and data
            this.requestCancel$.next();

            this.store
                .pipe(select(selectStatusList))
                .subscribe(list => {
                    this.statusList = list;
                });

            this.isLoading = !isFetching;
            this.isFetching = isFetching;

            const pageNumber = this.lists[this.fromPage].selectedBySearch.pageNumber;
            const pageSize = this.lists[this.fromPage].selectedBySearch.rowsInPage;
            this.getData(searchValueToServer, typesToServer, searchFilters, pageNumber, pageSize)
                .pipe(
                    takeUntil(this.requestCancel$),
                )
                .subscribe((results: any) => {
                    this.isLoading = false;
                    this.isFetching = false;
                    const selectedItemId = sessionStorage.getItem('selectedObjectFromUrl');
                    if (selectedItemId != null) {
                        this.setSelectedObjectOnListResultsById(selectedItemId);
                    }

                    observer.next(results);
                });
        });
    }

    public setObgResults(results) {
        if (results.length === 0) {
            this.lists[this.fromPage].noResults = true;
        }

        this.lists[this.fromPage].results = [...this.lists[this.fromPage].results, ...results];
        if (this.fromPage === AbgSource.listResultsContainer) {
            this.lists[this.fromPage].standardResults = [...this.lists[this.fromPage].standardResults, ...results.filter(item => !item.sS_RESULT)];
            this.lists[this.fromPage].enhancedResults = [...this.lists[this.fromPage].enhancedResults, ...results.filter(item => item.sS_RESULT)];
            switch (this.filterMode) {
                case FilterState.Standard:
                    this.items = this.lists[this.fromPage].standardResults.map(this.extendAssetItemParams.bind(this));
                    this.totalCount = this.lists[this.fromPage].standardCount;
                    break;
                case FilterState.Enhanced:
                    this.items = this.lists[this.fromPage].enhancedResults.map(this.extendAssetItemParams.bind(this));
                    this.totalCount = this.lists[this.fromPage].enhancedCount;
                    break;
                default:
                    this.items = this.lists[this.fromPage].results.map(this.extendAssetItemParams.bind(this));
                    this.totalCount = this.lists[this.fromPage].totalCount;
            }
        } else {
            this.lists[this.fromPage].results = this.lists[this.fromPage].results.map(this.extendAssetItemParams.bind(this));
        }
    }

    public resetSelectedBySearchPagination() {
        this.isResetSelectedBySearchPagination = true;
        this.lists[this.fromPage].selectedBySearch.pageNumber = 1;
        this.lists[this.fromPage].selectedBySearch.rowsInPage = 50;
    }

    public setAbgItem(abgItem: IABGItem) {
        this.lists[this.fromPage].selectedObject = abgItem;
    }

    public layerToServer(selectedLayer: string): string {
        const arr = ['Presentation', 'ABG', 'Semantic', 'Physical', 'Report']; // OBG Item
        let layerToServer;
        layerToServer = _.find(arr, function (val) {
            if (selectedLayer.toLowerCase().indexOf(val.toLowerCase()) > -1) {
                return val;
            }
        });
        return (layerToServer.indexOf('ABG') > -1 ? layerToServer : layerToServer.toLowerCase());
    }

    public getSelectedLayers() {
        // const items = this.lists[this.fromPage].objectFilters;
        //
        // // send just the checked checkboxs
        // const selectedOnTrueLayers = [];
        // for (let i = 0; i < Object.keys(items).length; i++) {
        // 	if (Object.values(items)[i] === true) {
        // 		selectedOnTrueLayers.push(Object.keys(items)[i]);
        // 	}
        // }
        // if (selectedOnTrueLayers.length === Object.keys(this.lists[this.fromPage].objectFilters).length) {
        // 	this.lists[this.fromPage].selectedBySearch.selectedLayers = [];
        // } else {
        // 	this.lists[this.fromPage].selectedBySearch.selectedLayers = selectedOnTrueLayers;
        // }
    }

    public resetTagFilter() {
        this.lists[this.fromPage].results = [];
        this.resetSelectedBySearchPagination();
        this.lists[this.fromPage].selectedBySearch.tags = [];
    }

    public setSearchTag(tag: IABGTag) {
        this.getObjectByTag(tag);
        this.store.dispatch(new SetPostsDrawerStatus(PostsDrawerStatus.close));
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    public getResultsTotals(isFetch = false) {
        if (!isFetch) {
            this.totalLoading$.next(true);
            this.getResultsTotal()
                .subscribe(results => {
                    results.forEach(item => {
                        this.totalCount = item.tOTAL || 0;
                        this.lists[this.fromPage].totalCount += item.tOTAL;
                        if (item.sS_RESULT) {
                            this.lists[this.fromPage].enhancedCount = item.tOTAL;
                        } else {
                            this.lists[this.fromPage].standardCount = item.tOTAL;
                        }
                    });
                    this.totalLoading$.next(false);
                });
        }
    }

    public getResults(fromPage?, isFetching = false) {
        this.fromPage = fromPage || this.fromPage;
        this.lists[this.fromPage].noResults = false;
        this.setTotalMsg();

        this.getAllResultsAsync(this.fromPage, isFetching)
            .subscribe(results => {
                this.setObgResults(results);
            });
    }

    public getAdvancedResults(pageNum: number, searchParams, isFetching = false) {
        if (pageNum === 1) {
            this.writeToLog();
        }
        this.setTotalMsg();

        const getValue = (fieldName: string, filterType: string, filterValue: string): string => {
            if (filterValue === '') {
                return;
            }
            let tempVal = filterValue;
            if ([filterFunctions.contains, filterFunctions.notContains].includes(filterType as filterFunctions)) {
                tempVal = `%${filterValue}%`;
            }
            if (filterType === filterFunctions.startsWith) {
                tempVal = `${filterValue}%`;
            }
            if (filterType === filterFunctions.endsWith) {
                tempVal = `%${filterValue}`;
            }
            return `${fieldName}: "${tempVal}"`;
        };

        const gqlModule = 'oBG';
        const gqlAction = 'advancedObjectsSearch';
        const gqlTotalAction = 'getTotalObjectsCountForAdvancedSearch ';
        const gqlResultFields = 'oBJECT_ID, oBJECT_NAME, lAYER , oBJECT_DESCRIPTION, oBJECT_PATH, tOOL_TYPE, aBG_STATUS, oBJECT_TYPE, sS_RESULT';

        const gqlFilters = this.searchService.searchFiltersToGraphQLParams(this.fromPage as AbgSource);

        const toolFiltering = this.getToolFilters(AbgSource.listResultsContainer);
        const gqlLayers = toolFiltering.length ? `toolFiltering: ${JSON.stringify(toolFiltering)}` : '';

        const val1 = getValue('fIRST_VALUE_TO_SEARCH', searchParams.searchValue.filter1, searchParams.searchValue.filter1val);
        const val2 = getValue('sECOND_VALUE_TO_SEARCH', searchParams.searchValue.filter2, searchParams.searchValue.filter2val);
        const val3 = getValue('tHIRD_VALUE_TO_SEARCH', searchParams.searchValue.filter3, searchParams.searchValue.filter3val);

        const operator1 = `fIRST_OPERATOR: "${searchParams.searchValue.operator1.toUpperCase()}"`;
        const operator2 = `sECOND_OPERATOR: "${searchParams.searchValue.operator2.toUpperCase()}"`;

        const function1 = `fIRST_FUNCTION: "${filterOperatorMap[searchParams.searchValue.filter1]}"`;
        const function2 = `sECOND_FUNCTION: "${filterOperatorMap[searchParams.searchValue.filter2]}"`;
        const function3 = `tHIRD_FUNCTION: "${filterOperatorMap[searchParams.searchValue.filter3]}"`;

        const pageNumber = `pageNumber: "${pageNum}"`;
        const rowsInPage = `rowsInPage: "50"`;

        let searchType = `searchType: "Regular"`;
        switch (this.filterMode) {
            case FilterState.Standard:
                searchType = `searchType: "NoSS"`;
                break;
            case FilterState.Enhanced:
                searchType = `searchType: "OnlySS"`;
                break;
        }

        const tagId = searchParams.searchByTag ? `,tagId: "${searchParams.searchByTag.tagId}"` : '';

        let gqlParams;
        switch (searchParams.searchObjType) {
            case 'short':
                gqlParams = `${function1},${val1},${operator1},${function2},${val2}${tagId}`;
                break;
            case 'full':
                gqlParams = `${function1},${val1},${operator1},${function2},${val2},${operator2},${function3},${val3}${tagId}`;
                break;
            default:
                gqlParams = `${function1},${val1}${tagId}`;
        }

        const gqlSearchQuery =
            `{ ${gqlModule} { ${gqlAction} (${pageNumber},${rowsInPage},${searchType},${gqlParams},${gqlLayers}, ${gqlFilters}){${gqlResultFields}}}}`;

        const payload = {
            operationName: null,
            query: gqlSearchQuery,
            variables: null
        };

        const gqlTotalQuery = `{ ${gqlModule} { ${gqlTotalAction} (${gqlParams},${gqlLayers}) { sS_RESULT, tOTAL }}}`;
        const totalPayload = {
            operationName: null,
            query: gqlTotalQuery,
            variables: null
        };

        this.isLoading = !isFetching;
        this.isFetching = isFetching;

        this.http.post(`${this.baseUrl}graphql`, payload)
            .pipe(
                map((_result: GraphQLResult) => _result.data.oBG.advancedObjectsSearch)
            )
            .subscribe(_result => {
                this.isLoading = false;
                this.isFetching = false;
                this.setObgResults(_result);
            });

    }

    getObjectByTag(tag: any) {
        if (tag.is_not_exist) {
            this.searchTagVal = '';
            this.toastrService.error('Tag not exist!');
            return;
        }

        this.resetTagFilter();

        this.lists[this.fromPage].selectedBySearch.tags.push(tag.tAG_ID);
        this.lists[this.fromPage].selectedBySearch.tags = _.uniq(this.lists[this.fromPage].selectedBySearch.tags);

        this.searchService.setSearchTags({
            tagId: tag.tAG_ID,
            tagName: tag.tAG_NAME,
        });

        const searchType = this.searchService.getSearchType();
        if (searchType === SearchType.advanced) {
            this.startGraphQLAdvancedSearch();
        } else {
            this.getResults();
        }
    }

    public getFilters(filters) {
        const toolFiltering: string[][] = [];
        if (filters.adc) {
            toolFiltering.push(['adc']);
        }
        filters.filters.forEach(_mapItem => {
            const checkedFlagNames = _mapItem.items.filter(_flag => _flag.checked).map(_flag => _flag.key);
            if (checkedFlagNames.length) {
                toolFiltering.push([_mapItem.key || _mapItem.name, ...checkedFlagNames]);
            }
        });
        return toolFiltering;
    }

    public isFiltered(a, b) {
        return JSON.stringify(a) !== JSON.stringify(b);
    }

    // public resetTags() {
    // 	const searchType = this.searchService.getSearchType();
    //
    // 	this.resetTagFilter();
    // 	this.searchTagVal = '';
    //
    // 	this.searchService.setSearchTags();
    //
    // 	if (searchType === SearchType.advanced) {
    // 		this.searchService.setSearchType(SearchType.advanced);
    // 		this.startGraphQLAdvancedSearch();
    // 	} else {
    // 		this.searchService.setSearchType(SearchType.default);
    // 		this.getResults();
    // 	}
    // }

    public getRid(adcItem) {
        // 1- startWith	 2- endWith		3- contains		4-equal

        const keyValues = [
            {
                txtSearch: adcItem.oBJECT_NAME,
                operator: 4,
                txtfield: 'ObjectName',
                connector: ''
            },
            {
                txtSearch: adcItem.oBJECT_PATH.split('.')[0] || '',
                operator: 4,
                txtfield: 'DatabaseName',
                connector: 'and'
            },
            {
                txtSearch: adcItem.oBJECT_PATH.split('.')[1] || '',
                operator: 4,
                txtfield: 'SchemaName',
                connector: 'and'
            },
            {
                txtSearch: '0',
                operator: 4,
                txtfield: 'IsFaked',
                connector: 'and'
            },
        ];

        if (adcItem.tOOL_TYPE === 'SQL Server') {
            keyValues.push({
                txtSearch: 'sqlserverdbwa03testETLTRUE',
                operator: 4,
                txtfield: 'ConnLogicName',
                connector: 'and'
            });
        }

        const payload = {
            outputFields: '*',
            keyValues,
        };

        return this.httpService.post('lineage/search', payload);
    }

    public getToolFilters(adsSource: AbgSource) {

        const toolFiltering = [];


        if (!_.isEmpty(this.selectedToolBySelectBox)) {
            // hila
            let filterGroupsArray = [];
            filterGroupsArray = Array.from(this.selectedToolBySelectBox);
            filterGroupsArray.forEach(group => {

                if (group[0] == 'Custom Asset Types') {
                    const tools = this.selectedToolBySelectBox.get(group[0]);
                    tools.forEach(tool => {
                        if (tool.isChecked && tool.isEnable) {
                            toolFiltering.push([tool.name]);
                        }
                    });
                } else {
                    const checkedTools = group[1].filter(tool => tool['isChecked'] && tool['isEnable']);
                    if (checkedTools.length > 0) {
                        checkedTools.forEach(checkedTool => {
                            const checkedFlagNames = checkedTool.items.filter(_flag => _flag.checked).map(_flag => _flag.key);
                            if (checkedFlagNames.length) {
                                toolFiltering.push([checkedTool.name, ...checkedFlagNames]);

                                //
                                // checkedFlagNames.forEach(flag => {
                                // 	toolFiltering.push([checkedTool.name, ...flag]);
                                // });
                            }
                        });

                    }
                }

            });

            // this.selectedToolBySelectBox.forEach(group => {
            // 	if (['Custom Asset Types'].includes(group)) {
            // 		this.selectedToolBySelectBox[group].forEach(tool => {
            // 			if (tool.isChecked) {
            // 				toolFiltering.push([tool.name]);
            // 			}
            // 		});
            // 	} else {
            // 		const checkedTools = this.selectedToolBySelectBox[group].filter(tool => tool['isChecked']);
            // 		if (checkedTools.length > 0) {
            // 			checkedTools.forEach(checkedTool => {
            // 				const checkedFlagNames = checkedTool.items.filter(_flag => _flag.checked).map(_flag => _flag.key);
            // 				if (checkedFlagNames.length) {
            // 					toolFiltering.push([checkedTool.name, ...checkedFlagNames]);
            //
            // 					//
            // 					// checkedFlagNames.forEach(flag => {
            // 					// 	toolFiltering.push([checkedTool.name, ...flag]);
            // 					// });
            // 				}
            // 			});
            //
            // 		}
            // 	}
            // });


        } else {
            this.store
                .pipe(
                    select(selectFilters(adsSource)),
                    filter((adcFilters: Array<any>) => !!adcFilters),
                    take(1),
                )
                .subscribe((adcFilters: Array<any>) => {
                    adcFilters.forEach(mapItem => {
                        if (['Custom Asset Types'].includes(mapItem.group) && mapItem.checked) {
                            toolFiltering.push([mapItem.name]);
                        } else {
                            const checkedFlagNames = mapItem.items.filter(_flag => _flag.checked).map(_flag => _flag.key);
                            if (checkedFlagNames.length) {
                                toolFiltering.push([mapItem.key || mapItem.name, ...checkedFlagNames]);
                            }
                        }
                    });
                });
        }


        return toolFiltering;
    }

    public startGraphQLAdvancedSearch(pageNumber: number = 1) {
        this.store
            .pipe(
                select(selectAdvancedSearch),
                filter((advancedSearch: ISearchParams) => !!advancedSearch && !!advancedSearch.searchValues),
                take(1),
            )
            .subscribe(this.buildSearchQuery.bind(this, pageNumber));
    }

    setSearchCondition(searchValue): string {
        if (searchValue) {
            let searchCondition = '';
            searchCondition = `${searchValue.bracketsCondition === 1 ? '( ' : ''}`;
            searchCondition += `${filtersMap[searchValue.filter1]} ${searchValue.filter1val} `;
            searchCondition += `${searchValue.filter2val ? searchValue.operator1.toUpperCase() : ''}`;
            searchCondition += `${searchValue.bracketsCondition === 2 ? ' ( ' : ''}`;
            searchCondition += searchValue.filter2val ? ` ${filtersMap[searchValue.filter2]} ${searchValue.filter2val} ` : '';
            searchCondition += `${searchValue.bracketsCondition === 1 ? ' ) ' : ''}`;
            searchCondition += `${searchValue.filter3val ? searchValue.operator2.toUpperCase() : ''}`;
            searchCondition += searchValue.filter3val ? ` ${filtersMap[searchValue.filter3]} ${searchValue.filter3val} ` : '';
            searchCondition += `${searchValue.bracketsCondition === 2 ? ' ) ' : ''}`;
            return `${searchCondition}`;
        }
        // if (search) {
        //     let searchCondition = '';
        //     searchCondition = `${search.searchValues.bracketsCondition === 1 ? '( ' : ''}`;
        //     searchCondition += `${filtersMap[search.searchValues.filter1]} ${search.searchValues.filter1val} `;
        //     searchCondition += `${search.searchValues.filter2val ? search.searchValues.operator1.toUpperCase() : ''}`;
        //     searchCondition += `${search.searchValues.bracketsCondition === 2 ? ' ( ' : ''}`;
        //     searchCondition += search.searchValues.filter2val ? ` ${filtersMap[search.searchValues.filter2]} ${search.searchValues.filter2val} ` : '';
        //     searchCondition += `${search.searchValues.bracketsCondition === 1 ? ' ) ' : ''}`;
        //     searchCondition += `${search.searchValues.filter3val ? search.searchValues.operator2.toUpperCase() : ''}`;
        //     searchCondition += search.searchValues.filter3val ? ` ${filtersMap[search.searchValues.filter3]} ${search.searchValues.filter3val} ` : '';
        //     searchCondition += `${search.searchValues.bracketsCondition === 2 ? ' ) ' : ''}`;
        //     return `${searchCondition}`;
        // }
        return '';
    }

    public buildURL(url, filters) {
        let newURL;
        if (url) {
            const decURL = JSON.parse(this.pageConfigService.decodeBtoa(url));
            newURL = this.pageConfigService.encodeBtoa(JSON.stringify(
                {...decURL, filters: filters, filtered: this.filtered}
            ));
        } else {
            newURL = this.pageConfigService.encodeBtoa(JSON.stringify({
                filters: filters,
                filtered: this.filtered
            }));
        }
        return newURL;
    }

    public getResultsTotal(): Observable<any> {
        const toolFiltering = this.getToolFilters(this.fromPage as AbgSource);
        const typesToServer = toolFiltering.length ? `toolFiltering: ${JSON.stringify(toolFiltering)}` : '';
        const searchValue = this.lists[this.fromPage].selectedBySearch.selectedValue;
        const searchValueToServer = this.setSearchValueToServer(this.lists[this.fromPage].selectedBySearch.selectedValue);
        const searchFilters = this.searchService.searchFiltersToGraphQLParams(this.fromPage as AbgSource);

        return this.getTotal(typesToServer, searchValueToServer, searchValue, searchFilters)
            .pipe(
                map(response => response.data.oBG.getTotalObjectsCount),
                catchError(error => {
                    this.errorService.failureCounter++;
                    this.errorService.failureCounterSubject$.next();
                    return throwError(error);
                })
            );
    }

    // **** Attributes ***
    // getAttributes(adcAssetId: string, adcAssetType): Observable<any> {
    // 	const payload = {
    // 		operationName: null,
    // 		query: `{ oBG { getObjectCustomAttributes(oBJECT_ID: "${adcAssetId}", oBJECT_TYPE: "${adcAssetType}") { oBJECT_ID, cUSTOM_ATTRIBUTES  }}}`,
    // 		variables: null
    // 	};
    // 	return this.httpService.post('graphql', payload);
    // }

    public saveAttribute(data) {
        let userId;
        this.store
            .pipe(
                select(selectUserId),
                take(1),
            )
            .subscribe(value => {
                userId = value;
            });

        const payload = {
            operationName: null,
            query: `mutation ($obgObject : ObgUpdateAttributeInputType) { obgAttributeMutations (obgObject: $obgObject) { updateAttribute (objectInput: $obgObject) { oBJECT_ID } } }`,
            variables: {
                obgObject: {
                    oBJECT_ID: data.adcAssetId,
                    aTTRIBUTE_ID: data.adcAttrId,
                    aTTRIBUTE_VALUE: data.value,
                    aTTRIBUTE_TYPE: data.adcAttrType,
                    uPDATED_BY: userId,
                }
            }
        };

        return this.httpService.post('graphql', payload);
        // **** Custom Attributes ***
    }

    public getAssetIcon(asset: IABGItem) {
        // hila
        return ADC_TYPE_NAME_MAPPING[asset.oBJECT_TYPE];
    }

    public getAdvancedTotal(payload) {
        return this.http.post(`${environment.apiUrl}graphql`, payload)
            .pipe(
                map((result: GraphQLResult) => result.data.oBG.getTotalObjectsCountForAdvancedSearch)
            );
    }

    public handleSimpleSearch(searchValue) {
        // *********************
        // Reflect search in URL
        const abgUrlData = this.abgUrlHelper.abgUrlData;

        if (searchValue === '') {
            abgUrlData.searchType = null;
            abgUrlData.searchValue = null;
        }

        const value = searchValue || (abgUrlData && abgUrlData.searchValue);

        this.abgUrlHelper.abgUrlData.searchType = SearchType.default;
        this.abgUrlHelper.abgUrlData.searchValue = value;
        this.abgUrlHelper.setUrl();
        this.url = this.abgUrlHelper.url;
        // *********************

        this.lists[this.fromPage].selectedBySearch.selectedValue = value;

        this.initResults();
        this.getResults();
    }

    public handleAdvancedSearch(searchValue) {
        let searchLog = '';
        searchLog = `${searchValue.bracketsCondition === 1 ? '( ' : ''}`;
        searchLog += `${filtersMap[searchValue.filter1]} ${searchValue.filter1val}`;
        searchLog += ` ${searchValue.filter2val ? searchValue.operator1.toUpperCase() : ''}`;
        searchLog += `${searchValue.bracketsCondition === 2 ? ' ( ' : ''}`;
        searchLog += searchValue.filter2val ? ` ${filtersMap[searchValue.filter2]} ${searchValue.filter2val}` : '';
        searchLog += `${searchValue.bracketsCondition === 1 ? ' ) ' : ''}`;
        searchLog += ` ${searchValue.filter3val ? searchValue.operator2.toUpperCase() : ''}`;
        searchLog += searchValue.filter3val ? ` ${filtersMap[searchValue.filter3]} ${searchValue.filter3val}` : '';
        searchLog += `${searchValue.bracketsCondition === 2 ? ' ) ' : ''}`;

        let searchObjType: string;
        this.store
            .pipe(
                select(selectSearchObjType),
                take(1),
            )
            .subscribe(value => searchObjType = value);

        this.abgUrlHelper.abgUrlData = {
            ...this.abgUrlHelper.abgUrlData,
            searchType: SearchType.advanced,
            searchObjType,
            searchValue,
        };

        this.abgUrlHelper.setUrl();
        this.url = this.abgUrlHelper.url;
        // ******************************
        this.store.dispatch(new Loading(true));
        this.initResults();
        this.lists[this.fromPage].noResults = false;
        this.getAdvancedResults(
            this.lists[this.fromPage].selectedBySearch.pageNumber, {
                searchValue,
                searchObjType,
            }, false);
    }

    setSearchValueToServer(selectedValue): string {
        let searchValueToServer = '';
        if (selectedValue) {
            selectedValue = selectedValue.replace(/(["])/g, '\\$1');
            searchValueToServer = 'searchContent: "%' + selectedValue.trim() + '%"';
        }
        return searchValueToServer;
    }

    async exportDataCatalog() {
        const customAttr = await this.getCustomAttributesForExport();
        const customAttrFields = customAttr.map(i => i.aLIAS).join(',');
        const customAttrExcelTemplate = customAttr.map(i => {
            return {colName: i.aTTRIBUTE_NAME, field: i.aLIAS};
        });

        this.exportAssetsPending = true;
        const a$ = this.store.pipe(select(selectSearchValue));
        const b$ = this.store.pipe(select(selectAdcSearchFilters(AbgSource.listResultsContainer)));
        const c$ = this.store.pipe(select(selectFilters(AbgSource.listResultsContainer)));
        const d$ = this.store.pipe(select(selectSearchType));
        const e$ = this.store.pipe(select(selectSearchObjType));
        combineLatest([a$, b$, c$, d$, e$])
            .pipe(
                take(1),
                switchMap(values => {
                    const searchType = values[3] || SearchType.default;
                    const searchObjType = values[4];
                    let searchValue;
                    if (searchType === SearchType.default) {
                        searchValue = values[0] ? `searchContent: "%${values[0].trim()}%"` : null;
                    } else {
                        searchValue = this.buildAdvancedSearchQuery(values[0], searchObjType);
                    }
                    const layersFilter = `toolFiltering: ${JSON.stringify(this.getToolFilters(AbgSource.listResultsContainer))}`;
                    const filters = this.searchService.searchFiltersToGraphQLParams(AbgSource.listResultsContainer);
                    return this.bulkExport(searchValue, layersFilter, filters, searchType, customAttrFields);
                }),
            )
            .subscribe(results => {
                const excelTemplate: IExcelTemplate[] = [
                    ...CATALOG_EXCEL_TEMPLATE,
                    ...customAttrExcelTemplate,
                ];

                const excelName = `Data Catalog export ${moment().format('YYYYDDMM HHMMSS')}`;
                this.excelUtilsService.export(excelName, excelTemplate, results, 'assets', this.totalCount);
                this.exportAssetsPending = false;
            });
    }

    public getCustomAttributesForExport(): Promise<any> {
        return new Promise(resolve => {
            const payload = {
                operationName: null,
                query: '{ oBG { getCustomAttributes { aTTRIBUTE_NAME, aTTR_DISPLAY_ORDER, aLIAS }}}',
                variables: null
            };
            this.httpService.post('graphql', payload)
                .pipe(
                    map(results => results.data.oBG.getCustomAttributes),
                )
                .subscribe(r => resolve(r));
        });
    }

    subscribeToSearchFilters() {
        const selector = () => {
            let location: Locations;
            this.store
                .pipe(
                    select(getLocation),
                    take(1),
                )
                .subscribe((value: Locations) => location = value);
            switch (location) {
                case Locations.adc:
                    return selectAdcSearchFilters(this.fromPage);
                case Locations.adcDashboard:
                    return selectAdcDashboardSearchFilters;
            }
        };
        return this.store
            .pipe(
                select(selector()),
                filter(searchFilters => !!searchFilters),
            );
    }

    subscribeToFilterChanges() {
        return this.store
            .pipe(
                select(selectFilters(this.fromPage)),
            );
    }

    subscribeToSearch() {
        return this.store
            .pipe(
                select(selectSearchValue),
            );
    }

    public getUsageData(usagePayload: any) {
        const payload = {
            operationName: null,
            query: `{ oBG {  getObjectUsage(oBJECT_ID: "${usagePayload.objectId}", uSER_ID: "${this.userProfileService.user_obj.id}", tOOL_TYPE: "${usagePayload.toolType}" ) {  lAST_RUN_DATE, lAST_RUN_BY_USER, lAST_CHANGE_DATE, pERFORMANCE, nUMBER_OF_RUNS }}}`,
            variables: null
        };
        return this.httpService.post(`graphql`, payload);
    }

    private extendAssetItemParams(item) {
        const selectedStatus = this.statusList.find(status => item?.aBG_STATUS == status.sTATUS_ID);
        return {
            ...item,
            colorStatus: selectedStatus?.sTATUS_COLOR,
            statusName: selectedStatus?.sTATUS_NAME,
            icon: item.oBJECT_TYPE.replace(/\s/g, '').toLowerCase(),
        };
    }

    private mapResults(array) {
        array.map(item => {
            const selectedStatus = this.statusList.find(status => item?.aBG_STATUS == status.sTATUS_ID);
            return {
                ...item,
                // objectType: AssetTypesMapping[item.oBJECT_TYPE] || item.oBJECT_TYPE,
                colorStatus: selectedStatus?.sTATUS_COLOR,
                statusName: selectedStatus?.sTATUS_NAME,
                icon: item.oBJECT_TYPE.replace(/\s/g, '').toLowerCase(),
            };
        });
    }

    private bulkExport(searchValue, layersFilter, filters, searchType, customAttrFields): Observable<any> {
        const payload = [];
        if (!!searchValue) {
            payload.push(searchValue);
        }
        if (!!layersFilter) {
            payload.push(layersFilter);
        }
        if (!!filters) {
            payload.push(filters);
        }

        this.store
            .pipe(
                select(selectSort),
                take(1),
            )
            .subscribe(sort => payload.push(`sortBy: "${sort}"`));

        if (this.lists[this.fromPage].selectedBySearch.pageNumber === 1) {
            this.writeToLog(filters);
        }

        let smartSearchType = 'Regular';
        switch (this.filterMode) {
            case FilterState.Standard:
                smartSearchType = 'NoSS';
                break;
            case FilterState.Enhanced:
                smartSearchType = 'OnlySS';
                break;
        }

        const query = `{ oBG { ${searchType === SearchType.default ? 'bulkExport' : 'bulkExportAdvanced '} ( pageNumber:"0", rowsInPage: "0" ,searchType: "${smartSearchType}", ${payload.join(', ')} ) { lAYER, tOOL_NAME, oBJECT_TYPE, oBJECT_NAME, oBJECT_PATH, dATA_TYPE, rATING, aBG_STATUS, iS_SENSITIVE, oCT_OBJECT_DESCRIPTION, oBJECT_DESCRIPTION, oCT_SHORT_DESCRIPTION, oCT_CALCULATION_DESCRIPTION, eXPRESSION, sOURCE_SYSTEM, oWNER, dATA_STEWARD, tAG_NAMES, ${customAttrFields} }}}`;

        const dataToServer = {
            operationName: null,
            query: query,
            variables: null
        };
        return this.httpService.post('graphql', dataToServer)
            .pipe(
                map(results => searchType === SearchType.default ? results.data.oBG.bulkExport : results.data.oBG.bulkExportAdvanced),
            );
    }

    private getData(searchValue, layersFilter, filters, pageNumber = 0, pageSize = 0): Observable<any> {
        const tagsValueToServer = this.setTagsValueToServer(this.lists[this.fromPage].selectedBySearch.tags);

        const payload = [];
        if (!!searchValue) {
            payload.push(searchValue);
        }
        if (!!layersFilter) {
            payload.push(layersFilter);
        }
        if (!!tagsValueToServer) {
            payload.push(tagsValueToServer);
        }
        if (!!filters) {
            payload.push(filters);
        }

        this.store
            .pipe(
                select(selectSort),
                take(1),
            )
            .subscribe(sort => payload.push(`sortBy: "${sort}"`));

        // if (this.lists[this.fromPage].selectedBySearch.pageNumber === 1) {
        // 	this.writeToLog(filters);
        // }

        let searchType = 'Regular';
        switch (this.filterMode) {
            case FilterState.Standard:
                searchType = 'NoSS';
                break;
            case FilterState.Enhanced:
                searchType = 'OnlySS';
                break;
        }

        const query = `{ oBG { searchObject ( pageNumber:"${pageNumber}", rowsInPage: "${pageSize}" ,searchType: "${searchType}", ${payload.join(', ')} ) { oBJECT_NAME, lAYER, oBJECT_DESCRIPTION, oCT_SHORT_DESCRIPTION, oBJECT_PATH, oBJECT_ID, tOOL_TYPE, aBG_STATUS, oBJECT_TYPE, sS_RESULT }}}`;

        const dataToServer = {
            operationName: null,
            query: query,
            variables: null
        };
        return this.httpService.post('graphql', dataToServer)
            .pipe(
                tap(() => {
                    if (this.lists[this.fromPage].selectedBySearch.pageNumber === 1) {
                        this.writeToLog(filters);
                    }
                }),
                map(results => results.data.oBG.searchObject),
                catchError(error => {
                    this.errorService.failureCounter++;
                    this.errorService.failureCounterSubject$.next();
                    return throwError(error);
                })
            );
    }

    private setTotalMsg() {
        if (this.lists[this.fromPage].totalCount > 0) {
            const totals = new DecimalPipe('en-US').transform(this.lists[this.fromPage].totalCount);
            this.lists[this.fromPage].totalMsg = `Showing ${this.lists[this.fromPage].results.length} of ${totals} total assets`;
        } else if (this.lists[this.fromPage].totalCount === 0) {
            this.lists[this.fromPage].totalMsg = 'No Results';
        } else {
            this.lists[this.fromPage].totalMsg = 'Loading...';
        }
    }

    private setStatusColor() {
    }

    private buildSearchQuery(pageNum: number, advancedSearch: ISearchParams) {
        const getValue = (fieldName: string, filterType: string, filterValue: string): string => {
            if (filterValue === '') {
                return;
            }
            let tempVal = filterValue;
            if (filterType === filterFunctions.contains) {
                tempVal = `%${filterValue}%`;
            }
            if (filterType === filterFunctions.startsWith) {
                tempVal = `${filterValue}%`;
            }
            if (filterType === filterFunctions.endsWith) {
                tempVal = `%${filterValue}`;
            }
            return `${fieldName}: "${tempVal}"`;
        };

        const gqlModule = 'oBG';
        const gqlAction = 'advancedObjectsSearch';
        const gqlTotalAction = 'getTotalObjectsCountForAdvancedSearch ';
        const gqlResultFields = 'oBJECT_ID, oBJECT_NAME, lAYER , oBJECT_DESCRIPTION, tOOL_TYPE, aBG_STATUS';

        const toolFiltering = this.getToolFilters(AbgSource.listResultsContainer);

        const gqlLayers = toolFiltering.length ? `toolFiltering: ${JSON.stringify(toolFiltering)}` : '';

        const val1 = getValue('fIRST_VALUE_TO_SEARCH', advancedSearch.searchValues.filter1, advancedSearch.searchValues.filter1val);
        const val2 = getValue('sECOND_VALUE_TO_SEARCH', advancedSearch.searchValues.filter2, advancedSearch.searchValues.filter2val);
        const val3 = getValue('tHIRD_VALUE_TO_SEARCH', advancedSearch.searchValues.filter3, advancedSearch.searchValues.filter3val);

        const operator1 = `fIRST_OPERATOR: "${advancedSearch.searchValues.operator1.toUpperCase()}"`;
        const operator2 = `sECOND_OPERATOR: "${advancedSearch.searchValues.operator2.toUpperCase()}"`;

        const function1 = `fIRST_FUNCTION: "${filterOperatorMap[advancedSearch.searchValues.filter1]}"`;
        const function2 = `sECOND_FUNCTION: "${filterOperatorMap[advancedSearch.searchValues.filter2]}"`;
        const function3 = `tHIRD_FUNCTION: "${filterOperatorMap[advancedSearch.searchValues.filter3]}"`;

        const pageNumber = `pageNumber:"${pageNum}"`;
        const rowsInPage = `rowsInPage:"50"`;

        const tagId = advancedSearch.searchByTag ? `,tagId: "${advancedSearch.searchByTag.tagId}"` : '';

        // TODO: Not supported
        const searchFilters = '';

        let gqlParams;
        switch (advancedSearch.searchObjType) {
            case 'short':
                gqlParams = `${function1},${val1},${operator1},${function2},${val2}${tagId}${searchFilters}`;
                break;
            case 'full':
                gqlParams = `${function1},${val1},${operator1},${function2},${val2},${operator2},${function3},${val3}${tagId}${searchFilters}`;
                break;
            default:
                gqlParams = `${function1},${val1}${tagId}${searchFilters}`;
        }

        const gqlSearchQuery =
            `{ ${gqlModule} { ${gqlAction} (${pageNumber},${rowsInPage},${gqlParams},${gqlLayers}){${gqlResultFields}}}}`;

        const payload = {
            operationName: null,
            query: gqlSearchQuery,
            variables: null
        };

        const gqlTotalQuery = `{ ${gqlModule} { ${gqlTotalAction} (${gqlParams},${gqlLayers})}}`;
        const totalPayload = {
            operationName: null,
            query: gqlTotalQuery,
            variables: null
        };

        this.store.dispatch(new GetAbgDataAndTotal({
            dataPayload: payload,
            totalPayload: totalPayload,
        }));
    }

    private getTotal(typesToServer: string, searchValueToServer: any, searchValue: string, searchFilters?): Observable<any> {
        if (!this.lists[this.fromPage].isFetchMore && this.lists[this.fromPage].isNewSearching) {
        } else {
            this.isResetSelectedBySearchPagination = false;
        }

        const tagsValueToServer = this.setTagsValueToServer(this.lists[this.fromPage].selectedBySearch.tags);

        const payload = [];

        let smartSearchType = 'Regular';
        switch (this.filterMode) {
            case FilterState.Standard:
                smartSearchType = 'NoSS';
                break;
            case FilterState.Enhanced:
                smartSearchType = 'OnlySS';
                break;
        }

        this.smartSearchType = `searchType: "${smartSearchType}"`;

        if (!!searchValueToServer) {
            payload.push(searchValueToServer);
        }
        if (!!typesToServer) {
            payload.push(typesToServer);
        }
        if (tagsValueToServer) {
            payload.push(tagsValueToServer);
        }
        if (searchFilters) {
            payload.push(searchFilters);
        }

        const dataToServerForTotals = {
            operationName: null,
            query: `{ oBG { getTotalObjectsCount (${this.smartSearchType}, ${payload.join(', ')}) { sS_RESULT, tOTAL }} }`,
            variables: null
        };

        return this.httpService.post(`graphql`, dataToServerForTotals)

            .pipe(
                catchError(error => {
                    this.errorService.failureCounter++;
                    this.errorService.failureCounterSubject$.next();
                    return throwError(error);
                })
            );
    }

    private validateTotalCount(totalCount: any) {
        const resultsCount = this.lists[this.fromPage].results.length;
        // validate is a valid number
        if (resultsCount > totalCount) {
            totalCount = resultsCount;
        }

        if (resultsCount < 50 && (totalCount >= 50 || resultsCount < totalCount)) {
            totalCount = resultsCount;
        }

        return this.numberWithCommas(totalCount);
    }

    private getAllLayerTypes(selectedLayers): string {
        let typesToServer = '';
        let split = '';

        for (let i = 0; i < selectedLayers.length; i++) {
            split = (typesToServer !== '' ? ' , ' : '');
            const layerNumber = i + 1;
            typesToServer += (split + 'layerType' + layerNumber + ': "' + this.layerToServer(selectedLayers[i]) + '"');
        }
        return typesToServer;
    }

    private setTagsValueToServer(selectedTags): string {
        let typesToServer = '';
        let split = '';

        for (let i = 0; i < selectedTags.length; i++) {
            split = (typesToServer !== '' ? ' , ' : '');
            const layerNumber = i + 1;
            typesToServer += (split + 'tagId: "' + selectedTags[i] + '"');
        }

        return typesToServer;
    }

    private writeToLog(searchFilters?) {
        if (this.abgUrlHelper.url) {
            const url = JSON.parse(this.pageConfigService.decodeBtoa(this.abgUrlHelper.url));
            if (!url.searchValue && url.selectedItemId && !this.filtered) {
                return;
            }
        }

        let searchType;
        this.store
            .pipe(
                select(selectSearchType),
                take(1),
            )
            .subscribe(_searchType => searchType = _searchType);

        let searchValue;
        this.store
            .pipe(
                select(selectSearchValue),
                take(1),
            )
            .subscribe(_searchValue => searchValue = _searchValue);

        if (this.abgUrlHelper.url || this.filtered === true) {
            searchValue = searchType === SearchType.default ? searchValue : this.setSearchCondition(searchValue);
            const log: ILogData = {
                usage_id: '-1',
                search_value: searchValue,
                module: 'Automated Data Catalog',
                activity: 'Search',
                url: this.buildURL(this.abgUrlHelper.url, this.searchFilters),
                filtered: this.filtered
            };
            this.logsService.writeToUsage(log);
        }
    }

    private initResults() {
        this.lists[this.fromPage].results = [];
        this.lists[this.fromPage].standardResults = [];
        this.lists[this.fromPage].enhancedResults = [];
        this.lists[this.fromPage].selectedBySearch.pageNumber = 1;
        this.lists[this.fromPage].selectedBySearch.rowsInPage = 50;
    }

    private getAssetsByIds() {
        return this.httpService.post(``);
    }

    private buildAdvancedSearchQuery(searchValue, searchObjType) {
        const getValue = (fieldName: string, filterType: string, filterValue: string): string => {
            if (filterValue === '') {
                return;
            }
            let tempVal = filterValue;
            if ([filterFunctions.contains, filterFunctions.notContains].includes(filterType as filterFunctions)) {
                tempVal = `%${filterValue}%`;
            }
            if (filterType === filterFunctions.startsWith) {
                tempVal = `${filterValue}%`;
            }
            if (filterType === filterFunctions.endsWith) {
                tempVal = `%${filterValue}`;
            }
            return `${fieldName}: "${tempVal}"`;
        };

        const val1 = getValue('fIRST_VALUE_TO_SEARCH', searchValue.filter1, searchValue.filter1val);
        const val2 = getValue('sECOND_VALUE_TO_SEARCH', searchValue.filter2, searchValue.filter2val);
        const val3 = getValue('tHIRD_VALUE_TO_SEARCH', searchValue.filter3, searchValue.filter3val);

        const operator1 = `fIRST_OPERATOR: "${searchValue.operator1.toUpperCase()}"`;
        const operator2 = `sECOND_OPERATOR: "${searchValue.operator2.toUpperCase()}"`;

        const function1 = `fIRST_FUNCTION: "${filterOperatorMap[searchValue.filter1]}"`;
        const function2 = `sECOND_FUNCTION: "${filterOperatorMap[searchValue.filter2]}"`;
        const function3 = `tHIRD_FUNCTION: "${filterOperatorMap[searchValue.filter3]}"`;

        let gqlQuery;
        switch (searchObjType) {
            case 'short':
                gqlQuery = `${function1},${val1},${operator1},${function2},${val2}`;
                break;
            case 'full':
                gqlQuery = `${function1},${val1},${operator1},${function2},${val2},${operator2},${function3},${val3}`;
                break;
            default:
                gqlQuery = `${function1},${val1}`;
        }

        return gqlQuery;
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { SideBarService } from '@core/services/layout/side-bar.service';
import * as objectPath from 'object-path';
import { LayoutConfigService } from '@core/services/layout-config.service';
import { Observable, combineLatest } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { CommonState, getLocation } from '@store/common';
import { Router } from '@angular/router';

@Component({
	selector: 'oct-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
	public headerLogo: any = '';
	private menuAsideLeftSkin: any = '';
	@Input() locationIcon: string = 'config.aside.left.skin';
	// @Input() showMe: boolean = true;
	// public showMenu$: Observable<boolean>;

	constructor(
		private sideBarService: SideBarService,
		private layoutConfigService: LayoutConfigService,
		private commonStore: Store<CommonState>,
		private router: Router
	) {
	}

	ngOnInit() {
		this.layoutConfigService.onLayoutConfigUpdated$
			.subscribe(model => {
				this.menuAsideLeftSkin = objectPath.get(model, this.locationIcon);
				const headerLogo = objectPath.get(model, 'config.header.self.logo');
				if (typeof headerLogo === 'object') {
					this.headerLogo = objectPath.get(headerLogo, this.menuAsideLeftSkin);
				} else {
					this.headerLogo = headerLogo;
				}
			});

		// this.showMenu$ = combineLatest([
		// 	this.commonStore.pipe(select(getLocation)),
		// 	this.commonStore.pipe(select(getSubLocation))
		// ])
		// 	.pipe(
		// 		map(locations => {
		// 			const location = locations[0];
		// 			const subLocation = locations[1];
		// 			return (location === 'discovery' || location === 'lineage' || location === 'obg') &&
		// 				(subLocation === 'LineageSchema' || subLocation === 'Map');
		// 		})
		// 	);
	}

	public go() {
		this.router.navigate(['/']);
		this.closeConnectionMenu();
	}

	private closeConnectionMenu(): void {
		if (this.sideBarService.contentName === 'connections') {
			this.sideBarService.close('connections');
		} else {
			this.sideBarService.close('properties');
		}
		this.sideBarService.setIsNodeSelectedFlag(false);
	}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClipboardService } from '@core/services/clipboard.sevice';
import { IToolBarItem, IToolBarItemComponent } from '@shared/components/toolbar/toolbar.interfaces';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  public items$ = new Subject<IToolBarItem[]>();
  public schemaLoad$ = new Subject<boolean>();
  public slider$ = new BehaviorSubject<any>({});
  public componentsItems$ = new BehaviorSubject<Array<IToolBarItemComponent>>([]);
  public resetToolbar$ = new Subject();

  public undo$ = new BehaviorSubject([]);
  public redo$ = new BehaviorSubject([]);

  constructor(
    private clipboardService: ClipboardService,
        private toaster: ToastrService,
    ) {
    }

    public saveAsImageCallback(imageName: string, blob) {
        const url = window.URL.createObjectURL(blob);
        const filename = `${imageName}.png`;

        const a: HTMLAnchorElement = document.createElement('a');
        a.style.setProperty('display', 'none');
        a.href = url;
        a.download = filename;

        document.body.appendChild(a);
        requestAnimationFrame(function () {
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        });
    }

    copyUrl() {
        this.clipboardService.copy(window.location.href)
            .then(() => this.toaster.success('URL copied successfully'));
    }
}

import { Action } from '@ngrx/store';

export const SET_SCRIPT_ON_LIST_PROPERTIES = '[CODE_PREVIEW] set script on list properties';
export const SET_SCRIPT_ON_TEXT_TAB_OF_MAP = '[CODE_PREVIEW] set script on text tab of map';


export class SetScriptOnListProperties implements Action {
	readonly type = SET_SCRIPT_ON_LIST_PROPERTIES;
	constructor(public payload: any) { }
}

export class SetScriptOnTextTabOfMap implements Action {
	readonly type = SET_SCRIPT_ON_TEXT_TAB_OF_MAP;
	constructor(public payload: any) { }
}

export type Actions =
	SetScriptOnTextTabOfMap |
	SetScriptOnListProperties;

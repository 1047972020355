import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'oct-show-more',
	templateUrl: './show-more.component.html',
	styleUrls: ['./show-more.component.scss']
})
export class ShowMoreComponent implements OnInit {
	@Input() fontSize: string;
	@Output() clicked = new EventEmitter();

	isCollapsed = true;

	constructor() {
	}

	ngOnInit(): void {
	}

	onChange() {
		this.isCollapsed = !this.isCollapsed;
		this.clicked.emit();
	}
}

import { Component, Inject, OnInit } from '@angular/core';
import { OCT_OVERLAY_DATA, OctOverlayRef } from '@shared/services/oct-overlay.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'oct-draw-toolbar',
	templateUrl: './draw-toolbar.component.html',
	styleUrls: ['./draw-toolbar.component.scss']
})
export class DrawToolbarComponent implements OnInit {

	constructor(
		public octOverlayRef: OctOverlayRef,
		// @Inject(OCT_OVERLAY_DATA) public data: any,
	) {
	}

	ngOnInit(): void {
	}

	public close(data?: any) {
		this.octOverlayRef.close(data);
	}

	public clickIcon(str) {
		this.close(str);
	}
}

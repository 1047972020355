import { Action } from '@ngrx/store';
import { CommonState } from '@store/common/index';

// New NGRX Syntax
// export const createRecentActivities = createAction('[RECENT_ACTIVITIES] create recent activities', props<{ payload: any }>());
// export const updateRecentActivities = createAction('[RECENT_ACTIVITIES] update recent activities', props<{ payload: any }>());
// export const recentActivitiesToggle = createAction('[RECENT_ACTIVITIES] toggle recent activities modal ', props<{ payload: any }>());
// export const setRecentActivitiesItemToDelete = createAction('[RECENT_ACTIVITIES] set recent activities item to delete ', props<{ payload: any }>());

// Old NGRX Syntax
export enum CommonActions {
    LOADING = '[COMMON] octopai loader',
    ADD_COMMON = '[COMMON] Add',
    REMOVE_COMMON = '[COMMON] Remove',
    EDIT_COMMON = '[COMMON] Edit',
    UPDATE_LOCATION = '[COMMON] Update Location',
    UPDATE_PUBLIC_SETTINGS = '[COMMON] Update Public Settings',
    UPDATE_DIC = '[COMMON] Update Dictionary',
    SCANNING_STATE = '[COMMON] Scanning state',
    UPDATE_ALLCONNECTIONS = '[COMMON] Update All Connections',
    SET_AVAILABLE_CONNECTIONS = '[COMMON] Set all available connections objects',
    DISABLE_CONNECTIONS = '[COMMON] disable connection in list settings',
    DISPLAY_LEGEND = '[COMMON] display legend',
}

export class DisplayMapLegend implements Action {
    readonly type = CommonActions.DISPLAY_LEGEND;

    constructor(public payload: boolean) {
    }
}

export class DisableListSettingsConnections implements Action {
    readonly type = CommonActions.DISABLE_CONNECTIONS;

    constructor(public payload: boolean) {
    }
}

export class Loading implements Action {
    readonly type = CommonActions.LOADING;

    constructor(public payload: boolean) {
    }
}

export class AddCommon implements Action {
    readonly type = CommonActions.ADD_COMMON;

    constructor(public payload: CommonState) {
    }
}

export class RemoveCommon implements Action {
    readonly type = CommonActions.REMOVE_COMMON;

    constructor(public payload: number) {
    }
}

export class EditCommon implements Action {
    readonly type = CommonActions.EDIT_COMMON;

    constructor(public payload: CommonState) {
    }
}

export class UpdateLocation implements Action {
    readonly type = CommonActions.UPDATE_LOCATION;

    constructor(public payload: any) {
    }
}

export class UpdatePublicSettings implements Action {
    readonly type = CommonActions.UPDATE_PUBLIC_SETTINGS;

    constructor(public payload: { public_settings: string }) {
    }
}

export class UpdateDic implements Action {
    readonly type = CommonActions.UPDATE_DIC;

    constructor(public payload: { dic: object }) {
    }
}

export class UpdateAllConnections implements Action {
    readonly type = CommonActions.UPDATE_ALLCONNECTIONS;

    constructor(public payload: { allConnections: object }) {
    }
}

export class ScanningState implements Action {
    readonly type = CommonActions.SCANNING_STATE;

    constructor(public payload: boolean) {
    }
}

export class SetAvailableConnections implements Action {
    readonly type = CommonActions.SET_AVAILABLE_CONNECTIONS;

    constructor(public payload: any) {
    }
}

export type CommonActionsTypes =
    Loading |
    AddCommon |
    RemoveCommon |
    EditCommon |
    UpdateLocation |
    UpdatePublicSettings |
    UpdateDic |
    UpdateAllConnections |
    ScanningState |
    SetAvailableConnections |
    DisplayMapLegend;

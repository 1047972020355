import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ReferenceTableService } from '@core/services/reference-table.service';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { ColToColMapETL, MapObjs, TableToTableMap } from '@core/interfaces/map-diagram-data';
import { MapsService } from '@main/maps/maps.service';
import { MapIDService } from '@main/maps/map-id/map-id.service';
import { DrillBetweenMapsService } from '@main/maps/drill-between-maps/drill-between-maps.service';
import { SideBarService } from '@core/services/layout/side-bar.service';
import { CodePreviewService } from '@content/partials/content/general/code-preview/code-preview.service';
import { URLforFullText } from '@core/enum/full-text';
import { moduleType } from '@shared/enums/module-type.enum';
import { MatRadioChange } from '@angular/material/radio';

@Component({
    selector: 'oct-drill-between-maps',
    templateUrl: './drill-between-maps.component.html',
    styleUrls: ['./drill-between-maps.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class DrillBetweenMapsComponent implements OnInit {
    public selectedIndex = 0;
    public searchTerm: string;
    public filterResults;
    private urlForFullText = URLforFullText;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private referenceTableService: ReferenceTableService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        public moduleUtilsService: ModuleUtilsService,
        private mapsService: MapsService,
        private mapIDService: MapIDService,
        public drillBetweenMapsService: DrillBetweenMapsService,
        private sideBarService: SideBarService,
        private codePreviewService: CodePreviewService
    ) {
    }

    ngOnInit(): void {
        this.filterResults = this.data.results;
        const query_obj = {
            query_number: this.data.title.queryNum
        };
        for (let i = 0; i < this.data.results.length; i++) {
            query_obj['tool_name'] = this.data.results[i].container_tool_type || this.data.results[i].TOOL_NAME;
            this.data.results[i] = this.referenceTableService.buildResultsValuesJumpBetweenTables([this.data.results[i]], query_obj)[0];
            this.data.results[i] = this.referenceTableService.buildResultsArrayOfObjs([this.data.results[i]])[0];
            this.data.results[i] = this.referenceTableService.buildInfoData(this.data.results[i]);
        }
    }

    submit() {
        const selectedOption = this.filterResults[this.selectedIndex];
        switch (this.data.title.queryNum) {
            case 27:
                this.goToTableToTable(selectedOption);
                break;
            default:
                this.goToColToCol();
                break;
        }
    }

    public goToTableToTable(selectedOption: any) {
        const tool = this.referenceTableService.getValueFromHiddenByObjectName(selectedOption, 'TOOL_NAME');
        const name = this.referenceTableService.getValueFromHiddenByObjectName(selectedOption, 'PACKAGE_NAME');
        const pathFolder = this.referenceTableService.getValueFromHiddenByObjectName(selectedOption, 'PACKAGE_PATH');
        const module = this.moduleUtilsService.getToolType(this.moduleUtilsService.getToolName(tool));

        const tableToTableMap: TableToTableMap = {
            module,
            tool,
            name,
            pathFolder,
            connIDs: this.data.title.connIDs,
            queryNum: this.mapsService.whiceQuerynumber(tool, module),
        };
        const fullObj: MapObjs = {
            module: module,
            tool: tool, // TODO: sql side - if they will bring me on 302,303,304 the same names, so I would not need this if
            tableToTableMap: tableToTableMap,
        };

        // close modal
        this.drillBetweenMapsService.dialogRef.close();
        this.mapIDService.initVals();
        this.mapsService.setParamsForMap(fullObj);


    }

    // 312
    public goToColToCol(arr?) {
        const obj = this.filterResults[this.selectedIndex];
        const tool = this.referenceTableService.getValueFromHiddenByObjectName(obj, 'container_tool_type');
        const toolType = this.referenceTableService.getValueFromHiddenByObjectName(obj, 'TOOL_TYPE');
        let name;
        let containerObjPath;
        let controlFlowPath;
        if (tool !== 'SQLS' || tool !== 'ORACLE' || tool !== 'TERADATA' || tool !== 'VERTICA' || tool !== 'SNOWFLAKE') {
            containerObjPath = this.referenceTableService.getValueFromHiddenByObjectName(obj, 'CONTAINER_OBJECT_PATH');
            controlFlowPath = this.referenceTableService.getValueFromHiddenByObjectName(obj, 'CONTROL_FLOW_PATH');
        } else {
            containerObjPath = this.referenceTableService.getValueFromHiddenByObjectName(obj, 'CONTAINER_OBJECT_PATH');
            controlFlowPath = this.referenceTableService.getValueFromHiddenByObjectName(obj, 'CONTROL_FLOW_PATH');
        }

        if (this.moduleUtilsService.getToolType(tool) === moduleType.ANALYSIS) {
            controlFlowPath = -1;
        }

        const _nameFullPath = this.referenceTableService.getValueFromHiddenByObjectName(obj, 'CONTROL_FLOW_PATH');
        if (_nameFullPath.lastIndexOf('\\') < 0) {
            name = _nameFullPath.substr(_nameFullPath.lastIndexOf('\/') + 1);
        } else {
            name = _nameFullPath.substr(_nameFullPath.lastIndexOf('\\') + 1);
        }

        // let toolType = this.moduleUtilsService.getToolType(this.moduleUtilsService.getToolName(tool));
        const connLogicName = this.referenceTableService.getValueFromHiddenByObjectName(obj, 'CONN_LOGIC_NAME');

        let colToColMapETL: ColToColMapETL;
        let colToColMapDATABASE: ColToColMapETL;

        // toolType = toolType == tool || tool === 'QLIKSENSE' ? moduleType.ETL : toolType;

        const fullObj: MapObjs = {
            module: this.moduleUtilsService.getModuleToGeneralModule(toolType),
            tool,
        };

        if (toolType === moduleType.ETL) {
            colToColMapETL = {
                module: toolType,
                tool,
                queryNum: this.mapsService.whiceQuerynumber(tool, toolType, {is_loop_container_step: false}), // 312
                containerObjPath,
                controlFlowPath,
                name,
                connIDs: this.data.title.connIDs,
                connName: connLogicName,
                objectType: toolType,
            };
            fullObj.colToColMapETL = colToColMapETL;
        } else {
            const moduleShortName = this.moduleUtilsService.getModuleShortName(toolType);
            if (moduleShortName === moduleType.DB || [moduleType.ANALYSIS, moduleType.FILES].includes(toolType as moduleType)) {
                colToColMapDATABASE = {
                    module: this.moduleUtilsService.getModuleLongName(this.moduleUtilsService.getModuleToGeneralModule(toolType)),
                    tool,
                    queryNum: this.mapsService.whiceQuerynumber(tool, this.moduleUtilsService.getModuleToGeneralModule(toolType), {is_loop_container_step: false}),
                    containerObjPath,
                    controlFlowPath,
                    name,
                    connIDs: this.data.title.connIDs,
                    connName: connLogicName,
                    objectType: toolType,
                };
                fullObj.colToColMapDATABASE = colToColMapDATABASE;
            }
        }

        // close modal
        this.drillBetweenMapsService.dialogRef.close();

        this.mapIDService.initVals();
        this.mapsService.setParamsForMap(fullObj);
        this.codePreviewService.getData(this.urlForFullText.GetSP, colToColMapETL || colToColMapDATABASE);
    }

    public filterResult(term: string) {
        this.searchTerm = term;
        this.filterResults = this.data.results.filter(item => {
            return item.infoData.find(infoDataItem => infoDataItem.value.toLowerCase().includes(term.toLowerCase()));
        });
    }

    onChange(e: MatRadioChange) {
        this.selectedIndex = e.value;
    }
}

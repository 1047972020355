<button [matMenuTriggerFor]="menu" class="recent-activities-trigger" mat-icon-button matTooltip="Recent Activities">
	<mat-icon svgIcon="recent-activities"></mat-icon>
</button>

<mat-menu #menu="matMenu" class="recent-activity">
	<span (click)="$event.stopPropagation()" [disableRipple]="true" mat-menu-item>
  		<!-- popover content begin -->
		<div style="height: auto; width: auto">
			<div class="topbar-popover">
				<span>Recent Activities</span>
				<mat-icon (click)="closeQuickAction()" class="close-btn" matTooltip="Cancel"
                          svgIcon="close-icon"></mat-icon>
			</div>
			<oct-list-timeline (close)="closeQuickAction()"></oct-list-timeline>
		</div>
        <!-- popover content end  -->
	</span>
</mat-menu>


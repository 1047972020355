import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'oct-context-menu',
	templateUrl: './context-menu.component.html',
	styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnChanges {
	@Input() contextMenu: any;
	@Input() iconColor: any;
	public menu: any;

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.menu = changes.contextMenu.currentValue;
	}
}

<header #mHeader class="m-grid__item m-header" mHeader>
    <div class="m-stack m-stack--ver m-stack--desktop">
        <ng-container *ngIf="(loader.progress$|async) as loaderProgress">
            <mat-progress-bar [value]="loaderProgress" class="m-loading-bar"></mat-progress-bar>
        </ng-container>
        <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
            <div class="header-title">
                <div>{{subheaderService.locationTitle}}</div>
                <m-topbar></m-topbar>
            </div>

            <!--			<ng-container *ngIf="(showSearch$ | async)">-->
            <!--                <button [ngClass]="headerService.headerMenuCloseClass" class="m-aside-header-menu-mobile-close">-->
            <!--                    id="m_aside_header_menu_mobile_close_btn">-->
            <!--                    <i class="la la-close"></i>-->
            <!--                </button>-->
            <!--                <div class="header-wrapper flex" space-between>-->
            <!--                    <oct-search-bar></oct-search-bar>-->
            <!--                </div>-->
            <!--            </ng-container>-->
        </div>
    </div>
</header>

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'oct-frame-text-form-control',
  templateUrl: './frame-text-form-control.component.html',
  styleUrls: ['./frame-text-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FrameTextFormControlComponent),
      multi: true
    }
  ],
})
export class FrameTextFormControlComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() key: string;
  @Input() data: any;
  @Input() form: FormGroup;
  @Input() formControl: FormControl;
  @Input() readonly: boolean = false;
  @Input() placeholder: string;
  public val: string;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.formControl) {
      this.formControl = new FormControl('');
      this.readonly ? this.formControl.disable() : this.formControl.enable();
    }
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value): void {
    // this.val = value;
    this.formControl.setValue(value);
    // this.formControl.patchValue({key: this.key, value: value, data: this.data});
  }
}

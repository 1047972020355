import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchHighlightPipe } from '@shared/pipes/search-highlight.pipe';
import { ShareUrlComponent } from './components/share-url/share-url.component';
import { HighlightPipe } from '@shared/pipes/highlight.pipe';
// import { FilterArrByKeyValuePipe } from './pipes/filter-arr-by-key-value.pipe';
import { ObjectNamePipe } from './pipes/object-name.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FirstDeliveryDialogComponent } from './components/first-delivery-dialog/first-delivery-dialog.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CheckBoxMapComponent } from './components/check-box-map/check-box-map.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { AddItemToasterComponent } from '@main/adc/adc-home/add-item/add-item-toaster/add-item-toaster.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { LogoComponent } from './components/logo/logo.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  DateRangeToolbarItemComponent
} from '@shared/components/date-range-toolbar-item/date-range-toolbar-item.component';
import { GeneralTablePageComponent } from './components/general-table-page/general-table-page.component';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { AvatarModule } from 'ngx-avatar';
import { HighlightMentionPipe } from './pipes/highlight-mention.pipe';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { LineageDiagramComponent } from '@main/lineage-diagram/lineage-diagram/lineage-diagram.component';
import { MatMenuModule } from '@angular/material/menu';
import { ContextMenuComponent } from '@main/adc/adc-home/asset-details/context-menu/context-menu.component';
import { SortByIndexPipe } from './pipes/sort-by-index.pipe';
import { AdcRichFilterComponent } from '@main/adc/adc-shared/components/adc-rich-filter/adc-rich-filter.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TextFormControlComponent } from './forms/form-controls/text-form-control/text-form-control.component';
import { ListFormControlComponent } from './forms/form-controls/list-form-control/list-form-control.component';
import { ClipboardModule } from 'ngx-clipboard';
import {
  FrameTextFormControlComponent
} from './forms/form-controls/frame-text-form-control/frame-text-form-control.component';
import { DrawToolbarComponent } from './components/draw-toolbar/draw-toolbar.component';
import { GenericFormModalComponent } from './forms/generic-form-modal/generic-form-modal.component';
import {
  AutocomplateFrameTextFormControlComponent
} from './forms/form-controls/autocomplate-frame-text-form-control/autocomplate-frame-text-form-control.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
// import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonModule } from 'ngx-skeleton';
import { OctOverlayService } from '@shared/services/oct-overlay.service';
import { ThousandsCommaPipe } from './pipes/thousands-comma.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { KUnitPipe } from './pipes/k-unit.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import {
  AvatarListFormControlComponent
} from '@shared/forms/form-controls/avatar-list-form-control/avatar-list-form-control.component';
import { FeatureFlagDirective } from '@shared/directives/feature-flag.directive';
import {
  BigFrameTextFormControlComponent
} from './forms/form-controls/big-frame-text-form-control/big-frame-text-form-control.component';
import { RtlDirective } from './directives/rtl.directive';
import { RtlViewDirective } from './directives/rtl-view.directive';
import { GenericKendoGridComponent } from './components/generic-kendo-grid/generic-kendo-grid.component';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import {
  SimpleToolbarButtonComponent
} from './components/toolbar/simple-toolbar-button/simple-toolbar-button.component';
import { MenuToolbarButtonComponent } from './components/toolbar/menu-toolbar-button/menu-toolbar-button.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { HtmlBoldPipe } from './pipes/html-bold.pipe';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MenuOverlayComponent } from '@shared/components/toolbar/menu-overlay/menu-overlay.component';
import { OctCheckBoxModule } from '@shared/oct-components/check-box/check-box.module';
import { RoundButtonModule } from '@shared/oct-components/round-button/round-button.module';
import { UnderlineButtonModule } from '@shared/oct-components/underline-button/underline-button.module';
import { AvatarItemModule } from '@shared/oct-components/avatar-item/avatar-item.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {
  WysiwygFormControlComponent
} from '@shared/forms/form-controls/wysiwyg-form-control/wysiwyg-form-control.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const COMPONENTS = [
  ProgressBarComponent,
  AddItemToasterComponent,
  DateRangeToolbarItemComponent,
  GeneralTablePageComponent,
];

const EXPORT_COMPONENTS = [
  ShareUrlComponent,
  ConfirmDialogComponent,
  FirstDeliveryDialogComponent,
  CheckBoxMapComponent,
  ToolbarComponent,
  LogoComponent,
  // LineageDiagramComponent,
  ContextMenuComponent,
  AdcRichFilterComponent,
  TextFormControlComponent,
  ListFormControlComponent,
  AvatarListFormControlComponent,
  FrameTextFormControlComponent,
  MenuOverlayComponent,
  WysiwygFormControlComponent,
];

const MATERIAL = [
  MatAutocompleteModule,
  MatButtonModule,
  MatIconModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatRadioModule,
  MatDialogModule,
  MatInputModule,
  MatListModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatSidenavModule,
  MatDividerModule,
  MatCardModule,
  MatTooltipModule,
  MatToolbarModule,
  MatSliderModule,
  MatBadgeModule,
  MatChipsModule,
  MatButtonToggleModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatMenuModule,
  ScrollingModule,
  DragDropModule,
  CdkTableModule,
];

const PIPES = [
  SearchHighlightPipe,
  HighlightPipe,
  // FilterArrByKeyValuePipe,
  ObjectNamePipe,
  HighlightMentionPipe,
  SortByIndexPipe,
  // SafeHtmlPipe,
  ThousandsCommaPipe,
  KUnitPipe,
];

const DIRECTIVES = [
  // ClickOutsideDirective,
  TimeAgoPipe,
  FeatureFlagDirective,
  RtlDirective,
  RtlViewDirective,
  HtmlBoldPipe,
];

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const avatarColors = ['#F16642', '#DC3565', '#36C3DD', '#2699FB', '#65DC7E', '#F1AB05'];

@NgModule({
  imports: [
    ...MATERIAL,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    PerfectScrollbarModule,
    TranslateModule.forChild(),
    AvatarModule.forRoot({
      colors: avatarColors
    }),
    NgxPermissionsModule,
    MomentDateModule,
    NgxSliderModule,
    ReactiveFormsModule,
    ClipboardModule,
    NgbModule,
    NgxSkeletonModule,
    ColorPickerModule,
    GridModule,
    ExcelModule,
    TooltipModule,
    SearchInputModule,
    DirectivesModule,
    OctCheckBoxModule,
    RoundButtonModule,
    UnderlineButtonModule,
    AvatarItemModule,
    AngularEditorModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...EXPORT_COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    ContextMenuComponent,
    DrawToolbarComponent,
    GenericFormModalComponent,
    AutocomplateFrameTextFormControlComponent,
    TimeAgoPipe,
    ColorPickerComponent,
    BigFrameTextFormControlComponent,
    GenericKendoGridComponent,
    GenericModalComponent,
    SimpleToolbarButtonComponent,
    MenuToolbarButtonComponent,
    ErrorDialogComponent,
    MenuOverlayComponent,
  ],
  exports: [
    ...EXPORT_COMPONENTS,
    ...MATERIAL,
    ...PIPES,
    ...DIRECTIVES,
    AvatarModule,
    ColorPickerModule,
    ColorPickerComponent,
    GenericKendoGridComponent
  ],
  providers: [
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: MatDialogRef, useValue: {}},
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    OctOverlayService,
  ],
  bootstrap: []
})
export class SharedModule {
}

<div>
	<div class="flex" flex-align-items-center>
		<div class="slider-div-btns m--padding-left-8" id="reset-btn"
			 (click)="click(sliderOptions.RESET)">
			<span title="Reset">RESET</span>
		</div>
		<div class="flex slider-div-btns" flex-center id="plus-btn"
			 (click)="click(sliderOptions.PLUS)">
			<mat-icon class="zoom-icon" svgIcon="zoomIn"></mat-icon>
		</div>
		<div id="slider"></div>
		<div id="zoom_slider"></div>
		<div class="flex slider-div-btns" flex-center id="minus-btn"
			 (click)="click(sliderOptions.MINUS)">
			<mat-icon class="zoom-icon" svgIcon="zoomOut"></mat-icon>
		</div>
		<div class="slider-div-btns"
			 id="fit-btn" (click)="click(sliderOptions.FIT)">
			<span title="Fit">FIT</span>
		</div>
	</div>
</div>

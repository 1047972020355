import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MenuConfig } from '../../config/menu';
import { PageConfigService } from './page-config.service';
import { UtilsService } from './utils.service';
import { SubheaderService } from '../../../app/core/services/layout/subheader.service';

@Injectable({
	providedIn: 'root',
})
export class MenuConfigService {
	public configModel: MenuConfig;
	public onMenuUpdated$ = new Subject<MenuConfig>();
	menuHasChanged: any = false;

	public lastNavigatedUrl: string = null;
	public deepLineageUrl: string = null;
	public deepCompareETLUrl: string = null;
	public deepCompareREPORTUrl: string = null;
	public lastTrigger: string = null;

	constructor(
		private router: Router,
		private utilsService: UtilsService,
		private pageConfigService: PageConfigService,
		private location: Location,
		private subheaderService: SubheaderService
	) {
		// this.routeToLastNavigatedUrl();
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				if (this.isPopStateEvent(event)) {
					this.lastTrigger = 'popstate';
				}
				// this.navigateToCurrentOpenedModule(event['url']);
				if (this.menuHasChanged) {
					this.resetModel();
				}
			});

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				// this.saveLastNavigatedUrlInLocalStorage(event['urlAfterRedirects']);
				this.checkGeneralPopStateEvent(event);
			});
	}

	isPopStateEvent(event) {
		if (event['navigationTrigger'] && event['navigationTrigger'] == 'popstate') {
			return true;
		}
		return false;
	}

	checkGeneralPopStateEvent(event: any) {
		if (event[1] instanceof NavigationEnd && this.lastTrigger == 'popstate') {
			this.lastTrigger = '';
			let eventUrl = event[1]['urlAfterRedirects'];
			if (eventUrl != undefined && eventUrl != '/' && eventUrl.indexOf('lineageSchema') > -1) {
				this.subheaderService.removeLastItem();
			}
		}
	}

	setModel(menuModel: MenuConfig) {
		this.configModel = Object.assign(this.configModel, menuModel);
		this.onMenuUpdated$.next(this.configModel);
		this.menuHasChanged = true;
	}

	resetModel() {
		this.configModel = new MenuConfig();
		this.onMenuUpdated$.next(this.configModel);
		this.menuHasChanged = false;
	}

	routeToLastNavigatedUrl() {
		let reset = this.getResetQueryVariable();
		if (!reset) {
			if (this.location.path() == '') {
				let lastNavigatedUrl = localStorage.getItem('lastNavigatedUrl');
				if (lastNavigatedUrl != null && lastNavigatedUrl != '/404' && lastNavigatedUrl != '/') {
					this.lastNavigatedUrl = lastNavigatedUrl;
					if (this.lastNavigatedUrl.indexOf('[vs]') > -1 || this.lastNavigatedUrl.indexOf('lineageSchema') || this.lastNavigatedUrl.indexOf('map')) {
						document.location.href = this.lastNavigatedUrl;
					} else {
						this.router.navigate([this.lastNavigatedUrl]);
					}
				}
			}
		} else {
			localStorage.removeItem('lastNavigatedUrl');
			document.location.href = '/';
		}
	}

	getResetQueryVariable() {
		var query = window.location.search.substring(1);
		if (query == 'r=t') {
			return true;
		} else {
			return false;
		}
	}

	saveLastNavigatedUrlInLocalStorage(url?: string) {
		this.lastNavigatedUrl = (url ? url : document.location.pathname);
		localStorage.setItem('lastNavigatedUrl', this.lastNavigatedUrl);
	}

	navigateToCurrentOpenedModule(eventUrl: string) {
		if (eventUrl.indexOf('compare') > -1 && eventUrl == document.location.pathname) {
			document.location.reload();
		}
		if (this.deepLineageUrl == eventUrl) {
			return;
		}
		if (this.isRoutedToCompareEtlDiagram(eventUrl)) {
			return;
		}
		if (this.isRoutedToCompareReportDiagram(eventUrl)) {
			return;
		}

		eventUrl = this.utilsService.fixUrlEncode(eventUrl);

		if (eventUrl.indexOf('compare-etls') > -1 && this.deepCompareETLUrl != null) {
			this.pageConfigService.navigatedUrlPage = 'compare-etls';
			this.pageConfigService.navigatedUrlParam = this.deepCompareETLUrl;
		}

		if (eventUrl.indexOf('compare-reports') > -1 && this.deepCompareREPORTUrl != null) {
			this.pageConfigService.navigatedUrlPage = 'compare-reports';
			this.pageConfigService.navigatedUrlParam = this.deepCompareREPORTUrl;
		}

		if (eventUrl === '/lineage' && this.deepLineageUrl != null) {
			if (!this.pageConfigService.backPressTime) {
				this.pageConfigService.generalHosterMode = 'map-id';
				this.router.navigate([encodeURI(this.deepLineageUrl)]);
				setTimeout(() => {
					this.pageConfigService.generalHosterMode = null;
				}, 2000);
				return;
			} else {
				return;
			}
		}

		if (this.deepLineageUrl == eventUrl) {
			return;
		}
		if (eventUrl == '/') {
			this.deepLineageUrl = null;
			return;
		}
		if (eventUrl.indexOf('/map/') > -1 || eventUrl.indexOf('/lineageSchema/') > -1) {
			this.deepLineageUrl = eventUrl;
			this.router.navigateByUrl(this.deepLineageUrl);
		}
	}

	isRoutedToCompareReportDiagram(eventUrl) {
		if (this.deepCompareREPORTUrl != null) {
			if (this.deepCompareREPORTUrl.indexOf('[vs]') > -1 && eventUrl.indexOf('vs') > -1) {
				return true;
			}
		}
		return false;
	}

	isRoutedToCompareEtlDiagram(eventUrl) {
		if (this.deepCompareETLUrl != null) {
			if (this.deepCompareETLUrl.indexOf('[vs]') > -1 && eventUrl.indexOf('vs') > -1) {
				return true;
			}
		}
		return false;
	}

}

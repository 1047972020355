import { Component, ElementRef, HostBinding, Inject, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService, AuthenticationType } from '@core/auth/authentication.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UserProfileService } from './user-profile.service';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { UserState } from '@core/states/user.state';
import { TokenStorage } from '@core/auth/token-storage.service';
import { BrowserService } from '@core/services/browser.service';
import { Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { OKTA_CONFIG, OktaAuthService } from '@okta/okta-angular';
import { OktaConfig } from '@okta/okta-angular/src/okta/models/okta.config';
import { environment } from '@environments/environment';

@Component({
    selector: 'oct-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit {
    @HostBinding('class')
    classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown--align-push m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

    @Input() avatar: string = './assets/app/img/users/avatar.png';
    @Input() avatarBg: SafeStyle = '';

    public user$: Observable<UserState[]>;
    public modalClosed: boolean = true;
    public version: string;

    constructor(
        private authService: AuthenticationService,
        private sanitizer: DomSanitizer,
        private httpUtilsService: HttpUtilsService,
        public userProfileService: UserProfileService,
        private tokenStorage: TokenStorage,
        private browserService: BrowserService,
        private eRef: ElementRef,
        private router: Router,
        private adalService: MsAdalAngular6Service,
        private oktaAuthService: OktaAuthService,
        private authenticationService: AuthenticationService,
        @Inject(OKTA_CONFIG) private oktaConfig: OktaConfig,
    ) {
    }

    ngOnInit(): void {
        this.version = environment.releaseVersion || '';

        if (this.browserService.getBrowser() != 'Chrome' && this.browserService.getBrowser() != 'Edge') {
            alert('The browser you are using is not supported by Octopai. Please use Chrome or Edge Version 83 and up');
        }
    }

    async logout() {
        console.log('auth type =>', this.authenticationService.authenticationType);
        if (localStorage.getItem('ssoToken')) {
            switch (this.authenticationService.authenticationType) {
                case AuthenticationType.azure:
					this.tokenStorage.logout();
                    this.adalService.logout();
                    break;
                case AuthenticationType.okta:
					this.tokenStorage.logout(true);
                    await this.oktaAuthService.logout();
                    break;
            }
        } else {
            this.tokenStorage.logout(true);
			this.authService.authenticationType = AuthenticationType.none;
			this.router.navigate(['login']);
        }
    }

    toggleUserProfile() {
        this.modalClosed = !this.modalClosed;
    }

    closeUserProfile() {
        this.closeModal();
        this.router.navigate(['admin']);
    }

    closeModal() {
        this.modalClosed = true;
    }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@shared/components/error-dialog/error-dialog.component';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ErrorService {

	maxFailure: number = 0;
	failureCounter: number = 0;
	failureCounterSubject$ = new Subject();
	isDialogAlreadyOpen: boolean = false;

	constructor(
		private dialog: MatDialog,
	) {
	}

	public openErrorDialog() {
		const obj = {};
		let dialogRef;
		if (!this.isDialogAlreadyOpen) {
			this.isDialogAlreadyOpen = true;
			dialogRef = this.dialog.open(ErrorDialogComponent, obj);
		}

		dialogRef.afterClosed()
			.subscribe(this.isDialogAlreadyOpen = false);

	}

}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { LineageService } from '@main/lineage-dashboard/lineage.service';
import { select, Store } from '@ngrx/store';
import { LineageIDService } from '@main/lineage-diagram/lineage-id/lineage-id.service';
import { MenuConfigService } from '@core/services/menu-config.service';
import { DiscoveryService } from '@main/discovery/discovery.service';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { IRecentActivities } from '@shared/interfaces/recent-activities';
import { iconByModule } from '@core/enum/recent-activities';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { selectActivities } from '@store/activities';
import { getActivities } from '@store/activities/actions';

@Component({
  selector: 'oct-list-timeline',
  templateUrl: './list-timeline.component.html',
  styleUrls: ['./list-timeline.component.scss'],
})

export class ListTimelineComponent implements OnInit, OnDestroy, OnChanges {
  @Output() close = new EventEmitter();
  @Output() filterIsOpen = new EventEmitter();
  @Input() isFocus: boolean;
  @ViewChild(SearchInputComponent, {static: true}) public searchInput: SearchInputComponent;
  public searchValue: string = '';
  public recentActivitiesList$: Observable<Array<object>>;
  public recentActivitiesList: Array<any>;
  public cp: number = 1;
  public toggle: boolean = false;
  public componentDestroy$ = new Subject();
  public dataLoading$ = new BehaviorSubject<boolean>(false);
  private modules: Array<string>;
  private email: string;
  private limit: number;

  constructor(
    private lineageService: LineageService,
    private discoveryService: DiscoveryService,
    private lineageIDService: LineageIDService,
    private menuConfigService: MenuConfigService,
    private router: Router,
    private el: ElementRef,
    private store: Store<any>,
    private httpUtilsService: HttpUtilsService,
  ) {
  }

  ngOnInit(): void {
    // this.store
    //     .pipe(
    //         select(getEmail),
    //         take(1)
    //     )
    //     .subscribe(email => {
    //         this.email = email;
    //     });
    //
    // this.store
    //     .pipe(
    //         select(getPublicSettings),
    //         filter((publicSettings: IPublicSettings) => !!publicSettings && Object.keys(publicSettings).length !== 0),
    //         take(1)
    //     )
    //     .subscribe((publicSettings: IPublicSettings) => {
    //         this.modules = publicSettings.showModules.split(';');
    //         this.limit = publicSettings.recentActivitiesLimit;
    //     });

    // this.recentActivitiesList$ = this.store
    //     .pipe(
    //         takeUntil(this.componentDestroy$),
    //         select(selectRecentActivities),
    //     );

    this.recentActivitiesList$ = this.store
      .pipe(
        takeUntil(this.componentDestroy$),
        select(selectActivities),
      );

    // get recent activities list from server
    // this.getRecentActivities();

    // this.store
    //     .pipe(
    //         select(selectRecentActivitiesIsOpen),
    //         takeUntil(this.componentDestroy$),
    //     )
    //     .subscribe(modalIsOpen => {
    //         if (!modalIsOpen) {
    //             this.cp = 1;
    //
    //             this.store
    //                 .pipe(
    //                     select(selectRecentActivities),
    //                     filter(list => list.length > 0),
    //                     take(1)
    //                 )
    //                 .subscribe(recentActivitiesList => {
    //                     const payload = JSON.parse(JSON.stringify(recentActivitiesList));
    //                     recentActivitiesList.forEach((item) => {
    //                         if (item.to_delete) {
    //                             const index = payload.findIndex(_item => item.usage_id === _item.usage_id);
    //                             payload.splice(index, 1);
    //                             this.deleteItem(item.usage_id);
    //                         }
    //                     });
    //                     // this.store.dispatch(createRecentActivities({payload}));
    //                 });
    //         }
    //     });
  }

  public onSearch(searchValue: string) {
    this.cp = 1; // reset the pagination to page 1

    this.recentActivitiesList$ = this.store
      .pipe(
        select(selectActivities),
        map(recent_activities => {
          if (searchValue !== '') {
            return recent_activities.filter(item => item['search_value'].toLowerCase().includes(searchValue.toLowerCase()));
          }
          return recent_activities;
        }),
        take(1),
      );

    // this.recentActivitiesList$ = this.store
    //     .pipe(
    //         select(selectRecentActivities),
    //         map(recent_activities => {
    //             if (searchValue !== '') {
    //                 return recent_activities.filter(item => item['search_value'].toLowerCase().includes(searchValue.toLowerCase()));
    //             }
    //             return recent_activities;
    //         }),
    //         take(1),
    //     );
  }

  public openCloseFilter() {
    this.toggle = !this.toggle;
    this.filterIsOpen.emit(this.toggle);
  }

  public onCheckFilter(item) {
    item.checked = !item.checked;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.searchInput) {
      this.searchInput.isFocus = changes.isFocus?.currentValue;
    }
  }

  public onFilter(payload: any) {
    this.cp = 1; // reset the pagination to page 1
    this.store.dispatch(getActivities({payload}));
    // this.dataLoading$.next(true);
    // this.httpUtilsService.postData('Connections/GetMyUsage', payload)
    //     .pipe(
    //         filter(response => !!response)
    //     )
    //     .subscribe((response: any) => {
    //         this.writeToStore(response, false);
    //         this.dataLoading$.next(false);
    //     });
  }

  public onPageChange($event: number) {
    this.cp = $event;
    event.stopPropagation();
  }

  public clickOnSelected(e) {
    if (!e.target.classList.contains('hidden-item-exclude-div') && !e.target.hidden) {
      this.close.emit(e);
    }
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.unsubscribe();
  }

  private deleteItem(id) {
    const dataToServer = {
      operationName: null,
      query: 'mutation ($usageLogUpdateInput: ObgUsageLogUpdateInputType!) { obgUsageUpdateLogMutations (usageLogUpdateInput: $usageLogUpdateInput) { obgHideUsageLogByUser(usageLogUpdateInput: $usageLogUpdateInput) { uSAGE_ID, sEARCH_VALUE, mODULE, aCTIVITY, uRL, fILTERED, tOOL_TYPE, tOOL_NAME, aDMIN_HIDE, uSER_HIDE } } }',
      variables: {
        usageLogUpdateInput: {
          uSAGE_ID: id
        }
      }
    };
    this.httpUtilsService.post('graphql', dataToServer)
      .subscribe(() => {
      });
  }

  private getRecentActivities() {
    const obj = {
      username: this.email,
      limit: this.limit,
      toolType: -1,
      toolName: -1,
      module: -1,
      activity: -1
    };
    this.httpUtilsService.postData('Connections/GetMyUsage', obj)
      .pipe(
        filter(response => !!response)
      )
      .subscribe((response: any) => {
        this.writeToStore(response, true);
        this.dataLoading$.next(false);
      });
  }

  private writeToStore(response, fromGetData) {
    const _data = JSON.parse(response.res);
    this.recentActivitiesList = _data.map((row) => {
      const recentActivityItem: IRecentActivities = {
        'usage_id': row.USAGE_ID,
        'search_value': row.SEARCH_VALUE,
        'timestamp': row.LOG_DATE,
        'module': row.MODULE,
        'url': row.URL,
        'filtered': row.FILTERED,
        'icon': iconByModule[row.MODULE.toUpperCase().trim()],
        'activity': row.ACTIVITY,
        'tool_type': row.TOOL_TYPE,
        'tool_name': row.TOOL_NAME,
        'admin_hide': row.ADMIN_HIDE,
        'user_hide': row.USER_HIDE
      };
      return recentActivityItem;
    });
    const payload = JSON.parse(JSON.stringify(this.recentActivitiesList));
    if (fromGetData) { // on first load we need to add data to the existing data, while user continue to use the app and write to log
      // this.store.dispatch(updateRecentActivities({payload}));
    } else { // from 'on filter' we need to reset the list
      // this.store.dispatch(createRecentActivities({payload}));
    }
  }
}

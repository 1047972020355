import * as UserActions from './../actions/user.actions';
import { initialUserState } from './../states/user.state';

export const initialState = initialUserState;

export function UserReducer(state = initialState, action: UserActions.Actions) {
    switch (action.type) {
        case UserActions.UPDATE_USER:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}

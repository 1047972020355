import {AfterViewInit, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {EnableSearchFilters, GetTags} from '@main/adc/adc-store/actions';
import {IABGTag, IAttribute, selectAssetTypes, selectStatusList, selectTagsList} from '@main/adc/adc-store';
import {IAbgItemStatus} from '@main/adc/adc-store/adc';
import {debounceTime, filter, map, take, takeUntil} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {IAppUser, IUser} from '@shared/interfaces/user';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {getLocation} from '@store/common';
import {Locations} from '@shared/enums/locations.enum';
import {DATE_FORMATS} from '@main/adc/adc-shared/types/dateFormats';
import {HttpUtilsService} from '@core/services/http-utils.service';
import {UsersService} from '@main/admin/users/users.service';
import {MatDialog} from '@angular/material/dialog';
import {IFilters} from '@main/adc/adc-shared/interfaces/filters';
import {AbgService} from '@main/adc/adc.service';
import {AssetTypesMapping} from '@shared/types/adcMappings';
import {CustomAttributesService} from '@main/admin/custom-attributes/custom-attributes.service';
import {PropertiesEnum} from '@shared/enums/properties.enum';

const moment = require('moment');

export const MY_FORMATS = {
	parse: {
		dateInput: 'YYYY-MM-DD',
	},
	display: {
		dateInput: 'MMM DD, YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'MMM DD, YYYY',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'oct-adc-filter',
	templateUrl: './adc-filter.component.html',
	styleUrls: ['./adc-filter.component.scss'],
	providers: [
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	],
})
export class AdcFilterComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	@Input() collapse$: Subject<boolean>;
	@Input() data;
	@Input() readOnlyData: boolean;
	@Input() showSettingsToolbar: boolean = true;
	@Input() isAddLink: boolean = false;
	@Output() filterCollapse = new EventEmitter();
	@Output() applyFilter = new EventEmitter();
	@Output() filterInit = new EventEmitter();
	@Output() settingsToolbarItemClick = new EventEmitter();
	@ViewChild('tagSearchInput') tagSearchInputElement;
	@ViewChild('dataOwnerSearchInput') dataOwnerSearchInputElement;
	@ViewChild('dataUpdatedBySearchInput') dataUpdatedBySearchInputElement;
	@ViewChild('dataTypeSearchInput') dataTypeSearchInputElement;
	@ViewChild('dataStewardSearchInput') dataStewardSearchInputElement;
	@ViewChild('customAttributeSearchInput') customAttributeSearchInput;

	public form: FormGroup;
	public ratingOptions = {
		floor: 0,
		ceil: 5,
	};
	public sensitivity: IFilters = {
		buttons: [
			{text: 'All', value: ''},
			{text: 'Yes', value: 'true'},
			{text: 'No', value: 'false'}
		],
		defaultSelected: ''
	};

	// public propertiesList: any[] = [];
	public suspended: IFilters = {
		buttons: [
			{text: 'All', value: ''},
			{text: 'Yes', value: 'true'},
			{text: 'No', value: 'false'}
		],
		defaultSelected: 'false'
	};
	public tags$: Observable<IABGTag[]>;
	public dataOwners$ = new BehaviorSubject<IUser[]>([]);
	public dataStewards$ = new BehaviorSubject<IUser[]>([]);
	public dataUpdatedBy$ = new BehaviorSubject<IUser[]>([]);
	public dataTypes$ = new BehaviorSubject<any>([]);
	public customAttributes$ = new BehaviorSubject<IAttribute[]>([]);
	public statusList: IAbgItemStatus[];
	public statusCheckAll = true;
	public tagsList: IABGTag[] = [];
	public dataOwnersList: IUser[] = [];
	public dataUpdatedByList: IUser[] = [];
	public dataTypes: any[] = [];
	public dataStewardsList: IUser[] = [];
	public customAttributesList: IAttribute[] = [];
	public assetTypes: any[];
	public tagsSearch: any;
	@HostBinding('class.collapse') collapseClass: boolean;
	public dataToShow: Array<any> = [];
	public showMoreDisplayCounter = 4;
	public filtered: boolean = false;
	public moreOptions: any = [{
		FULL_NAME: 'All Assigned',
		ID: 'Assigned'
	}, {
		FULL_NAME: 'All Unassigned',
		ID: 'Unassigned'
	}];
	toggleValues = [
		{text: 'Missing', value: 'true'},
		{text: 'Including', value: 'false'}
	];
	public propertiesEnum: PropertiesEnum;
	private initialFilterPayload;
	private componentDestroy$ = new Subject();
	private contentHeight: string;

	constructor(
		private store: Store<any>,
		private userService: UsersService,
		private fb: FormBuilder,
		private httpUtilsService: HttpUtilsService,
		private dialog: MatDialog,
		public adcService: AbgService,
		private customAttributesService: CustomAttributesService,
	) {
	}

	ngAfterViewInit(): void {
		this.initForm();
		this.store
			.pipe(
				takeUntil(this.componentDestroy$),
				select(getLocation),
				filter((location: Locations) => ![Locations.adc, Locations.adcDashboard].includes(location))
			)
			.subscribe(() => this.store.dispatch(new EnableSearchFilters(false)));

		this.tags$ = this.store.pipe(
			select(selectTagsList),
			filter(list => !!list),
		);

		this.subscribeToTagSearch('');
		// this.subscribeToDataOwnerSearch('');
		// this.subscribeToDataStewardSearch('');

		if (this.collapse$) {
			this.collapse$.subscribe(val => {
				this.collapseClass = val;
			});
		}
	}

	ngOnInit() {

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.data?.currentValue) {
			this.updateForm(changes.data.currentValue);
		}
	}


	onCheckAll() {
		this.statusCheckAll = !this.statusCheckAll;
		this.statusList.forEach(item => item.checked = this.statusCheckAll);
	}

	onStatusChange(status: IAbgItemStatus) {
		status.checked = !status.checked;
		this.statusCheckAll = !this.statusList.find(item => !item.checked);
	}

	displayFn(option: IABGTag) {
		return option && option.tAG_NAME || '';
	}

	tagSelected(e: MatAutocompleteSelectedEvent) {
		const exists = this.tagsList.find(item => item.tAG_ID === e.option.value.tAG_ID);
		if (!exists) {
			this.tagsList.push(e.option.value);
		}
		this.form.controls.tags.setValue(this.tagsList.map(a => a.tAG_ID));
		// this.tagsSearch.setValue('');
		this.tagSearchInputElement.clearText();
	}

	onRemoveTag(selectedTag: IABGTag) {
		const index = this.tagsList.findIndex(item => item.tAG_ID === selectedTag.tAG_ID);
		this.tagsList.splice(index, 1);
		this.form.controls.tags.setValue(this.tagsList.map(a => a.tAG_ID));
	}

	dataOwnerSelected(e: MatAutocompleteSelectedEvent) {
		const exists = this.dataOwnersList.find(item => item.ID === e.option.value.ID);
		if (!exists) {
			this.dataOwnersList.push(e.option.value);
		}
		this.form.controls.dataOwnersIds.setValue(this.dataOwnersList.map(a => a.ID));
		// this.dataOwnerSearch.setValue('');
		this.dataOwnerSearchInputElement.clearText();
	}

	dataUpdaterSelected(e: MatAutocompleteSelectedEvent) {
		const exists = this.dataUpdatedByList.find(item => item.ID === e.option.value.ID);
		if (!exists) {
			this.dataUpdatedByList.push(e.option.value);
		}
		this.form.controls.dataUpdatedByIds.setValue(this.dataUpdatedByList.map(a => a.ID));
		this.dataUpdatedBySearchInputElement.clearText();
	}

	dataTypeSelected(e: MatAutocompleteSelectedEvent) {
		const exists = this.dataTypes.find(item => item.ID === e.option.value.ID);
		if (!exists) {
			this.dataTypes.push(e.option.value);
		}
		this.form.controls.dataType.setValue(this.dataTypes.map(a => a.ID));
		this.dataTypeSearchInputElement.clearText();
	}

	customAttributeSelected(e: MatAutocompleteSelectedEvent) {
		let enabledAttributes = [];

		const exists = this.customAttributesList.find(item => item.aTTRIBUTE_NAME === e.option.value.aTTRIBUTE_NAME);
		if (!exists) {
			this.customAttributesList.push(e.option.value);
		}

		enabledAttributes = this.customAttributesList.map((item: any) => {
			return {
				attributeName: item.aTTRIBUTE_NAME,
				assets: (JSON.parse(item.aSSETS)).filter(asset => asset.IS_ATTRIBUTE_ENABLED)
			};
		});

		let attributesName = [];
		enabledAttributes.forEach((s: any) => {
			const assetsNames = s.assets.map(item => item.ASSET_TYPE_NAME);
			attributesName = [...attributesName, assetsNames];
		});

		this.form.controls.customAttributesIds.setValue(attributesName);
		this.customAttributeSearchInput.clearText();
	}

	userDisplayFn(option: IUser) {
		return option && option.FULL_NAME || '';
	}

	dataTypeDisplayFn(option) {
		return option && option.value || '';

	}

	customAttributeSelectedDisplayFn(option: IAttribute) {
		return option && option.aTTRIBUTE_NAME || '';
	}

	dataStewardSelected(e: MatAutocompleteSelectedEvent) {
		const exists = this.dataStewardsList.find(item => item.ID === e.option.value.ID);
		if (!exists) {
			this.dataStewardsList.push(e.option.value);
		}
		this.form.controls.dataStewardsIds.setValue(this.dataStewardsList.map(a => a.ID));
		this.dataStewardSearchInputElement.clearText();
	}

	onRemoveDataOwner(selectedUser: IUser) {
		let index;

		if (selectedUser.ID == '-1') {
			index = this.dataOwnersList.findIndex(item => item.ID == 'Assigned' || item.ID == 'Unassigned');
			this.dataOwnersList.splice(index, 1);

		} else {
			index = this.dataOwnersList.findIndex(item => item.ID === selectedUser.ID);
		}

		this.dataOwnersList.splice(index, 1);
		this.form.controls.dataOwnersIds.setValue(this.dataOwnersList.map(a => a.ID));

	}

	onRemoveDataUpdater(selectedUser: IUser) {
		let index;

		if (selectedUser.ID == '-1') {
			index = this.dataUpdatedByList.findIndex(item => item.ID == 'Assigned' || item.ID == 'Unassigned');
			this.dataUpdatedByList.splice(index, 1);

		} else {
			index = this.dataUpdatedByList.findIndex(item => item.ID === selectedUser.ID);
		}

		this.dataUpdatedByList.splice(index, 1);
		this.form.controls.dataUpdatedByIds.setValue(this.dataUpdatedByList.map(a => a.ID));

	}

	onRemoveDataType(selectedDataType: any) {
		let index;

		if (selectedDataType.ID == '-1') {
			index = this.dataTypes.findIndex(item => item.ID == 'Assigned' || item.ID == 'Unassigned');
			this.dataTypes.splice(index, 1);

		} else {
			index = this.dataTypes.findIndex(item => item.ID === selectedDataType.ID);
		}

		this.dataTypes.splice(index, 1);
		this.form.controls.dataType.setValue(this.dataTypes.map(a => a.ID));

	}


	onRemoveCustomAttribute(selectedCustomAttribute: IAttribute) {
		let index;
		let enabledAttributes = [];

		index = this.customAttributesList.findIndex(item => item.aTTRIBUTE_NAME === selectedCustomAttribute.aTTRIBUTE_NAME);
		this.customAttributesList.splice(index, 1);

		enabledAttributes = this.customAttributesList.map((item: any) => {
			return {
				attributeName: item.aTTRIBUTE_NAME,
				assets: (JSON.parse(item.aSSETS)).filter(asset => asset.IS_ATTRIBUTE_ENABLED)
			};
		});

		let attributesName = [];
		enabledAttributes.forEach((s: any) => {
			const assetsNames = s.assets.map(item => item.ASSET_TYPE_NAME);
			attributesName = [...attributesName, assetsNames];
		});

		this.form.controls.customAttributesIds.setValue(attributesName);
	}

	public deleteAllDataOwnerList(assigned: IUser) {
		this.dataOwnersList = [];
		this.dataOwnersList.push(assigned);
	}

	public deleteAllDataStewardList(assigned: IUser) {
		this.dataStewardsList = [];
		this.dataStewardsList.push(assigned);
	}

	public deleteAllDataUpdatedByList(assigned: IUser) {
		this.dataUpdatedByList = [];
		this.dataUpdatedByList.push(assigned);
	}

	public deleteAllDataTypes(assigned: any) {
		this.dataTypes = [];
		this.dataTypes.push(assigned);
	}

	public deleteSpecialOptionDataOwnerList(assigned: IUser) {
		let index;
		index = this.dataOwnersList.findIndex(item => item.ID == 'Assigned' || item.ID == 'Unassigned');
		if (index > -1) {
			this.dataOwnersList.splice(index, 1);
			this.form.controls.dataOwnersIds.setValue(this.dataOwnersList.map(a => a.ID));
		}
	}

	public deleteSpecialOptionDataStewardList(assigned: IUser) {
		let index;
		index = this.dataStewardsList.findIndex(item => item.ID == 'Assigned' || item.ID == 'Unassigned');
		if (index > -1) {
			this.dataStewardsList.splice(index, 1);
			this.form.controls.dataStewardsIds.setValue(this.dataStewardsList.map(a => a.ID));
		}
	}

	public deleteSpecialOptionDataUpdatedByList(assigned: IUser) {
		let index;
		index = this.dataUpdatedByList.findIndex(item => item.ID == 'Assigned' || item.ID == 'Unassigned');
		if (index > -1) {
			this.dataUpdatedByList.splice(index, 1);
			this.form.controls.dataUpdatedByIds.setValue(this.dataUpdatedByList.map(a => a.ID));
		}
	}

	public deleteSpecialOptionDatatype(assigned: any) {
		let index;
		index = this.dataTypes.findIndex(item => item.ID == 'Assigned' || item.ID == 'Unassigned');
		if (index > -1) {
			this.dataTypes.splice(index, 1);
			this.form.controls.dataType.setValue(this.dataTypes.map(a => a.ID));
		}
	}


	onRemoveDataSteward(selectedUser: IUser) {
		let index;
		index = this.dataStewardsList.findIndex(item => item.ID === selectedUser.ID);

		this.dataStewardsList.splice(index, 1);
		this.form.controls.dataStewardsIds.setValue(this.dataStewardsList.map(a => a.ID));
	}

	onApply() {
		this.filtered = true;
		const formData = this.form.getRawValue();
		const propertiesList = this.form.value.propertiesList.filter(x => x.checked === true);
		const missingPropertiesObj: { [key: string]: boolean } = propertiesList.length > 0 ? {} : undefined;
		propertiesList.forEach(element => {
			if (element.value !== PropertiesEnum.dataType) {
				missingPropertiesObj[element.value] = element.toggleValue;
			} else {
				if (element.toggleValue === 'false' && this.dataTypes.length === 0) {
					formData.dataType = ['all'];
				} else if (element.toggleValue === 'true') {
					formData.dataType = [];
				}
			}
		});

		const payload = {
			...formData,
			entryFrom: formData.entryFrom ? formData.entryFrom.format(DATE_FORMATS[0]) : '',
			entryTo: formData.entryTo ? formData.entryTo.format(DATE_FORMATS[0]) : '',
			lastUpdateFrom: formData.lastUpdateFrom ? formData.lastUpdateFrom.format(DATE_FORMATS[0]) : '',
			lastUpdateTo: formData.lastUpdateTo ? formData.lastUpdateTo.format(DATE_FORMATS[0]) : '',
			missingProperties: missingPropertiesObj,
		};

		const filtered = JSON.stringify(payload) !== JSON.stringify(this.initialFilterPayload);
		this.applyFilter.emit({payload, filtered});
	}

	public onSettingsToolbar() {
		this.settingsToolbarItemClick.emit();
	}

	onClearDate(key) {
		event.stopPropagation();
		this.form.controls[key].setValue('');
	}

	onResetFilter() {
		this.filtered = false;
		this.initialFilterPayload.assetTypes.forEach(a => a.checked = true);
		this.initialFilterPayload.statusList.forEach(a => a.checked = true);
		this.tagsList = [];
		this.dataOwnersList = [];
		this.dataStewardsList = [];
		this.dataTypes = [];
		this.form.setValue(this.initialFilterPayload);

		this.initialFilterPayload = JSON.parse(JSON.stringify(this.form.getRawValue()));
	}

	ngOnDestroy(): void {
		this.componentDestroy$.next();
		this.componentDestroy$.unsubscribe();
	}

	public clearText(key: any) {
		this[key].setValue('');
	}

	public subscribeToTagSearch(searchValue: string) {
		this.store.dispatch(new GetTags(searchValue));
	}

	public subscribeToDataOwnerSearch(searchValue: string) {
		// this.dataOwnerSearchInputElement.valueChanges.subscribe(searchTerm => {
		this.getUsers(searchValue)
			.pipe(
				debounceTime(200),
			)
			.subscribe(users => {
				const allOptions = [...users, ...this.moreOptions];
				this.dataOwners$.next(allOptions);
			});
		// });
	}

	public subscribeToCustomAttributeSearch(searchValue: string) {
		this.customAttributesService.getAttributesForFilter()
			.pipe(
				debounceTime(200),
			)
			.subscribe(result => {
				const allOptions = result['data'].oBG.getAdminAssetsTable.filter(item => item.aTTRIBUTE_NAME.toLowerCase().includes(searchValue.toLowerCase()) && item.iS_ACTIVE);
				this.customAttributes$.next(allOptions);
			});


	}

	public subscribeToDataStewardSearch(searchValue: string) {
		// this.dataStewardSearch.valueChanges.subscribe(searchTerm => {
		this.getUsers(searchValue)
			.pipe(
				debounceTime(200),
			)
			.subscribe(users => {
				const allOptions = [...users, ...this.moreOptions];
				this.dataStewards$.next(allOptions);
			});
		// });
	}

	public subscribeToUpdatedBySearch(searchValue: string) {
		this.getUsers(searchValue)
			.pipe(
				debounceTime(200),
			)
			.subscribe(users => {
				const allOptions = [...users];
				this.dataUpdatedBy$.next(allOptions);
			});
	}

	public subscribeToDataTypeSearch(searchValue: string) {
		this.getDataType(searchValue)
			.pipe()
			.subscribe(types => {
				let dataTypes = [...types.data.oBG.getAllDataTypes];
				dataTypes = dataTypes.filter(option => {
					if (option.dATA_TYPE.toLowerCase().includes(searchValue.toLowerCase().trim())) {
						return option;
					}
				});
				const allOptions = dataTypes.map(type => {
					return {
						FULL_NAME: type.dATA_TYPE as string,
						ID: type.dATA_TYPE as string,
					};
				});
				this.dataTypes$.next(allOptions);
			});
	}

	onBookmarkToggle() {
		this.form.controls.bookmark.setValue(!this.form.controls.bookmark.value);
	}

	onNotificationToggle() {
		this.form.controls.notification.setValue(!this.form.controls.notification.value);
	}

	// onResize() {
	// 	this.updateContentSize();
	// }

	// getStyle() {
	// 	this.updateContentSize();
	// 	return {
	// 		height: this.contentHeight
	// 	};
	// }

	// updateContentSize() {
	// 	this.contentHeight = (window.innerHeight - 210) + 'px';
	// }


	private updateForm(data) {
		if (!this.form) {
			this.initForm();
			data.tags?.forEach(tag => {
				const dataToServer = {
					operationName: null,
					query: `{ oBG { getTagsById (tAG_ID: "${tag}") { tAG_ID, tAG_NAME }}}`,
					variables: null
				};
				this.httpUtilsService.post('graphql', dataToServer)
					.pipe(
						filter(list => list.data.oBG.getTagsById.length > 0)
					)
					.subscribe(list => {
						this.tagsList.push(list.data.oBG.getTagsById[0]);
					});
			});

			this.userService.getUsers()
				.subscribe(users => {
					this.dataOwnersList = data.dataOwnersIds.map(id => {
						const user = users.find(item => item.useR_ID === id);
						return {
							ID: user.useR_ID,
							FULL_NAME: `${user.useR_FIRST_NAME} ${user.useR_LAST_NAME}`,
						};
					});
					this.dataStewardsList = data.dataStewardsIds.map(id => {
						const user = users.find(item => item.useR_ID === id);
						return {
							ID: user.useR_ID,
							FULL_NAME: `${user.useR_FIRST_NAME} ${user.useR_LAST_NAME}`,
						};
					});
				});
		}
		if (data) {
			if (this.readOnlyData) {
				this.compareData();
			}
			this.form?.patchValue({
				entryFrom: data.entryFrom ? moment(data.entryFrom) : '',
				entryTo: data.entryTo ? moment(data.entryTo) : '',
				lastUpdateFrom: data.lastUpdateFrom ? moment(data.lastUpdateFrom) : '',
				lastUpdateTo: data.lastUpdateTo ? moment(data.lastUpdateTo) : '',
			});
		}
	}

	private compareData() {
		for (const key in this.initialFilterPayload) {
			const _data = this.data[key];
			const _initData = this.initialFilterPayload[key];
			if (JSON.stringify(_data) !== JSON.stringify(_initData)) {
				this.dataToShow[key] = true;
			}
		}
	}

	private initForm() {
		if (this.form) {
			return;
		}

		const a$ = this.store.pipe(
			select(selectAssetTypes),
			filter(list => !!list),
			take(1),
		);
		const b$ = this.store
			.pipe(
				select(selectStatusList),
				filter(list => !!list),
				take(1),
			);

		combineLatest([a$, b$]).subscribe((res) => {
			const assetTypes = res[0].map((item, index) => {
				return {
					id: index,
					text: AssetTypesMapping[item.oBJECT_TYPE] || this.fixToDisplay(item.oBJECT_TYPE),
					value: item.oBJECT_TYPE,
					checked: true,
				};
			});

			const statusList = res[1].map(item => {
				return {
					id: item.sTATUS_ID,
					text: item.sTATUS_NAME,
					color: item.sTATUS_COLOR,
					checked: true,
				};
			});

			statusList.unshift({
				id: 0,
				text: 'No Status Assigned',
				color: '#fff',
				checked: true,
			});


			const propertiesList = [
				{id: 1, text: 'Description', value: PropertiesEnum.description, checked: false, toggleValue: 'true'},
				{id: 2, text: 'Technical Description', value: PropertiesEnum.technicalDescription, checked: false, toggleValue: 'true'},
				{id: 3, text: 'Calculation Description', value: PropertiesEnum.calculationDescription, checked: false, toggleValue: 'true'},
				{id: 4, text: 'Origin Description', value: PropertiesEnum.originDescription, checked: false, toggleValue: 'true'},
				{id: 5, text: 'Origin Calculation', value: PropertiesEnum.originCalculation, checked: false, toggleValue: 'true'},
				{id: 6, text: 'Source System', value: PropertiesEnum.sourceSystem, checked: false, toggleValue: 'true'},
				{id: 7, text: 'Data Type', value: PropertiesEnum.dataType, checked: false, toggleValue: 'true'},
			];

			this.form = this.fb.group({
				statusList: [statusList],
				assetTypes: [assetTypes],
				rate: [[0, 5]],
				sensitivity: '',
				entryFrom: '',
				entryTo: '',
				lastUpdateFrom: '',
				lastUpdateTo: '',
				tags: [[]],
				dataOwnersIds: [[]],
				dataStewardsIds: [[]],
				customAttributesIds: [[]],
				bookmark: [false],
				propertiesList: [propertiesList],
				dataType: [[]],
				dataUpdatedByIds: [[]],
				suspended: 'false',
			});
			this.initialFilterPayload = JSON.parse(JSON.stringify(this.form.getRawValue()));
			this.adcService.initialFilterValues = this.initialFilterPayload;
			this.filterInit.emit(JSON.parse(JSON.stringify(this.form.getRawValue())));
			this.store.pipe(
				select(getLocation)).subscribe((location => {
				let controls;
				switch (location) {
					case 'adc':
						controls = JSON.parse(localStorage.getItem('adcSetSearchFilters'));
						break;
					case 'adc-dashboard':
						controls = JSON.parse(localStorage.getItem('dashboardFilter'));
						break;
				}
				if (!this.isAddLink && controls) {
					this.form = new FormGroup({
						statusList: new FormControl(controls.statusList),
						assetTypes: new FormControl(controls.assetTypes),
						rate: new FormControl(controls.rate),
						sensitivity: new FormControl(controls.sensitivity),
						entryFrom: new FormControl(controls.entryFrom),
						entryTo: new FormControl(controls.entryTo),
						lastUpdateFrom: new FormControl(controls.lastUpdateFrom),
						lastUpdateTo: new FormControl(controls.lastUpdateTo),
						tags: new FormControl(controls.tags),
						dataOwnersIds: new FormControl(controls.dataOwnersIds),
						dataStewardsIds: new FormControl(controls.dataStewardsIds),
						customAttributesIds: new FormControl(controls.customAttributesIds),
						bookmark: new FormControl(controls.bookmark),
						propertiesList: new FormControl(controls.propertiesList),
						dataType: new FormControl(controls.dataType),
						dataUpdatedByIds: new FormControl(controls.dataUpdatedByIds),
						suspended: new FormControl(controls.suspended),
					});
					this.onApply();
				}

			}));
		});

	}

	private getUsers(searchTerm?): Observable<IUser[]> {
		if (searchTerm && typeof searchTerm !== 'string') {
			return of([searchTerm]);
		}
		return this.userService.getUsers().pipe(
			map((users: IAppUser[]) => {
				const a = users.filter((user: IAppUser) =>
					user.useR_FIRST_NAME.toLowerCase().includes(searchTerm.toLowerCase()) ||
					user.useR_LAST_NAME.toLowerCase().includes(searchTerm.toLowerCase()));
				return a.map((user: IAppUser) => {
					return {
						ID: user.useR_ID,
						FULL_NAME: `${user.useR_FIRST_NAME} ${user.useR_LAST_NAME} ${user.useR_ACTIVE === 'NO' ? '(Deleted)' : ''}`,
					};
				});
			}),
		);
	}

	private getDataType(searchTerm?): Observable<any> {
		if (searchTerm && typeof searchTerm !== 'string') {
			return of([searchTerm]);
		}
		const dataToServer = {
			'operationName': null,
			'query': '{ oBG { getAllDataTypes {dATA_TYPE} }}',
			'variables': null
		};
		return this.httpUtilsService.post('graphql', dataToServer);

	}

	private fixToDisplay(oBJECT_TYPE: string): string {
		const newStr = oBJECT_TYPE.replace(/_/gi, ' ').toLowerCase();
		const arr = newStr.split(' ');

		// loop through each element of the array and capitalize the first letter.
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}

		// Join all the elements of the array back into a string
		// using a blankspace as a separator
		return arr.join(' ');
	}
}

import {Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Input, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
	selector: 'oct-menu-overlay',
	templateUrl: './menu-overlay.component.html',
	styleUrls: ['./menu-overlay.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
})
export class MenuOverlayComponent {
	@ViewChild(MatMenuTrigger) notificationMenuBtn: MatMenuTrigger;
	@ViewChild('container', {static: true, read: ViewContainerRef}) container;
	@Input() contentComponent: any;
	@Input() contentData: any;
	isFiltered: boolean = false;

	constructor(
		private resolver: ComponentFactoryResolver,
	) {
	}

	close() {
		this.notificationMenuBtn.closeMenu();
	}

	onMenuOpen() {
		this.container.clear();
		const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.contentComponent);
		const componentRef: ComponentRef<any> = this.container.createComponent(factory);
		componentRef.instance.data = this.contentData;
		componentRef.instance.close = this.close.bind(this);
		if (componentRef.instance.isFiltered !== undefined) {
			componentRef.instance.isFiltered.subscribe((isFiltered) => {
				this.isFiltered = isFiltered;
			});

		}
	}
}

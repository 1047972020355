import { createFeatureSelector, createSelector } from '@ngrx/store';

export enum SideBarTypes {
  none = '',
  connections = 'Sources',
  properties = 'Properties',
  usage = 'Usage',
}

export enum Status {
  open = 'open',
  close = 'close',
}

export interface QuickSideBar {
  status: Status;
  type?: SideBarTypes;
}

export interface ILayoutState {
  quickSideBar: QuickSideBar;
  navStatus: Status;
  topHeader?: {
    totalWidth: number;
    toolBarWidth: number;
    subHeaderWidth: number;
  };
}

export const layoutState = createFeatureSelector<ILayoutState>('layout');
export const selectQuickSideBar = createSelector(layoutState, state => state.quickSideBar);
export const selectQuickSideBarStatus = createSelector(selectQuickSideBar, state => state.status);
export const selectQuickSideBarType = createSelector(selectQuickSideBar, state => state.type);
export const selectNavMenuStatus = createSelector(layoutState, state => state.navStatus);
export const selectTopHeader = createSelector(layoutState, state => state.topHeader);

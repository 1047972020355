import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ColToColText } from '@core/interfaces/map-diagram-data';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { URLforFullText } from '@core/enum/full-text';
import { PageConfigService } from '@core/services/page-config.service';
import { CommonState, getLocation } from '@store/common';
import { getLineageProperties, LineageState } from '@store/lineage';
import { DiscoveryState, getDiscoveryProperties } from '@store/discovery';
import { CompareETLState, getCompareETLProperties } from '@core/states/compare-etl.state';
import { CompareREPORTState, getCompareREPORTProperties } from '@core/states/compare-report.state';
import { select, Store } from '@ngrx/store';
import { UtilsService } from '@core/services/utils.service';
import { DiscoveryService } from '@main/discovery/discovery.service';
import { CodePreviewState, getScriptOnListProperties, getScriptOnTextTabOfMap } from '@app/store/code-preview/index';
import * as CodePreviewActions from '@app/store/code-preview/actions';
import { Locations } from '@shared/enums/locations.enum';

@Injectable({
	providedIn: 'root',
})
export class CodePreviewService {
    API_ENDPOINT = 'lineageGener/getSpData';
    public arrayOfUrl: Array<string>;
    public params: string;
    public location: string;
    public content: string = `There is no definition for this object`;
    public contentSubject$ = new Subject<string>();
    public id: string;
    public hosterMode: string;
    public config: object = {
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/x-mysql',
        readOnly: true
    };
    private url = URLforFullText;
    private properties: object;

	public highlightCoordinates$ = new Subject();

    constructor(
            private httpUtilsService: HttpUtilsService,
            private pageConfigService: PageConfigService,
            private storeCommon: Store<CommonState>,
            private storeLineage: Store<LineageState>,
            private storeDiscovery: Store<DiscoveryState>,
            private storeCompareETL: Store<CompareETLState>,
            private storeCompareREPORT: Store<CompareREPORTState>,
            private utilsService: UtilsService,
            private discoveryService: DiscoveryService,
            private storeCodePreview: Store<CodePreviewState>,
    ) {
        this.storeCommon.select(getLocation).subscribe(location => {
            this.location = location;
        });
    }

    public getMapTextByIdData(colToColText: ColToColText): Observable<any> {
        return this.httpUtilsService.post(this.API_ENDPOINT, colToColText)
                .pipe(
                        map((result: any) => {
                            return result.res;
                        })
                );
    }

    public getURL(url: URLforFullText): URLforFullText {
        return URLforFullText[url];
    }

    public getData(_url: string, item?: object) {
        if (_url === URLforFullText.GetSP) {
            this.arrayOfUrl = !item ? JSON.parse(this.pageConfigService.decodeBtoa(this.params)) : item;
            this.getTextDataFromSP();
        } else {
            this.getTextData();
        }
    }

    getCodePreviewStyle() {
        let _width, _height, parentContainer;
        this.hosterMode = this.pageConfigService.generalHosterMode ? this.pageConfigService.generalHosterMode : this.hosterMode;
        switch (this.hosterMode) {
            case 'map-id':
                _height = '650px';
                break;

            case 'list-properties':
                _width = '270px';
                _height = '450px';
                break;

            case 'list-properties-large':
                _width = '1280px';
                _height = '450px';
                break;

            default:
                _width = '280px';
                _height = '300px';
        }

        this.setElementStyleAndRefresh(_width, _height);
        this.highlightSearchVal();
    }

    setElementStyleAndRefresh(_width: any, _height: any) {
        // set style and refresh the inner code-editor
        const codeMirrorElements = document.getElementsByClassName('CodeMirror');
        if (codeMirrorElements && codeMirrorElements.length > 0) {
            const codeMirrorElem = (codeMirrorElements[0] as HTMLElement);
            if (codeMirrorElem) {
                if (codeMirrorElem.style) {
                    codeMirrorElem.style.width = _width;
                    codeMirrorElem.style.height = _height;
                    codeMirrorElem.style.border = '1px solid #ededed';
                }

                if (codeMirrorElem['CodeMirror']) {
                    setTimeout(() => {
                        codeMirrorElem['CodeMirror'].refresh();
                    }, 0);
                }
            }
        }
    }

    public setScriptOnListProperties(script: string) {
        this.storeCodePreview.dispatch(new CodePreviewActions.SetScriptOnListProperties(script));
    }

    public setScriptOnTextTabOfMap(script: string) {
        this.storeCodePreview.dispatch(new CodePreviewActions.SetScriptOnTextTabOfMap(script));
    }

    public getScriptOnListPropertiesAsync(): Observable<any> {
        return this.storeCodePreview
                .pipe(
                        select(getScriptOnListProperties),
                );
    }

    public getScriptOnTextTabOfMapAsync(): Observable<any> {
        return this.storeCodePreview
                .pipe(
                        select(getScriptOnTextTabOfMap),
                );
    }

    private getTextDataFromSP() {
        if (this.arrayOfUrl['containerObjPath']) {
            const db_schema_obj = this.utilsService.splitDBAndSchema(this.arrayOfUrl);
            const _data: ColToColText = {
                // queryNum: 310,
                // db_name: db_schema_obj['db'],
                queryNum: this.arrayOfUrl['tool'] === 'ADH' || this.arrayOfUrl['tool'] === 'FILE' ? 330 : 310,
                db_name: this.arrayOfUrl['tool'] === 'ADH' || this.arrayOfUrl['tool'] === 'FILE' ? this.arrayOfUrl['containerObjPath'] : db_schema_obj['db'],
                schema_name: db_schema_obj['schema'],
                obj_name: this.arrayOfUrl['controlFlowPath'],
                conn_ids: this.arrayOfUrl['connIDs'],
                conn_name: this.arrayOfUrl['connName']
            };
            this.getMapTextByIdData(_data)
                    .pipe(
                            filter(response => response[0] !== '[]' && response[0] !== 'null'),
                    )
                    .subscribe(this.buildMapText.bind(this));
        }
    }

    private getTextData() {
        switch (this.location) {
            case 'lineage':
                this['store' + this.utilsService.uppercaseFirstLetter(this.location)].select(getLineageProperties).subscribe(properties => {
                    this.properties = properties;
                });
                break;
            case 'discovery':
                this['store' + this.utilsService.uppercaseFirstLetter(this.location)].select(getDiscoveryProperties).subscribe(properties => {
                    this.properties = properties;
                });
                break;
            case 'compare-etls':
                this.storeCompareETL
                        .pipe(
                                select(getCompareETLProperties),
                        )
                        .subscribe(properties => {
                            this.properties = properties;
                        });
                break;
            default:
                this.storeCompareREPORT
                        .pipe(
                                select(getCompareREPORTProperties),
                        )
                        .subscribe(properties => {
                            this.properties = properties;
                        });
        }

        this.contentSubject$.next(this.properties[this.id].value);
        this.highlightSearchVal(); ////////////////////////
    }

    private buildMapText(response) {
        const data = JSON.parse(response[0])[0];
        this.setScriptOnTextTabOfMap(data.Definition);
        //this.highlightSearchVal();
    }

    private highlightSearchVal() {
        if (this.location.toLowerCase() === Locations.discovery && this.discoveryService.searchVal && this.discoveryService.searchVal != '') {
            const searchVal = this.discoveryService.searchVal;
            let regExPattern = searchVal;
            regExPattern = regExPattern.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
            const regex = new RegExp(regExPattern, 'ig');
            const codeLines = document.getElementsByClassName('CodeMirror-line');
            let argEndIndex;

            for (let i = 0; codeLines.length > i; i++) {
                const line = codeLines[i];

                const argStartIndex = line.innerHTML.toLowerCase().indexOf(searchVal.toLowerCase().trim());
                if (argStartIndex > -1) {

                    if (searchVal.toLowerCase().trim().length < searchVal.length) { // last char is space
                        argEndIndex = searchVal.length - 1;
                    } else {
                        argEndIndex = searchVal.length;
                    }
                }

                const matchingString = line.innerHTML.substr(argStartIndex, argEndIndex);
                line.innerHTML = line.innerHTML.replace(regex, '<span class=\'gbg\'>' + matchingString + '</span>');
            }
        }
    }
}

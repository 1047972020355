import { Injectable } from '@angular/core';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { from, BehaviorSubject, Subject } from 'rxjs';
import { mergeMap, filter } from 'rxjs/operators';


@Injectable()
export class BrowserService {
	constructor() { }

	getBrowser() {
		return this.isOpera() || this.isFirefox() || this.isSafari() || this.isIE() || this.isEdge() || this.isChrome();
	}

	isOpera() {
		// Opera 8.0+
		return ((!!(window as any).opr && !!window['opr'].addons) || !!(window as any).opera || navigator.userAgent.indexOf(' OPR/') >= 0) ? "Opera" : false;
	}

	isFirefox() {
		// Firefox 1.0+
		return navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? "Firefox" : false;
	}

	isSafari() {
		// At least Safari 3+: "[object HTMLElementConstructor]"
		return (Object.prototype.toString.call((window as any).HTMLElement).indexOf('Constructor') > 0) ? "Safari" : false;
	}

	isIE() {
		// Internet Explorer 6-11
		return (false || !!(window as any).document.documentMode) ? "IE" : false;
	}

	isEdge() {
		// Edge 83+
		return navigator.userAgent.indexOf('Edg') >= 0 ? "Edge" : false;
		//return (!this.isIE() && !!window.styleMedia) ? "Edge" : false;
	}

	isChrome() {
		// Chrome 1+
		return (!!(window as any).chrome) ? "Chrome" : false;
		//&& !!window.chrome.webstore;
	}

	detectIE () {
		var ua = window.navigator.userAgent;

		// Test values; Uncomment to check result …

		// IE 10
		// ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

		// IE 11
		// ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

		// Edge 12 (Spartan)
		// ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

		// Edge 13
		// ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		var edge = ua.indexOf('Edge/');
		if (edge > 0) {
			// Edge (IE 12+) => return version number
			return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
		}

		// other browser
		return false;
	}
}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutConfigService } from '@core/services/layout-config.service';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CommonState, getLocation } from '@store/common';
import { AppConfig } from '@core/services/app-config.service';
import { UserProfileService } from './user-profile/user-profile.service';
import { SideBarService } from '@core/services/layout/side-bar.service';
import { SideBarTypes } from '@store/layout';
import { Locations } from '@shared/enums/locations.enum';

@Component({
	selector: 'm-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
	location$: Observable<string>;
	isMonitorDisplay: boolean = false;
	public locations: typeof Locations = Locations;

	constructor(
		private appConfig: AppConfig,
		private layoutConfigService: LayoutConfigService,
		private router: Router,
		private commonStore: Store<CommonState>,
		private userProfileService: UserProfileService,
		private sideBarService: SideBarService,
	) {
		this.location$ = commonStore
			.pipe(
				select(getLocation)
			);
	}

	ngOnInit(): void {
	}

	openSideBar() {
		this.sideBarService.open(SideBarTypes.connections);
	}
}

import { Action, createAction, props } from '@ngrx/store';
import { ViewType } from '@main/adc/adc-shared/types/viewType';

export enum abgDashboardActionsTypes {
	SET_DATE_FILTER = '[ABG-DASHBOARD] set date range',
	SET_SEARCH = '[ABG-DASHBOARD] set search',
	SET_VIEW_TYPE = '[ABG-DASHBOARD] set view type',
	RESET = '[ABG-DASHBOARD] reset data',

	RESET_ABG_ITEM = '[ABG-DASHBOARD] set abg item to null',
	GET_ABG_ITEM = '[ABG-DASHBOARD] get abg item object',
	GET_ABG_ITEM_SUCCESS = '[ABG-DASHBOARD] get abg item object was a success',
	GET_ABG_ITEM_FAILURE = '[ABG-DASHBOARD] get abg item object was failed',

	GET_DASHBOARD_TOTAL = '[ABG-DASHBOARD] get total',
	GET_DASHBOARD_TOTAL_SUCCESS = '[ABG-DASHBOARD] get total success',
	GET_DASHBOARD_TOTAL_FAILURE = '[ABG-DASHBOARD] get total failure',

	GET_DASHBOARD_CHART_DATA = '[ABG-DASHBOARD] get charts data',
	GET_DASHBOARD_CHART_DATA_SUCCESS = '[ABG-DASHBOARD] get charts data success',
	GET_DASHBOARD_CHART_DATA_FAILURE = '[ABG-DASHBOARD] get charts data failure',

	GET_DASHBOARD_CHART_TOTAL = '[ABG-DASHBOARD] get chart total',
	GET_DASHBOARD_CHART_TOTAL_SUCCESS = '[ABG-DASHBOARD] get chart total success',
	GET_DASHBOARD_CHART_TOTAL_FAILURE = '[ABG-DASHBOARD] get chart total failure',

	GET_DASHBOARD_CHART_DETAILS = '[ABG-DASHBOARD] get details',
	GET_DASHBOARD_CHART_DETAILS_SUCCESS = '[ABG-DASHBOARD] get details success',
	GET_DASHBOARD_CHART_DETAILS_FAILURE = '[ABG-DASHBOARD] get details failure',
	GET_DASHBOARD_CHART_DETAILS_LOADING = '[ABG-DASHBOARD] get details loading',

	GET_DASHBOARD_ABG_ITEM_TAGS = '[ABG-DASHBOARD] get abg item tags',
	GET_DASHBOARD_ABG_ITEM_TAGS_SUCCESS = '[ABG-DASHBOARD] get abg item tags success',
	GET_DASHBOARD_ABG_ITEM_TAGS_FAILURE = '[ABG-DASHBOARD] get abg item tags failure',

	GET_DASHBOARD_TOP_TAGS = '[ABG-DASHBOARD] get top tags',
	GET_DASHBOARD_TOP_TAGS_SUCCESS = '[ABG-DASHBOARD] get top tags success',
	GET_DASHBOARD_TOP_TAGS_FAILURE = '[ABG-DASHBOARD] get top tags failure',

	ADD_ITEM = '[ABG-DASHBOARD] add abg item',
	ADD_ITEM_SUCCESS = '[ABG-DASHBOARD] add abg item success',
	ADD_ITEM_FAILURE = '[ABG-DASHBOARD] add abg item failure',

	SET_ABG_ITEM_RATE = '[ABG-DASHBOARD] set dashboard abg item rate',
	SET_ABG_ITEM_RATE_SUCCESS = '[ABG-DASHBOARD] set dashboard abg item rate success',
	SET_ABG_ITEM_RATE_FAILURE = '[ABG-DASHBOARD] set dashboard abg item rate failure',

	GET_RATES = '[ABG-DASHBOARD] get dashboard all rates',
	GET_RATES_SUCCESS = '[ABG-DASHBOARD] get dashboard all rates success',
	GET_RATES_FAILURE = '[ABG-DASHBOARD] get dashboard all rates failure',

	GET_ABG_ITEM_AVG_RATE = '[ABG-DASHBOARD] get abg item average rank',
	GET_ABG_ITEM_AVG_RATE_SUCCESS = '[ABG-DASHBOARD] get abg item average rank success',
	GET_ABG_ITEM_AVG_RATE_FAILURE = '[ABG-DASHBOARD] get abg item average rank failure',

	SET_SENSITIVE_DASHBOARD = '[ABG-DASHBOARD] set abg item sensitive',
	SET_SENSITIVE_DASHBOARD_SUCCESS = '[ABG-DASHBOARD] set abg item sensitive success',
	SET_SENSITIVE_DASHBOARD_FAILURE = '[ABG-DASHBOARD] set abg item sensitive failure',

	SET_ABG_ITEM_PROPERTY = '[ABG-DASHBOARD] set abg item property',

	UPDATE_ABG_ITEM = '[ABG-DASHBOARD] update adc asset',
	UPDATE_ABG_ITEM_SUCCESS = '[ABG-DASHBOARD] update adc asset success',
	UPDATE_ABG_ITEM_FAILURE = '[ABG-DASHBOARD] update adc asset failure',

	SET_SEARCH_FILTERS = '[ABG-DASHBOARD] set search filters',
	SET_SEARCH_FILTERS_SUCCESS = '[ABG-DASHBOARD] set search filters success',
	SET_SEARCH_FILTERS_FAILURE = '[ABG-DASHBOARD] set search filters failure',

	SET_FILTERED = '[ABG-DASHBOARD] set filtered flag',
}

// NGRX 11 Syntax
export const setFilterStatus = createAction('[ADC-DASHBOARD] set filter state', props<{ payload: any }>());
export const activityAction = createAction('[ADC-DASHBOARD] execute action', props<{ payload: any }>());
export const totalAssets = createAction('[ADC-DASHBOARD] get total assets', props<{ payload: any }>());
export const totalAssetsSuccess = createAction('[ADC-DASHBOARD] get total assets success', props<{ payload: any }>());
export const totalAssetsFailure = createAction('[ADC-DASHBOARD] get total assets failure', props<{ error: any }>());
export const insightTotal = createAction('[ADC-DASHBOARD] get insight total', props<{ payload: any }>());
export const insightTotalSuccess = createAction('[ADC-DASHBOARD] get insight total success', props<{ payload: any }>());
export const insightTotalFailure = createAction('[ADC-DASHBOARD] get insight total failure', props<{ error: any }>());
export const chartTotal = createAction('[ADC-DASHBOARD] get chart total', props<{ payload: any }>());
export const chartTotalSuccess = createAction('[ADC-DASHBOARD] get chart total success', props<{ payload: any }>());
export const chartTotalFailure = createAction('[ADC-DASHBOARD] get chart total failure', props<{ error: any }>());
// export const setViewType = createAction('[ADC-DASHBOARD] set view type', props<{ payload: any }>());
export const setInsightType = createAction('[ADC-DASHBOARD] set insight type', props<{ payload: any }>());
export const setSearchFilters = createAction('[ADC-DASHBOARD] set search filters', props<{ payload: any }>());
export const setDateRange = createAction('[ADC-DASHBOARD] set dates filters', props<{ payload: any }>());
export const getAssetsList = createAction('[ADC-DASHBOARD] get assets list', props<{ payload: any }>());
export const getAssetsListSuccess = createAction('[ADC-DASHBOARD] get assets list success', props<{ payload: any }>());
export const getAssetsListFailure = createAction('[ADC-DASHBOARD] get assets list failure', props<{ error: any }>());
export const getTrendingTags = createAction('[ADC-DASHBOARD] get trending tags', props<{ payload: any }>());

export class SetAdcDashboardFiltered implements Action {
	readonly type = abgDashboardActionsTypes.SET_FILTERED;

	constructor(public payload: any) {
	}
}

export class UpdateAbgItem implements Action {
	readonly type = abgDashboardActionsTypes.UPDATE_ABG_ITEM;

	constructor(public payload: any) {
	}
}

export class UpdateAbgItemSuccess implements Action {
	readonly type = abgDashboardActionsTypes.UPDATE_ABG_ITEM_SUCCESS;

	constructor(public payload: any) {
	}
}

export class UpdateAbgItemFailure implements Action {
	readonly type = abgDashboardActionsTypes.UPDATE_ABG_ITEM_FAILURE;

	constructor(public payload: any) {
	}
}

export class Reset implements Action {
	readonly type = abgDashboardActionsTypes.RESET;
}

export class ResetAbgItem implements Action {
	readonly type = abgDashboardActionsTypes.RESET_ABG_ITEM;
}

export class AddItem implements Action {
	readonly type = abgDashboardActionsTypes.ADD_ITEM;

	constructor(public payload: any) {
	}
}

export class AddItemSuccess implements Action {
	readonly type = abgDashboardActionsTypes.ADD_ITEM_SUCCESS;

	constructor(public payload: any) {
	}
}

export class AddItemFailure implements Action {
	readonly type = abgDashboardActionsTypes.ADD_ITEM_FAILURE;

	constructor(public payload: any) {
	}
}

export class SetViewType implements Action {
	readonly type = abgDashboardActionsTypes.SET_VIEW_TYPE;

	constructor(public payload: any) {
	}
}

export class SetSearch implements Action {
	readonly type = abgDashboardActionsTypes.SET_SEARCH;

	constructor(public payload: any) {
	}
}

export class SetDateRange implements Action {
	readonly type = abgDashboardActionsTypes.SET_DATE_FILTER;

	constructor(public payload: any) {
	}
}

export class GetDashboardTotal implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_TOTAL;

	constructor(public payload: any) {
	}
}

export class GetDashboardTotalSuccess implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_TOTAL_SUCCESS;

	constructor(public payload: any) {
	}
}

export class GetDashboardTotalFailure implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_TOTAL_FAILURE;

	constructor(public payload: any) {
	}
}

export class GetDashboardChartData implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_CHART_DATA;

	constructor(public name: string, public payload: any) {
	}
}

export class GetDashboardChartDataSuccess implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_CHART_DATA_SUCCESS;

	constructor(public payload: any) {
	}
}

export class GetDashboardChartDataFailure implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_CHART_DATA_FAILURE;

	constructor(public payload: any) {
	}
}

export class GetDashboardChartTotal implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_CHART_TOTAL;

	constructor(public viewType: ViewType, public payload: any) {
	}
}

export class GetDashboardChartTotalSuccess implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_CHART_TOTAL_SUCCESS;

	constructor(public viewType: ViewType, public payload: any) {
	}
}

export class GetDashboardChartTotalFailure implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_CHART_TOTAL_FAILURE;

	constructor(public payload: any) {
	}
}

export class GetDashboardChartDetailsLoading implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_CHART_DETAILS_LOADING;

	constructor(public payload: any) {
	}
}

export class GetDashboardChartDetails implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_CHART_DETAILS;

	constructor(public viewType: ViewType, public payload: any) {
	}
}

export class GetDashboardChartDetailsSuccess implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_CHART_DETAILS_SUCCESS;

	constructor(public viewType: ViewType, public payload: any) {
	}
}

export class GetDashboardChartDetailsFailure implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_CHART_DETAILS_FAILURE;

	constructor(public payload: any) {
	}
}

export class GetDashboardAbgItemTags implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_ABG_ITEM_TAGS;

	constructor(public payload: any) {
	}
}

export class GetDashboardAbgItemTagsSuccess implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_ABG_ITEM_TAGS_SUCCESS;

	constructor(public payload: any) {
	}
}

export class GetDashboardAbgItemTagsFailure implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_ABG_ITEM_TAGS_FAILURE;

	constructor(public payload: any) {
	}
}

export class GetDashboardTopTags implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_TOP_TAGS;

	constructor(public payload: any) {
	}
}

export class GetDashboardTopTagsSuccess implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_TOP_TAGS_SUCCESS;

	constructor(public payload: any) {
	}
}

export class GetDashboardTopTagsFailure implements Action {
	readonly type = abgDashboardActionsTypes.GET_DASHBOARD_TOP_TAGS_FAILURE;

	constructor(public payload: any) {
	}
}

export class GetAbgItem implements Action {
	readonly type = abgDashboardActionsTypes.GET_ABG_ITEM;

	constructor(public payload: any) {
	}
}

export class GetAbgItemSuccess implements Action {
	readonly type = abgDashboardActionsTypes.GET_ABG_ITEM_SUCCESS;

	constructor(public payload: any) {
	}
}

export class GetAbgItemFailure implements Action {
	readonly type = abgDashboardActionsTypes.GET_ABG_ITEM_FAILURE;

	constructor(public payload: any) {
	}
}

export class SetAbgItemRate implements Action {
	readonly type = abgDashboardActionsTypes.SET_ABG_ITEM_RATE;

	constructor(public payload: any) {
	}
}

export class SetAbgItemRateSuccess implements Action {
	readonly type = abgDashboardActionsTypes.SET_ABG_ITEM_RATE_SUCCESS;

	constructor(public payload: any) {
	}
}

export class SetAbgItemRateFailure implements Action {
	readonly type = abgDashboardActionsTypes.SET_ABG_ITEM_RATE_FAILURE;

	constructor(public payload: any) {
	}
}

export class GetRates implements Action {
	readonly type = abgDashboardActionsTypes.GET_RATES;

	constructor(public payload: any) {
	}
}

export class GetRatesSuccess implements Action {
	readonly type = abgDashboardActionsTypes.GET_RATES_SUCCESS;

	constructor(public payload: any) {
	}
}

export class GetRatesFailure implements Action {
	readonly type = abgDashboardActionsTypes.GET_RATES_FAILURE;

	constructor(public payload: any) {
	}
}

export class GetAbgItemAvgRate implements Action {
	readonly type = abgDashboardActionsTypes.GET_ABG_ITEM_AVG_RATE;

	constructor(public payload: any) {
	}
}

export class GetAbgItemAvgRateSuccess implements Action {
	readonly type = abgDashboardActionsTypes.GET_ABG_ITEM_AVG_RATE_SUCCESS;

	constructor(public payload: any) {
	}
}

export class GetAbgItemAvgRateFailure implements Action {
	readonly type = abgDashboardActionsTypes.GET_ABG_ITEM_AVG_RATE_FAILURE;

	constructor(public payload: any) {
	}
}

export class SetSensitiveDashboard implements Action {
	readonly type = abgDashboardActionsTypes.SET_SENSITIVE_DASHBOARD;

	constructor(public payload: any) {
	}
}

export class SetSensitiveDashboardSuccess implements Action {
	readonly type = abgDashboardActionsTypes.SET_SENSITIVE_DASHBOARD_SUCCESS;

	constructor(public payload: any) {
	}
}

export class SetSensitiveDashboardFailure implements Action {
	readonly type = abgDashboardActionsTypes.SET_SENSITIVE_DASHBOARD_FAILURE;

	constructor(public payload: any) {
	}
}

export class UpdateSelectedAbgProperty implements Action {
	readonly type = abgDashboardActionsTypes.SET_ABG_ITEM_PROPERTY;

	constructor(public payload: any) {
	}
}

export class SetAdcDashboardSearchFilters implements Action {
	readonly type = abgDashboardActionsTypes.SET_SEARCH_FILTERS;

	constructor(public payload: any) {
	}
}

export class SetSearchFiltersSuccess implements Action {
	readonly type = abgDashboardActionsTypes.SET_SEARCH_FILTERS_SUCCESS;

	constructor(public payload: any) {
	}
}

export class SetSearchFiltersFailure implements Action {
	readonly type = abgDashboardActionsTypes.SET_SEARCH_FILTERS_FAILURE;

	constructor(public payload: any) {
	}
}

export type abgDashboardActions =
	SetAdcDashboardFiltered |
	SetAdcDashboardSearchFilters |
	SetSearchFiltersSuccess |
	SetSearchFiltersFailure |
	UpdateAbgItem |
	UpdateAbgItemSuccess |
	UpdateAbgItemFailure |
	SetSensitiveDashboard |
	SetSensitiveDashboardSuccess |
	SetSensitiveDashboardFailure |
	GetAbgItemAvgRate |
	GetAbgItemAvgRateSuccess |
	GetAbgItemAvgRateFailure |
	GetRates |
	GetRatesSuccess |
	GetRatesFailure |
	SetAbgItemRate |
	SetAbgItemRateSuccess |
	SetAbgItemRateFailure |
	GetAbgItem |
	GetAbgItemSuccess |
	GetAbgItemFailure |
	ResetAbgItem |
	Reset |
	SetViewType |
	GetDashboardTotal |
	GetDashboardTotalSuccess |
	GetDashboardTotalFailure |
	GetDashboardChartData |
	GetDashboardChartDataSuccess |
	GetDashboardChartDataFailure |
	GetDashboardChartTotal |
	GetDashboardChartTotalSuccess |
	GetDashboardChartTotalFailure |
	GetDashboardChartDetailsLoading |
	GetDashboardChartDetails |
	GetDashboardChartDetailsSuccess |
	GetDashboardChartDetailsFailure |
	SetDateRange |
	SetSearch |
	GetDashboardAbgItemTags |
	GetDashboardAbgItemTagsSuccess |
	GetDashboardAbgItemTagsFailure |
	GetDashboardTopTags |
	GetDashboardTopTagsSuccess |
	GetDashboardTopTagsFailure |
	AddItem |
	AddItemSuccess |
	AddItemFailure |
	UpdateSelectedAbgProperty;

import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from 'ngx-clipboard';
import { select, Store } from '@ngrx/store';
import { selectSearchType, selectSearchValue } from '@store/search';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchType } from '@shared/enums/searchType';
import { UtilsService } from '@core/services/utils.service';
import { Directionality } from '@angular/cdk/bidi';

@Component({
  selector: 'oct-text-form-control',
  templateUrl: './text-form-control.component.html',
  styleUrls: ['./text-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFormControlComponent),
      multi: true
    }
  ],
})
export class TextFormControlComponent implements OnInit, AfterViewInit {
  @ViewChild('copyLabel', {static: true}) copyLabel: ElementRef;
  @ViewChild('inputText', {static: true}) inputText: ElementRef;
  @Input() formControl: FormControl;
  @Input() id: string;
  @Input() title: string;
  @Input() key: string;
  @Input() value: string;
  @Input() data: any;
  @Input() readonly: boolean;
  @Input() isUserRole: boolean;
  @Input() characterLimit: number = 0;
  // @Output() itemClick: (arg0: string, arg1: string) => void;
  saveClick: (value: string) => void;
  editMode = false;
  copy = false;
  hoverMode: boolean = false;
  characterCount: number = 0;
  @HostBinding('class') textFormControl = 'text-form-control';
  public search$: Observable<{ searchType: SearchType, searchValue: any }>;
  private isRtl: boolean;

  constructor(
    private _clipboardService: ClipboardService,
    private _toaster: ToastrService,
    private store: Store<any>,
    private utilsService: UtilsService,
    private dir: Directionality
  ) {
    this.isRtl = dir.value === 'rtl';
  }

  ngOnInit(): void {
    const a = this.store.pipe(select(selectSearchValue));
    const b = this.store.pipe(select(selectSearchType));
    this.search$ = combineLatest([a, b])
      .pipe(
        // takeUntil(this.componentDestroy$),
        map(results => {
          return {
            searchValue: results[0],
            searchType: results[1],
          };
        })
      );
  }

  ngAfterViewInit(): void {
    this._clipboardService.copyResponse$.subscribe(re => {
      if (re.isSuccess) {
        this._toaster.success('Value Copied!', 'Clipboard');
      } else {
        this._toaster.error('Value Empty!', 'Clipboard');
      }
    });
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value): void {
    this.value = value;
    this.formControl.setValue(value);
  }

  onEditClick() {
    if (this.checkPermissions() || this.formControl.disabled) {
      return;
    }
    this.editMode = !this.editMode;
    this.formControl.addValidators([Validators.maxLength(this.characterLimit - 1)]);
    this.characterCount = this.formControl.value.length;
    this.formControl.valueChanges
      .subscribe((value: string) => {
        this.characterCount = value.length;
      });
  }

  onCancel() {
    this.editMode = false;
    this.hoverMode = false;
    this.formControl.setValue(this.value);
  }

  onSave(value: string) {
    this.value = value;
    this.formControl.setValue(value);
    this.editMode = false;
    this.hoverMode = false;
    // this.itemClick(this.value, this.key);
    this.saveClick(value);
  }

  onCopyClick() {
    this._clipboardService.copy(this.inputText.nativeElement.value);
  }

  public makeHoverFunc() {
    this.hoverMode = !this.hoverMode;
  }

  public checkLang(event) {
    return this.utilsService.isRightToLeftLanguage(event);
  }

  private checkPermissions(): boolean {
    return this.isUserRole;
  }
}

<div [ngClass]="{'has-danger': formControl.invalid && (formControl.dirty || formControl.touched)}"
	 class="form-group m-form__group row item-row">
	<mat-form-field [style.width.%]="100" appearance="outline">
		<mat-label class="col-form-label">{{title}}</mat-label>
		<input #inputElement class="form-control m-input oct-input" matInput type="text"
			   [matAutocomplete]="autoSource">
		<!--		(change)="onChange(inputElement.value)"-->
		<mat-autocomplete #autoSource="matAutocomplete" (optionSelected)="onSelected($event)"
						  class="test-class" classlist="test-class">
			<mat-option *ngFor="let option of options" [value]="option"
						class="auto-complete flex" flex-dircetion-col>
				<div style="font-size: 1rem;"
					 [innerHTML]="option.UIDisplay | highlight: inputElement.highlight | objectName: option.name"></div>
				<div style="font-size: 1rem;">{{option.type}}</div>
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
	<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)"
		 class="form-control-feedback">
		<p *ngIf="formControl.errors.required">{{title}} is required</p>
		<p *ngIf="formControl.errors.minlength">{{title}} must be 2 characters long</p>
		<p *ngIf="formControl.errors.maxlength">{{title}} is limited to 100 characters max</p>
	</div>
</div>

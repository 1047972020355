import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { TokenService } from '@content/pages/token.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private url: string;

  constructor(
    private router: Router,
    private tokenService: TokenService,
  ) {
  }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = this.setHeaders(request);
		return next.handle(request)
			.pipe(
				catchError(err => {
					const accessToken = localStorage.getItem('accessToken');
					if (!accessToken || accessToken === 'undefined') {
						this.router.navigate(['login']);
					}
					return throwError(err);
				}),
			);
	}

  private setHeaders(request) {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${accessToken}`),
      });
    }

    if (request.headers.has('Content-Type') && request.headers.get('Content-Type') === 'multipart/form-data') {
      request = request.clone({
        headers: request.headers.delete('Content-Type'),
      });
    } else if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }

    if (!request.headers.has('Accept')) {
      request = request.clone({
        headers: request.headers.set('Accept', request.url.includes('.svg') ? 'image/svg+xml' : 'application/json'),
      });
    }

    if (!request.headers.has('company-host')) {
      request = request.clone({
        headers: request.headers.set('company-host', 'web.dev.octopai.com'),
      });
    }

    if (!request.headers.has('Access-Control-Allow-Origin')) {
      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Origin', '*.octopai.com'),
      });
    }

    if (!request.headers.has('Access-Control-Allow-Methods')) {
      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE'),
      });
    }

    return request;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BrandComponent } from './header/brand/brand.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { CoreModule } from '@core/core.module';
import { UserProfileComponent } from './header/topbar/user-profile/user-profile.component';
import { SearchDropdownComponent } from './header/topbar/search-dropdown/search-dropdown.component';
import { NotificationsComponent } from '@content/layout/header/topbar/notifications/notifications.component';
import { QuickActionComponent } from './header/topbar/quick-action/quick-action.component';
import { HeaderSearchComponent } from './header/header-search/header-search.component';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { SharedContentModule } from '../shared-content.module';
import { MonitorComponent } from './header/topbar/monitor/monitor.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { AbgService } from '@main/adc/adc.service';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  ListTimelineComponent
} from '@content/layout/header/topbar/recent-activities/list-timeline/list-timeline.component';
import {
  TimelineItemComponent
} from '@content/layout/header/topbar/recent-activities/list-timeline/timeline-item/timeline-item.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { RecentActivitiesComponent } from '@content/layout/header/topbar/recent-activities/recent-activities.component';
import { SideMenuComponent } from '@content/layout/side-menu/side-menu.component';
import { StoreModule } from '@ngrx/store';
import * as fromNotifications from '@store/notifications/reducers';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { NotificationsEffects } from '@store/notifications/effects';
import { AdcFilterModule } from '@shared/oct-components/adc-filter/adc-filter.module';
import { RoundButtonModule } from '@shared/oct-components/round-button/round-button.module';
import { PipesModule } from '@shared/pipes/pipes.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
};

const COMPONENTNS = [
  HeaderComponent,
  NotificationsComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTNS,
    HeaderComponent,
    FooterComponent,
    BrandComponent,
    TopbarComponent,
    UserProfileComponent,
    SearchDropdownComponent,
    QuickActionComponent,
    ListTimelineComponent,
    TimelineItemComponent,
    SideMenuComponent,
    HeaderSearchComponent,
    MonitorComponent,
    RecentActivitiesComponent
  ],
  exports: [
    ...COMPONENTNS,
    HeaderComponent,
    FooterComponent,
    BrandComponent,
    TopbarComponent,
    UserProfileComponent,
    SearchDropdownComponent,
    QuickActionComponent,
    ReactiveFormsModule,
    SideMenuComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AbgService,
  ],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    CoreModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    GridModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    SharedContentModule,
    TranslateModule.forChild(),
    LoadingBarModule.forRoot(),
    TooltipModule,
    NgxPermissionsModule,
    NgxPaginationModule,
    SearchInputModule,
    DirectivesModule,
    StoreModule.forFeature('notifications', fromNotifications.reducers),
    EffectsModule.forFeature([NotificationsEffects]),
    AdcFilterModule,
    RoundButtonModule,
    PipesModule,
  ]
})
export class LayoutModule {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    const svgIconConfig = [
      {name: 'notifications', path: 'assets/user_notification_toggle.svg'},
      {name: 'read-check', path: 'assets/check-mark.svg'},
    ];

    svgIconConfig.forEach(item => {
      this.iconRegistry.addSvgIcon(
        item.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(item.path));
    });
  }
}

<div class="reach-filter-header" [hidden]="readOnlyData">
    <div class="reach-filter-section flex" space-between>
        <div class="flex">
<!--            <mat-icon class="filter cursor" svgIcon="filter"></mat-icon>-->
            <div class="reach-filter-title">Search Filters</div>
        </div>
        <div>
            <mat-icon (click)="onFilterCollapse()" class="close-icon-gray" svgIcon="close"></mat-icon>
        </div>
    </div>
    <div class="reach-filter-separator"></div>
</div>
<div *ngIf="form" [formGroup]="form" class="reach-filter-content">
    <!--    <div class="reach-filter-section">-->
    <!--        <div class="flex" flex-align-items-center>-->
    <!--            <div class="reach-filter-sub-title">My Lists</div>-->
    <!--            <mat-icon (click)="onBookmarkToggle()" [ngClass]="{on: form.controls.bookmark.value, off: !form.controls.bookmark.value}"-->
    <!--                      class="icon"-->
    <!--                      matTooltip="Bookmarks"-->
    <!--                      svgIcon="bookmark"></mat-icon>-->
    <!--            <mat-icon (click)="onNotificationToggle()" [ngClass]="{on: form.controls.notification.value, off: !form.controls.notification.value}"-->
    <!--                      class="icon"-->
    <!--                      matTooltip="Notifications"-->
    <!--                      svgIcon="bell"></mat-icon>-->
    <!--        </div>-->
    <!--    </div>-->
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['statusList']">
        <div class="reach-filter-sub-title">Status</div>
        <div class="flex" flex-dircetion-col>
            <oct-check-box-list class="adc-check-list" formControlName="statusList" [readOnlyData]="readOnlyData"></oct-check-box-list>
        </div>
    </div>
    <div class="reach-filter-separator" [hidden]="readOnlyData && (!dataToShow['rate'] || !dataToShow['statusList'])"></div>
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['rate']">
        <div class="flex" flex-align-items-center>
            <div class="reach-filter-sub-title">Rating</div>
            <div [style.width.%]="100">
                <ngx-slider [options]="sensitivityOptions" formControlName="rate"></ngx-slider>
            </div>
        </div>
    </div>
    <div class="reach-filter-separator" [hidden]="readOnlyData && (!dataToShow['sensitivity'] || !dataToShow['rate'])"></div>
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['sensitivity']">
        <div>
            <div class="reach-filter-sub-title">Sensitive</div>
            <mat-button-toggle-group class="sensitivity" formControlName="sensitivity">
                <mat-button-toggle value="">All</mat-button-toggle>
                <mat-button-toggle value="true">Yes</mat-button-toggle>
                <mat-button-toggle value="false">No</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div class="reach-filter-separator" [hidden]="readOnlyData && (!dataToShow['assetTypes'] || !dataToShow['sensitivity'])"></div>
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['assetTypes']">
        <div class="reach-filter-sub-title">Asset Type</div>
        <div class="flex" flex-dircetion-col>
            <oct-check-box-list class="adc-check-list" formControlName="assetTypes" [readOnlyData]="readOnlyData"></oct-check-box-list>
        </div>
    </div>
    <div class="reach-filter-separator" [hidden]="readOnlyData && (!dataToShow['tags'] || !dataToShow['assetTypes'])"></div>
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['tags']">
        <div class="reach-filter-sub-title">Tags</div>
        <div class="round-wrapper search" [hidden]="readOnlyData && dataToShow['tags']">
            <mat-icon class="search" svgIcon="search"></mat-icon>
            <input [formControl]="tagsSearch" [matAutocomplete]="tags" class="search-input" placeholder="Search">
            <mat-autocomplete #tags (optionSelected)="tagSelected($event)" [displayWith]="displayFn">
                <mat-option *ngFor="let option of tags$ | async" [value]="option" matTooltip="{{option.tAG_NAME}}">
                    {{ option.tAG_NAME }}
                </mat-option>
            </mat-autocomplete>
			<mat-icon *ngIf="tagsSearch.value" (click)="clearText('tagsSearch')" class="clear" svgIcon="close"></mat-icon>
		</div>
        <div *ngIf="tagsList.length" class="m--padding-top-10">
            <mat-chip-list>
                <mat-chip *ngFor="let selectedTag of tagsList">
                    {{selectedTag.tAG_NAME}}
                    <mat-icon (click)="onRemoveTag(selectedTag)" matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div class="reach-filter-separator" [hidden]="readOnlyData && (!dataToShow['dataOwnersIds'] || !dataToShow['tags'])"></div>
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['dataOwnersIds']">
        <div class="reach-filter-sub-title">Data Owner</div>
        <div class="round-wrapper search" [hidden]="readOnlyData && dataToShow['dataOwnersIds']">
            <mat-icon class="search" svgIcon="search"></mat-icon>
            <input [formControl]="dataOwnerSearch" [matAutocomplete]="dataOwner" class="search-input"
                   placeholder="Search">
            <mat-autocomplete #dataOwner (optionSelected)="dataOwnerSelected($event)" [displayWith]="userDisplayFn">
                <mat-option *ngFor="let option of dataOwners$ | async" [value]="option">
                    <div class="flex" flex-align-items-center>
                        <ngx-avatar [name]="option.FULL_NAME" size="20"></ngx-avatar>
						{{ option.FULL_NAME }}
                    </div>
                </mat-option>
            </mat-autocomplete>
			<mat-icon *ngIf="dataOwnerSearch.value" (click)="clearText('dataOwnerSearch')" class="clear" svgIcon="close"></mat-icon>
		</div>
        <div *ngIf="dataOwnersList.length" class="m--padding-top-10">
            <mat-chip-list>
                <mat-chip *ngFor="let selectedUser of dataOwnersList">
                    {{selectedUser.FULL_NAME}}
                    <mat-icon (click)="onRemoveDataOwner(selectedUser)" matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div class="reach-filter-separator" [hidden]="readOnlyData && (!dataToShow['dataStewardsIds'] || !dataToShow['dataOwnersIds'])"></div>
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['dataStewardsIds']">
        <div class="reach-filter-sub-title">Data Steward</div>
        <div class="round-wrapper search" [hidden]="readOnlyData && dataToShow['dataStewardsIds']">
            <mat-icon class="search" svgIcon="search"></mat-icon>
            <input [formControl]="dataStewardSearch" [matAutocomplete]="dataSteward" class="search-input"
                   placeholder="Search">
            <mat-autocomplete #dataSteward (optionSelected)="dataStewardSelected($event)" [displayWith]="userDisplayFn">
                <mat-option *ngFor="let option of dataStewards$ | async" [value]="option">
                    {{ option.FULL_NAME }}
                </mat-option>
            </mat-autocomplete>
			<mat-icon  *ngIf="dataStewardSearch.value" (click)="clearText('dataStewardSearch')" class="clear" svgIcon="close"></mat-icon>
		</div>
        <div *ngIf="dataStewardsList.length" class="m--padding-top-10">
            <mat-chip-list>
                <mat-chip *ngFor="let selectedUser of dataStewardsList">
                    {{selectedUser.FULL_NAME}}
                    <mat-icon (click)="onRemoveDataSteward(selectedUser)" matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div class="reach-filter-separator" [hidden]="readOnlyData && (!dataToShow['entryFrom'] || !dataToShow['entryTo'] || !dataToShow['dataStewardsIds'])"></div>
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['entryFrom'] && !dataToShow['entryTo']">
        <div class="reach-filter-sub-title">Entry Date</div>
        <div class="flex">
            <div (click)="entryFrom.open()" class="round-wrapper date" [hidden]="readOnlyData && !dataToShow['entryFrom']">
                <mat-icon class="calendar" svgIcon="calendar"></mat-icon>
                <input [matDatepicker]="entryFrom" class="search-input" formControlName="entryFrom" placeholder="From"
                       readonly>
                <mat-datepicker #entryFrom></mat-datepicker>
                <mat-icon *ngIf="!!form.controls.entryFrom.value" (click)="onClearDate('entryFrom')" class="clear" svgIcon="close"></mat-icon>
            </div>
            <div (click)="entryTo.open()" class="round-wrapper date flex" [hidden]="readOnlyData && !dataToShow['entryTo']">
                <mat-icon class="calendar" svgIcon="calendar"></mat-icon>
                <input [matDatepicker]="entryTo" class="search-input" formControlName="entryTo" placeholder="To"
                       readonly>
                <mat-datepicker #entryTo></mat-datepicker>
                <mat-icon *ngIf="!!form.controls.entryTo.value" (click)="onClearDate('entryTo')" class="clear" svgIcon="close"></mat-icon>
            </div>
        </div>
    </div>
    <div class="reach-filter-separator" [hidden]="readOnlyData && (!dataToShow['entryFrom'] || !dataToShow['entryTo'] || !dataToShow['lastUpdateFrom'] || !dataToShow['lastUpdateTo'])"></div>
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['lastUpdateFrom'] && !dataToShow['lastUpdateTo']">
        <div class="reach-filter-sub-title">Last Update</div>
        <div class="flex">
            <div (click)="lastUpdateFrom.open()" class="round-wrapper date" [hidden]="readOnlyData && !dataToShow['lastUpdateFrom']">
                <mat-icon class="calendar" svgIcon="calendar"></mat-icon>
                <input [matDatepicker]="lastUpdateFrom" class="search-input" formControlName="lastUpdateFrom"
                       placeholder="From" readonly>
                <mat-datepicker #lastUpdateFrom></mat-datepicker>
                <mat-icon *ngIf="!!form.controls.lastUpdateFrom.value" (click)="onClearDate('lastUpdateFrom')" class="clear" svgIcon="close"></mat-icon>
            </div>
            <div (click)="lastUpdateTo.open()" class="round-wrapper date flex" [hidden]="readOnlyData && !dataToShow['lastUpdateTo']">
                <mat-icon class="calendar" svgIcon="calendar"></mat-icon>
                <input [matDatepicker]="lastUpdateTo" class="search-input" formControlName="lastUpdateTo"
                       placeholder="To" readonly>
                <mat-datepicker #lastUpdateTo></mat-datepicker>
                <mat-icon *ngIf="!!form.controls.lastUpdateTo.value" (click)="onClearDate('lastUpdateTo')" class="clear" svgIcon="close"></mat-icon>
            </div>
        </div>
    </div>
    <div class="reach-filter-separator" [hidden]="readOnlyData && (!dataToShow['lastUpdateFrom'] || !dataToShow['lastUpdateTo'] || !dataToShow['description'])"></div>
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['description']">
        <div class="reach-filter-sub-title">Description</div>
        <mat-button-toggle-group class="description" formControlName="description">
            <mat-button-toggle value="">All</mat-button-toggle>
            <mat-button-toggle value="true">Missing</mat-button-toggle>
            <mat-button-toggle value="false">Including</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="reach-filter-separator" [hidden]="readOnlyData && (!dataToShow['description'] || !dataToShow['suspended'])"></div>
    <div class="reach-filter-section" [hidden]="readOnlyData && !dataToShow['suspended']">
        <div class="reach-filter-sub-title">Suspended</div>
        <mat-button-toggle-group class="suspended" formControlName="suspended">
            <mat-button-toggle value="">All</mat-button-toggle>
            <mat-button-toggle value="true">Yes</mat-button-toggle>
            <mat-button-toggle value="false">No</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>
<div class="reach-filter-footer" [hidden]="readOnlyData">
    <div class="reach-filter-separator"></div>
    <div class="reach-filter-section flex">
        <div class="icon-wrapper cursor">
            <mat-icon (click)="onResetFilter()" class="reset-filter" matTooltip="Reset Search Filter"
                      svgIcon="reset-filter"></mat-icon>
        </div>
        <button (click)="onApply()" class="apply-btn primary" mat-button>APPLY</button>
    </div>
</div>

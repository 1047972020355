import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeContext } from '@angular-slider/ngx-slider';

@Component({
    selector: 'oct-rating-slider',
    templateUrl: './rating-slider.component.html',
    styleUrls: ['./rating-slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RatingSliderComponent),
            multi: true
        }
    ],
})
export class RatingSliderComponent implements OnInit, ControlValueAccessor {
    @Input() options: any;
    public lowValue: number;
    public highValue: number;
    showSlider = false;

    constructor() {
    }

    onChange: any = () => {
    }
    onTouch: any = () => {
    }

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue([lowValue, highValue]): void {
        setTimeout(() => {
            this.lowValue = lowValue;
            this.highValue = highValue;
            this.showSlider = true;
        }, 0);
    }

    onClick(changeContext: ChangeContext) {
        this.onChange([changeContext.value, changeContext.highValue]);
    }
}

export enum usage {
	lastRunDate = 'tLast Run Date',
	lastRunByUser = 'Last Run by User',
	lastChangeDate = 'Last Change Date',
	performance = 'Performance',
	numberOfRuns = 'Number of Runs',
}

export class UsagePayload {
	tool: string;
	queryNum: number;
	containerObjPath: string;
	connLogicName: string;
	userId: string;
}

<div *ngIf="!item.tooBig && !item.valid" class="container">
	<div class="container-header">{{item.page != 'map' ? "Cross System Lineage" : "Inner System Lineage"}} not found!</div>

	<div class="container-body">
		Your search for <b>
			{{item.module}} object
			- {{item.page == 'lineage' ? item.name : item.mapName || item.controlFlowPath  || item.name}}
		</b> - did not match
		any {{item.page != 'map' ? "Cross System Lineage" : "Inner System Lineage"}}.
	</div>

	<div class="container-body-report" *ngIf="item.module == 'REPORT'">
		<div>Report path:</div>
		<b>{{item.pathFolder || item.path}}</b>
	</div>

	<div *ngIf="!item.noRedirect" class="container-footer">
		You can get more information about this {{item.module}} object on the <b (click)="redirectToDiscovery()" class="label-link">Discovery</b>
		module.
	</div>
</div>

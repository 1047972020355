import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CodePreviewComponent } from './code-preview.component';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';

@NgModule({
	imports: [
		CommonModule,
		NgbModule,
		FormsModule,
		CodemirrorModule,
		SharedModule,
		SearchInputModule,
		TranslateModule.forChild()
	],
	exports: [CodePreviewComponent],
	declarations: [CodePreviewComponent],
})
export class CodePreviewModule {
}

import { Injectable } from '@angular/core';
import { of, Subscription, timer } from 'rxjs';
import { environment } from '@environments/environment';
import moment from 'moment';
import { catchError, take } from 'rxjs/operators';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private bypassClient: HttpClient;
  private timerSubscription: Subscription;

  constructor(
    private handler: HttpBackend,
    private router: Router,
  ) {
    this.bypassClient = new HttpClient(handler);
    if (location.href.includes('live-lineage') || location.href.includes('reset-password') || location.href.includes('signup') || location.href.includes('login')) {
      return;
    }
    const tokenExpired = this.isAccessTokenExpire();
    if (tokenExpired) {
      this.requestAccessToken();
    }
  }

  startTokenTimer() {
    this.timerSubscription = timer(0, environment.accessTokenCheckup)
      .subscribe(value => {
        const tokenExpired = this.isAccessTokenExpire();
        const tokenLess = window.location.href.includes('live-lineage');
        if (tokenExpired && !tokenLess) {
          this.requestAccessToken();
        }
      });
  }

  stopTokenTime() {
    this.timerSubscription.unsubscribe();
  }

  validateToken(email, token) {
    return this.bypassClient
      .post(`${environment.apiUrl}userAccount/ValidateOTT`, {email}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  }

  public isAccessTokenExpire() {
    const accessTokenExpiration = localStorage.getItem('accessTokenExpiration');
    if (accessTokenExpiration) {
      const b = moment.utc(accessTokenExpiration);
      const a = moment.utc();
      const diff = b.diff(a, 'minutes');
      return diff < environment.accessTokenExpirationHeadUp;
    }
    return true;
  }

  private requestAccessToken(request?) {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!!refreshToken) {
      this.bypassClient.post(`${environment.apiUrl}userAccount/refresh`, {RefreshToken: refreshToken})
        .pipe(
          take(1),
          catchError(err => {
            if ([403].includes(err.status)) {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              localStorage.removeItem('accessTokenExpiration');
              localStorage.removeItem('refreshTokenExpiration');
              setTimeout(() => {
                this.router.navigate(['login']);
              }, 1000);
            }

            return of();
          }),
        )
        .subscribe((accessData: any) => {
          localStorage.setItem('accessToken', accessData.accessToken);
          localStorage.setItem('accessTokenExpiration', accessData.expiration);
          localStorage.setItem('refreshToken', accessData?.refreshToken.token);
          localStorage.setItem('refreshTokenExpiration', accessData?.refreshToken.expiration);
        });
    } else {
      this.router.navigate(['login']);
    }
  }
}

import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { LineageData } from '@core/interfaces/lineage-data';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { PageConfigService } from '@core/services/page-config.service';
import { ReferenceTableService } from '@core//services/reference-table.service';
import { getLineageSelectedConnectionsIDs, SearchInfo } from '@store/lineage';
import { LineageIDService } from '../lineage-diagram/lineage-id/lineage-id.service';
import { DiscoveryService } from '@main/discovery/discovery.service';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { ScanningState } from '@store/common/actions';
import { LogsService } from '@core/services/logs.service';
import { getPublicSettings } from '@store/common';
import { ILegendItem } from '@shared/interfaces/lineage';
import { ErrorService } from '@shared/services/error.service';
import { LINEAGE_TYPE_TITLE_MAP, QUERY_NUM_MAP } from '@shared/enums/module-type.enum';


@Injectable()
export class LineageService {
  public doughnutCounterSubject = new Subject();
  public querynums: any = null;
  public searchVal: string = ''; // main search
  public lastFilterVal_ETL: string = '';
  public lastFilterVal_DB: string = '';
  public lastFilterVal_REPORT: string = '';
  public pageFrom: number = 0;
  public pageTo: number = 50;
  public requestedFrom: number = 0;
  public requestedTo: number = 50;
  public timeout = null;
  public totals = [];
  public connectionId: string;
  public queryNumsObj = [];
  public isScanning = false;
  public sharedTotals = {'302': [], '303': [], '304': []};
  public isDoughnutRendered = {'302': false, '303': false, '304': false};
  public lastFilterVal = {'302': '', '303': '', '304': ''};
  public dataItems = {'302': {}, '303': {}, '304': {}};
  public isFetching = {'302': false, '303': false, '304': false};
  public totalChange = {'302': 0, '303': 0, '304': 0};
  public compareObj = {'first': null, 'second': null};
  public filterChange = 0;
  public lastSearchValueLineage: string = '';
  public colorLegend = [];
  public rebindingNum: number = 0;
  public lastSearchValue: string;
  public lineageSearchValue;
  public markLegendTools: { index: number, type: string, text: string, toolName: string, active: boolean }[] = [];
  public legendTools: {
    index: number,
    type: string,
    text: string,
    toolName: string,
    active: boolean,
    strike?: boolean
  }[] = [];
  selectedLegendItems = {
    ETL: [],
    DB: [],
    REPORT: []
  };
  private requestSubscription: Subscription;
  private orientChunk: number;

  constructor(
    private httpUtilsService: HttpUtilsService,
    private pageConfigService: PageConfigService,
    private router: Router,
    private referenceTableService: ReferenceTableService,
    private lineageIDService: LineageIDService,
    private moduleUtilsService: ModuleUtilsService,
    private discoveryService: DiscoveryService,
    private logsService: LogsService,
    private store: Store<any>,
    private errorService: ErrorService
  ) {
    this.store
      .pipe(
        select(getLineageSelectedConnectionsIDs),
      )
      .subscribe(selectedConnectionsIds => {
        this.connectionId = selectedConnectionsIds;
      });

    this.store
      .pipe(
        select(getPublicSettings),
      )
      .subscribe(public_settings => {
        this.orientChunk = public_settings.orientChunk;
      });
  }

  public getToolByQueryNumber(queryNumber) {
    switch (queryNumber) {
      case 302:
        return 'ETL';
      case 303:
        return 'DB';
      case 304:
        return 'REPORT';
      default:
    }
  }

  public getTotalLineageResult(lineageData: LineageData): Observable<any> {
    const payload = this.buildDataObjForServer(lineageData);
    return this.httpUtilsService.post('lineage/GetMainScreenItems', payload)
      .pipe(
        map(result => this.buildResultObjFromServer(JSON.parse(result), payload)),
        catchError(error => {
          this.errorService.openErrorDialog();
          return throwError(error);
        })
      );
  }

  public getQuerynmber(_module): number {
    switch (_module) {
      case 'ETL':
        return 307;
      case 'DB':
        return 308;
      case 'REPORT':
        return 306;
      default:
    }
  }

  setColorsWithToolsNames(queryNum: string, colorsWithToolsNames: any[]): any {
    const colorLegendItem = this.colorLegend.find(item => item.key === queryNum);
    if (colorLegendItem) {
      colorLegendItem.values = colorsWithToolsNames;
    } else {
      this.colorLegend.push({
        key: queryNum,
        values: colorsWithToolsNames
      });
    }
  }

  setParamsForLineage(item): void {
    let _id: object;
    // the order of the values inside the array is the order of the values inside the query - {0},{1},...
    switch (item.TOOL_TYPE) {
      case 'ETL':
        _id = {
          module: item.TOOL_TYPE,
          name: item.object_name,
          path: item.object_path,
          seq: item.object_container || -1,
          connIDs: this.connectionId,
          connName: item.conn_name,
          tool: item.tool_name || item.tool,
          queryNum: 'LINEAGE',
          ObjectGUID: item.ObjectGUID,
        };
        break;
      case 'DB':
      case 'DATABASE':
        _id = {
          module: item.TOOL_TYPE,
          name: item.OBJECT_NAME,
          path: item.DATABASE_NAME || -1,
          seq: item.SCHEMA_NAME || -1,
          connIDs: this.connectionId,
          tool: item.TOOL_NAME || item.tool,
          connName: item.conn_name,
          queryNum: 'LINEAGE',
          ObjectGUID: item.ObjectGUID,
        };
        break;
      case 'REPORT':
        _id = {
          module: item.TOOL_TYPE,
          name: item.REPORT_NAME,
          path: item.REPORT_PATH,
          connIDs: this.connectionId,
          connName: item.conn_name,
          queryNum: 'LINEAGE',
          tool: item.TOOL_NAME || item.tool,
          ObjectGUID: item.ObjectGUID,
        };
        break;
    }
    // if user clicked on lineage diagram and then click again on the same lineage diagram object -
    // so e save his last state (includes his expand objects)
    if ((!this.lineageIDService.selectedObj && !item.ObjectGUID)
      || this.lineageIDService.selectedObj !== item.ObjectGUID) {
      this.lineageIDService.url = this.pageConfigService.encodeBtoa(JSON.stringify(_id));
    }
    this.lineageIDService.selectedObj = item.ObjectGUID;
    this.router.navigate(['/lineageSchema', this.lineageIDService.url]);
  }

  getLineage(searchInfo: SearchInfo): Observable<any> {
    this.colorLegend = [];

    return new Observable(observer => {
      let payload: LineageData = null;
      if (searchInfo) {
        const legendItems = this.getLegendItems();
        let toolNames = [];
        if (searchInfo.module) {
          toolNames = legendItems
            .filter(item => item.type === searchInfo.module && item.active)
            .map(item => item.toolName);
        } else {
          toolNames = legendItems
            .filter(item => item.active)
            .map(item => item.toolName);
        }
        searchInfo.filters = {ToolName: toolNames || []};

        payload = this.buildDataObj(searchInfo);
      }

      this.store.dispatch(new ScanningState(true));

      this.getTotalLineageResult(payload)
        .subscribe(response => {
          this.store.dispatch(new ScanningState(false));
          this.tryTriggerNotification(response);

          response.lineage[0].returnData = this.referenceTableService.buildResults(response.lineage[0]);
          const obj = {
            querynumber: QUERY_NUM_MAP[searchInfo.module],
            tool: searchInfo.module,
            data: response.lineage[0],
            totals: JSON.parse(response.totals),
            title: LINEAGE_TYPE_TITLE_MAP[searchInfo.module]
          };
          observer.next(obj);
        });
    });
  }

  public tryTriggerNotification(response: any) {

    const totals = JSON.parse(response.totals);

    // if results is empty
    if (totals.length === 0) {
      // there is an input text
      if (this.searchVal && this.searchVal !== '' && this.searchVal !== '  ') {
        // at least one connection checked
        if (this.connectionId.length > 0) {
          if (this.discoveryService.tinyModalMode === 'open') {
            this.discoveryService.tinyModalMode = null;
          }
          setTimeout(() => {
            this.discoveryService.tinyModalMode = 'open';
          }, 0);
        }
      } else {
        this.discoveryService.tinyModalMode = 'close';
      }
    } else {
      this.discoveryService.tinyModalMode = 'close';
    }
  }

  public setPageFromToByQuery(payload, start, end, queryNum) {
    const lineageItem = payload.lineage.find(Item => Item.queryNumber === queryNum);
    lineageItem.pageFrom = start;
    lineageItem.pageTo = end;
  }

  // public buildDataObj(searchInfo: SearchInfo): any {
  //   let autoCompleteSearchVal: string;
  //   this.initQueryNumObj();
  //   const lineage = [];
  //
  //   for (const query of this.queryNumsObj) {
  //     let filterVal = '';
  //     this.store
  //       .pipe(
  //         select(selectQuerySearchValue(query.querynumber)),
  //       )
  //       .subscribe(_searchValue => {
  //         filterVal = _searchValue.trim().replace(/'/g, '\'\'') || '';
  //       });
  //
  //     const item = {
  //       filterVal,
  //       pageFrom: this.pageFrom,
  //       pageTo: this.pageTo,
  //       queryNumber: query.querynumber,
  //     };
  //
  //     if (searchInfo.query_num === query.querynumber && !!searchInfo.filters.ToolName.length) {
  //       item['filters'] = searchInfo.filters;
  //     }
  //
  //     lineage.push(item);
  //   }
  //
  //   if (searchInfo.query_num && (searchInfo.isAutoComplete === undefined || searchInfo.isAutoComplete === false)) {
  //     this.searchVal = this.lastSearchValueLineage;
  //   } else if (searchInfo.query_num && searchInfo.isAutoComplete) {
  //     autoCompleteSearchVal = searchInfo.outer_search;
  //   } else {
  //     this.searchVal = searchInfo.outer_search;
  //   }
  //
  //   return {
  //     connectionId: searchInfo.connectionIds || this.connectionId || '-1',
  //     searchVal: searchInfo.isAutoComplete ? autoCompleteSearchVal : (searchInfo.outer_search ? searchInfo.outer_search.trim().replace(/'/g, '\'\'') : ''),
  //     lineage,
  //   };
  // }

  public buildDataObj(searchInfo: SearchInfo): any {
    let autoCompleteSearchVal: string;
    // this.initQueryNumObj();
    const lineage = [];
    //
    // for (const query of this.queryNumsObj) {
    //   let filterVal = '';
    //   this.store
    //     .pipe(
    //       select(selectQuerySearchValue(query.querynumber)),
    //     )
    //     .subscribe(_searchValue => {
    //       filterVal = _searchValue.trim().replace(/'/g, '\'\'') || '';
    //     });

    const item = {
      filterVal: searchInfo.inner_search?.trim().replace(/'/g, '\'\'') || '',
      pageFrom: searchInfo.pageFrom,
      pageTo: searchInfo.pageTo,
      queryNumber: searchInfo.query_num,
    };

    if (!!searchInfo.filters?.ToolName?.length) {
      item['filters'] = searchInfo.filters;
    }

    lineage.push(item);
    // }

    if (searchInfo.query_num && (searchInfo.isAutoComplete === undefined || searchInfo.isAutoComplete === false)) {
      this.searchVal = this.lastSearchValueLineage;
    } else if (searchInfo.query_num && searchInfo.isAutoComplete) {
      autoCompleteSearchVal = searchInfo.outer_search;
    } else {
      this.searchVal = searchInfo.outer_search;
    }

    return {
      connectionId: searchInfo.connectionIds || this.connectionId || '-1',
      searchVal: searchInfo.isAutoComplete ? autoCompleteSearchVal : (searchInfo.outer_search ? searchInfo.outer_search.trim().replace(/'/g, '\'\'') : ''),
      lineage,
    };
  }

  lineageSearch(searchInfo: SearchInfo): Observable<any> {
    return this.getLineage(searchInfo);
  }

  public initQueryNumObj() {
    this.queryNumsObj = [
      {
        querynumber: 302,
        tool: 'ETL',
        data: undefined,
        totals: [],
        title: 'MAPS & JOBS & PACKAGES & SP'
      }, {
        querynumber: 303,
        tool: 'DATABASE',
        data: undefined,
        totals: [],
        title: 'TABLES & VIEWS & SP & FILES'
      }, {
        querynumber: 304,
        tool: 'REPORT',
        data: undefined,
        totals: [],
        title: 'REPORTS'
      }
    ];
  }

  public getLastFilterValByQueryNum(queryNum: number) {
    if (queryNum) {
      return this.lastFilterVal[queryNum];
    }
  }

  // run on all dbs and check is duplicate, so merge them
  public meregAllDBs(arr) {
    const output = [];
    arr.forEach(function (value, key) {
      const existing = output.filter(function (v, i) {
        return v.id === value.id && value.type === 'DATABASE' && v.type === value.type;
      });
      if (existing.length) {
        const existingIndex = output.indexOf(existing[0]);

        if (output[existingIndex].dbRightId || value.dbRightId) {
          if (!output[existingIndex].dbRightId) {
            output[existingIndex].dbRightId = '';
          }
          output[existingIndex].dbRightId = output[existingIndex].dbRightId.concat(value.dbRightId);
        }

        if (output[existingIndex].dbLeftId || value.dbLeftId) {
          if (!output[existingIndex].dbLeftId) {
            output[existingIndex].dbLeftId = '';
          }
          output[existingIndex].dbLeftId = output[existingIndex].dbLeftId.concat(value.dbLeftId);
        }

        if (output[existingIndex].reportId) {
          output[existingIndex].reportId = output[existingIndex].reportId.concat(value.reportId);
        }

        if (output[existingIndex].dbRightTwoId || value.dbRightTwoId) {
          if (!output[existingIndex].dbRightTwoId) {
            output[existingIndex].dbRightTwoId = '';
          }
          output[existingIndex].dbRightTwoId = output[existingIndex].dbRightTwoId.concat(value.dbRightTwoId);
        }

        if (output[existingIndex].dbLeftTwoId || value.dbLeftTwoId) {
          if (!output[existingIndex].dbLeftTwoId) {
            output[existingIndex].dbLeftTwoId = '';
          }
          output[existingIndex].dbLeftTwoId = output[existingIndex].dbLeftTwoId.concat(value.dbLeftTwoId);
        }

        if (output[existingIndex].etlId) {
          output[existingIndex].etlId = output[existingIndex].etlId.concat(value.etlId);
        }

        if (output[existingIndex].dbId || value.dbId) {
          if (!output[existingIndex].dbId) {
            output[existingIndex].dbId = '';
          }
          output[existingIndex].dbId = output[existingIndex].dbId.concat(value.dbId);
        }

      } else {
        value.id = value.id;
        output.push(value);
      }
    });
    return output;
  }

  public getQueryNumberByToolName(toolName) {
    const tools = {
      'ETL': 302,
      'DATABASE': 303,
      'REPORT': 304
    };

    return tools[toolName];
  }

  public buildObjForLineageQuery(obj) {
    let _obj = {};

    if (obj.TOOL_TYPE === 'ETL' || obj.module === 'ETL' || (obj.value && obj.value.dataObj.module === 'ETL')) { // 307
      _obj = {
        module: obj.value ? obj.value.module : obj.TOOL_TYPE || obj.module,
        tool: obj.value ? obj.value.dataObj.tool : obj.tool_name || obj.tool,
        TOOL_TYPE: 'ETL',
        object_name: obj.value ? obj.value.dataObj.name : obj.object_name || obj.name,
        object_path: obj.value ? obj.value.dataObj.path_folder : obj.object_path || obj.path,
        object_container: obj.value ? obj.value.dataObj.etl_sequencer || -1 : obj.object_container || obj.seq || -1,
        conn_name: obj.value ? obj.value.dataObj.connection_logic_name : obj.conn_name || obj.connName,
        queryNum: 'LINEAGE', // 307
        rID: obj.value ? obj.value.dataObj.rID : obj.rID || obj['@rid'],
        ObjectGUID: obj.value ? obj.value.dataObj.ObjectGUID : obj.ObjectGUID,
      };
    } else if (obj.TOOL_TYPE === 'REPORT' || obj.module === 'REPORT' || (obj.value && obj.value.dataObj.module === 'REPORT')) { // 306
      _obj = {
        module: obj.value ? obj.value.module : obj.TOOL_TYPE || obj.module,
        tool: obj.value ? obj.value.dataObj.tool : obj.TOOL_NAME || obj.tool,
        TOOL_TYPE: 'REPORT',
        REPORT_NAME: obj.value ? obj.value.dataObj.name : obj.REPORT_NAME || obj.name,
        REPORT_PATH: obj.value ? obj.value.dataObj.url : obj.REPORT_PATH || obj.path,
        conn_name: obj.value ? obj.value.dataObj.connection_logic_name : obj.conn_name || obj.connName,
        queryNum: 'LINEAGE', // 306
        rID: obj.value ? obj.value.dataObj.rID : obj.rID || obj['@rid'],
        ObjectGUID: obj.value ? obj.value.dataObj.ObjectGUID : obj.ObjectGUID,
      };
      // if jumping between lineages or if came from target map and click lineage
    } else if (this.moduleUtilsService.getModuleLongName((obj.TOOL_TYPE || obj.module ? obj.TOOL_TYPE || obj.module : obj.value.dataObj.tool)) === 'DATABASE'
      || obj.value
      && (this.moduleUtilsService.getModuleLongName(obj.value.dataObj.module) === 'DATABASE' || obj.value.dataObj.as.toLowerCase() === 'target' || obj.value.dataObj.as.toLowerCase() === 'transform')) { // 308
      _obj = {
        module: obj.value ? obj.value.module : this.moduleUtilsService.getModuleLongName(obj.TOOL_TYPE || obj.module),
        tool: obj.value ? obj.value.dataObj.tool : obj.TOOL_NAME || obj.tool,
        TOOL_TYPE: 'DB',
        OBJECT_NAME: obj.value ? obj.value.dataObj.name : obj.OBJECT_NAME || obj.name,
        DATABASE_NAME: obj.value ? (obj.value.dataObj.db_name || obj.value.dataObj.path_folder) || -1 : obj.DATABASE_NAME || obj.path || -1,
        SCHEMA_NAME: obj.value ? obj.value.dataObj.schema || -1 : obj.SCHEMA_NAME || obj.seq || -1,
        conn_name: obj.value ? obj.value.dataObj.connection_logic_name : obj.conn_name || obj.connName,
        queryNum: 'LINEAGE', // 308
        rID: obj.value ? obj.value.dataObj.rID : obj.rID || obj['@rid'],
        ObjectGUID: obj.value ? obj.value.dataObj.ObjectGUID : obj.ObjectGUID,
      };
    }
    // }

    this.lineageIDService.initVals();
    this.setParamsForLineage(_obj);
  }

  // all the db levels - if obj exist in two diff levels, so merge him

  // ***************************
  public addLegendItem(index: number, type: string, text: string, toolName: string, active: boolean) {
    this.legendTools.push({
      index,
      type,
      text,
      toolName,
      active,
    });
  }

  // public getLegendItems(type?: string): ILegendItem[] {
  //   if (type) {
  //     return this.legendTools.filter(item => item.type === type);
  //   }
  //   return this.legendTools;
  // }

  public updateLegendItem(type: string, text: string, active: boolean) {
    const legendItem = this.legendTools.find((item: ILegendItem) => item.type === type && item.text === text);
    if (legendItem) {
      legendItem.active = active;
    }
  }


  // ***************************
  // Chart Legend Utils

  public updateLegendIndex(type: string, text: string, index: number) {
    const legendItem = this.legendTools.find((item: ILegendItem) => item.type === type && item.text === text);
    if (legendItem) {
      legendItem.index = index;
    }
  }

  public getLegendItem(type: string, text: string): ILegendItem {
    return this.legendTools.find((item: ILegendItem) => item.type === type && item.text === text);
  }

  public getLegendItems(type?: string): ILegendItem[] {
    if (type) {
      return this.legendTools.filter(item => item.type === type);
    }
    return this.legendTools;
  }

  resetLegendItems(type: string) {
    this.legendTools
      .filter(item => item.type === type)
      .forEach(item => {
        item.active = true;
      });
  }

  // public setDisableTheResetLegendItems(selectedItem) {
  //   const tmp = _.difference(this.legendTools, this.markLegendTools);
  //   tmp.forEach(item => {
  //     item.active = false;
  //   });
  // }

  private buildDataObjForServer(lineageData): object {
    const searches = [];
    if (lineageData.lineage) {
      Object.values(lineageData.lineage)
        .forEach((value: any) => {
          searches.push({
            type: this.getToolByQueryNumber(value.queryNumber),
            filter: value.filterVal,
            from: value.pageFrom,
            filters: value.filters,
          });
        });
    }
    return {
      connections: lineageData.connectionId.split(','),
      mainSearch: lineageData.searchVal,
      searches,
      type: lineageData.type,
    };
  }

  private buildResultObjFromServer(result: any, requestObj: any): object {
    const obj_result: LineageData = {
      connectionId: undefined,
      searchVal: undefined,
      lineage: [{
        filterVal: undefined,
        pageFrom: undefined,
        pageTo: undefined,
        queryNumber: undefined
      }]
    };
    const totals: Array<object> = [];

    // build total obj
    for (const key in result.total) {
      const _key: any = key;
      if (typeof result.total[key] === 'object') {
        for (const k in result.total[key]) {
          if (k.length > 0) {
            const objOfTotals = {
              cnt: result.total[key][k],
              object_type: k,
              tool_type: key.slice(0, _key.startsWith('details') - 7).toUpperCase()
            };
            totals.push(objOfTotals);
          }
        }
      }
    }

    obj_result.lineage = [];
    obj_result.lineage[0] = {
      queryNumber: QUERY_NUM_MAP[requestObj.searches[0].type],
      pageFrom: requestObj.searches[0].from,
      pageTo: requestObj.searches[0].from + this.orientChunk,
      filterVal: requestObj.searches[0].filter,
      returnData: (() => {
        switch (requestObj.searches[0].type) {
          case 'ETL':
            return result.ETLobjects;
          case 'DB':
            return result.DBobjects;
          case 'REPORT':
            return result.REPORTobjects;
        }
        // result.ETLobjects
      })()
    };
    // obj_result.lineage[1] = {
    //   queryNumber: 303,
    //   pageFrom: requestObj.searches[1].from,
    //   pageTo: requestObj.searches[1].from + this.orientChunk,
    //   filterVal: requestObj.searches[1].filter,
    //   returnData: result.DBobjects
    // };
    // obj_result.lineage[2] = {
    //   queryNumber: 304,
    //   pageFrom: requestObj.searches[2].from,
    //   pageTo: requestObj.searches[2].from + this.orientChunk,
    //   filterVal: requestObj.searches[2].filter,
    //   returnData: result.REPORTobjects
    // };

    obj_result.totals = JSON.stringify(totals);
    obj_result.searchVal = requestObj.search;
    obj_result.connectionId = requestObj.connections.join(',');
    return obj_result;
  }

  private checkIfSearchValueIsEmpty(searchInfo?: SearchInfo): boolean {
    let result = false;
    if (searchInfo) {
      if (searchInfo.outer_search && searchInfo.outer_search.trim().length > 0) {
        result = true;
      }

      if (searchInfo.inner_search && searchInfo.inner_search.trim().length > 0) {
        result = true;
      }

      if (searchInfo.item_search) {
        result = true;
      }
    }
    return result;
  }

  // ***************************
}

import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatTooltip } from '@angular/material/tooltip';
import { SearchFacadeService } from '@store/services/search-facade.service';
import { debounceTime, filter, tap } from 'rxjs/operators';

// Declare gTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'm-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})

export class SearchInputComponent implements AfterContentInit, AfterViewInit {
  @ViewChild(MatTooltip) matToolTip: MatTooltip;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('searchContainer') searchContainer: ElementRef<HTMLDivElement>;
  @Input() id: string;
  @Input() applySearchOnEnter = false;
  @Input() applyNavigation = false;
  @Input() placeholder: string;
  @Input() options: any[];
  @Input() autoComplete: MatAutocomplete;
  @Input() autocompleteDisabled = true;
  @Input() tooltip = '';
  @Input() searchIcon: boolean = true;
  @Input() disableSearch: boolean;
  @Output() enter: EventEmitter<string> = new EventEmitter();
  @Output() keyPress: EventEmitter<string> = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Output() focusIn = new EventEmitter();
  value$ = new BehaviorSubject<string>('');
  indexValue: number = -1;
  totalValue: number;
  searchValue: string;
  private searchContainerWidth: number;
  private previousValue: string;

  constructor(
    private searchFacadeService: SearchFacadeService,
  ) {
  }

  @Input()
  set isFocus(value: boolean) {
    if (value) {
      this.searchInput.nativeElement.focus();
    }
  }

  @Input()
  set index(value: number) {
    this.indexValue = value;
  }

  @Input()
  set total(value: number) {
    this.totalValue = value;
  }

  get value() {
    return this.searchValue;
  }

  @Input()
  set value(value: string) {
    this.searchValue = value;
  }

  get getSearchValue() {
    return this.searchInput.nativeElement.value;
  }

  ngAfterContentInit(): void {
    if (this.matAutocompleteTrigger) {
      this.matAutocompleteTrigger.autocompleteDisabled = true;
    }
  }

  public clearText() {
    this.searchInput.nativeElement.value = '';
    this.previousValue = '';
    this.indexValue = -1;
    this.clear.emit();
  }

  public onFocusIn(e) {
    if (this.matAutocompleteTrigger) {
      this.matAutocompleteTrigger.autocompleteDisabled = true;
      this.matAutocompleteTrigger.closePanel();
      if (this.searchInput.nativeElement.value.length > 1) {
        this.focusIn.emit();
        this.matAutocompleteTrigger.autocompleteDisabled = this.autocompleteDisabled;
        this.handleAutoCompletePanel();
      }
    }
  }

  public onMouseUp() {
    if (this.searchInput.nativeElement.value.length > 1) {
      this.handleAutoCompletePanel();
    }
  }

  public closePanel() {
    this.matAutocompleteTrigger.closePanel();
  }

  ngAfterViewInit(): void {
    this.value$
      .subscribe(value => {
        this.searchInput.nativeElement.value = value;
        this.previousValue = value;
      });

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        tap(() => {
          if (this.matAutocompleteTrigger.autocomplete) {
            this.matAutocompleteTrigger.autocomplete.panelWidth = this.searchContainer.nativeElement.offsetWidth;
          }
        }),
        filter(() => this.searchInput.nativeElement.value.length <= 200),
        debounceTime(1000),
      )
      .subscribe((e: KeyboardEvent) => {
        if (this.searchInput.nativeElement.value.trim() !== '') {
          this.onKeyUp(e);
        } else {
          this.clearText();
        }
      });
  }

  private onKeyUp(e) {
    const searchValue = this.searchInput.nativeElement.value;

    if (searchValue.length <= 1) {
      this.matAutocompleteTrigger.autocompleteDisabled = true;
      if (this.autoComplete) {
        this.matAutocompleteTrigger.closePanel();
      }
      if (searchValue === '') {
        this.keyPress.emit(searchValue.trim());
      }
    } else {
      // for google tag (gtag)
      window.dataLayer.push({
        event: 'CustomSearch',
        customSearchInput: searchValue
      });

      if (this.applySearchOnEnter) {
        if (e.key === 'Enter') {
          this.enter.emit(searchValue);
          this.matAutocompleteTrigger.closePanel();
        } else {
          this.keyPress.emit(searchValue);
          this.matAutocompleteTrigger.autocompleteDisabled = false;
          this.matAutocompleteTrigger.openPanel();
        }
      } else {
        if (this.previousValue !== this.searchInput.nativeElement.value) {
          this.previousValue = this.searchInput.nativeElement.value;
          this.keyPress.emit(searchValue);
        }
      }
    }
  }

  private handleAutoCompletePanel() {
    if (this.matAutocompleteTrigger.autocompleteDisabled) {
      return;
    }
    this.searchContainerWidth = this.searchContainer.nativeElement.offsetWidth;
    if (this.searchInput.nativeElement.value && this.searchInput.nativeElement.value.length > 1) {
      this.matAutocompleteTrigger.openPanel();
    } else {
      this.matAutocompleteTrigger.closePanel();
    }
  }
}

import { Injectable } from '@angular/core';
import { ILogData } from '../interfaces/log-data';
import { Store } from '@ngrx/store';
import { addActivity } from '@store/activities/actions';

@Injectable()
export class LogsService {
	API_ENDPOINT: any = '/logs';

	constructor(
		private store: Store<any>,
	) {
	}

	public writeToUsage(log: ILogData) {
		this.store.dispatch(addActivity({payload: log}));
	}
}

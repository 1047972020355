import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'oct-avatar-icon',
	templateUrl: './avatar-icon.component.html',
	styleUrls: ['./avatar-icon.component.scss']
})
export class AvatarIconComponent implements OnInit {
	@Input() fullName: string;
	@Input() size: number;

	constructor() {
	}

	ngOnInit(): void {
	}

}

import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface CompareETLState {
	selected_connections_ids: string;
	count: string;
	selected_connections_objs: Array<any>;
	properties: object;
	properties_obj: object;
	connections_list: Array<any>;
	connections?: any[];
	connectionsBG?: any[];
}

export const initialCompareETLState: CompareETLState = {
	selected_connections_ids: getAllCompareETLSelectedConnectionsIDs(),
	count: '0',
	selected_connections_objs: [],
	properties: {
		obj_1: undefined,
		val_1: {
			as_full_text: undefined,
			as_multi_values: undefined,
			as_url: undefined,
			value: undefined
		}
	},
	properties_obj: {},
	connections_list: [],
};

export const selectCompareETLState = createFeatureSelector<CompareETLState>('compare_etl');
export const getCompareETLSelectedConnectionsIDs = createSelector(selectCompareETLState, state => state.selected_connections_ids);
export const getCompareETLProperties = createSelector(selectCompareETLState, state => state.properties);
export const getCompareETLPropertiesObj = createSelector(selectCompareETLState, state => state.properties_obj);
export const getCompareETLSelectedConnectionsObjs = createSelector(selectCompareETLState, state => state.selected_connections_objs);
export const getCompareETLCount = createSelector(selectCompareETLState, state => state.count);
export const getCompareETLConnectionsList = createSelector(selectCompareETLState, state => state.connections_list);
export const selectCompareETLConnections = createSelector(
	selectCompareETLState,
	state => {
		return {
			connections: state.connections,
			connectionsBG: state.connectionsBG,
		};
	});


function getAllCompareETLSelectedConnectionsIDs() {
	const allItemsFromLocalStorage = {...localStorage};
	const allCompareETLItemsFromLocalStorage = [];
	for (const key in allItemsFromLocalStorage) {
		if (key.startsWith('CE_') && allItemsFromLocalStorage[key] === 'true') {
			const _obj_id = key.substr(key.lastIndexOf('_') + 1);
			allCompareETLItemsFromLocalStorage.push(_obj_id);
		}
	}
	return allCompareETLItemsFromLocalStorage.toString() || '-1';
}

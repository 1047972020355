<div class="m-nav__link m-dropdown__toggle pointer user-profile-box" id="user-profile-trigger">
	<span class="m-topbar__userpic user-profile-box" matTooltip="{{userProfileService.full_name}}">
		<span (click)="toggleUserProfile()" class="m-nav__link-icon-wrapper user-profile-box">
		<ngx-avatar [name]="userProfileService.full_name" bgColor="#e6eaef" class="user-profile-box"
					fgColor="#365B89" size="30"></ngx-avatar>
	</span>
	</span>
</div>

<div (clickOutside)="closeModal()" [excludeClasses]="['user-profile-box', 'avatar-content']"
	 [ngClass]="{active: !modalClosed}"
	 class="m-dropdown__wrapper">
	<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust tooltip-arrow-color"></span>
	<div class="m-dropdown__inner">
		<div class="m-dropdown__header m--align-center topbar-popover">
			<ngx-avatar [name]="userProfileService.full_name" bgColor="#e6eaef" fgColor="#365B89"></ngx-avatar>
			<div *ngIf="userProfileService.user_obj" class="right-header ellipsis">
						<span class="ellipsis align-left" matTooltip="{{ userProfileService.user_obj.full_name }}"
							  octRtlView>
							{{ userProfileService.user_obj.full_name }}
						</span>
				<a class="m-card-user__email m-link ellipsis align-left"
				   href="mailto:{{userProfileService.user_obj.email}}">
					{{ userProfileService.user_obj.email }}
				</a>
				<div class="version">
					Version {{version}}
				</div>
			</div>
			<mat-icon (click)="closeModal()" class="close-btn" matTooltip="Cancel" svgIcon="close-icon"></mat-icon>
		</div>
		<div class="m-dropdown__body">
			<div class="m-dropdown__content">
				<ul class="m-nav m-nav--skin-light">
					<li (click)="closeUserProfile()"
						*ngIf="userProfileService.user_obj && userProfileService.user_obj.user_auth_level == 'ADMIN'"
						class="m-nav__item">
						<mat-icon class="blue-icon" svgIcon="settings"></mat-icon>
						<span class="m-nav__link-text">
							Switch to Admin Console
						</span>
					</li>
					<li class="m-nav__separator m-nav__separator--fit"></li>
					<li class="m-nav__item">
						<mat-icon class="blue-icon" svgIcon="logout"></mat-icon>
						<span (click)="logout()" class="m-nav__link-text">
							Logout
						</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>

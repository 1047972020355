export enum URLforFullText {
	Text = "",
	GetSP = "lineageGener/getSpData",
	GetPowerBI4 = "generUI/PowerBi_UI_Full_Text", //Get + toolname + querynumber
	GetOracle4 = "generUI/Oracle_UI_Full_Text", //Get + toolname + querynumber
	GetSQLS4 = "generUI/SQLS_OBJ_UI_Full_Text",
	GetFiles4 = "generUI/Files_SQL_UI_Full_Text",
	GetSsrs5 = "generUI/Ssrs_UI_Full_Text",

	//TODO: insert all
	//Oracle_UI_Full_Text
}

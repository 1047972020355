import { Component, OnInit, Inject } from '@angular/core';
import { OCT_OVERLAY_DATA, OctOverlayRef } from '@shared/services/oct-overlay.service';

@Component({
  selector: 'oct-general-table-page',
  templateUrl: './general-table-page.component.html',
  styleUrls: ['./general-table-page.component.scss']
})
export class GeneralTablePageComponent implements OnInit {

	constructor(
		public octOverlayRef: OctOverlayRef,
		@Inject(OCT_OVERLAY_DATA) public data: any) { }

	ngOnInit() { }

	public close() {
		this.octOverlayRef.close();
	}
}

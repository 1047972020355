<div [style.height.%]="100" class="m-list-settings">

    <div class="btns flex" space-around>
        <div [style.width.%]="100" class="m--margin-top-5 m--margin-bottom-15 flex" flex-center>

			<oct-round-button (clicked)="onCheckAllClick(true)" [theme]="buttonThemeColor.whiteTheme"
							  fontSize="1rem" [disabled]="needToDisabled"
							  matTooltip="Check all" text="Check all"></oct-round-button>

			<oct-round-button (clicked)="onCheckAllClick(false)" [theme]="buttonThemeColor.whiteTheme"
							  fontSize="1rem" [disabled]="needToDisabled" class="m--margin-left-8 m--margin-right-8"
							  matTooltip="Uncheck all" text="Uncheck all"></oct-round-button>

			<oct-round-button (clicked)="expandCollapseState()" [theme]="buttonThemeColor.whiteTheme"
							  fontSize="1rem" [disabled]="needToDisabled"
							  matTooltip="{{isCollapsed ? 'Expand all' : 'Collapse all'}}"
							  text="{{isCollapsed ? 'Expand all' : 'Collapse all'}}"></oct-round-button>
        </div>
    </div>


    <perfect-scrollbar [style.max-height.vh]="80">
        <div class="m-list-settings__heading-bigger">
            METADATA SOURCE
        </div>
        <div *ngFor="let connection of connectionsCollections?.connections; let i = index"
             class="m-list-settings__group">
            <mat-accordion>
                <mat-expansion-panel (closed)="toggleSelectedModule(false, i)" (opened)="toggleSelectedModule(true, i)"
                                     [expanded]="allExpandState" class="mat-elevation-z">
                    <mat-expansion-panel-header [ngClass]="{'open': setOpenSelectedMenuStyle(i)}" class="main_header">
                        {{connection.key}}
                        <span *ngIf="connectionsList.moduleCounter[connection.key]"
                              class="m-menu__link-badge badge-container">
							<span class="m-badge m-badge--danger">
								{{connectionsList.moduleCounter[connection.key].checked}}
                                /{{connectionsList.moduleCounter[connection.key].total}}
							</span>
						</span>
                    </mat-expansion-panel-header>

                    <div *ngFor="let item of connection.value" class="m-list-settings__heading">
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="allExpandState" class="mat-elevation-z">
                                <mat-expansion-panel-header class="sub_header">
                                    {{moduleUtilsService.shortToLongToolNameToDisplay(item.key)}}
                                </mat-expansion-panel-header>
                                <div *ngFor="let connection of item.value" class="m-list-settings__item">
									<span class="m-list-settings__item-label">
										{{connection.connection_name}}
									</span>
                                    <span class="m-list-settings__item-control">
										<span [ngClass]="{'m-switch--disabled' : needToDisabled}"
                                              class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
											<label>
												<input (change)="isChecked(connection)"
                                                       [checked]="connection.is_checked"
                                                       [disabled]="needToDisabled" name=""
                                                       type="checkbox">
												<span></span>
											</label>
										</span>
									</span>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div *ngIf="connectionsList.locationSubString == 'D' && connectionsCollections?.connectionsBG.length"
             [ngClass]="{'list-setting-item-d': connectionsList.locationSubString == 'D'}"
             class="data-dictionary">
            <div class="m-list-settings__heading-bigger">
                REPORTING & ANALYTICS MODELS
            </div>
            <div *ngFor="let connection of connectionsCollections?.connectionsBG; let i = index"
                 class="m-list-settings__group">
                <mat-accordion>
                    <mat-expansion-panel (closed)="toggleSelectedModule(false, i)"
                                         (opened)="toggleSelectedModule(true, i)"
                                         [expanded]="true"
                                         class="mat-elevation-z">
                        <div *ngFor="let item of connection.value" class="m-list-settings__heading">
                            <mat-accordion>
                                <mat-expansion-panel [expanded]="allExpandState" class="mat-elevation-z">
                                    <mat-expansion-panel-header [ngClass]="{'open': setOpenSelectedMenuStyle(i)}"
                                                                class="sub_header">
                                        {{moduleUtilsService.shortToLongToolNameToDisplay(item.key)}}
                                    </mat-expansion-panel-header>
                                    <div *ngFor="let val of item.value" class="m-list-settings__item">
										<span class="m-list-settings__item-label">
											{{val.connection_name}}
										</span>
                                        <span class="m-list-settings__item-control">
											<span [ngClass]="{'m-switch--disabled' : needToDisabled}"
                                                  class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
												<label>
													<input (change)="isChecked(val)" [checked]="val.is_checked"
                                                           [disabled]="needToDisabled" name=""
                                                           type="checkbox">
													<span></span>
												</label>
											</span>
										</span>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

    </perfect-scrollbar>
</div>

<div class="flex width-present-100" flex-align-items-center>
	<input class="new-text" value='{{color}}' style="margin:0 5px; flex:1;"
		   [cpSaveClickOutside]="false"
		   [cpOKButtonClass]="'round-btn'"
		   [cpDisableInput]="true"
		   [cpOKButton]="true"
		   [colorPicker]="color"
		   (colorPickerSelect)="onApply()"
		   [cpOKButtonText]="'Apply'"
		   [cpHeight]="height"
		   [cpWidth]="width"
		   [cpPosition]="position"
		   [cpPositionOffset]="'10%'"
		   [cpPositionRelativeToArrow]="true"
		   (colorPickerChange)="colorPickerOnChange($event)"/>
	<div [ngClass]="{'color-selection': customSelectColor}"
		 (click)="selectCustomColor()">
		<div class="circle" [ngStyle]="{'background-color': color}"></div>
	</div>
</div>

import { createAction, props } from '@ngrx/store';

export const e2eReset = createAction('[E2E] reset dashboard data');
export const getE2EData = createAction('[E2E] get e2e dashboard data', props<{ payload: any }>());
export const getE2EDataSuccess = createAction('[E2E] get e2e dashboard data success', props<{ payload: any }>());
export const getE2EDataFailure = createAction('[E2E] get e2e dashboard data failure', props<{ error }>());

export const fetchE2EMoreData = createAction('[E2E] get e2e dashboard fetch more data', props<{ payload: any }>());
export const fetchE2EMoreDataSuccess = createAction('[E2E] get e2e dashboard fetch more data success', props<{ payload: any }>());
export const fetchE2EMoreDataDataFailure = createAction('[E2E] get e2e dashboard fetch more data failure', props<{ error }>());

export const resetE2EJson = createAction('[E2E] reset e2e column json');
export const getE2EJson = createAction('[E2E] get e2e column json', props<{ payload: any }>());
export const getE2EJsonSuccess = createAction('[E2E] get e2e column json success', props<{ json: any }>());
export const getE2EJsonFailure = createAction('[E2E] get e2e column json failure', props<{ error }>());

export const setInnerSearch = createAction('[E2E] set inner search value', props<{
  searchType: string,
  searchValue: string
}>());

export const setE2EPropertiesObj = createAction('[E2E] set e2e properties obj', props<{ payload: any }>());

export const updateE2ENodes = createAction('[E2E] update e2e nodes', props<{ payload: any }>());

export const addHistory = createAction('[E2E] add history', props<{ payload: any[] }>());

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfig } from '@core/services/app-config.service';

@Injectable({
	providedIn: 'root'
})
export class LoginGuard implements CanActivate {
	constructor(
		private router: Router,
	) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> {
		const manualLogin = route.queryParams.manual === 'true';
		return new Observable(o => {
			const autoLogin = AppConfig.settings.azureAutoLogin;
			const isAzureLogin = AppConfig.settings.showAzureLogin;
			const isOktaLogin = AppConfig.settings.oktaLogin;
			const isSSO = isAzureLogin || isOktaLogin;

			if (manualLogin) {
				localStorage.removeItem('ssoToken');
				o.next(true);
				return;
			}

			if (isSSO) {
				if (autoLogin) {
					this.router.navigate(['sso']);
				} else {
					o.next(true);
				}
			} else {
				o.next(true);
			}
		});
	}
}

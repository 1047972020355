import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationType } from '@core/enum/notifications';
import { selectAll } from '@store/notifications/reducers';

export interface INotification {
  id: string;
  type: number;
  message: string;
  percentage: number;
  isRead: boolean;
}

export interface State extends EntityState<INotification> {
}

export const selectNotificationsState = createFeatureSelector('notifications');
export const selectNotifications = createSelector(
  selectNotificationsState,
  selectAll,
);
export const selectOctomizeNotifications = createSelector(
  selectNotifications,
  (state: any) => state.filter((notification: INotification) => notification.type === NotificationType.Octomize)
);

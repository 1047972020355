<ng-container class="toast-success toast ng-trigger ng-trigger-flyInOut" style="" toast-component="">
	<div class="toast-success-body">
		<div aria-label="Test title" class="toast-title ng-star-inserted" style="">{{title}}
			<span (click)="openNewItem()" class="link">{{toasterData.objName}}</span>
		</div>
		<div aria-label="Test message" aria-live="polite" class="toast-message ng-star-inserted" role="alertdialog"
			 style="">{{message}}</div>
	</div>
<!--	<button aria-label="Close" class="toast-close-button ng-tns-c39-10 ng-star-inserted" style="">-->
<!--		<span aria-hidden="true" class="ng-tns-c39-10">×</span></button>-->
</ng-container>

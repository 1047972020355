import { Action } from '@ngrx/store';
import { AdcState, PostsDrawerStatus } from '@main/adc/adc-store/adc';

export enum abgActionTypes {
    RESET_SELECTED = '[ABG] Reset selected',
    RESET_ABG_RESULTS = '[ABG] Reset obg results',
    LOADING = '[ABG] set loading',
    GET_ABG_ITEM = '[ABG] get abg item object',
    GET_ABG_ITEM_SUCCESS = '[ABG] get abg item object was a success',
    GET_ABG_ITEM_FAILURE = '[ABG] get abg item object was failed',
    GET_ABG_DATA_AND_TOTAL = '[ABG] Get obg data and total',
    GET_ABG_DATA_AND_TOTAL_SUCCESS = '[ABG] Get obg data and total success',
    GET_ABG_DATA_AND_TOTAL_FAILURE = '[ABG] Get obg data and total failure',
    GET_ABG_DATA = '[ABG] Get obg data',
    GET_ABG_DATA_SUCCESS = '[ABG] Get obg data success',
    GET_ABG_DATA_FAILURE = '[ABG] Get obg data failure',
    GET_ABG_TOTAL = '[ABG] Get obg total count',
    GET_ABG_TOTAL_SUCCESS = '[ABG] Get obg total count success',
    GET_ABG_TOTAL_FAILURE = '[ABG] Get obg total count failure',
    SET_ABG_FILTER = '[ABG] Set abg filter',
    SET_ABG_TAGS = '[ABG] Set abg tags',
    SET_ABG_TAG = '[ABG] set abg tag',
    REMOVE_ABG_TAG = '[ABG] remove abg tag',
    ADD_OBG = '[ABG] Add',
    REMOVE_OBG = '[ABG] Remove',
    UPDATE_SELECTED_ABG_ID = '[ABG] Update Selected Obj ID',
    UPDATE_SELECTED_ABG = '[ABG] Update Selected Obj',
    GET_POSTS = '[ABG] Get posts',
    GET_POSTS_SUCCESS = '[ABG] Get posts success',
    GET_POSTS_FAILURE = '[ABG] Get posts failure',
    SET_POSTS_DRAWER_STATUS = '[ABG] Set post drawer status',
    SET_LINK_SEARCH_VALUE = '[ABG] Set add link search value',
    UPDATE_ABG_ITEM = '[ADC] updating adc item',
    UPDATE_ABG_ITEM_SUCCESS = '[ADC] update adc item success',
    UPDATE_ABG_ITEM_FAILURE = '[ADC] update adc item failure',
    SET_ABG_ITEM_PROPERTY = '[ABG] set abg item property',
    SET_ABG_ITEM_RATE = '[ABG] set abg item rate',
    SET_ABG_ITEM_RATE_SUCCESS = '[ABG] set abg item rate success',
    SET_ABG_ITEM_RATE_FAILURE = '[ABG] set abg item rate failure',
    GET_RATES = '[ABG] get all rates',
    GET_RATES_SUCCESS = '[ABG] get all rates success',
    GET_RATES_FAILURE = '[ABG] get all rates failure',
    GET_ABG_ITEM_AVG_RATE = '[ABG] get abg item average rank',
    GET_ABG_ITEM_AVG_RATE_SUCCESS = '[ABG] get abg item average rank success',
    GET_ABG_ITEM_AVG_RATE_FAILURE = '[ABG] get abg item average rank failure',

    SET_SENSITIVE = '[ABG] set abg item sensitive',
    SET_SENSITIVE_SUCCESS = '[ABG] set abg item sensitive success',
    SET_SENSITIVE_FAILURE = '[ABG] set abg item sensitive failure',

    SET_SEARCH_FILTERS = '[ABG] set search filters',
    SET_SEARCH_FILTERS_SUCCESS = '[ABG] set search filters success',
    SET_SEARCH_FILTERS_FAILURE = '[ABG] set search filters failure',

    SET_FILTERED = '[ABG] set filtered flag',
	SET_FILTER_STATE = '[ADC] set filter state',

    SET_DETAILS_LOADER = '[ABG] set adc detail loader',

    SET_SORT = '[ABG] set sort',
}

export class SetDetailsLoader implements Action {
    readonly type = abgActionTypes.SET_DETAILS_LOADER;

    constructor(public payload: any) {
    }
}

export class SetFiltered implements Action {
    readonly type = abgActionTypes.SET_FILTERED;

    constructor(public payload: any) {
    }
}

export class SetFilterState implements Action {
    readonly type = abgActionTypes.SET_FILTER_STATE;

    constructor(public payload: any) {
    }
}

export class Loading implements Action {
    readonly type = abgActionTypes.LOADING;

    constructor(public payload: any) {
    }
}

export class SetSort implements Action {
    readonly type = abgActionTypes.SET_SORT;

    constructor(public payload: any) {
    }
}

export class GetAbgItem implements Action {
    readonly type = abgActionTypes.GET_ABG_ITEM;

    constructor(public payload: any) {
    }
}

export class GetAbgItemSuccess implements Action {
    readonly type = abgActionTypes.GET_ABG_ITEM_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetAbgItemFailure implements Action {
    readonly type = abgActionTypes.GET_ABG_ITEM_FAILURE;

    constructor(public payload: any) {
    }
}

export class UpdateSelectedAbgProperty implements Action {
    readonly type = abgActionTypes.SET_ABG_ITEM_PROPERTY;

    constructor(public payload: any) {
    }
}

export class UpdateAbgItem implements Action {
    readonly type = abgActionTypes.UPDATE_ABG_ITEM;

    constructor(public payload: any) {
    }
}

export class UpdateAbgItemSuccess implements Action {
    readonly type = abgActionTypes.UPDATE_ABG_ITEM_SUCCESS;

    constructor(public payload: any) {
    }
}

export class UpdateAbgItemFailure implements Action {
    readonly type = abgActionTypes.UPDATE_ABG_ITEM_FAILURE;

    constructor(public payload: any) {
    }
}

export class RemoveAbgTag implements Action {
    readonly type = abgActionTypes.REMOVE_ABG_TAG;
}

export class SetAbgTag implements Action {
    readonly type = abgActionTypes.SET_ABG_TAG;

    constructor(public payload: any) {
    }
}

export class ResetSelected implements Action {
    readonly type = abgActionTypes.RESET_SELECTED;
}

export class ResetAbgResults implements Action {
    readonly type = abgActionTypes.RESET_ABG_RESULTS;
}

export class SetAbgTags implements Action {
    readonly type = abgActionTypes.SET_ABG_TAGS;

    constructor(public payload: any) {
    }
}

export class GetAbgDataAndTotal implements Action {
    readonly type = abgActionTypes.GET_ABG_DATA_AND_TOTAL;

    constructor(public payload: any) {
    }
}

export class GetAbgDataAndTotalSuccess implements Action {
    readonly type = abgActionTypes.GET_ABG_DATA_AND_TOTAL_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetAbgDataAndTotalFailure implements Action {
    readonly type = abgActionTypes.GET_ABG_DATA_AND_TOTAL_FAILURE;

    constructor(public payload: any) {
    }
}

export class GetAbgData implements Action {
    readonly type = abgActionTypes.GET_ABG_DATA;

    constructor(public payload: any) {
    }
}

export class GetAbgDataSuccess implements Action {
    readonly type = abgActionTypes.GET_ABG_DATA_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetAbgDataFailure implements Action {
    readonly type = abgActionTypes.GET_ABG_DATA_FAILURE;

    constructor(public payload: any) {
    }
}

export class GetAbgTotal implements Action {
    readonly type = abgActionTypes.GET_ABG_TOTAL;

    constructor(public payload: any) {
    }
}

export class GetAbgTotalSuccess implements Action {
    readonly type = abgActionTypes.GET_ABG_TOTAL_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetAbgTotalFailure implements Action {
    readonly type = abgActionTypes.GET_ABG_TOTAL_FAILURE;

    constructor(public payload: any) {
    }
}

export class AddObg implements Action {
    readonly type = abgActionTypes.ADD_OBG;

    constructor(public payload: AdcState) {
    }
}

export class RemoveObg implements Action {
    readonly type = abgActionTypes.REMOVE_OBG;

    constructor(public payload: number) {
    }
}

export class UpdateSelectedAbg implements Action {
    readonly type = abgActionTypes.UPDATE_SELECTED_ABG;

    constructor(public payload: any) {
    }
}

export class UpdateSelectedAbgID implements Action {
    readonly type = abgActionTypes.UPDATE_SELECTED_ABG_ID;

    constructor(public payload: any) {
    }
}

export class GetPosts implements Action {
    readonly type = abgActionTypes.GET_POSTS;

    constructor(public payload: any) {
    }
}

export class GetPostsSuccess implements Action {
    readonly type = abgActionTypes.GET_POSTS_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetPostsFailure implements Action {
    readonly type = abgActionTypes.GET_POSTS_FAILURE;

    constructor(public payload: any) {
    }
}

export class SetPostsDrawerStatus implements Action {
    readonly type = abgActionTypes.SET_POSTS_DRAWER_STATUS;

    constructor(public payload: PostsDrawerStatus) {
    }
}

export class SetAddLinkSearchValue implements Action {
    readonly type = abgActionTypes.SET_LINK_SEARCH_VALUE;

    constructor(public payload: string) {
    }
}

export class SetAbgItemRate implements Action {
    readonly type = abgActionTypes.SET_ABG_ITEM_RATE;

    constructor(public payload: any) {
    }
}

export class SetAbgItemRateSuccess implements Action {
    readonly type = abgActionTypes.SET_ABG_ITEM_RATE_SUCCESS;

    constructor(public payload: any) {
    }
}

export class SetAbgItemRateFailure implements Action {
    readonly type = abgActionTypes.SET_ABG_ITEM_RATE_FAILURE;

    constructor(public payload: any) {
    }
}

export class GetRates implements Action {
    readonly type = abgActionTypes.GET_RATES;

    constructor(public payload: any) {
    }
}

export class GetRatesSuccess implements Action {
    readonly type = abgActionTypes.GET_RATES_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetRatesFailure implements Action {
    readonly type = abgActionTypes.GET_RATES_FAILURE;

    constructor(public payload: any) {
    }
}

export class GetAbgItemAvgRate implements Action {
    readonly type = abgActionTypes.GET_ABG_ITEM_AVG_RATE;

    constructor(public payload: any) {
    }
}

export class GetAbgItemAvgRateSuccess implements Action {
    readonly type = abgActionTypes.GET_ABG_ITEM_AVG_RATE_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetAbgItemAvgRateFailure implements Action {
    readonly type = abgActionTypes.GET_ABG_ITEM_AVG_RATE_FAILURE;

    constructor(public payload: any) {
    }
}

export class SetSensitive implements Action {
    readonly type = abgActionTypes.SET_SENSITIVE;

    constructor(public payload: any) {
    }
}

export class SetSensitiveSuccess implements Action {
    readonly type = abgActionTypes.SET_SENSITIVE_SUCCESS;

    constructor(public payload: any) {
    }
}

export class SetSensitiveFailure implements Action {
    readonly type = abgActionTypes.SET_SENSITIVE_FAILURE;

    constructor(public payload: any) {
    }
}

export class SetSearchFilters implements Action {
    readonly type = abgActionTypes.SET_SEARCH_FILTERS;

    constructor(public payload: any) {
    }
}

export class SetSearchFiltersSuccess implements Action {
    readonly type = abgActionTypes.SET_SEARCH_FILTERS_SUCCESS;

    constructor(public payload: any) {
    }
}

export class SetSearchFiltersFailure implements Action {
    readonly type = abgActionTypes.SET_SEARCH_FILTERS_FAILURE;

    constructor(public payload: any) {
    }
}

export type abgActions =
    SetDetailsLoader |
    SetFiltered |
	SetFilterState |
    SetSearchFilters |
    SetSearchFiltersSuccess |
    SetSearchFiltersFailure |
    Loading |
    SetSensitive |
    SetSensitiveSuccess |
    SetSensitiveFailure |
    GetAbgItemAvgRate |
    GetAbgItemAvgRateSuccess |
    GetAbgItemAvgRateFailure |
    GetRates |
    GetRatesSuccess |
    GetRatesFailure |
    SetAbgItemRate |
    SetAbgItemRateSuccess |
    SetAbgItemRateFailure |
    GetAbgItem |
    GetAbgItemSuccess |
    GetAbgItemFailure |
    UpdateAbgItem |
    UpdateAbgItemSuccess |
    UpdateAbgItemFailure |
    SetAbgTag |
    RemoveAbgTag |
    ResetAbgResults |
    ResetSelected |
    SetAbgTags |
    GetAbgDataAndTotal |
    GetAbgDataAndTotalSuccess |
    GetAbgDataAndTotalFailure |
    GetAbgData |
    GetAbgDataSuccess |
    GetAbgDataFailure |
    GetAbgTotal |
    GetAbgTotalSuccess |
    GetAbgTotalFailure |
    AddObg |
    RemoveObg |
    UpdateSelectedAbg |
    UpdateSelectedAbgID |
    GetPosts |
    GetPostsSuccess |
    GetPostsFailure |
    SetPostsDrawerStatus |
    SetAddLinkSearchValue |
    UpdateSelectedAbgProperty;

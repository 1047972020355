<ng-container *ngIf="form" [formGroup]="form">
    <h2 mat-dialog-title>
        User Details
        <div (click)="close()">
            <img src="./assets/app/img/icons/small_x.svg"/>
        </div>
    </h2>

    <mat-dialog-content>
        <div [ngClass]="{'form-disabled': formDisabled}" class="add-new-form-body">
            <div class="flex" space-between>
                <div [ngClass]="{'has-danger': firstName.invalid && (firstName.dirty || firstName.touched)}"
                     [style.width.%]="49">
                    <label class="col-form-label">First Name</label>
                    <input #firstInput class="form-control m-input oct-input" formControlName="firstName"
                           id="firstName" octRtl type="text">
                    <div *ngIf="firstName.errors && (firstName.dirty || firstName.touched)"
                         class="form-control-feedback error">
                        <p *ngIf="firstName.errors.required">First Name is required</p>
                        <p *ngIf="firstName.errors.maxlength">First Name is limited to 150 characters max</p>
                    </div>
                </div>

                <div [ngClass]="{'has-danger': lastName.invalid && (lastName.dirty || lastName.touched)}"
                     [style.width.%]="49">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control m-input oct-input" formControlName="lastName" id="lastName" octRtl
                           required type="text">
                    <div *ngIf="lastName.errors && (lastName.dirty || lastName.touched)"
                         class="form-control-feedback error">
                        <p *ngIf="lastName.errors.required">Last Name is required</p>
                        <p *ngIf="lastName.errors.maxlength">Last Name is limited to 150 characters max</p>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'has-danger': email.invalid && (email.dirty || email.touched)}">
                <label class="col-form-label">Email</label>
                <input class="form-control m-input oct-input" formControlName="email"
                       id="email" type="email">
                <div *ngIf="email.errors && (email.dirty || email.touched)" class="form-control-feedback">
                    <p *ngIf="email.errors.required">Email is required</p>
                    <p *ngIf="email.errors.maxlength">Email is limited to 150 characters max</p>
                    <p *ngIf="email.errors.pattern">Email is not valid</p>
                </div>
            </div>

            <div *ngxPermissionsOnly="[userRoles.Admin]"
                 [ngClass]="{'has-danger': userRole.invalid && (userRole.dirty || userRole.touched)}">
                <label class="col-form-label">User Role</label>
                <select class="form-control" formControlName="userRole" id="roles">
                    <option *ngFor="let role of userRoleOptions; let i = index" id="role_{{i}}"
                            value="{{role}}">{{role}}</option>
                </select>
                <div *ngIf="userRole.errors && (userRole.dirty || userRole.touched)"
                     class="form-control-feedback">
                    <p *ngIf="userRole.errors.required">User Role is required</p>
                </div>
            </div>

            <div [ngClass]="{'has-danger': jobTitles.invalid && (jobTitles.dirty || jobTitles.touched)}">
                <label class="col-form-label">Job Title</label>
                <oct-job-titles formControlName="jobTitles"></oct-job-titles>
                <div *ngIf="!!jobTitles.errors && (jobTitles.dirty || jobTitles.touched)"
                     class="form-control-feedback">
                    <p>{{jobTitles.errors.error}}</p>
                </div>
            </div>

            <div *ngxPermissionsOnly="[userRoles.Admin]"
                 [ngClass]="{'has-danger': permissions.invalid && (permissions.dirty || permissions.touched)}">
                <label class="col-form-label">User Permissions</label>
                <oct-permissions formControlName="userPermissions"></oct-permissions>
                <div *ngIf="!!permissions.errors && (permissions.dirty || permissions.touched)"
                     class="form-control-feedback">
                    <p>{{permissions.errors.error}}</p>
                </div>
            </div>

            <mat-label *ngIf="octomizeChecked" class="octomize-warning">
                By using the Octomize capabilities, you acknowledge its secure practices, non-learning data usage, and
                known absence of security risks.
            </mat-label>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="modal-footer">
            <ng-container *ngIf="formDisabled else submit">
                <oct-round-button (click)="onActivateUser()" id="activate-user" text="Activate User">
                </oct-round-button>
            </ng-container>
            <ng-template #submit>
                <oct-round-button (click)="onSubmit()" [disabled]="form.invalid"
                                  id="submit" text="Submit">
                </oct-round-button>
            </ng-template>
        </div>
    </mat-dialog-actions>
</ng-container>

import { Injectable } from '@angular/core';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class SplashScreenService {
    public player: AnimationPlayer;
    public isLoaderOn: boolean = false;
    public unvisibleLoader: boolean = false;
    public loaderTopPosition: string = '50%';
    loaderText;
    private splashElement;

    constructor(
            private animationBuilder: AnimationBuilder,
            private router: Router,
    ) {
    }

    init(element) {
        // Get the splash screen element
        this.splashElement = element;
        // Hide it on the first NavigationEnd event
        this.router.events
                .pipe(
                        filter(e => e instanceof NavigationEnd)
                )
                .subscribe(e => {
                    this.hide();
                });
    }

    getLoaderStyles() {
        return {
            'width': '150px',
            'margin-bottom': '30px',
            'position': 'absolute',
            'left': '50%',
            'top': this.loaderTopPosition,
            'transform': 'translate(-50%, -50%)',
            'z-index': '100000',
            'display': this.isLoaderOn && !this.unvisibleLoader ? 'block' : 'none'
        };
    }

    public setLoaderOn(text?) {
        this.isLoaderOn = true;
        this.unvisibleLoader = false;
		this.loaderText = text;
    }

    show() {
        this.player = this.animationBuilder
                .build([
                    style({opacity: '0', zIndex: '99999'}),
                    animate('600ms ease', style({opacity: '1'}))
                ])
                .create(this.splashElement);

        setTimeout(() => {
            this.player.play();
        }, 0);
    }

    hide() {
        this.player = this.animationBuilder
                .build([
                    style({opacity: '1'}),
                    animate('600ms ease', style({opacity: '0'}))
                ])
                .create(this.splashElement);

        setTimeout(() => {
            this.player.onDone(
                    () => {
                        this.splashElement.style.display = 'none';
                    }
            );
            this.player.play();
        }, 0);
    }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { SplashScreenService } from '@core/services/splash-screen.service';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';
import { UtilsService } from '@core/services/utils.service';
import { select, Store } from '@ngrx/store';
import { getUser } from '@core/states/user.state';
import { UserDialogComponent } from '@main/admin/users/user-dialog/user-dialog.component';

@Injectable()
export class UsersService {
  public killRequest$ = new Subject();
  public list = {
    data: []
  };
  public gridData: Array<object> = [];
  public usersList$ = new Subject();
  private API_ENDPOINT_GET_USERS = 'userAccount/users';

  constructor(
    private dialog: MatDialog,
    private splashScreenService: SplashScreenService,
    private httpUtilsService: HttpUtilsService,
    private toasterService: ToastrService,
    private utilsService: UtilsService,
    private store: Store<any>,
  ) {
  }

  public openUsersDialog(data?) {
    return this.dialog.open(UserDialogComponent, {
      width: '520px',
      data,
    });
  }

  public updateUser(updatedUser, row): Observable<any> {
    let loggedInUser;
    this.store
      .pipe(
        select(getUser),
        take(1),
      )
      .subscribe(user => loggedInUser = user);

    let userPermissions = row.dataItem.USER_PERMISSIONS.join(';');
    if (loggedInUser.user_auth_level === 'ADMIN') {
      userPermissions = updatedUser.form.userPermissions
        .filter(permission => permission.checked)
        .map(permission => permission.value)
        .join(';');
    }

    const jobTitles = updatedUser.form.jobTitles
      .filter(title => title.checked)
      .map(title => title.name)
      .join(';');

    const _data = {
      useR_NAME: updatedUser.form.email ? updatedUser.form.email.trim() : updatedUser.form.email,
      useR_FIRST_NAME: updatedUser.form.firstName ? updatedUser.form.firstName.trim() : updatedUser.form.firstName,
      useR_LAST_NAME: updatedUser.form.lastName ? updatedUser.form.lastName.trim() : updatedUser.form.lastName,
      useR_AUTH_LEVEL: this.userRoleNamesToServer(updatedUser.form.userRole),
      joB_TITLE: jobTitles,
      useR_PERMISSIONS: userPermissions,
      useR_ID: row.dataItem.ID
    };
    return this.httpUtilsService.postData('userAccount/updateUser', _data, true)
      .pipe(
        map((result: any) => {
          this.splashScreenService.isLoaderOn = false;
          if (!updatedUser.afterLogin) {
            this.toasterService.success(updatedUser.form.email + ' updated successfully.');
          }
          return true;
        }),
        catchError(error => {
          this.splashScreenService.isLoaderOn = false;
          if (!updatedUser.afterLogin) {
            if (updatedUser.form.email === 'support@octopai.com') {
              this.toasterService.error('Cannot update ' + updatedUser.form.email + '.');
            } else {
              this.toasterService.error('Please try again later.', 'Cannot update ' + updatedUser.form.email + '.');
            }
          }
          return throwError(error);
        })
      );
  }

  public userRoleNamesToServer(user_role) {
    switch (user_role) {
      case 'VIEWER':
        return 'USER';
      case 'EDITOR':
        return 'WRITER';
      default:
        return user_role;
    }
  }

  public getData(): Observable<any> {
    this.splashScreenService.setLoaderOn();
    this.list.data = [];
    this.gridData = [];

    return this.getUsers()
      .pipe(
        takeUntil(this.killRequest$),
        tap(users => {
          this.splashScreenService.isLoaderOn = false;
          this.gridData = users.map(user => {
            return {
              'EMAIL': user.useR_NAME,
              'FIRST_NAME': user.useR_FIRST_NAME,
              'LAST_NAME': user.useR_LAST_NAME,
              'FULL_NAME': `${user.useR_FIRST_NAME} ${user.useR_LAST_NAME}`,
              'JOB_TITLE': user.joB_TITLE ? user.joB_TITLE.split(';').filter(a => a) : '',
              'USER_ROLE': this.userRoleNamesToUI(user.useR_AUTH_LEVEL),
              'USER_PERMISSIONS': user.useR_PERMISSIONS ? this.utilsService.sortArrayByASC(user.useR_PERMISSIONS.split(';').filter(a => a)) : '',
              'STATUS': user.useR_ACTIVE,
              'ID': user.useR_ID
            };
          });
        }),
      );

  }

  public getUsers() {
    return this.httpUtilsService.getData(this.API_ENDPOINT_GET_USERS);
  }

  public userRoleNamesToUI(user_role) {
    switch (user_role) {
      case 'USER':
        return 'VIEWER';
      case 'WRITER':
        return 'EDITOR';
      default:
        return user_role;
    }
  }

  public killRequests() {
    this.killRequest$.next();
  }
}

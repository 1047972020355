import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipContentComponent } from '@shared/oct-components/chip-content/chip-content.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [ChipContentComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule
  ],
  exports: [ChipContentComponent]
})
export class ChipContentModule {
}

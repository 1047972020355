<div class="filter-box">
    <perfect-scrollbar>
        <div *ngFor="let list of filters">
            <div class="filter-options">
                <div class="filter-title">{{ list.name }}</div>
                <div class="checkbox-list-order">
                    <oct-check-box-list [data]="list.values"></oct-check-box-list>
                </div>
            </div>
        </div>
    </perfect-scrollbar>
    <oct-round-button (clicked)="onFilter($event)" class="right-btn"
                      fontSize="1rem" id="recent-filter-apply"
                      matTooltip="Apply" text="Apply"></oct-round-button>
</div>

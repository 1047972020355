import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TokenService } from '@content/pages/token.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SignupGuard implements CanActivate {
    private bypassClient: HttpClient;

    constructor(
            private router: Router,
            private tokenService: TokenService,
            private handler: HttpBackend,
    ) {
        this.bypassClient = new HttpClient(handler);
    }

    canActivate(
            route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<boolean> {
        return new Observable(o => {
            const tokenExpired = this.tokenService.isAccessTokenExpire();
            if (tokenExpired) {
                const refreshToken = localStorage.getItem('refreshToken');
                if (!!refreshToken) {
                    this.bypassClient.post(`${environment.apiUrl}userAccount/refresh`, {RefreshToken: refreshToken})
                            .pipe(
                                    take(1),
                                    catchError(err => {
                                        if ([403].includes(err.status)) {
                                            localStorage.removeItem('accessToken');
                                            localStorage.removeItem('refreshToken');
                                            localStorage.removeItem('accessTokenExpiration');
                                            localStorage.removeItem('refreshTokenExpiration');
                                            o.next(true);
                                        }
                                        return of();
                                    }),
                            )
                            .subscribe((accessData: any) => {
                                localStorage.setItem('accessToken', accessData.accessToken);
                                localStorage.setItem('accessTokenExpiration', accessData.expiration);
                                localStorage.setItem('refreshToken', accessData?.refreshToken.token);
                                localStorage.setItem('refreshTokenExpiration', accessData?.refreshToken.expiration);
                                this.router.navigate(['login']);
                            });
                } else {
                    o.next(true);
                }
            } else {
                this.router.navigate(['login']);
            }
        });
    }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { moduleType } from '@shared/enums/module-type.enum';
import { IConnection } from '@store/common';

export enum SearchSource {
  none,
  outer,
  inner,
  outerInner,
  item,
}

export interface SearchInfo {
  searchSource?: SearchSource;
  outer_search?: string;
  inner_search?: string;
  item_search?: any;
  query_num?: number;
  module?: moduleType;
  isAutoComplete?: boolean;
  datetime?: Date;
  addToHistory?: boolean;
  addToLog?: boolean;
  connectionIds?: string;
  filters?: any;
  pageFrom?: number;
  pageTo?: number;
}

export interface FetchMore {
  from: number;
  to: number;
  query_num: number;
  searchValue: string;
  outerSearch?: string;
  innerSearch?: string;
  module?: moduleType;
  filters?: any;
}

export interface QueryData {
  querynumber: number;
  tool: string;
  data: any;
  totals: any[];
  title: string;
}

export interface LineageState {
  selected_connections_ids: string;
  count: string;
  selected_connections_objs: Array<any>;
  history_list: Array<object>;
  properties: object;
  properties_obj: object;
  connections_list: Array<any>;
  isSearchModelScanning?: any;
  mapInfo?: {
    name: string;
    url: string;
    searchValue: string;
  };
  controlFlowSearchValue: string;
  loopContainerSearchValue: string;
  searchValue?: {
    [key: number]: string,
  };
  queries?: QueryData[];
  connections?: any[];
  connectionsBG?: any[];
  connectionsFlat?: IConnection[];
  loadingAndDisableDiagram;
}

export const selectLineageState = createFeatureSelector<LineageState>('lineage');
export const getLineageSelectedConnectionsIDs = createSelector(selectLineageState, state => state.selected_connections_ids);
export const getLineageProperties = createSelector(selectLineageState, state => state.properties);
export const getLineagePropertiesObj = createSelector(selectLineageState, state => state.properties_obj);
export const getLineageSQLFromPropertiesObj = createSelector(selectLineageState, state => state.properties_obj['sql']);
export const getLineageHistoryList = createSelector(selectLineageState, state => state.history_list);
export const getLineageSelectedConnectionsObjs = createSelector(selectLineageState, state => state.selected_connections_objs);
export const getLineageCount = createSelector(selectLineageState, state => state.count);
export const getLineageConnectionsList = createSelector(selectLineageState, state => state.connections_list);
export const selectIsSearchModelScanning = createSelector(selectLineageState, state => state.isSearchModelScanning);
export const selectMapInfo = createSelector(selectLineageState, state => state.mapInfo);
export const selectMapSearchValue = createSelector(selectLineageState, state => state.mapInfo.searchValue);
export const selectControlFlowSearchValue = createSelector(selectLineageState, state => state.controlFlowSearchValue);
export const selectLoppContainerSearchValue = createSelector(selectLineageState, state => state.loopContainerSearchValue);
export const selectLoadingAndDisableDiagram = createSelector(selectLineageState, state => state.loadingAndDisableDiagram);
export const selectConnectionsFlat = createSelector(selectLineageState, state => state.connectionsFlat);
export const selectQueries = createSelector(selectLineageState, state => state.queries);
export const selectLineageConnections = createSelector(
  selectLineageState,
  state => {
    return {
      connections: state.connections,
      connectionsBG: state.connectionsBG,
    };
  });
export const selectLineageConnectionsBG = createSelector(selectLineageState, state => state.connectionsBG);
export const selectSearchValue = createSelector(selectLineageState, state => state.searchValue);
export const selectQuerySearchValue = (id: number) => createSelector(selectLineageState, state => {
  return state.searchValue[id];
});
export const selectLineageQuery = (id: number) => createSelector(
  selectLineageState,
  state => {
    return state.queries ? state.queries[id] : null;
  });
export const selectLineageData = (id: number) => createSelector(
  selectLineageState,
  state => {
    return state.queries ? state.queries[id].data : null;
  });
export const selectLineageReturnData = (id: number) => createSelector(
  selectLineageState,
  state => {
    return state.queries?.[id]?.data.returnData;
  });
export const selectLineageTotals = (id: number) => createSelector(
  selectLineageState,
  state => {
    return state.queries?.[id]?.totals || null;
  });
export const selectLineageTitle = (id: number) => createSelector(
  selectLineageState,
  state => {
    return state.queries ? state.queries[id].title : null;
  });

import { Directive, ElementRef, OnInit } from '@angular/core';
import { UtilsService } from '@core/services/utils.service';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[octRtl]'
})
export class RtlDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private utilsService: UtilsService
  ) {
  }

  ngOnInit(): void {
    fromEvent(this.el.nativeElement, 'keyup')
      .subscribe((e: KeyboardEvent) => {
        this.addRemoveClassRtl(this.el.nativeElement.value);
      });
    if (this.el.nativeElement.innerText && this.el.nativeElement.type !== 'input' && this.el.nativeElement.type !== 'textarea') {
      this.addRemoveClassRtl(this.el.nativeElement.innerText);
    }
  }

  public findFirstLetter(text): string {
    if (text.length <= 1) {
      return text;
    } else {
      if (this.utilsService.charIsLetter(text.charAt(0))) {
        return text.charAt(0);
      } else {
        text = text.substring(1);
        return this.findFirstLetter(text);
      }
    }
  }

  private addRemoveClassRtl(text) {
    // if first char is not a letter (a number for example) so need to check the first letter and then decide which is the right side.
    const _firstLetter = this.findFirstLetter(text);

    if (_firstLetter !== '') {
      if (this.utilsService.isRightToLeftLanguage(_firstLetter)) {
        this.el.nativeElement.classList.add('rtl');
      } else {
        this.el.nativeElement.classList.remove('rtl');
      }
    }
  }
}

import { Action } from '@ngrx/store';

export enum layoutActions {
  RESET_SIDE_BAR = '[Layout] reset side bar status',
  UPDATE_SIDE_BAR = '[Layout] update side bar status',
  UPDATE_NAV_MENU_STATUS = '[Layout] update nav menu status',
  UPDATE_TOP_HEADER = '[Layout] update top header',
}

export class ResetSideBarState implements Action {
  type = layoutActions.RESET_SIDE_BAR;
}

export class UpdateSideBarState implements Action {
  type = layoutActions.UPDATE_SIDE_BAR;

  constructor(public payload: any) {
  }
}

export class UpdateNavMenuStatus implements Action {
  type = layoutActions.UPDATE_NAV_MENU_STATUS;

  constructor(public payload: any) {
  }
}

export class UpdateTopHeader implements Action {
  type = layoutActions.UPDATE_TOP_HEADER;

  constructor(public payload: any) {
  }
}

export type LayoutActions =
  ResetSideBarState |
  UpdateSideBarState |
  UpdateNavMenuStatus |
  UpdateTopHeader;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdcFilterComponent } from '@shared/oct-components/adc-filter/adc-filter.component';
import {
  PropertiesCheckBoxListModule
} from '@shared/oct-components/properties-check-box-list/properties-check-box-list.module';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ChipContentModule } from '@shared/oct-components/chip-content/chip-content.module';
import { ShowMoreModule } from '@shared/oct-components/show-more/show-more.module';
import { CheckBoxListModule } from '@shared/oct-components/check-box-list/check-box-list.module';
import { ButtonToggleModule } from '@shared/oct-components/button-toggle/button-toggle.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarItemModule } from '@shared/oct-components/avatar-item/avatar-item.module';
import { TranslateModule } from '@ngx-translate/core';
import { UnderlineButtonModule } from '@shared/oct-components/underline-button/underline-button.module';
import { RoundButtonModule } from '@shared/oct-components/round-button/round-button.module';
import { NgxSkeletonModule } from 'ngx-skeleton';
import { ReactiveFormsModule } from '@angular/forms';
import { RatingSliderModule } from '@shared/oct-components/rating-slider/rating-slider.module';


@NgModule({
  declarations: [AdcFilterComponent],
  imports: [
    CommonModule,
    PropertiesCheckBoxListModule,
    MatIconModule,
    MatDatepickerModule,
    MatChipsModule,
    SearchInputModule,
    MatAutocompleteModule,
    ChipContentModule,
    ShowMoreModule,
    CheckBoxListModule,
    ButtonToggleModule,
    MatTooltipModule,
    AvatarItemModule,
    TranslateModule,
    UnderlineButtonModule,
    RoundButtonModule,
    NgxSkeletonModule,
    ReactiveFormsModule,
    RatingSliderModule,
  ],
  exports: [AdcFilterComponent],
})
export class AdcFilterModule {
}

<button  (menuOpened)="onMenuOpen()" [disableRipple]="true" [matMenuTriggerFor]="menu" class="menu-trigger"
        mat-icon-button matTooltip="Filter">
    <mat-icon [ngClass]="{'filtered': isFiltered}"  svgIcon="filter_frame"></mat-icon>
</button>

<mat-menu #menu="matMenu">
    <div (click)="$event.stopPropagation()" [disableRipple]="false" mat-menu-item style="height: auto; width: auto">
        <div class="menu-top">
            <span>{{contentData.title}}</span>
            <mat-icon (click)="close()" class="close-btn" matTooltip="Cancel"
                      svgIcon="close-icon"></mat-icon>
        </div>
        <ng-container #container></ng-container>
    </div>
</mat-menu>


import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'oct-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PermissionsComponent),
      multi: true
    }
  ],
})
export class PermissionsComponent implements OnInit, ControlValueAccessor {

  public permissions: any[];

  constructor() {
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(permissions: any[]): void {
    this.permissions = permissions;
  }

  onClick(permission: any) {
    permission.checked = !permission.checked;
    this.onChange(this.permissions);
  }
}

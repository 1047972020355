<div *ngFor="let item of data; let i = index">
    <oct-check-box (change)="onCheckChange(item, $event)"
                   [checked]="item.checked"
                   [index]="i+1" [ngStyle]="{color: item.color}"
                   checkBoxName="{{checkBoxListName}}"
                   checkboxLabel="{{item.text || item.FULL_NAME}}" value="{{item.id}}">
    </oct-check-box>

    <oct-button-toggle (toggleChange)="onToggleValueChange($event, item)" *ngIf="item.checked"
                       [listOfButtons]="toggleValues"
                       defaultValue="{{item.toggleValue}}"
                       height="24px"></oct-button-toggle>

</div>



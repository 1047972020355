import { Action } from '@ngrx/store';
import { IAdvancedSearchParams, IAdvancedSearchValue, ISearchParams, ISearchTag } from '@store/search/index';
import { SearchType } from '@shared/enums/searchType';
import { Locations } from '@shared/enums/locations.enum';
import { SelectedFilter } from '@core/interfaces/obg-data';

export enum searchActionsTypes {
	INIT = '[SEARCH] initialize the search',

	SET_SEARCH_TYPE = '[SEARCH] set search type',
	SET_SEARCH_VALUE = '[SEARCH] set search value',
	SET_SEARCH_OBJ_TYPE = '[SEARCH] set search type object',
	SET_DEFAULT_SEARCH = '[SEARCH] set default search',
	SET_SEARCH_TAGS = '[SEARCH] set search tags',
	SET_SEARCH_LAYERS = '[SEARCH] set search layers',
	SET_SEARCH_DEFAULT_VALUE = '[LINEAGE] Set the main search input value',
	SET_SEARCH_PLACEHOLDER = '[LAYOUT] set main search placeholder',

	// ***************
	// Advanced search
	APPLY_ADVANCED_SEARCH = '[SEARCH] apply advanced search',
	SET_ADVANCED_SEARCH = '[SEARCH] search',
	SET_ADVANCED_SEARCH_VALUE = '[SEARCH] set advanced search values',
	SET_ADVANCED_SEARCH_PARAMS = '[SEARCH] set advanced search params',
	// ***************

	// *********************
	// Auto complete actions
	SET_AUTOCOMPLETE_SEARCH_VALUE = '[SEARCH] set autocomplete search value',
	SET_AUTOCOMPLETE_RESULTS = '[SEARCH] set autocomplete results',
	// *********************
}

export class Init implements Action {
	type = searchActionsTypes.INIT;
}

export class SetSearchValue implements Action {
	type = searchActionsTypes.SET_SEARCH_VALUE;

	constructor(public payload: any) {
	}
}

export class SetSearchPlaceholder implements Action {
	type = searchActionsTypes.SET_SEARCH_PLACEHOLDER;

	constructor(public payload: any, public location: Locations) {
	}
}

export class SetAutoCompleteSearchValue implements Action {
	readonly type = searchActionsTypes.SET_AUTOCOMPLETE_SEARCH_VALUE;

	constructor(public payload: string, public location: Locations) {
	}
}

export class SetAutoCompleteSearchResults implements Action {
	readonly type = searchActionsTypes.SET_AUTOCOMPLETE_RESULTS;

	constructor(public payload: any, public location: Locations) {
	}
}

export class SetSearchLayers implements Action {
	readonly type = searchActionsTypes.SET_SEARCH_LAYERS;

	constructor(public payload: SelectedFilter, public location: Locations) {
	}
}

export class SetSearchTags implements Action {
	readonly type = searchActionsTypes.SET_SEARCH_TAGS;

	constructor(public payload: ISearchTag, public location: Locations) {
	}
}

export class SetAdvancedSearchData implements Action {
	readonly type = searchActionsTypes.SET_ADVANCED_SEARCH;

	constructor(public payload: ISearchParams, public location: Locations) {
	}
}

export class SetAdvancedSearchValues implements Action {
	readonly type = searchActionsTypes.SET_ADVANCED_SEARCH_VALUE;

	constructor(public payload: IAdvancedSearchValue, public location: Locations) {
	}
}

export class SetAdvancedSearchParams implements Action {
	readonly type = searchActionsTypes.SET_ADVANCED_SEARCH_PARAMS;

	constructor(public payload: IAdvancedSearchParams, public location: Locations) {
	}
}

export class SetSearchType implements Action {
	readonly type = searchActionsTypes.SET_SEARCH_TYPE;

	constructor(public payload: SearchType, public location: Locations) {
	}
}

export class SetSearchTypeObject implements Action {
	readonly type = searchActionsTypes.SET_SEARCH_OBJ_TYPE;

	constructor(public payload: string, public location: Locations) {
	}
}

export class ApplyAdvancedSearch implements Action {
	readonly type = searchActionsTypes.APPLY_ADVANCED_SEARCH;

	constructor(public payload: boolean, public location: Locations) {
	}
}

export class SetDefaultSearch implements Action {
	readonly type = searchActionsTypes.SET_DEFAULT_SEARCH;

	constructor(public payload: string, public location: Locations) {
	}
}

export class SetDefaultSearchValue implements Action {
	readonly type = searchActionsTypes.SET_SEARCH_DEFAULT_VALUE;

	constructor(public payload: string, public location: Locations) {
	}
}

export type searchActions =
	SetSearchPlaceholder |
	SetDefaultSearchValue |
	SetAdvancedSearchData |
	SetAdvancedSearchValues |
	SetAdvancedSearchParams |
	SetSearchType |
	ApplyAdvancedSearch |
	SetDefaultSearch |
	SetSearchTags |
	SetSearchLayers |
	SetAutoCompleteSearchValue |
	SetAutoCompleteSearchResults |
	SetSearchTypeObject |
	SetSearchValue;

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { select, Store } from '@ngrx/store';

import { UtilsService } from '../utils.service';
import { CommonState, getLocation } from '@store/common';
import { ModuleUtilsService } from '../module-utils.service';
import { Locations } from '@shared/enums/locations.enum';

import { detect } from 'detect-browser';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const browser = detect();

@Injectable()
export class SubheaderService {
    public listOfBreadcrumbsByModule: object = {};
    public location: string;
    public locationTitle: string;
    public wrapperWidth: string;
    public showDots: boolean = false;
    public infoPropertiesArr: any = [];
    public clickOnItemFromBreadcrumbs: object;
    public abgTooltipProperties: string[];
    public version: string;
    public scrollBreadcrumbs$ = new Subject<boolean>();

    constructor(
        private moduleUtilsService: ModuleUtilsService,
        private storeCommon: Store<CommonState>,
        private utilsService: UtilsService
    ) {
        this.resetItemFromBreadcrumbs();
        this.abgTooltipProperties = ['lAYER', 'oBJECT_NAME', 'tOOL_TYPE'];

        // get location
        this.storeCommon
            .pipe(
                select(getLocation),
                filter((location: Locations) => !!location && Object.values(Locations).includes(location))
            )
            .subscribe((location: Locations) => {
                if (location.indexOf('compare') > -1) {
                    location = this.moduleUtilsService.getLocationPreferredKey(location);
                }
                // this.location = this.utilsService.uppercaseFirstLetter(location);
                this.location = location;
                this.locationTitle = this.moduleUtilsService.getLocationNameToShow(this.location);
            });
    }

    public removeLastItem() {
        if (this.listOfBreadcrumbsByModule[this.location] != undefined && this.listOfBreadcrumbsByModule[this.location].length > 0) {
            this.listOfBreadcrumbsByModule[this.location].pop();
        }
    }

    public checkIfIsLastItem(item, breadIndex) {
        const _lastItem = this.listOfBreadcrumbsByModule[this.location];
        const isEqualToLastItem = _.isEqualWith(item, _lastItem[_lastItem.length - 1]);

        if (isEqualToLastItem) {
            const lastIndex = _lastItem.length - 1;
            if (breadIndex < lastIndex) {
                return false;
            } else {
                return isEqualToLastItem;
            }
        }

        return isEqualToLastItem;
    }

    public appendBreadcrumbs(data) {

        let breadcrumbsItems = this.listOfBreadcrumbsByModule[this.location] || [];

        // remove all the next items
        if (this.clickOnItemFromBreadcrumbs['clicked']) {
            const key = breadcrumbsItems.findIndex(item => item.url === this.clickOnItemFromBreadcrumbs['url']);
            breadcrumbsItems = breadcrumbsItems.slice(0, key + 1);
            this.resetItemFromBreadcrumbs();
            this.listOfBreadcrumbsByModule[this.location] = [...breadcrumbsItems];
            // this.scrollWrapperToEnd();
            return;
        }

        // append new item to just if the user don't click on item from the breadcrumbs
        if (!this.clickOnItemFromBreadcrumbs['clicked']) {
            if (!breadcrumbsItems) {
                this.initListOfModuls();
            }

            // we need this if condition because on obg it's throw in a few times
            if ((breadcrumbsItems.length > 0 && data.url && data.url != breadcrumbsItems[breadcrumbsItems.length - 1].url)
                || breadcrumbsItems.length === 0
                // we need this if condition for discovery because on discovery the url is the always the same
                || data.url == '/discovery'
                // we need this if condition for compare because it's more than one object
                || (this.location.indexOf('compare') > -1 && data[0].url != breadcrumbsItems[breadcrumbsItems.length - 1].url)) {
                let _obj: { title?, info?, url? } = {};
                if (this.location.toLowerCase().indexOf('compare') > -1) {
                    _obj = {
                        title: data[0].name + ' - VS - ' + data[1].name,
                        info: this.buildInfo(data),
                        url: data[0].url
                    };
                } else {
                    if (this.location === Locations.e2eColumn) {
                        const name = data.find(item => item.key === 'Column Name');
                        _obj = {
                            title: name.value,
                            info: this.buildInfo(data),
                            url: data.url
                        };
                    } else {
                        _obj = {
                            title: data.name,
                            info: this.location.toLowerCase().indexOf('adc') > -1 ? this.buildInfo(data) : this.buildInfo(data.infoData),
                            url: data.url
                        };
                    }
                }

                if (this.location.toLowerCase() === Locations.adc) {
                    // Verify that breadcrumbs item is not exists and than add it
                    // The verification relay on 'object id' in item 0 of info
                    const breadcrumbsItem = breadcrumbsItems.find(breadcrumbItem => {
                        return breadcrumbItem.info[0].value === _obj['info'][0].value;
                    });
                    if (!breadcrumbsItem) {
                        breadcrumbsItems.push(_obj);
                    }
                } else {
                    breadcrumbsItems.push(_obj);
                }

                if (this.location.toLowerCase() === Locations.lineage) { // move the scroll to right
                    this.scrollBreadcrumbs$.next(true);
                }
            }
        }

        this.listOfBreadcrumbsByModule[this.location] = [...breadcrumbsItems];
        // this.scrollWrapperToEnd();
    }

    public removeLastBreadcrumb() {
        if (this.listOfBreadcrumbsByModule[this.location] != undefined && this.listOfBreadcrumbsByModule[this.location].length >= 0) {
            const lastIndex = this.listOfBreadcrumbsByModule[this.location].length - 1;

            if (lastIndex > 0) {
                if (this.listOfBreadcrumbsByModule[this.location][lastIndex].url == this.listOfBreadcrumbsByModule[this.location][lastIndex - 1].url) {
                    this.listOfBreadcrumbsByModule[this.location].splice(lastIndex, 1);
                }
            }
        }

    }

    // scrollWrapperToEnd() {
    //     switch (browser && browser.name) {
    //         case 'chrome':
    //             const wrapper = document.getElementsByClassName('breadcrumbs-wrapper')[0];
    //             const flexContainer = document.getElementsByClassName('d-flex')[0];
    //             const neededWrapperWidth = (flexContainer.scrollWidth - 500);
    //             this.wrapperWidth = neededWrapperWidth + 'px';
    //             if (wrapper && flexContainer) {
    //                 wrapper.scroll(flexContainer.scrollWidth, 0);
    //             }
    //     }
    // }

    public deleteBreadcrumbs(): void {
        this.listOfBreadcrumbsByModule[this.location] = [];
    }

    private initListOfModuls() {
        const vm = this;
        // for example: //lineage array: [{name: a, path: aa, link: theurl}, {},...]
        const _exist = _.find(this.listOfBreadcrumbsByModule, function (val, key) {
            if (key.toString() != vm.location) {
                return false;
            }
        });
        if (!_exist) {
            this.listOfBreadcrumbsByModule[this.location] = [];
        }
    }

    private isDotsDisplay(width: any): boolean {
        if (width.offsetWidth && width.offsetWidth >= 1150) {
            // this.scrollWrapperToEnd();
            return true;
        } else {
            return false;
        }
    }

    private resetItemFromBreadcrumbs() {
        this.clickOnItemFromBreadcrumbs = {url: undefined, clicked: false};
    }

    private buildInfo(data): Array<object> {
        this.infoPropertiesArr = [];

        if (data) {
            if (this.location.toLowerCase() === Locations.adc) {

                for (const [key, value] of Object.entries(data)) {
                    const temp = {
                        'key': key.replace('_', ' ').toLowerCase(),
                        'value': value,
                        'tooltip': this.abgTooltipProperties.includes(key) ? true : false
                    };

                    this.infoPropertiesArr.push(temp);
                }
            } else {
                this.infoPropertiesArr = [...data];
            }
        }
        return this.infoPropertiesArr;
    }
}

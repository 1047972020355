<div [ngClass]="{expand: layoutConfigService.isOpen}" class="connections-label">
    <span>{{menuAsideService.connectionsCounter$ | async}}</span>
</div>

<!--Spaces List-->
<div (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
     class="m-aside-menu">
    <div [ngClass]="{expand: layoutConfigService.isOpen}" class="space-separator"></div>
    <ul class="m-menu__nav">
        <ng-container [ngTemplateOutletContext]="{spaces: spaces, items: menuAsideService.menuList$ | async}"
                      [ngTemplateOutlet]="mMenuListing"></ng-container>
    </ul>
</div>

<ng-template #mMenuListing let-items="items" let-spaces="spaces">
    <ng-container *ngFor="let space of spaces">
        <li *ngIf="(items | filterArrByKeyValue : 'group' : space.id).length !== 0"
            [ngClass]="{expand: layoutConfigService.isOpen}" class="space-item" flex-dircetion-col>
            <div [ngClass]="{expand: layoutConfigService.isOpen}" class="space-title">
                {{layoutConfigService.isOpen ? space.title : space['titleShort']}}
            </div>
            <!--Menu List-->
            <ul [ngClass]="{expand: layoutConfigService.isOpen}" class="space-menu-items">
                <ng-container *ngFor="let menuItem of items | filterArrByKeyValue : 'group' : space.id"
                              [ngTemplateOutletContext]="{item: menuItem}"
                              [ngTemplateOutlet]="mMenuItem">
                </ng-container>
            </ul>
            <div [ngClass]="{expand: layoutConfigService.isOpen}" class="space-separator"></div>
        </li>
    </ng-container>
</ng-template>

<!--Menu Items-->
<ng-template #mMenuItem let-item="item" let-parentItem="parentItem">
    <li [matTooltip]="item['tooltip']"
        [ngClass]="{ expand: layoutConfigService.isOpen, inner: !!item.parentId, selected: item.active}"
        aria-haspopup="true"
        class="space-menu-item"
        id="{{item.id}}" matTooltipPosition="left">
        <a (click)="navigate(item['page'])" [ngClass]="{disabled: item.disabled}"
           class="m-menu__link m-menu__toggle">
            <ng-container [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
                          [ngTemplateOutlet]="mMenuItemText"></ng-container>
        </a>
    </li>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem">
    <!-- if menu item has icon -->
    <div class="icon-wrapper">
        <img class="space-menu-item-icon icon-{{item.name}}" [style.width.px]="item.width || 'auto'"
			 [ngClass]="{'icon-selected': item.active, 'rotate-icon': item.name === 'discovery'}"
             src="{{item.active ? item['selectedIcon'] : item.icon}}"/>
    </div>
    <div *ngIf="item.badge.value && item['location_name'] === 'dashboard'" class="img-badge badge-transparent"
         matBadge="{{item.badge.value}}"
         matBadgeColor="danger" matBadgePosition="before"></div>

    <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
        <!-- menu item title text -->
        <div class="menu-item-text" title="{{item['tooltip']}}">
            {{item.title}}
        </div>
    </ng-container>

    <ng-template #mMenuLinkBadge>
        <!-- menu item with badge -->
        <div [hidden]="!layoutConfigService.isOpen" class="m-menu__link-title">
            <div class="m-menu__link-wrap">
                <div class="menu-item-text">{{item.title}}</div>
            </div>
        </div>
    </ng-template>


    <!-- if menu item has submenu child then put arrow icon -->
    <i *ngIf="item['submenu']" class="m-menu__ver-arrow la la-angle-right"></i>
</ng-template>
<!-- END: Left Aside -->

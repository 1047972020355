import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MemoizedSelector, select, Store } from '@ngrx/store';

import { UtilsService } from '@core/services/utils.service';
import { ConnectionsList } from '@core/services/connections-list.service';
import { MenuAsideService } from '@core/services/layout/menu-aside.service';
import { selectLineageConnections } from '@store/lineage';
import { selectDiscoveryConnections } from '@store/discovery';
import { selectCompareETLConnections } from '@core/states/compare-etl.state';
import { selectCompareREPORTConnections } from '@core/states/compare-report.state';
import { LineageService } from '@main/lineage-dashboard/lineage.service';
import { DiscoveryService } from '@main/discovery/discovery.service';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { Locations } from '@shared/enums/locations.enum';
import { getLocation, IConnection, selectConnectionsDisabled } from '@store/common';
import { SetLineageConnections } from '@store/lineage/actions';
import { SetDiscoveryConnections } from '@store/discovery/actions';
import { Subject } from 'rxjs';
import { ButtonsTheme } from '@shared/enums/searchType';

@Component({
    selector: 'oct-list-settings',
    templateUrl: './list-settings.component.html',
    styleUrls: ['./list-settings.component.scss'],
})
export class ListSettingsComponent implements OnInit, AfterViewInit, OnDestroy {
    public isCollapsed = true;
    public needToDisabled: boolean;
    public selectedModuleIndex: number;
    public connectionsCollections: { connections: any[], connectionsBG: any[] };
    public connections: any[];
    public allExpandState: boolean = false;
    private location: string;
    private componentDestroy$ = new Subject();

    constructor(
        private store: Store<any>,
        private utilsService: UtilsService,
        public connectionsList: ConnectionsList,
        private menuAsideService: MenuAsideService,
        private lineageService: LineageService,
        private discoveryService: DiscoveryService,
        public moduleUtilsService: ModuleUtilsService,
    ) {
    }

    ngOnInit(): void {
        this.store
            .pipe(
                takeUntil(this.componentDestroy$),
                select(getLocation),
            )
            .subscribe((location: Locations) => {
                this.location = this.moduleUtilsService.getLocationPreferredKey(location);
                this.registerToConnectionsChanges();
            });

        this.store
            .pipe(
                select(selectConnectionsDisabled),
				takeUntil(this.componentDestroy$),
            )
            .subscribe(value => {
                this.needToDisabled = value;
            });
    }

    ngAfterViewInit(): void {
        this.discoveryService.toggleGridSubject
            .subscribe(data => {
                this.needToDisabled = data;
            });
    }

    getConnectionsSelectorByLocation(): MemoizedSelector<any, any> {
        switch (this.location) {
            case Locations.lineage:
            case Locations.e2eColumn:
            case Locations.e2eColumnDashboard:
            case Locations.lineageSchema:
            case Locations.map:
                return selectLineageConnections;
            case Locations.discovery:
                return selectDiscoveryConnections;
            case Locations.compareEtls:
                return selectCompareETLConnections;
            case Locations.compareReports:
                return selectCompareREPORTConnections;
            default:
                return null;
        }
    }

    public expandCollapseState() {
        this.selectedModuleIndex = null;
        this.isCollapsed ? this.collapseState() : this.expandState();
        this.isCollapsed = !this.isCollapsed;
    }

    expandState() {
        this.allExpandState = false;
    }

    collapseState() {
        this.allExpandState = true;
    }

    public onCheckAllClick(isChecked: boolean): void {
        if (this.needToDisabled) {
            return;
        }

        for (const key of Object.keys(this.connectionsList.moduleCounter)) {
            this.connectionsList.moduleCounter[key].checked = isChecked ? 0 : this.connectionsList.moduleCounter[key].total;
        }

        // inner function
        const setConnectionStatus = connections => {
            connections.forEach((a: { key: string, value: any[] }) => {
                a.value.forEach((b: { key: string, value: any[] }) => {
                    b.value.forEach((connection: IConnection) => {
                        if (connection.isBG === false) {
                            isChecked ? this.connectionsList.moduleCounter[connection.module_type].checked++ : this.connectionsList.moduleCounter[connection.module_type].checked--;
                        }
                        connection.is_checked = isChecked;
                        this.connectionsList.updateStorage(connection);
                    });
                });
            });
        };

        const connectionsObj = {
            connections: this.connectionsCollections.connections,
            connectionsBG: this.connectionsCollections.connectionsBG
        };
        setConnectionStatus(connectionsObj.connections);
        setConnectionStatus(connectionsObj.connectionsBG);
        switch (this.location) {
            case Locations.e2eColumnDashboard:
            case Locations.lineage:
            case Locations.map:
            case Locations.lineageSchema:
                this.store.dispatch(new SetLineageConnections(connectionsObj));
                break;
            case Locations.discovery:
                this.store.dispatch(new SetDiscoveryConnections(connectionsObj));
                break;
        }

        this.connectionsList.updateConnectionsIDsList(connectionsObj);
        this.connectionsList.updateStore();
        this.menuAsideService.updateBadgeValue();
    }

    public setOpenSelectedMenuStyle(i) {
        return i === this.selectedModuleIndex;
    }

    public toggleSelectedModule(panelOpenState, i) {
        if (panelOpenState) { // open
            this.selectedModuleIndex = i;
        } else { // close
            this.selectedModuleIndex = null;
        }
    }

    isChecked(connection: IConnection): void {
        connection.is_checked = !connection.is_checked;
        if (connection.isBG === false) {
            connection.is_checked ?
                this.connectionsList.moduleCounter[connection.module_type].checked++ :
                this.connectionsList.moduleCounter[connection.module_type].checked--;
        }

        this.connectionsList.updateConnectionsIDsList(this.connectionsCollections);
        this.connectionsList.updateStorage(connection);
        this.connectionsList.updateStore(connection);
        this.menuAsideService.updateBadgeValue();

        const connections = {
            connections: this.connectionsCollections.connections,
            connectionsBG: this.connectionsCollections.connectionsBG
        };
        switch (this.location) {
            case Locations.e2eColumnDashboard:
            case Locations.lineage:
            case Locations.map:
            case Locations.lineageSchema:
                this.store.dispatch(new SetLineageConnections(connections));
                break;
            case Locations.discovery:
                this.store.dispatch(new SetDiscoveryConnections(connections));
                break;
        }
    }

    ngOnDestroy(): void {
        this.componentDestroy$.next();
        this.componentDestroy$.unsubscribe();
    }

    private registerToConnectionsChanges() {
        const selector = this.getConnectionsSelectorByLocation();
        if (selector) {
            this.store
                .pipe(
                    select(selector),
                    filter((connectionsCollections: any) => connectionsCollections && !!connectionsCollections.connections),
                    take(1),
                )
                .subscribe(results => {
                    this.connectionsCollections = results;
                });
        }
    }

	public get buttonThemeColor(): typeof ButtonsTheme {
		return ButtonsTheme;
	}
}

import { Component, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ClassInitService } from '@core/services/class-init.service';
import { LayoutConfigService } from '@core/services/layout-config.service';
import { MenuAsideService } from '@core/services/layout/menu-aside.service';
import * as objectPath from 'object-path';
import { DOCUMENT } from '@angular/common';
import { ILayoutState, selectNavMenuStatus, Status } from '@store/layout';
import { select, Store } from '@ngrx/store';
import { UpdateNavMenuStatus } from '@store/layout/actions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'oct-brand',
    templateUrl: './brand.component.html',
    styleUrls: ['./brand.component.scss'],
    // changeDetection: ChangeDetectionStrategy.Default
})
export class BrandComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'm-stack__item m-brand';
    @Input() menuAsideMinimizeDefault: any = false;
    // @Input() menuAsideMinimizToggle: any = false;
    @Input() menuAsideDisplay: any = false;
    @Input() menuHeaderDisplay: any = true;
    public componentDestroy$ = new Subject();
    private menuStateOpen: any;

    constructor(
        private classInitService: ClassInitService,
        public layoutConfigService: LayoutConfigService,
        private menuAsideService: MenuAsideService,
        private store: Store<ILayoutState>,
        @Inject(DOCUMENT) private document: Document,
    ) {

        // subscribe to class update event
        this.classInitService.onClassesUpdated$
            .subscribe(classes => {
                this.classes = 'm-stack__item m-brand ' + classes.brand.join(' ');
            });

        this.layoutConfigService.onLayoutConfigUpdated$
            .subscribe(model => {
                this.menuAsideMinimizeDefault = objectPath.get(model, 'config.aside.left.minimize.default');

                // this.menuAsideMinimizToggle = objectPath.get(model, 'config.aside.left.minimize.toggle');

                this.menuStateOpen = localStorage.getItem('menuState');
                this.menuAsideDisplay = this.menuStateOpen ? (/true/i).test(this.menuStateOpen) : objectPath.get(model, 'config.menu.aside.display'); ////

                this.menuHeaderDisplay = objectPath.get(model, 'config.menu.header.display');
                this.layoutConfigService.isOpen = this.menuAsideDisplay;
            });
    }

    ngOnInit(): void {
        this.store
            .pipe(
                takeUntil(this.componentDestroy$),
                select(selectNavMenuStatus),
            )
            .subscribe(navMenuState => {
                if (navMenuState === Status.open) {
                    document.getElementsByTagName('body')[0].classList.remove('m-aside-left--minimize');
                    document.getElementsByTagName('body')[0].classList.remove('m-brand--minimize');
                    this.layoutConfigService.isOpen = true;
                }

                if (navMenuState === Status.close) {
                    document.getElementsByTagName('body')[0].classList.add('m-aside-left--minimize');
                    document.getElementsByTagName('body')[0].classList.add('m-brand--minimize');
                    this.layoutConfigService.isOpen = false;
                }

                this.saveMenuState();
                this.updateMenuItems(this.layoutConfigService.isOpen);
            });
    }

    /**
     * Toggle class topbar show/hide
     * @param event
     */
    clickTopbarToggle(event: Event): void {
        this.document.body.classList.toggle('m-topbar--on');
    }

    asideToggle() {
        this.layoutConfigService.isOpen = !this.layoutConfigService.isOpen;
        this.store.dispatch(new UpdateNavMenuStatus(this.layoutConfigService.isOpen ? Status.open : Status.close));
    }

    saveMenuState() {
        const isOpenStr = this.layoutConfigService.isOpen === true ? 'true' : 'false';
        localStorage.setItem('menuState', isOpenStr);
    }

    updateMenuItems(menuOpen?) {
        for (const item of this.menuAsideService.menuList$.value) {
            if (item.sign) {
                if (item.sign === 1) {
                    item.show = !menuOpen;
                }
                if (item.sign === 2) {
                    item.show = menuOpen;
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.componentDestroy$.next();
        this.componentDestroy$.unsubscribe();
    }
}

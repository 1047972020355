// USA
export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			REPORTS: 'Reports',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			LINEAGE: 'Lineage',
			DISCOVERY: 'Discovery',
			COMPARE: 'Compare',
			COMPAREETLS: 'Compare ETLs',
			COMPAREREPORTS: 'Compare Reports',
			OBG: 'ABG',
		},
		GENERAL: {
            LOGO_MSG: 'Welcome Back',
            TOOLTIP_MSG: 'Minimum length of 2 characters'
        },
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				RESET_BUTTON: 'Reset',
				FORGOT_BUTTON: "Can't log in?",
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				COVER_TITLE: 'WHERE IS THE DATA ?!?',
				COVER_TITLE_2: '',
				BUTTON: 'Next',
			},
			LOGIN_PASSWORD: {
				COVER_TITLE: 'WHERE THE F&#%',
				COVER_TITLE_2: 'IS THE DATA?!',
				BUTTON: 'Sign In',
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Done, check your mailbox'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				// INVALID_PASSWORD:'Your password must contain:<br >' +
				// 	'Upper & lowercase letters<br >' +
				// 	'At least one number<br >' +
				// 	'At least one special character ',
				MIN_LENGTH: 'At least {{min}} characters',
				STRONG_PASSWORD: 'Upper & lowercase letters<br >' +
					'At least one number<br >' +
					'At least one special character',
				NOT_FOUND: 'Oops. {{name}} does not exist.<br > Please contact your system administrator',
				INVALID_EMAIL: 'If this user exists, an email will be sent.',
				INVALID_LOGIN: 'OCTOPAI account does not exist, please try again or contact your system administrator',
				FAILED_LOGIN: 'Incorrect email or password',
				SUCCESS_LOGIN: 'Login success. Please wait...'
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		},
		LIVE_LINEAGE_AI_NO_SCRIPT_ERROR: 'Action not applicable. Please insert a script in the left panel to proceed.',
		LIVE_LINEAGE_AI_REQUEST_ERROR: 'Request failed. Please try again later.',
		SCRIPT_COPY_SUCCESS: 'Script copied successfully!',
    DELETE_FILE: 'Delete file',
    DOWNLOAD_FILE: 'Download file',
	},
};

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAbgItemStatus } from '@main/adc/adc-store/adc';
import { EntityState } from '@ngrx/entity';
import { postAdapter } from '@main/adc/adc-store/adc/reducer';
import { IRate } from '@main/adc/adc-shared/interfaces/rank';
import { AttributeType, IAsset } from '@main/admin/custom-attributes/custom-attributes';
import { AbgSource } from '@main/adc/adc-shared/types/abgSource';

export interface IABGTag {
    oBJECT_ID?: string;
    tAG_ID?: number;
    tAG_NAME?: string;
    is_new?: boolean;
    cOUNT?: string;
	oRIGIN?: string;
}

export interface IABGItem {
    icon?: string;
    lAYER?: string;
    oBJECT_DESCRIPTION?: string;
    oBJECT_ID?: string;
    oBJECT_NAME?: string;
    tOOL_TYPE?: string;
    dATA_TYPE?: string;
    eXPRESSION?: string;
    iS_OBG_OBJECT?: boolean;
    lAST_UPDATEDATE?: string;
    cREATEDATE?: string;
    oBJECT_PATH?: string;
    oBJECT_TYPE?: string;
    oCT_CALCULATION_DESCRIPTION?: string;
    oCT_OBJECT_DESCRIPTION?: string;
    oCT_SHORT_DESCRIPTION?: string;
  oWNER?: string | number;
  dATA_STEWARD?: string | number;
    sOURCE_SYSTEM?: string;
    sTATUS?: boolean;
    uSER_NAME?: string;
    objectTags?: IABGTag[];
    aBG_STATUS?: number;
    iS_BOOKMARK?: boolean;
    sENSITIVITY?: boolean;
    name?: string;
    url?: string;
    queryNum?: string;
    selected?: boolean;
    rATE?: number;
    aVG?: number;
    rATE_COUNT?: number;
    rATES?: IRate[];
    iS_SENSITIVE?: boolean;
  uPDATED_BY?: string | number;
    colorStatus?: string;
    statusName?: string;
}

export interface IAssetType {
    aSSET_ID: string;
    aSSET_TYPE_NAME: string;
    iS_SYSTEM: boolean;
}

export interface IAttribute {
    aTTRIBUTE_NAME: string;
    aTTRIBUTE_TYPE?: AttributeType;
    aTTRIBUTE_ID?: string;
    iS_ACTIVE: boolean;
    aSSETS: IAsset[];
    value?: string;
}

export interface IAbgCommonState {
    filters?: any;
    filtersOpen?: boolean;
    statusList?: IAbgItemStatus[];
    assetTypes?: any[];
    customAssetTypes?: IAssetType[];
    abgItem?: IABGItem;
    tags?: IABGTag[];
    searchFilters?: any;
    enableSearchFilters?: boolean;
}

export const getAbgCommonState = createFeatureSelector<IAbgCommonState>('adc-common');
export const selectFiltersStatus = createSelector(getAbgCommonState, state => state.filtersOpen);
export const selectStatusList = createSelector(getAbgCommonState, state => state.statusList);
export const selectAssetTypes = createSelector(getAbgCommonState, state => state.assetTypes);
export const selectCustomAssetTypes = createSelector(getAbgCommonState, state => state.customAssetTypes);
export const selectTagsList = createSelector(getAbgCommonState, state => state.tags);
export const selectAbgItem = createSelector(getAbgCommonState, state => state.abgItem);
export const selectEnableSearchFilters = createSelector(getAbgCommonState, state => state && state.enableSearchFilters);

export const selectFilters = (filterType: AbgSource) => createSelector(
    getAbgCommonState,
    state => {
        if (state?.filters) {
            return state.filters[filterType];
        }
    });

export interface IPost {
    oBJECT_ID?: string;
    cOMMENT_ID?: string;
    cOMMENT_TEXT?: string;
    uPDATED_BY?: number;
    uPDATED_BY_NAME?: string;
    uPDATED_BY_LAST?: string;
    cOMMENT_DATE?: string;
    tAGGED_USERS?: string[];
    uRL_OBJECT?: string;
}

export interface IPostState extends EntityState<IPost> {
    active: boolean;
}

const {selectIds, selectEntities, selectAll, selectTotal} = postAdapter.getSelectors();

export const selectPostsState = createFeatureSelector<IPostState>('adcPosts');
export const getPostsState = createSelector(selectPostsState, state => state.entities);
export const selectPosts = createSelector(selectPostsState, selectAll);
export const selectPostsDrawerActive = createSelector(selectPostsState, state => state.active);

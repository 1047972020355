import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterArrPipe } from '@shared/pipes/filter-arr.pipe';
import { FileSizePipe } from './file-size.pipe';
import { HtmlToTextPipe } from './html-to-text.pipe';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { FilterArrByKeyValuePipe } from '@shared/pipes/filter-arr-by-key-value.pipe';

@NgModule({
  declarations: [
    FilterArrPipe,
    FileSizePipe,
    HtmlToTextPipe,
    SafeHtmlPipe,
    FilterArrByKeyValuePipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FilterArrPipe,
    FileSizePipe,
    HtmlToTextPipe,
    SafeHtmlPipe,
    FilterArrByKeyValuePipe
  ]
})
export class PipesModule {
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getNotifications,
  getNotificationsSuccess,
  removeNotifications,
  updateNotification,
  updateNotificationFailure,
  updateNotificationSuccess
} from '@store/notifications/actions';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { NotificationsService } from '@shared/services/notifications.service';
import { select, Store } from '@ngrx/store';
import { INotification, selectOctomizeNotifications } from '@store/notifications/index';

@Injectable()
export class NotificationsEffects {
  getNotifications$ = createEffect(() => this.actions$
    .pipe(
      ofType(getNotifications),
      switchMap(() => {
        return this.notificationsService.getAll()
          .pipe(
            filter(result => !!result),
            switchMap(async result => {
              const ids = await this.getIdsToRemove(result);
              this.store.dispatch(removeNotifications({ids}));
              return result;
            }),
            map(result => getNotificationsSuccess({result})),
            catchError(() => EMPTY)
          );
      })
    ),
  );

  updateNotification$ = createEffect(() => this.actions$
    .pipe(
      ofType(updateNotification),
      switchMap(action => {
        return this.notificationsService.markRead(action.id)
          .pipe(
            map(() => {
              const updates = {
                id: action.id,
                changes: {
                  isRead: action.payload.isRead,
                },
              };
              return updateNotificationSuccess({updates});
            }),
            catchError(() => of(updateNotificationFailure({error: 'error'})))
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private store: Store<any>,
  ) {
  }

  private getIdsToRemove(notification: any): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.store
        .pipe(
          select(selectOctomizeNotifications),
        )
        .subscribe(results => {
          const ids = results
            .filter((item: INotification) => item.id === notification.id && item.type === notification.type)
            .map((item: INotification) => item.id) || [];
          resolve(ids);
        }, error => {
          reject(error);
        }, () => {
          resolve([]);
        });
    });
  }
}

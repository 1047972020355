import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridComponent } from '@progress/kendo-angular-grid';
import { UtilsService } from '@core/services/utils.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { KendoGridActionsService } from '@shared/services/kendo-grid-actions.service';
import { State } from '@progress/kendo-data-query';

@Component({
  selector: 'oct-generic-kendo-grid',
  templateUrl: './generic-kendo-grid.component.html',
  styleUrls: ['./generic-kendo-grid.component.scss'],
})
export class GenericKendoGridComponent implements OnInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  @ViewChild('grid') grid: GridComponent;
  @Input() fieldsMap;
  @Input() gridView;
  @Input() excelName: string;
  state: State;

  constructor(
    public dialogRef: MatDialogRef<GenericKendoGridComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utilsService: UtilsService,
    public kendoGridActionsService: KendoGridActionsService
  ) {
    this.state = {
      skip: 0,
      take: 10,
    };
  }

  ngOnInit(): void {
    // this.gridView = this.data.gridView;
    this.fieldsMap = this.data.fieldsMap;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public exportGridToExcel() {
    this.grid.saveAsExcel();
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN' || element.nodeName === 'A') &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  public openField(dataItem) {
    this.kendoGridActionsService.clickRowFromTable$.next(dataItem);
    this.close();
  }
}

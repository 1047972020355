import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as CommonActions from '@store/common/actions';
import { environment } from '@environments/environment';
import { AuthenticationService, AuthenticationType } from '@core/auth/authentication.service';
import { locale as enLang } from '../../../assets/i18n/en';
import { locale as chLang } from '../../../assets/i18n/ch';
import { locale as esLang } from '../../../assets/i18n/es';
import { locale as jpLang } from '../../../assets/i18n/jp';
import { locale as deLang } from '../../../assets/i18n/de';
import { locale as frLang } from '../../../assets/i18n/fr';
import { TranslationService } from '@core/services/translation.service';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { Router } from '@angular/router';

@Injectable()
export class AppConfig {
    public static settings: any = {};
    public loggedOutRoute;

    constructor(
            private http: HttpClient,
            private api: HttpUtilsService,
            private store: Store<any>,
            private authenticationService: AuthenticationService,
            private translationService: TranslationService,
            private router: Router,
    ) {
    }

    async load() {
        const currentUrl = window.location.href;
        if (!currentUrl.includes('login')) {
            this.loggedOutRoute = decodeURIComponent(window.location.href.split('#')[1]);
        }
        if (currentUrl.includes('#id_token')) {
            const a = window.location.href.split('#');
            const params = a[1].split('&');
            let queryParams;
            params.forEach(param => {
                const p = param.split('=');
                queryParams = {
                    ...queryParams,
                    [p[0]]: p[1],
                };
            });
			this.router.navigate(['sso'], {queryParams});
            return;
        } else {
            this.authenticationService.authenticationType = AuthenticationType.none;
            this.authenticationService.storeUrlParams();
        }

        // signup translations
        this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);

        return new Promise<void>((resolve, reject) => {
            if (window.location.href.includes('live-lineage')) {
                resolve();
            } else {
                this.http
                        .post(`${environment.apiUrl}general/GetPublicSettings`, null)
                        .subscribe((response: any) => {
                            AppConfig.settings = <any>response.res || {};
                            this.store.dispatch(new CommonActions.UpdatePublicSettings({public_settings: response.res}));
                            resolve();
                        }, error => {
                            console.log(`GetPublicSettings => ${error}`);
                            reject(error);
                        });
            }
        });
    }
}

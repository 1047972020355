import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
	selector: 'oct-autocomplate-frame-text-form-control',
	templateUrl: './autocomplate-frame-text-form-control.component.html',
	styleUrls: ['./autocomplate-frame-text-form-control.component.scss']
})
export class AutocomplateFrameTextFormControlComponent implements OnInit, AfterViewInit {
	@ViewChild('inputElement') inputElement: ElementRef;
	@Input() title: string;
	@Input() formControl: FormControl;
	@Input() options: any;
	@Input() data: any;

	constructor() {
	}

	ngAfterViewInit(): void {
		this.onKeyUp();
	}

	ngOnInit(): void {
	}

	onSelected(selectedOption: MatAutocompleteSelectedEvent) {
		const value = selectedOption.option.value;
		this.formControl.setValue({
			...this.data,
			value,
		});
	}

	onKeyUp() {
		fromEvent(this.inputElement.nativeElement, 'keyup')
			.pipe(
				debounceTime(200),
			)
			.subscribe((e: KeyboardEvent) => {
				this.formControl.setValue(this.inputElement);
				this.options = this.data.function(this.inputElement);
			});
	}
}

import {Pipe, PipeTransform} from '@angular/core';
import {IUser} from '@shared/interfaces/user';

@Pipe({
	name: 'highlightMention',
	pure: false,
})
export class HighlightMentionPipe implements PipeTransform {

	transform(value: string, args?: IUser[]): any {
		args.forEach((user: IUser) => {
			if (value.includes(`@${user.FULL_NAME}`)) {
				value = value.split(`@${user.FULL_NAME}`).join(`<span class="mention-mark">${user.FULL_NAME}</span>`);
			}
		});
		return value;
	}

}

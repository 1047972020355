export enum moduleType {
  ETL = 'ETL',
  DB = 'DB',
  DATABASE = 'DATABASE',
  ANALYSIS = 'ANALYSIS',
  REPORT = 'REPORT',
  FILES = 'FILES',
  UNKNOWN = 'UNKNOWN',
  UNK = 'UNK',
}

export interface ImoduleTypeMapper {
  [key: number]: moduleType;
}

export const MODULE_TYPE_MAP: ImoduleTypeMapper = {
  302: moduleType.ETL,
  303: moduleType.DATABASE,
  304: moduleType.REPORT,
};

export const QUERY_NUM_MAP = {
  ETL: 302,
  DATABASE: 303,
  DB: 303,
  REPORT: 304,
};

export const LINEAGE_TYPE_TITLE_MAP = {
  ETL: 'ETLs, Stored Procedures & SQL Files',
  DATABASE: 'DB Objects, Files & Analysis Services',
  DB: 'DB Objects, Files & Analysis Services',
  REPORT: 'Reports, Analytics & Information Consumers',
};

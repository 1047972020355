import { Attribute, Directive, ElementRef, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { UtilsService } from '@core/services/utils.service';

@Directive({
	selector: '[octRtlView]'
})
export class RtlViewDirective implements OnInit {

	constructor(
		@Attribute('type') public type: string,
		private el: ElementRef,
		private utilsService: UtilsService
	) {
	}

	ngOnInit(): void {
		if (this.el.nativeElement.innerText && this.el.nativeElement.type !== 'input' && this.el.nativeElement.type !== 'textarea') {
			this.addRemoveClassRtl(this.el.nativeElement.innerText);
		}
	}

	private addRemoveClassRtl(text) {
		// if first char is not a letter (a number for example) so need to check the first letter and then decide which is the right side.
		const _firstLetter = this.findFirstLetter(text);

		if (_firstLetter !== '') {
			if (this.utilsService.isRightToLeftLanguage(_firstLetter)) {
				this.el.nativeElement.classList.add('rtlView');

				if (this.el.nativeElement.tagName === 'SPAN') { // direction=rtl didn't work on span, need to set attr dir=rtl
					this.el.nativeElement.setAttribute('dir', 'rtl');
				}
			} else {
				this.el.nativeElement.classList.remove('rtlView');
			}
		}
	}

	public findFirstLetter(text): string {
		if (text.length <= 1) {
			return text;
		} else {
			if (this.utilsService.charIsLetter(text.charAt(0))) {
				return text.charAt(0);
			} else {
				text = text.substring(1);
				return this.findFirstLetter(text);
			}
		}
	}
}

import { moduleType } from '@shared/enums/module-type.enum';
import { SearchType } from '@shared/enums/searchType';
import { PageConfigService } from '@core/services/page-config.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { IABGItem } from '@main/adc/adc-store';

export interface SearchResultsItem {
    [undefined: string]: {
        selectedObject: IABGItem;
        results: Array<any>;
        standardResults: Array<any>;
        enhancedResults: Array<any>;
        totalCount: number;
        standardCount: number;
        enhancedCount: number;
        totalMsg: string;
        endOfResults: boolean;
        selectedBySearch: SelectedBySearch;
        isFetchMore: boolean;
        isNewSearching: boolean;
        searchFilters?: any;
        noResults: boolean;
    };
}

export interface SelectedBySearch {
    isSearching: boolean;
    selectedValue: string;
    selectedLayers: Array<any>;
    pageNumber: number;
    rowsInPage: number;
    tags: Array<string>;
    advancedSearch?: any;
}

export const AbgFilers = {
    Physical: 'Physical layer',
    Presentation: 'Presentation layer',
    Semantic: 'Semantic layer',
    ACD: 'ACD item',
    Report: 'Report Names',
};

export const ModuleLayerIconMapping = {
    Physical: 'physical_layer',
    Semantic: 'semantic_layer',
    Presentation: 'presentation_layer',
    Report: 'report_name_layer',
    business: 'business-enabled',
};

export const moduleLayersMapping = {
    DB: ['Physical'],
    ETL: ['Physical'],
    ANALYSIS: ['Semantic'],
    REPORT: ['Physical', 'Semantic', 'Presentation', 'Report'],
    BUSINESS: ['business'],
};

export const layersDisplayNameByToolMapping = {
    '*': {Report: 'Report Names', Physical: 'Physical', Presentation: 'Presentation', Semantic: 'Semantic'},
    ssis: {Physical: 'Package Names'},
    datastage: {Physical: 'Job Names'},
    informatica: {Physical: 'Map Names'},
    business: {business: 'Business Names'},
};

export const moduleLayersOrder = {
    REPORT: 0,
    ANALYSIS: 1,
    DB: 2,
    ETL: 3,
    BUSINESS: 4,
};

export interface IServerAbgFilterTool {
    tOOL_NAME: string;
    tOOL_TYPE: moduleType;
}

export interface IAbgUrlData {
    selectedItemId?: string;
    selectedItem?: IABGItem;
    searchType?: SearchType;
    searchValue?: string;
    searchObjType?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AbgUrlHelper {
    public abgUrlData?: IAbgUrlData;
    public url?: string;

    constructor(
        private router: Router,
        private loc: Location,
        private pageConfigService: PageConfigService) {
    }

    isValidUrlData() {
        return this.abgUrlData.selectedItemId;
    }

    public encodeUrl() {
        if (this.abgUrlData.selectedItemId || this.abgUrlData.searchValue) {
            return this.pageConfigService.encodeBtoa(JSON.stringify(this.abgUrlData));
        } else {
            return null;
        }
    }

    public decodeUrl(id?) {
        let abgUrlData: IAbgUrlData = null;
        if (id || this.url) {
            abgUrlData = JSON.parse(this.pageConfigService.decodeBtoa(id || this.url));
            this.abgUrlData = abgUrlData;
        }
        return abgUrlData;
    }

    public getUrl() {
        let url = null;
        if (this.abgUrlData.searchValue || this.abgUrlData.selectedItemId) {
            url = this.encodeUrl();
        }
        return url;
    }

    public buildRoute() {
        const route = ['adc'];
        this.url = null;
        if (this.abgUrlData.searchValue || this.abgUrlData.selectedItemId) {
            if (this.abgUrlData.selectedItemId) {
                route.push('details');
            }
            this.url = this.encodeUrl();
            route.push(this.url);
        }
        return route;
    }

    public setUrl() {
        const routeArr = [this.router.routerState.snapshot.url.replace('/', '').split('/')[0]];
        this.url = this.encodeUrl();
        if (this.url) {
            routeArr.push(this.url);
        }
        const url = this.router
            .createUrlTree(routeArr, /* Removed unsupported properties by Angular migration: replaceUrl. */ {})
            .toString();

        this.loc.go(url);
    }
}

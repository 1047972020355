import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { ChartsModule } from 'ng2-charts';

@NgModule({
	imports: [
		CommonModule,
		// ChartsModule
	],
	exports: [
	],
	declarations: [
	]
})
export class WidgetChartsModule {
}

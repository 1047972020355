import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckBoxListComponent } from '@shared/oct-components/check-box-list/check-box-list.component';
import { OctCheckBoxModule } from '@shared/oct-components/check-box/check-box.module';


@NgModule({
  declarations: [CheckBoxListComponent],
  imports: [
    CommonModule,
    OctCheckBoxModule
  ],
  exports: [CheckBoxListComponent]
})
export class CheckBoxListModule {
}

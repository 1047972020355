import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'oct-simple-toolbar-button',
	templateUrl: './simple-toolbar-button.component.html',
	styleUrls: ['./simple-toolbar-button.component.scss']
})

export class SimpleToolbarButtonComponent implements OnInit {

	@Input() item: any;

	constructor() {
	}

	ngOnInit(): void {
	}

}

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';

import { PageConfigService } from '@core/services/page-config.service';
import { MapObjs } from '@core/interfaces/map-diagram-data';
import { CommonState, getLocation } from '@store/common';
import { MapIDService } from '@main/maps/map-id/map-id.service';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { Locations } from '@shared/enums/locations.enum';
import { take } from 'rxjs/operators';
import { CodePreviewService } from '@content/partials/content/general/code-preview/code-preview.service';

@Injectable()
export class MapsService {
    private location: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        private pageConfigService: PageConfigService,
        private storeCommon: Store<CommonState>,
        private mapIDService: MapIDService,
        private codePreviewService: CodePreviewService,
        private moduleUtilsService: ModuleUtilsService
    ) {
    }

    public setParamsForMap(data: MapObjs) {
        let id: object;

        // this.setTheModuleOfTheSelectedObjectInPageConfigService(data.module);
        this.storeCommon
            .pipe(
                select(getLocation),
                take(1),
            )
            .subscribe(location => {
                this.location = location;
            });

        if (['SSIS', 'ADF'].includes(data.tool) && data.colToColMapETLDrillLeftRight) {
            id = data.colToColMapETLDrillLeftRight;
        } else if (['SSIS', 'ADF'].includes(data.tool) && [Locations.lineage, Locations.map, Locations.lineageSchema].includes(<Locations>this.location) && !data.colToColMapETL) { // if SSIS from discovery - open col to col map (not table to table)
            id = data.tableToTableMap;
        } else {
            id = data[`colToColMap${this.moduleUtilsService.getModuleLongName(data.module)}`];
        }

        const _url = this.pageConfigService.encodeBtoa(JSON.stringify(id));
        this.mapIDService.url = _url;
        this.router.navigate([Locations.map, _url]);
    }

    // step is from type any because this Func is called from places without step
    public whiceQuerynumber(tool: string, module: string, step?: any): number {
        if (tool === 'both') {
            return 320;
        }
        if (['SSIS', 'ADF'].includes(tool.toUpperCase()) && !step) {
            return 23;
        }
        if (['SSIS', 'ADF'].includes(tool.toUpperCase()) && step['is_control_flow_step']) {
            return 25;
        }
		if (['SSIS', 'ADF'].includes(tool.toUpperCase()) && step['is_execute_task']) {
            return 27;
        }
        if (step && step['is_drill_left_step']) {
            return 319;
        }
        if (step && step['is_drill_right_step']) {
            return 318;
        }
        if ((module.toUpperCase() === 'ETL' || this.moduleUtilsService.getModuleLongName(module).toUpperCase() === 'DATABASE') && step && step['sql']) {
            return 311;
        }
        // database is instead of GSP
        if (module.toUpperCase() === 'ETL' || this.moduleUtilsService.getModuleLongName(module).toUpperCase() === 'DATABASE') {
            return 312;
        }
        if (module.toUpperCase() === 'REPORT') {
            return 309;
        }
    }
}

import { IAdvancedSearchValue } from '@store/search';
import {IConnection} from '@store/common';
import { SearchType } from '@shared/enums/searchType';

export interface IConnectionsCollections {
	connections: IConnection[];
	connectionsBG: IConnection[];
}

export class DiscoveryUrlState {
	connectionIds: string;
	connectionsCollection: IConnectionsCollections;
	searchType: SearchType;
	searchObjType: string;
	searchValue: any;
	advancedSearch: IAdvancedSearchValue;
	selectedObject: any;

	constructor() {
		this.connectionIds = '';
	}
}

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { State } from '@store/users';
import { Action, createReducer, on } from '@ngrx/store';
import {
	deleteActivitySuccess,
	getActivitiesSuccess,
	setAllActivities,
	updateActivity
} from '@store/activities/actions';

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
	selectId: (model: any) => model.usage_id,
});
export const initialState: State = adapter.getInitialState();

const activitiesReducer = createReducer(
	initialState,
	on(getActivitiesSuccess, (state, {activities}) => {
		return adapter.setAll(activities.slice(0, 999), state);
	}),
	on(setAllActivities, (state, {payload}) => {
		return adapter.setAll([payload, ...selectAll(state).slice(0, 998)], state);
	}),
	on(updateActivity, (state, {payload}) => {
		return adapter.updateOne({id: payload.id, changes: {to_delete: payload.value}}, state);
	}),
	on(deleteActivitySuccess, (state, {payload}) => {
		return adapter.removeOne(payload, state);
	})
);

export function reducer(state: State, action: Action) {
	return activitiesReducer(state, action);
}

const {
	selectIds,
	selectEntities,
	selectAll,
	selectTotal,
} = adapter.getSelectors();

// select the array of activities ids
export const selectActivityIds = selectIds;

// select the dictionary of activity entities
export const selectActivityEntities = selectEntities;

// select the array of users
export const selectAllActivities = selectAll;

// select the total user count
export const selectActivityTotal = selectTotal;

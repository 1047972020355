import { Action } from '@ngrx/store';
import { DiscoveryState } from '@store/discovery/index';

export const ADD_DISCOVERY = '[DISCOVERY] Add';
export const REMOVE_DISCOVERY = '[DISCOVERY] Remove';
export const UPDATE_DISCOVERY_SELECTED_CONNECTIONS_IDS = '[DISCOVERY] Update Connections Selected ids';
export const UPDATE_DISCOVERY_PROPERTIES = '[DISCOVERY] Update Properties';
export const UPDATE_DISCOVERY_PROPERTIES_OBJ = '[DISCOVERY] Update Properties Obj';
export const UPDATE_DISCOVERY_COUNT = '[DISCOVERY] Update Count';
export const UPDATE_DISCOVERY_SELECTED_CONNECTIONS_OBJS = '[DISCOVERY] Update Selected Connections Objs';
export const UPDATE_DISCOVERY_SEARCH_OBJ = '[DISCOVERY] Update Search Obj';
export const UPDATE_DISCOVERY_CONNECTIONS_LIST = '[DISCOVERY] Update Connections List';
export const UPDATE_DISCOVERY_CONNECTIONS_LIST_BG = '[DISCOVERY] Update Connections List BG';
export const UPDATE_DISCOVERY_HISTORY_LIST = '[DISCOVERY] Update History List';
export const SET_DISCOVERY_CONNECTIONS = '[DISCOVERY] set Connections';
export const SET_DISCOVERY_URL = '[DISCOVERY] set url';
export const UPDATE_SELECTED_OBJECT = '[DISCOVERY] update selected green button object';

export class SetDiscoveryUrl implements Action {
	readonly type = SET_DISCOVERY_URL;

	constructor(public payload: any) {
	}
}

export class AddDiscovery implements Action {
	readonly type = ADD_DISCOVERY;

	constructor(public payload: DiscoveryState) {
	}
}

export class RemoveDiscovery implements Action {
	readonly type = REMOVE_DISCOVERY;

	constructor(public payload: number) {
	}
}

export class UpdateDiscoveryCount implements Action {
	readonly type = UPDATE_DISCOVERY_COUNT;

	constructor(public payload: { count: string }) {
	}
}

export class UpdateSelectedObject implements Action {
	readonly type = UPDATE_SELECTED_OBJECT;

	constructor(public payload: { selectedObject: string }) {
	}
}

export class UpdateDiscoverySelectedConnectionsObjs implements Action {
	readonly type = UPDATE_DISCOVERY_SELECTED_CONNECTIONS_OBJS;

	constructor(public payload: { selected_connections_objs: Array<any> }) {
	}
}

export class UpdateDiscoverySelectedConnectionsIDs implements Action {
	readonly type = UPDATE_DISCOVERY_SELECTED_CONNECTIONS_IDS;

	constructor(public payload: { selected_connections_ids: string }) {
	}
}

export class UpdateDiscoveryProperties implements Action {
	readonly type = UPDATE_DISCOVERY_PROPERTIES;

	constructor(public payload: { properties: object }) {
	}
}

export class UpdateDiscoveryPropertiesObj implements Action {
	readonly type = UPDATE_DISCOVERY_PROPERTIES_OBJ;

	constructor(public payload: { properties_obj: object }) {
	}
}

export class UpdateDiscoverySearchObj implements Action {
	readonly type = UPDATE_DISCOVERY_SEARCH_OBJ;

	constructor(public payload: any) {
	}
}

export class UpdateDiscoveryConnectionsList implements Action {
	readonly type = UPDATE_DISCOVERY_CONNECTIONS_LIST;

	constructor(public payload: { connections_list: object }) {
	}
}

export class UpdateDiscoveryConnectionsListBG implements Action {
	readonly type = UPDATE_DISCOVERY_CONNECTIONS_LIST_BG;

	constructor(public payload: { connections_list_bg: object }) {
	}
}

export class UpdateDiscoveryHistoryList implements Action {
	readonly type = UPDATE_DISCOVERY_HISTORY_LIST;

	constructor(public payload: { history_list: Array<object> }) {
	}
}

export class SetDiscoveryConnections implements Action {
	readonly type = SET_DISCOVERY_CONNECTIONS;

	constructor(public payload: any) {
	}
}

export type Actions =
	AddDiscovery |
	RemoveDiscovery |
	UpdateDiscoveryConnectionsListBG |
	UpdateDiscoveryConnectionsList |
	UpdateDiscoverySearchObj |
	UpdateDiscoverySelectedConnectionsIDs |
	UpdateDiscoveryProperties |
	UpdateDiscoveryPropertiesObj |
	UpdateDiscoveryCount |
	UpdateDiscoverySelectedConnectionsObjs |
	UpdateDiscoveryHistoryList |
	SetDiscoveryConnections |
	SetDiscoveryUrl |
	UpdateSelectedObject;

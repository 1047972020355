<div *ngFor="let item of data" class="flex filter-item">
	<div *ngIf="item.group === 'node'">
		<mat-label class="filter-item-label">{{item.displayName}}</mat-label>
		<mat-checkbox (change)="change($event, flag)" *ngFor="let flag of item.items"
					  [checked]="flag.checked"
					  [disabled]="item.disabled"
					  class="{{flag.color ? 'coloring-check-box-' + flag.color : ''}}">{{flag.displayName || flag.name}}</mat-checkbox>
	</div>
</div>

<div *ngFor="let item of data; let first = first" class="flex filter-item link" [class.first-item]="first">
	<div *ngIf="item.group === 'link'">
		<mat-label class="filter-item-label">{{item.displayName}}</mat-label>
		<mat-checkbox (change)="change($event, flag)" *ngFor="let flag of item.items"
					  [checked]="flag.checked"
					  [disabled]="item.disabled"
					  class="{{flag.color ? 'coloring-check-box-' + flag.color : ''}}">
			<div class="checkbox-content">
				<div>{{flag.displayName || flag.name}}</div>
				<div class="legend-arrow {{flag.name}}">
					<div class="legend-color-link {{flag.name}}"></div>
					<div *ngIf="flag.name !== 'Dependency Link'" class="legend-arrow-right {{flag.name}}"></div>
				</div>
			</div>
		</mat-checkbox>
	</div>
</div>

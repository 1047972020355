import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundButtonComponent } from '@shared/oct-components/round-button/round-button.component';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [RoundButtonComponent],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [RoundButtonComponent]
})
export class RoundButtonModule {
}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'm-jump-to-another-page',
	templateUrl: './jump-to-another-page.component.html',
	styleUrls: ['./jump-to-another-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class JumpToAnotherPageComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}

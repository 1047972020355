import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
	selector: 'oct-chip-content',
	templateUrl: './chip-content.component.html',
	styleUrls: ['./chip-content.component.scss']
})

export class ChipContentComponent implements OnChanges {

	@Input() selectedTag: { tAG_NAME: string, FULL_NAME: string, aTTRIBUTE_NAME: string };
	@Input() allowDelete = true;
	@Output() tag = new EventEmitter<any>();
	@Output() deleteAllList = new EventEmitter<any>();
	@Output() deleteSpecialOption = new EventEmitter<any>();

	constructor() {
	}

	public removeTag(selectedTag) {
		this.tag.emit(selectedTag);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.selectedTag.currentValue.FULL_NAME == 'All Assigned' || changes.selectedTag.currentValue.FULL_NAME == 'All Unassigned') {
			this.deleteAllList.emit(changes.selectedTag.currentValue);
		} else {
			this.deleteSpecialOption.emit(changes.selectedTag.currentValue);
		}
	}
}

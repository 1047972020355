import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromActivity from '@store/activities/reducers';

export interface State extends EntityState<any> {
}

export const selectActivitiesState = createFeatureSelector('activities');
export const selectActivityEntities = createSelector(
	selectActivitiesState,
	fromActivity.selectActivityEntities
);
export const selectActivities = createSelector(
	selectActivitiesState,
	fromActivity.selectAllActivities
);

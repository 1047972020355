export enum iconByModule {
	'DISCOVERY' = 'discovery.png',
	'AUTOMATED DATA CATALOG' = 'automated_data_catalog.png',
	'AUTOMATED DATA CATALOG DASHBOARD' = 'data_catalog_insights.png',
	'CROSS SYSTEM LINEAGE DASHBOARD - SEARCH BAR' = 'cross_system_lineage_dashboard.png',
	'CROSS SYSTEM LINEAGE DASHBOARD - TOP SEARCH BAR' = 'cross_system_lineage_dashboard.png',
	'CROSS SYSTEM LINEAGE DASHBOARD - INNER SEARCH BAR' = 'cross_system_lineage_dashboard.png',
	'LINEAGE' = 'cross_system_lineage_dashboard.png',
	'INNER SYSTEM LINEAGE' = 'inner_system_lineage.png',
	'CROSS SYSTEM LINEAGE' = 'cross_system_lineage.png',
	'CROSS SYSTEM ETLS COMPARE' = 'cross_system_etl_compare.png',
	'CROSS SYSTEM REPORT COMPARE' = 'cross_system_report_compare.png',
	'END TO END LINEAGE DASHBOARD - TOP SEARCH BAR' = 'e2e_lineage_dashboard.png',
	'END TO END LINEAGE DASHBOARD - INNER SEARCH BAR' = 'e2e_lineage_dashboard.png',
	'END TO END LINEAGE DASHBOARD - SEARCH BAR' = 'e2e_lineage_dashboard.png',
	'E2E COLUMN LINEAGE' = 'e2e_column_lineage.png',
	'LIVE LINEAGE' = 'editLineage.svg'
}

export enum filterNames {
	'Sub Module' = 'module',
	'Activity' = 'activity',
	'Tool Name' = 'toolName',
	'Tool Type' = 'toolType'
}

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'oct-button-toggle',
	templateUrl: './button-toggle.component.html',
	styleUrls: ['./button-toggle.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ButtonToggleComponent),
			multi: true
		}
	],
})
export class ButtonToggleComponent implements OnInit, ControlValueAccessor {
	@Input() height: string;
	@Input() listOfButtons: Array<{ text: string, value: string }>;
	@Input() newLine: boolean = false;
	@Input() buttonToggleName: string;
	@Input() defaultValue: string;
  @Output() toggleChange = new EventEmitter<string>();
	public formControl = new FormControl();
	selectedValue;

	constructor() {
	}

	onChange: any = () => {
	};

	onTouch: any = () => {
	};

	ngOnInit(): void {
		if (!this.formControl.value) {
			this.formControl.setValue(this.defaultValue);
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	writeValue(value): void {
		this.formControl.setValue(value);
		this.defaultValue = value;
		this.selectedValue = value;
	}

	onClick(e) {
		this.selectedValue = e.value;
		this.defaultValue = e.value;
    this.toggleChange.emit(this.selectedValue);
		this.onChange(e.value);
	}
}

<button [matMenuTriggerFor]="menu" class="monitor-trigger" mat-icon-button
        matTooltip="System Monitor">
    <mat-icon class="system-monitor" svgIcon="timer"></mat-icon>
</button>

<mat-menu #menu="matMenu" xPosition="before">
    <div (click)="$event.stopPropagation()" class="menu-wrapper">
        <mat-label class="menu-title">System Monitor</mat-label>
        <div>
            <ng-template #tooltip_template let-anchor>
                <span>{{ anchor.nativeElement.innerText }}</span>
            </ng-template>
            <div (mouseover)="showTooltip($event)"
                 [tooltipTemplate]="tooltip_template"
                 filter=".k-grid td"
                 kendoTooltip
                 showOn="none">
                <kendo-grid #grid (sortChange)="sortChange($event)" [kendoGridBinding]="connections"
                            [resizable]="true"
                            [sort]="sort"
                            [sortable]="true" class="monitor grid-as-list">

                    <kendo-grid-column *ngFor="let col of columns" [field]="col.field"
                                       [headerStyle]="{'color': '#365B89', 'background-color': 'rgb(243 245 247 / 30%)', 'font-weight': '400'}"
                                       [style]="{'color': '#365B89', 'background-color': '#FFFFFF'}"
                                       [title]="col.title"
                                       [width]="col.width">
                        <ng-template *ngIf="col.field == 'Status'" kendoGridCellTemplate let-dataItem>
                            <div [ngClass]="dataItem['LastRefreshed'] == '' ? 'waiting':'' "
                                 class="status-shape"></div>
                        </ng-template>
                        <ng-template *ngIf="col.field == 'LastRefreshed'" kendoGridCellTemplate let-dataItem>
                            <div>{{ getFormatedDate(dataItem['LastRefreshed']) }}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</mat-menu>


<!--<div class="m-nav__link m-dropdown__toggle monitor-box" id="m_topbar_notification_icon" matTooltip="System Monitor">-->
<!--		<span class="m-nav__link-icon monitor-box">-->
<!--			<span (click)="openMonitor()" class="m-nav__link-icon-wrapper monitor-box">-->
<!--				<img alt="" class="monitor-box"-->
<!--                     id="Cross_System_Lineage_Dashboard_System_Monitor" src="./assets/system_monitor_toggle.svg"/>-->
<!--			</span>-->
<!--		</span>-->
<!--</div>-->

<!--<div (clickOutside)="closeMonitor()" [excludeClasses]="['monitor-box']"-->
<!--     [ngClass]="{active: !modalClosed}" class="m-dropdown__wrapper">-->
<!--    <span class="m-dropdown__arrow m-dropdown__arrow&#45;&#45;right m-dropdown__arrow&#45;&#45;adjust tooltip-arrow-color"></span>-->
<!--    <div class="m-dropdown__inner">-->
<!--        <div class="m-dropdown__header topbar-popover">-->
<!--				<span class="m-dropdown__header-subtitle">-->
<!--					System Monitor-->
<!--				</span>-->
<!--            <mat-icon (click)="closeMonitor()" class="close-btn" matTooltip="Cancel" svgIcon="close-icon"></mat-icon>-->
<!--        </div>-->
<!--        <div class="m-dropdown__body">-->
<!--            <div class="m-dropdown__content">-->
<!--                <ng-template #tooltip_template let-anchor>-->
<!--                    <span>{{ anchor.nativeElement.innerText }}</span>-->
<!--                </ng-template>-->
<!--                <div (mouseover)="showTooltip($event)"-->
<!--                     [tooltipTemplate]="tooltip_template"-->
<!--                     filter=".k-grid td"-->
<!--                     kendoTooltip-->
<!--                     showOn="none">-->
<!--                    <kendo-grid #grid (sortChange)="sortChange($event)" [kendoGridBinding]="metadata-sources"-->
<!--                                [resizable]="true"-->
<!--                                [sort]="sort"-->
<!--                                [sortable]="true" class="monitor grid-as-list">-->

<!--                        <kendo-grid-column *ngFor="let col of columns" [field]="col.field"-->
<!--                                           [headerStyle]="{'color': '#365B89', 'background-color': 'rgb(243 245 247 / 30%)', 'font-weight': '400'}"-->
<!--                                           [style]="{'color': '#365B89', 'background-color': '#FFFFFF'}"-->
<!--                                           [title]="col.title"-->
<!--                                           [width]="col.width">-->
<!--                            <ng-template *ngIf="col.field == 'Status'" kendoGridCellTemplate let-dataItem>-->
<!--                                <div [ngClass]="dataItem['LastRefreshed'] == '' ? 'waiting':'' "-->
<!--                                     class="status-shape"></div>-->
<!--                            </ng-template>-->
<!--                            <ng-template *ngIf="col.field == 'LastRefreshed'" kendoGridCellTemplate let-dataItem>-->
<!--                                <div>{{getFormatedDate(dataItem['LastRefreshed'])}}</div>-->
<!--                            </ng-template>-->
<!--                        </kendo-grid-column>-->
<!--                    </kendo-grid>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

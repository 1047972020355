<div class="code-preview">
    <div class="search-wrapper flex" flex-align-items-center>
		<ng-content select="[slot=start]"></ng-content>

		<m-search-input (clear)="onClearSearch()" (keyPress)="onSearch($event)" (next)="onNext()"
						(prev)="onPrev()"
						[applyNavigation]="true"
						[isFocus]="true"
						style="margin: 5px 0;"
						tooltip="{{'GENERAL.TOOLTIP_MSG' | translate}}"></m-search-input>
		<div *ngIf="![locations.liveVisualizer, locations.liveLineage].includes(location)" class="flex m--margin-5">
			<button (click)="navigateToLiveVisualize()" *ngIf="applyLiveLineage"
					class="visualizer-action btn-action  flex"
					flex-center
					matTooltip="Edit in Live Lineage">
				<mat-icon svgIcon="editLineage"></mat-icon>
			</button>

			<button class="visualizer-action btn-action flex" flex-center>
				<mat-icon [matTooltip]="copyMessage" class="copyCodeToClipboardClass" style="border: none"
						  svgIcon="copyScript"></mat-icon>
			</button>
		</div>
	</div>

    <div>
        <ng-content select="[slot=end]"></ng-content>
    </div>
</div>

<textarea #editorHolder></textarea>

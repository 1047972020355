<div (mouseenter)="makeHoverFunc()" (mouseleave)="makeHoverFunc()"
     [ngClass]="{disabledMe: formControl.disabled, 'on-hover-btns': hoverMode}"
     class="padding-wrapper" id="text-form-control">
    <div [ngClass]="{edit: editMode}" class="row item-row">
        <div class="flex">
            <div [ngClass]="{'on-hover-btns': hoverMode}" class="row-label">
                <label [ngClass]="{edit: editMode}"
                       class="row-label-body" matTooltip="{{title}}">{{ title }}</label>
            </div>

            <div class="actions">
                <div (click)="onEditClick()" *ngIf="!readonly"
                     [ngClass]="{edit: editMode, 'disabledMe': isUserRole}"
                     class="action-btn btn-edit"
                     id="Data_Catalog_Edit_{{formControl.value}}"
                     matTooltip="Edit">
                    <mat-icon [ngClass]="{edit: editMode, 'on-hover-icons': hoverMode, 'edit-icon': !hoverMode}"
                              svgIcon="edit-property"></mat-icon>
                </div>
                <div #copyBtn (click)="onCopyClick()" (mousedown)="copy = true"
                     (mouseup)="copy = false"
                     [ngClass]="{copy: copy}"
                     class="action-btn btn-copy" matTooltip="Copy">
                    <mat-icon *ngIf="!editMode" [ngClass]="{'on-hover-icons': hoverMode}"
                              class="copy-icon"
                              svgIcon="copy-property"></mat-icon>
                </div>
            </div>
        </div>

        <div class="row-content">
            <div #copyLabel *ngIf="!editMode" [innerHTML]="value  | highlight : (search$ | async)"
                 class="input-label"
                 matTooltipClass="extend-tooltip" octRtl
                 readonly
                 style="white-space: pre-line"></div>

            <div [hidden]="!editMode" class="text-area-container">
                <textarea #inputText [formControl]="formControl"
                          [ngClass]="{edit: editMode}" class="form-control m-input oct-input text-area"
                          maxlength="{{characterLimit}}"
                          octRtl
                          placeholder="[Type Here]"
                          rows="2"></textarea>
            </div>
        </div>

        <div *ngIf="editMode" class="flex btns-form-action" space-between>
            <div class="flex" style="flex: 1;">
                <mat-label *ngIf="characterLimit">{{ characterCount }}/{{ characterLimit }}</mat-label>
                <div *ngIf="formControl.errors?.maxlength" class="control-error-message">
                    <mat-label style="color: red; position: absolute; margin-left: 10px;">
                        Up to {{ formControl.errors.maxlength['requiredLength'] + 1 }} characters
                    </mat-label>
                </div>
            </div>
            <div class="flex" style="align-items: center;">
                <oct-underline-button (clicked)="onCancel()" fontSize="0.85rem" matTooltip="Cancel"
                                      text="Cancel"></oct-underline-button>
                <oct-round-button (clicked)="onSave(inputText.value)" class="submit-btn" fontSize="0.9rem"
                                  matTooltip="Save" text="Save"></oct-round-button>
            </div>
        </div>
    </div>
</div>

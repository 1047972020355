import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AppConfig } from '@core/services/app-config.service';
import { select, Store } from '@ngrx/store';
import { getEmail } from '@core/states/user.state';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[octFeatureFlag]'
})
export class FeatureFlagDirective implements OnInit {
  @Input('octFeatureFlag') featureFlag: string;

  constructor(
    private el: ElementRef,
    private store: Store<any>
  ) {
  }

  ngOnInit(): void {
    if (this.featureFlag) {
      this.store
        .pipe(
          select(getEmail),
          filter(email => email !== 'support@octopai.com'),
        )
        .subscribe(() => {
          this.el.nativeElement.hidden = !AppConfig.settings[this.featureFlag];
        });
    }
  }
}

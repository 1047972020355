import { Component, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'oct-list-form-control',
  templateUrl: './list-form-control.component.html',
  styleUrls: ['./list-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListFormControlComponent),
      multi: true
    }
  ],
})
export class ListFormControlComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() key: string;
  @Input() data: any;
  @Input() listOptions: Array<any> = [];
  @Input() placeholder: string;
  @Output() itemClick;
  @Input() formControl: FormControl;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.formControl) {
      this.formControl = new FormControl('');
    }
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value): void {
    this.formControl.setValue({key: this.key, value: value, data: this.data});
  }

  onSelected(value) {
    this.itemClick({key: this.key, value: value, data: this.data});
    this.onChange({key: this.key, value: value, data: this.data});
  }

  onSelectionChange(e: MatSelectChange) {
    // const p = {
    //   key: this.key,
    //   value: e.value,
    //   data: this.data
    // };
    // if (this.itemClick) {
    //   this.itemClick(p);
    // }
    // this.onChange(p);
    this.formControl.setValue(e.value);
    this.onChange(e.value);
  }
}

export enum ViewType {
	None = 'None',
	Active = 'Active',
	Missing = 'Missing',
	Suspended = 'Suspended',
	Dropped = 'Dropped',
	New = 'New',
	Updated = 'Updated',
	Average = 'Average',
	AssignToMe = 'AssignToMe',
}

export const TotalApiName = {
	Active: 'getTotalActiveObjectsCount',
	Missing: 'getTotalMissingDesObjectsCount',
	Suspended: 'getTotalSuspendObjectsCount',
	Dropped: 'getTotalDroppedObjectsCount',
	New: 'getTotalObjectsCount',
	Updated: 'getTotalObjectsCount',
	Average: 'getAvgRatesOwner',
	AssignToMe: 'getTotalUserObjectsCount',
};

export const SearchApiName = {
	Active: 'searchActiveObject',
	Missing: 'searchMissingDesObject',
	Suspended: 'searchSuspendObject',
	Dropped: 'searchDroppedObject',
	New: 'searchObject',
	Updated: 'searchObject',
	AssignToMe: 'searchObject',
	Average: 'searchObject',
};

export const AdcSearchType = {
    New: 'NewObject',
    Updated: 'UpdatedObject',
    Average: 'WithRates',
};

export const ChartTitle = {
	Active: 'Active',
	Missing: 'Missing Description',
	Suspended: 'Suspended',
	Dropped: 'Dropped',
	New: 'New',
	Updated: 'Updated',
	AssignToMe: 'Assigned to Me',
	Average: 'Rated Assets',
};

export const ChartTitleTooltip = {
	Active: 'Active',
	Missing: 'Missing Description',
	Suspended: 'Suspended',
	Dropped: 'Dropped',
	New: 'New',
	Updated: 'Updated',
	AssignToMe: 'Assigned to Me',
	Average: 'Assets Assigned to me that include rating',
};

export const ChartType = {
	type: 'type',
	status: 'status',
};

export const ChartTotalApi = {
	type: 'getTotalObjectsTypesCount',
	status: 'getTotalStatusCount',
};

export const editableViewType = [ViewType.Active, ViewType.Missing, ViewType.Suspended];

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarItemComponent } from '@shared/oct-components/avatar-item/avatar-item.component';
import { AvatarIconModule } from '@shared/oct-components/avatar-icon/avatar-icon.module';


@NgModule({
  declarations: [AvatarItemComponent],
  imports: [
    CommonModule,
    AvatarIconModule
  ],
  exports: [AvatarItemComponent]
})
export class AvatarItemModule {
}

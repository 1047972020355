<oct-monitor></oct-monitor>
<oct-notifications></oct-notifications>
<oct-recent-activities></oct-recent-activities>
<div class="question-mark" matTooltip="Octopai Knowledge Center">
    <a id="questionMarkButton" href="https://octopai.zendesk.com/hc/en-us" target="_blank">
        <mat-icon  svgIcon="questionMark"></mat-icon>
    </a>
</div>
<oct-user-profile></oct-user-profile>

<div [hidden]="[locations.adc, locations.adcDashboard, locations.settings].includes(location$ | async)"
     class="right-panel-toggle"
     matTooltip="Metadata Connections">
    <div class="m-nav__link m-dropdown__toggle">
					<span class="m-nav__link-icon">
						<a (click)="openSideBar()" clickOutSideExclude>
							<img alt="" class="panel-box" clickOutSideExclude src="./assets/right_panel_toggle.svg"/>
						</a>
					</span>
    </div>
</div>

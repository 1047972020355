import { Component, Input, OnInit } from '@angular/core';
import { usage } from '@shared/enums/usage';

@Component({
    selector: 'oct-usage-content',
    templateUrl: './usage-content.component.html',
    styleUrls: ['./usage-content.component.scss', '../list-properties.component.scss']
})
export class UsageContentComponent implements OnInit {
    public readonly Object = Object;
    @Input() usageData: any = {};
    usage: typeof usage = usage;

    constructor() {
    }

    ngOnInit(): void {
    }



}

import { Injectable } from '@angular/core';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IAttribute } from '@main/adc/adc-store';

@Injectable({
    providedIn: 'root'
})
export class CustomAttributesService {

    constructor(
        private http: HttpUtilsService,
    ) {
    }

    public getAssetTypes(): Observable<any> {
        const payload = {
            operationName: null,
            query: '{ oBG { getAllAssetTypes {aSSET_TYPE_NAME, aSSET_ID} }}',
            variables: null
        };

        return this.http.post(`graphql`, payload)
            .pipe(
                map(response => response.data.oBG.getAllAssetTypes),
            );
    }

    public getAttributes(): Observable<IAttribute[]> {
        const payload = {
            operationName: null,
            query: '{ oBG { getAdminAssetsTable { aTTRIBUTE_NAME, aTTRIBUTE_ID, aTTRIBUTE_TYPE, aSSETS, aTTR_DISPLAY_ORDER, iS_ACTIVE }}}',
            variables: null,
        };
        return this.http.post('graphql', payload)
            .pipe(
                map(results => {
                    return results.data.oBG.getAdminAssetsTable
                        .map(item => {
                            return {
                                ...item,
                                aSSETS: JSON.parse(item.aSSETS),
                            };
                        });
                }),
            );
    }

	public getAttributesForFilter(): Observable<IAttribute[]> {
		const payload = {
			operationName: null,
			query: '{ oBG { getAdminAssetsTable {  aTTRIBUTE_NAME, aSSETS, iS_ACTIVE }}}',
			variables: null,
		};
		return this.http.post('graphql', payload);
	}


	public setAssetValue(attrId, assetTypeName, enabled): Observable<any> {
        const payload = {
            operationName: null,
            query: 'mutation ($obgObject : ObgEnableAttributeInputType) { obgEnableAttributeMutations (obgObject: $obgObject) { enableAttribute (objectInput: $obgObject) { oBJECT_ID } } }',
            variables: {
                obgObject: {
                    aSSET_TYPE_NAME: assetTypeName,
                    aTTRIBUTE_ID: attrId,
                    iS_ENABLED: enabled
                }
            }
        };

        return this.http.post(`graphql`, payload);
    }

    public addAttributes(attrName: string, attrType: string, assets: string) {
        const payload = {
            operationName: null,
            query: 'mutation ($obgObject : ObgInsertAttributeInputType) { obgAddAttributeMutations (obgObject: $obgObject) { addAttribute (objectInput: $obgObject) { aTTRIBUTE_ID, aSSETS } } }',
            variables: {
                obgObject: {
                    aTTRIBUTE_NAME: attrName,
                    aTTRIBUTE_TYPE: attrType,
                    aSSETS: assets,
                }
            }
        };

        return this.http.post(`graphql`, payload);
    }

    public updateAttributesOrder(attributes) {
        const updatedAttributes = attributes.map((item, i) => {
            return {
                attributeId: item.attributeId,
                displayOrder: i + 1,
            };
        });

        const payload = {
            operationName: null,
            query: 'mutation ($obgObject : ObgUpdateAttributeDisplayOrderInputType) { obgUpdateAttributeDisplayOrderMutations (obgObject: $obgObject) { updateAttributeDisplayOrder (objectInput: $obgObject) { aTTRIBUTE_NAME } } }',
            variables: {
                obgObject: {
                    uPDATED_ATTRIBUTES: JSON.stringify(updatedAttributes),
                }
            }
        };

        return this.http.post(`graphql`, payload);
    }

    public updateAttribute(attributeId, attributeName) {
        const payload = {
            operationName: null,
            query: 'mutation ($obgObject : ObgUpdateAttributeInputType) { obgAttributeMutations (obgObject: $obgObject) { renameAttribute (objectInput: $obgObject) { oBJECT_ID } } }',
            variables: {
                obgObject: {
                    aTTRIBUTE_ID: attributeId,
                    aTTRIBUTE_NAME: attributeName
                }
            }
        };

        return this.http.post(`graphql`, payload);
    }

    disableAttribute(attributeId, attributeActive) {
        const payload = {
            operationName: null,
            query: 'mutation ($obgObject : ObgUpdateAttributeInputType) { obgAttributeMutations (obgObject: $obgObject) { updateAttributeActive (objectInput: $obgObject) { aTTRIBUTE_ID, iS_ACTIVE } } }',
            variables: {
                obgObject: {
                    aTTRIBUTE_ID: attributeId,
                    iS_ACTIVE: attributeActive,
                }
            }
        };

        return this.http.post(`graphql`, payload);
    }
}

import { ISearch } from '@store/search/index';
import { SearchType } from '@shared/enums/searchType';
import { searchActionsTypes } from '@store/search/actions';
import { DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';

export const initialState: ISearch = {
	adc: {
		searchType: SearchType.default,
	}
};

export function advancedSearchReducer(state: ISearch = initialState, action) {
    const _state = {...state};

    switch (action.type) {
        case searchActionsTypes.INIT:
            return {...initialState};
        case searchActionsTypes.SET_SEARCH_TYPE:
            _state[action.location] = {
                ..._state[action.location],
                searchType: action.payload,
            };
            return _state;
        case searchActionsTypes.SET_SEARCH_OBJ_TYPE:
            _state[action.location].searchObjType = action.payload;
            return _state;
        case searchActionsTypes.SET_ADVANCED_SEARCH:
            _state[action.location] = {..._state[action.location], searchType: SearchType.advanced, ...action.payload};
            return _state;
        case searchActionsTypes.SET_ADVANCED_SEARCH_VALUE:
            _state[action.location] = {
                ..._state[action.location],
                searchType: SearchType.advanced,
                searchValues: {...action.payload}
            };
            return _state;
        case searchActionsTypes.SET_ADVANCED_SEARCH_PARAMS:
            _state[action.location] = {
                ..._state[action.location],
                searchType: action.payload.searchType,
                searchValues: action.payload.searchValues,
                searchObjType: action.payload.searchObjType,
            };
            return _state;
        case searchActionsTypes.SET_SEARCH_VALUE:
            return {
                ...state,
                [action.payload.location]: {
                    ...state[action.payload.location],
                    searchType: action.payload.searchType,
                    searchObjType: action.payload.searchObjType,
                    searchValue: action.payload.searchValue,
					tools: action.payload.tools,
                }
            };
        case searchActionsTypes.SET_DEFAULT_SEARCH:
			return {
				...state,
				[action.location]: {
					..._state[action.location],
					searchType: SearchType.default,
					searchDefaultValue: action.payload,
					searchValue: action.payload,
				}
			};

            // _state[action.location] = {
            //     ..._state[action.location],
            //     searchType: SearchType.default,
            //     searchDefaultValue: action.payload,
            //     searchValue: action.payload,
            // };
            // return _state;
        case searchActionsTypes.SET_SEARCH_TAGS:
            _state[action.location] = {..._state[action.location], searchByTag: action.payload};
            return _state;
        case searchActionsTypes.APPLY_ADVANCED_SEARCH:
            _state[action.location] = {..._state[action.location], ...{applyAdvancedSearch: action.payload}};
            return _state;
        case searchActionsTypes.SET_SEARCH_LAYERS:
            _state[action.location] = {..._state[action.location], searchLayers: action.payload};
            return _state;
        case searchActionsTypes.SET_AUTOCOMPLETE_SEARCH_VALUE:
            _state[action.location] = {..._state[action.location], autoCompleteSearchValue: action.payload};
            return _state;
        case searchActionsTypes.SET_AUTOCOMPLETE_RESULTS:
            _state[action.location] = {..._state[action.location], autoCompleteSearchResults: action.payload};
            return _state;
        case searchActionsTypes.SET_SEARCH_DEFAULT_VALUE:
            _state[action.location] = {..._state[action.location], searchInputValue: action.payload};
            return _state;
        case searchActionsTypes.SET_SEARCH_PLACEHOLDER:
            _state[action.location] = {..._state[action.location], searchPlaceholder: action.payload};
            return _state;
        default:
            return _state;
    }
}

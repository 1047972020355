import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'oct-avatar-list-form-control',
	templateUrl: './avatar-list-form-control.component.html',
	styleUrls: ['./avatar-list-form-control.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AvatarListFormControlComponent),
			multi: true
		}
	],
})
export class AvatarListFormControlComponent implements OnInit, ControlValueAccessor {
	@Input() title: string;
	@Input() key: string;
	@Input() listOptions: Array<any> = [];
	@Input() data: any;
	public formControl = new FormControl();

	constructor() {
	}

	ngOnInit(): void {
	}

	onChange: any = () => {
	};

	onTouch: any = () => {
	};

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	writeValue(value): void {
		this.formControl.setValue({key: this.key, value: value, data: this.data});
	}

	onSelected(value) {
		this.onChange({key: this.key, value: value, data: this.data});
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import {
  SearchInputOverlayComponent
} from '@shared/components/search-input/search-input-overlay/search-input-overlay.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@shared/directives/directives.module';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [
		SearchInputComponent,
		SearchInputOverlayComponent,
	],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatAutocompleteModule,
    TranslateModule.forChild(),
    DirectivesModule,
    FormsModule,
  ],
	exports: [
		SearchInputComponent,
		SearchInputOverlayComponent,
	]
})
export class SearchInputModule {
}

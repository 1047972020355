import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnderlineButtonComponent } from '@shared/oct-components/underline-button/underline-button.component';


@NgModule({
  declarations: [UnderlineButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [UnderlineButtonComponent]
})
export class UnderlineButtonModule {
}

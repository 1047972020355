import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface CompareREPORTState {
	selected_connections_ids: string;
	count: string;
	selected_connections_objs: Array<any>;
	properties: object;
	properties_obj: object;
	connections_list: Array<any>;
	connections?: any[];
	connectionsBG?: any[];
}

export const initialCompareREPORTState: CompareREPORTState = {
	selected_connections_ids: getAllCompareREPORTSelectedConnectionsIDs(),
	count: '0',
	selected_connections_objs: [],
	properties: {
		obj_1: undefined,
		val_1: {
			as_full_text: undefined,
			as_multi_values: undefined,
			as_url: undefined,
			value: undefined
		}
	},
	properties_obj: {},
	connections_list: [],
};

export const selectCompareREPORTState = createFeatureSelector<CompareREPORTState>('compare_report');
export const getCompareREPORTProperties = createSelector(selectCompareREPORTState, state => state.properties);
export const getCompareREPORTPropertiesObj = createSelector(selectCompareREPORTState, state => state.properties_obj);
export const getCompareREPORTSelectedConnectionsIDs = createSelector(selectCompareREPORTState, state => state.selected_connections_ids);
export const getCompareREPORTSelectedConnectionsObjs = createSelector(selectCompareREPORTState, state => state.selected_connections_objs);
export const getCompareREPORTCount = createSelector(selectCompareREPORTState, state => state.count);
export const getCompareREPORTConnectionsList = createSelector(selectCompareREPORTState, state => state.connections_list);
export const selectCompareREPORTConnections = createSelector(
	selectCompareREPORTState,
	state => {
		return {
			connections: state.connections,
			connectionsBG: state.connectionsBG,
		};
	});


function getAllCompareREPORTSelectedConnectionsIDs() {
	const allItemsFromLocalStorage = {...localStorage};
	const allCompareREPORTItemsFromLocalStorage = [];
	for (const key in allItemsFromLocalStorage) {
		if (key.startsWith('CR_') && allItemsFromLocalStorage[key] === 'true') {
			const _obj_id = key.substr(key.lastIndexOf('_') + 1);
			allCompareREPORTItemsFromLocalStorage.push(_obj_id);
		}
	}
	return allCompareREPORTItemsFromLocalStorage.toString() || '-1';
}

import { Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SplashScreenService } from '@core/services/splash-screen.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GenericFormModalInterface } from '@shared/forms/generic-form-modal/generic-form-modal.interface';

@Component({
  selector: 'oct-generic-form-modal',
  templateUrl: './generic-form-modal.component.html',
  styleUrls: ['./generic-form-modal.component.scss']
})
export class GenericFormModalComponent implements OnInit {
  public form: FormGroup;
  public applyClose = true;
  @ViewChild('genericFormModalComponentPropertiesContainer', {
    static: true,
    read: ViewContainerRef
  }) genericFormModalComponentPropertiesContainer: ViewContainerRef;
  @ViewChild('genericFormModalAddDataComponentPropertiesContainer', {
    static: true,
    read: ViewContainerRef
  }) genericFormModalAddDataComponentPropertiesContainer: ViewContainerRef;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<GenericFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericFormModalInterface,
    private splashScreenService: SplashScreenService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({});
    // this.form.valueChanges.subscribe(console.log);
    this.createDynamicComponent(this.genericFormModalComponentPropertiesContainer);
  }

  public addData(addDataArray) {
    this.genericFormModalAddDataComponentPropertiesContainer.clear();
    this.createDynamicComponent(this.genericFormModalAddDataComponentPropertiesContainer, addDataArray);
  }

  onSubmit(): void {
    this.dialogRef.close(this.form.getRawValue());
  }

  close(): void {
    this.dialogRef.close();
  }

  private createDynamicComponent(componentRef, addDataArray?) {
    const arr = addDataArray ? addDataArray : this.data;
    arr.formControlData.forEach(row => {
      const formControl = new FormControl('', row.validators);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(row.classComponent);
      const component: any = componentRef.createComponent(componentFactory);
      component.instance.title = row.title;
      component.instance.key = row.key;
      component.instance.itemClick = row.onClick;
      if (row.function) {
        component.instance.data = {
          function: row.function
        };
      }
      if (row.listOptions) {
        component.instance.listOptions = row.listOptions;
      }
      if (row.data) {
        component.instance.data = row.data;
      }

      this.form.addControl(row.key, formControl);
      component.instance.formControl = formControl;
      formControl.valueChanges.subscribe(x => {
      });
    });
  }
}

<button (menuOpened)="onOpen()" [matMenuTriggerFor]="menu" class="notifications-trigger" mat-icon-button
        matTooltip="Notifications">
    <mat-icon [matBadgeHidden]="hideBadge" matBadge="{{unreadCount}}" matBadgeColor="danger"
              matBadgePosition="after"
              matBadgeSize="small"
              svgIcon="notifications"></mat-icon>
</button>

<mat-menu #menu="matMenu" xPosition="before">
    <div (click)="$event.stopPropagation()" [ngClass]="{'no-notifications': notifications.length === 0}"
         class="menu-wrapper">
        <mat-label class="menu-title">Notification Center</mat-label>
        <ng-container *ngIf="!!notifications.length else noNotifications">
            <div *ngFor="let notification of notifications" [disableRipple]="true"
                 mat-menu-item>
                <mat-card>
                    <div class="text-container">{{notification.message}}</div>
                </mat-card>
            </div>
        </ng-container>
    </div>
</mat-menu>

<ng-template #noNotifications>
    <div class="no-notifications-wrapper">
        <mat-label>No Notifications Found</mat-label>
    </div>
</ng-template>

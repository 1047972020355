import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ICheckBoxMap} from '@shared/interfaces/check-box';
import { MatCheckboxChange } from '@angular/material/checkbox';

export enum toggleTypes {
	checkBox,
	icon,
}

@Component({
	selector: 'oct-check-box-map',
	templateUrl: './check-box-map.component.html',
	styleUrls: ['./check-box-map.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckBoxMapComponent),
			multi: true
		}
	],
})
export class CheckBoxMapComponent implements OnInit, ControlValueAccessor {
	public groupedData: any = {};
	public data: ICheckBoxMap[];
	public toggleType = toggleTypes;
	public groupsNames: string[];
	public groupsSubNames = {};
	public groupsList = [];

	@Input() public type: toggleTypes = toggleTypes.checkBox;
	@Input() public useDisplayName = true;
	@Input() public groupBy: string;
	@Input() public applyShowAll = false;
	@Input() public showAll = false;
	@Output() public checkAll = new EventEmitter<boolean>();

	onChange = (map: ICheckBoxMap[]) => {
	}

	constructor() {
	}

	ngOnInit() {
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
	}

	writeValue(data: ICheckBoxMap[]): void {
		if (data) {
			this.data = data;
		}
	}

	change(matCheckboxChange: MatCheckboxChange, item: any) {
		item.checked = matCheckboxChange.checked;
		this.onChange(this.data);
	}
}

import moment from 'moment';

export const DATE_FORMATS = ['YYYY-MM-DD', 'MMM DD, YYYY'];

export enum rangeLabels {
	_7days = 'Last 7 Days',
	_30days = 'Last 30 Days',
	_6months = 'Last 6 Months',
	_12months = 'Last 12 Months',
}

export const DATE_RANGE: any = {
	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	'Last 6 Months': [moment().subtract(6, 'month'), moment()],
	'Last 12 Months': [moment().subtract(12, 'month'), moment()],
};

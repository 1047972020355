<div class="m-widget5 group" *ngIf="usageData  else noData">
	<div *ngFor="let key of Object.keys(usageData)" class="m-widget5__item" style="border: none">
		<div class="m-widget5__content">
			<div class="m-widget5__section">
				<h4 class="m-widget5__title">{{key}}<span class="m-widget5__title" *ngIf="key === usage.performance"> (Median)</span> </h4>
				<div class="m-widget5__desc--bold no-spaces">
					{{usageData[key]}} <span class="m-widget5__desc--bold no-spaces" *ngIf="key === usage.performance">(sec)</span>
					<span *ngIf="usageData[key] ===  null">No Data Available Yet</span>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #noData class="m-widget5 group">No Data Available Yet</ng-template>


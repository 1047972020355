import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericModalComponent } from '@shared/components/generic-modal/generic-modal.component';
import XLSX from 'xlsx';
import { ThousandsCommaPipe } from '@shared/pipes/thousands-comma.pipe';

export interface IExcelTemplate {
  colName: string;
  field?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExcelUtilsService {
  constructor(
    private dialog: MatDialog,
  ) {
  }

  export(excelName, template: IExcelTemplate[], data?: any[], wsName?, quantity?) {
    const thousandsPipe = new ThousandsCommaPipe();
    const total = quantity > 50000 ? thousandsPipe.transform(50000) : thousandsPipe.transform(quantity);
    const dialogRef = this.dialog.open(GenericModalComponent, {
      width: '430px',
      height: '250px',
      disableClose: true,
      backdropClass: 'backdropBackground',
      data: {
        enableClose: false,
        headerIcon: {
          name: 'assets/app/img/loader/octopus.gif',
          width: '90px',
        },
        title: `Exporting ${total} Assets`,
        subTitle: '* Export is limited to 50K results. Use search and filter to export relevant results',
        content: 'Almost there...',
      },
    });

    dialogRef.afterOpened()
      .subscribe(() => {
        const templateColNames = template.map(a => a.colName);
        const templateFieldNames = template.map(a => a.field);
        const excelArr = [templateColNames];
        data?.forEach(a => {
          const dataArr = templateFieldNames.map(b => a[b]);
          excelArr.push(dataArr);
        });
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelArr);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, wsName);
        XLSX.writeFile(wb, `${excelName}.xlsx`);
        dialogRef.close();
      });
  }

  export2(config, template, data) {
    const thousandsPipe = new ThousandsCommaPipe();
    if (config.quantity) {
      const total = config.quantity > 50000 ? thousandsPipe.transform(50000) : thousandsPipe.transform(config.quantity);
    }
    const dialogRef = this.dialog.open(GenericModalComponent, {
      width: '430px',
      height: config.height,
      disableClose: true,
      backdropClass: 'backdropBackground',
      data: {
        enableClose: false,
        headerIcon: {
          name: 'assets/app/img/loader/octopus.gif',
          width: '90px',
        },
        title: config.title || '',
        subTitle: config.subTitle || '',
        content: config.content || '',
      },
    });

    dialogRef.afterOpened()
      .subscribe(() => {
        const templateColNames = template.map(a => a.colName);
        const templateFieldNames = template.map(a => a.field);
        const excelArr = [templateColNames];
        data?.forEach(a => {
          const dataArr = templateFieldNames.map(b => a[b]);
          excelArr.push(dataArr);
        });
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelArr);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, config.wsName || '');
        XLSX.writeFile(wb, `${config.excelName || 'temp'}.xlsx`);
        dialogRef.close();
      });
  }

  /// Export without a dialog
  export3(excelName, template: IExcelTemplate[], data?: any[], wsName?, quantity?) {
    const templateColNames = template.map(a => a.colName);
    const templateFieldNames = template.map(a => a.field);
    const excelArr = [templateColNames];
    data?.forEach(a => {
      const dataArr = templateFieldNames.map(b => a[b]);
      excelArr.push(dataArr);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelArr);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, wsName);
    XLSX.writeFile(wb, `${excelName}.xlsx`);
  }

  multipleDataExport(excelName: string, templates: Array<{
    data: Array<any>,
    template: Array<IExcelTemplate>
  }>, wsName?: string) {
    const excelArr = [];
    templates.forEach((template, index) => {
      const templateColNames = template.template.map(a => a.colName);
      const templateFieldNames = template.template.map(a => a.field);
      excelArr.push(templateColNames);
      template.data.forEach(a => {
        const dataArr = templateFieldNames.map(b => a[b]);
        excelArr.push(dataArr);
      });
      excelArr.push([]);
      excelArr.push([]);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelArr);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, wsName);
    XLSX.writeFile(wb, `${excelName}.xlsx`);
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'oct-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('check') checkBox: ElementRef;
  @Input() checkboxLabel: string;
  @Input() changeFunc: any;
  @Input() checked: boolean = true;
  @Input() disabled: boolean = false;
  @Input() index: number;
  @Input() checkBoxName: string;
  @Input() checkColor: string;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
    // this.index = this.index ? this.index : 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.checked) {
    //   this.checked = changes.checked.currentValue;
    // }
  }

  ngAfterViewInit(): void {
    fromEvent(this.checkBox.nativeElement, 'change')
      .subscribe((event: any) => {
        // console.log('checkbox event', event.target.checked);
        this.checkChanged.emit(event.target.checked);
      });
  }
}

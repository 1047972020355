import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PagesConfig } from '../../config/pages';
import { Router } from '@angular/router';
import * as objectPath from 'object-path';

@Injectable()
export class PageConfigService {
	public configModel: PagesConfig = new PagesConfig();
	public deepCompareUrl: string = "";
	public navigatedUrlPage: string = null;
	public navigatedUrlParam: any = null;
	public backPressTime: boolean = false;
	public generalHosterMode: string = null;
	public onPageUpdated$: BehaviorSubject<PagesConfig> = new BehaviorSubject(
		this.configModel
	);

	//this property is to know what is the module of the selected object
	//public moduleTypeSchema: moduleType; 

	constructor(private router: Router) {
		this.fixDeepCuttedUrls();
	}

	setBackPressTime(forTimeInMs?: number) {
		this.backPressTime = true;
		setTimeout(() => {
			this.backPressTime = false;
		}, (forTimeInMs ? forTimeInMs :1000));
	}

	setModel(menuModel: PagesConfig) {
		this.configModel = Object.assign(this.configModel, menuModel);
		this.onPageUpdated$.next(this.configModel);
	}

	getCurrentPageConfig(): any {
		return objectPath.get(
			this.configModel,
			'config.' + this.router.url.substring(1).replace(/\//g, '.')
		);
	}

	encodeBtoa(id: string): string {
		try {
			return btoa((encodeURIComponent(id)));
		} catch (e) {
			console.log(e);
		}
	}

	decodeBtoa(key: string): string {
		try {
			return decodeURIComponent((atob(key)));
		} catch (e) {
			console.log(e);
		}
	}

	fixDeepCuttedUrls() {
		//check compare vs pages url
		if (document.location.href.indexOf('vs%') > -1 || document.location.href.indexOf('vs]') > -1) {
			let deepUrlPart = document.location.href.split("/");
			this.deepCompareUrl = deepUrlPart[deepUrlPart.length - 1];
		}
	}

	//public setModuleType(moduleType) {
	//	this.moduleTypeSchema = moduleType;
	//}

	//public getModuleType(): moduleType {
	//	return this.moduleTypeSchema;
	//}
}

import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const getNotifications = createAction('[NOTIFICATIONS] get notifications');
export const getNotificationsSuccess = createAction('[NOTIFICATIONS] get notifications success', props<{
  result: any
}>());
export const getNotificationsFailure = createAction('[NOTIFICATIONS] get notifications failure', props<{
  error: any
}>());
export const removeNotifications = createAction('[NOTIFICATIONS] remove notifications', props<{ ids: string[] }>());
export const updateNotification = createAction('[NOTIFICATIONS] update notification', props<{
  id: string,
  payload: any
}>());
export const updateNotificationSuccess = createAction('[NOTIFICATIONS] update notification success', props<{
  updates: Update<any>
}>());
export const updateNotificationFailure = createAction('[NOTIFICATIONS] update notification failure', props<{
  error: any
}>());

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICheckBoxMap } from '@shared/interfaces/check-box';

@Component({
  selector: 'oct-check-box-list',
  templateUrl: './check-box-list.component.html',
  styleUrls: ['./check-box-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxListComponent),
      multi: true
    }
  ],
})
export class CheckBoxListComponent implements OnInit, ControlValueAccessor {
  public checkAll = true;
  @Input() data: any;
  @Input() selectAllOption = false;
  @Input() checkBoxListName: string;
  @Output() updateList = new EventEmitter<any>();

  constructor() {
  }

  onChange = (map: ICheckBoxMap[]) => {
  };

  onTouch: any = () => {
  };

  ngOnInit(): void {
  }

  onCheckAllChange(event: any) {
    this.data.forEach((a: any) => a.checked = event.target.checked);
    this.updateList.emit(this.data);
    this.onChange(this.data);
  }

  onCheckChange(item: any, event: any) {
    item.checked = event.target.checked;
    this.checkAll = this.data.find(f => !f.checked) ? false : true;
    this.updateList.emit(this.data);
    this.onChange(this.data);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(list: any): void {
    if (list) {
      this.data = list;
    }
    const foundUnChecked = this.data.find(f => !f.checked);
    this.checkAll = !foundUnChecked;
    this.onChange(list);
  }
}

<button #element (click)="item.click(element._elementRef.nativeElement, item)"
		[disabled]="item.disabled" [matBadgeHidden]="(item.notification$ | async)?.length === 0"
		[ngClass]="{disabled: item.disabled}" [ngStyle]="{width:  item.width || 'auto'}"
		id="{{item.id}}" mat-flat-button matBadge="{{(item.notification$ | async)?.length}}"
		matBadgePosition="before"
		matBadgeSize="small">

	<mat-icon clickOutSideExclude
			  svgIcon="{{item.active || (item.notification$ | async)?.length ? item.nameInActive : item.name}}"
			  class="toolbar-icon" matTooltip="{{item.title}}"
			  [ngStyle]="{'height': item.height || item.iconSize  + 'px', 'width': item.iconSize + 'px'}"
			  [ngClass]="{'inActive': item.active || (item.notification$ | async)?.length,
					  'redo-icon': item.title === 'Redo', 'undo-icon': item.title === 'Undo'}"></mat-icon>
</button>


<!--<mat-icon-->
<!--		  svgIcon="{{item.name}}"-->
<!--		  class="toolbar-icon" matTooltip="{{item.title}}"></mat-icon>-->

<div class="list-properties">
	<div class="row list-properties--container">
		<mat-tab-group  class="matTabGroup">
			<mat-tab label="Properties">
				<div class="list-properties__content col-xl-12">
					<div *ngIf="location == 'e2eColumn'; else other">
						<div class="m-widget5 group">
							<div *ngFor="let obj of c2CDisplayProperties" class="m-widget5__item" style="border: none">
								<div class="m-widget5__content">
									<div class="m-widget5__section">
										<h4 class="m-widget5__title">{{obj.key}}</h4>
										<div class="m-widget5__desc--bold no-spaces">
											{{obj.value}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<ng-template #other>
						<div *ngIf="data?.length == 0 && !propertiesBtnsService.propertiesBtns[0] && !isGroup && !linkBetweenNodes"
							 class="emptyPropsMessageContainer">
							No details were found for this object
						</div>
						<ng-container *ngIf="isGroup; else notGroup">
							<ng-container [ngTemplateOutlet]="link"></ng-container>

							<div class="m-widget5 group">
								<div class="m-widget5__desc bold">Source</div>
								<div *ngFor="let obj of source" class="m-widget5__item">
									<div class="header">{{obj.key}}</div>
									<div class="m-widget5__desc--bold">{{obj.value.value}}</div>
								</div>
							</div>
							<div class="m-widget5 group">
								<div class="m-widget5__desc bold">Target</div>
								<div class="subGroup">
									<div *ngFor="let obj of target" class="m-widget5__item ">
										<div class="header">{{obj.key}}</div>
										<div class="m-widget5__desc--bold">{{obj.value.value}}</div>
									</div>
								</div>

							</div>
						</ng-container>
						<ng-template #notGroup>
							<ng-container [ngTemplateOutlet]="link"></ng-container>

							<div *ngFor="let obj of data | keyvalue" class="m-widget5">
								<div class="m-widget5__item">
									<div class="m-widget5__content">
										<div class="m-widget5__section">
											<h4 [innerHTML]="obj.value.key"
												[ngStyle]="{'max-width': !isExpand ? '100%' : '100%' }"
												class="m-widget5__title"></h4>
											<div *ngIf="obj.value.value.as_full_text == 1"
												 [ngStyle]="{'width': !isExpand ? '270px' : '100%' }"
												 class="m-widget5__desc">
												<oct-code-preview #codePreview
																  class="codePreview"
																  [id]="obj.value.itemNumber"
																  [search]="codePreviewSearchTerm"
																  [tool]="tool"></oct-code-preview>
											</div>
											<div *ngIf="obj.value.value.as_full_text == 0"
												 class="m-widget5__desc--bold no-spaces">
												{{obj.value.value.value}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</ng-template>
				</div>

				<div class="list-properties__actions">
					<div *ngFor="let obj of propertiesBtnsService.propertiesBtns[0] | keyvalue"
						 [ngClass]="{'m-widget5': obj.key < 2}">
						<m-spinner-button (click)="propertiesBtnsService.goTo($event, obj, location, connectionsIds)"
										  *ngIf="location != locations.compareEtls && location != locations.compareReports"
										  [options]="obj.value.spinnerOptions" color="primary"
										  title={{obj.value.tooltip}}>
							<span *ngIf="propertiesBtnsService.disableButtons" class="disabled-btn"></span>
							<i *ngIf="obj.value.title == 'Back' || (obj.value.title.indexOf('Expand') > -1 && obj.value.tooltip.indexOf('Source') > -1)"
							   class="la la-arrow-left btn-backwards"></i>
							{{obj.value.title}}
							<i *ngIf="obj.value.title == 'Fwd' || (obj.value.title.indexOf('Expand') > -1 && obj.value.tooltip.indexOf('Target') > -1)"
							   class="la la-arrow-right btn-forward"></i>
						</m-spinner-button>
					</div>
				</div>
			</mat-tab>

			<mat-tab [disabled]="!showUsage">
				<ng-template mat-tab-label>
     				<span [matTooltip]="!showUsage ? 'No Information Available' : null">
     				  Usage
     				</span>
				</ng-template>
				<oct-usage-content  *ngIf="showUsage" [usageData]="usageData"></oct-usage-content>
			</mat-tab>

		</mat-tab-group>

	</div>
</div>

<ng-template #link>
	<div *ngIf="linkBetweenNodes" class="m-widget5 group">
		<div *ngFor="let obj of linkBetweenNodes" class="m-widget5__item">
			<div class="header">{{obj.key}}</div>
			<div class="m-widget5__desc--bold">{{obj.value.value}}</div>
		</div>
	</div>
</ng-template>

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppConfig } from '@core/services/app-config.service';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, DatePipe, HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { environment } from '@environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from '@core/auth/authentication.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LayoutModule } from '@content/layout/layout.module';
import { PartialsModule } from '@content/partials/partials.module';
import { CoreModule } from '@core/core.module';
import { AclService } from '@core/services/acl.service';
import { LayoutConfigService } from '@core/services/layout-config.service';
import { MenuConfigService } from '@core/services/menu-config.service';
import { PageConfigService } from '@core/services/page-config.service';
import { UserService } from '@core/services/user.service';
import { UtilsService } from '@core/services/utils.service';
import { ClassInitService } from '@core/services/class-init.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { MenuAsideService } from '@core/services/layout/menu-aside.service';
import { ConnectionsList } from '@core/services/connections-list.service';
import { ReferenceTableService } from '@core/services/reference-table.service';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { BrowserService } from '@core/services/browser.service';
import { SideBarService } from '@core/services/layout/side-bar.service';
import { ToastrModule } from 'ngx-toastr';
import { MessengerService } from '@core/services/messenger.service';
import { ClipboardService } from '@core/services/clipboard.sevice';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LayoutConfigStorageService } from '@core/services/layout-config-storage.service';
import { LogsService } from '@core/services/logs.service';
import { QuickSearchService } from '@core/services/quick-search.service';
import { SubheaderService } from '@core/services/layout/subheader.service';
import { HeaderService } from '@core/services/layout/header.service';
import { MenuHorizontalService } from '@core/services/layout/menu-horizontal.service';
import { LayoutRefService } from '@core/services/layout/layout-ref.service';
import { SplashScreenService } from '@core/services/splash-screen.service';
import { DataTableService } from '@core/services/datatable.service';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { PropertiesBtnsService } from '@shared/services/properties-btns.service';
import { UserProfileService } from '@content/layout/header/topbar/user-profile/user-profile.service';
import { LineageService } from '@main/lineage-dashboard/lineage.service';
import { LineageIDService } from '@main/lineage-diagram/lineage-id/lineage-id.service';
import { MapIDService } from '@main/maps/map-id/map-id.service';
import { DiscoveryService } from '@main/discovery/discovery.service';
import { MapsService } from '@main/maps/maps.service';
import { AngularSplitModule } from 'angular-split';
import { SharedContentModule } from '@content/shared-content.module';
import { StoreModule } from '@ngrx/store';
import { UserReducer } from '@core/reducers/user.reducer';
import { DiscoveryReducer } from '@store/discovery/reducer';
import { CommonReducer } from '@store/common/reducers';
import { MsAdalAngular6Module } from 'microsoft-adal-angular6';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { advancedSearchReducer } from '@store/search/reducer';
import { UploadModule } from '@progress/kendo-angular-upload';
import { layoutReducer } from '@store/layout/reducer';
import { CodePreviewReducer } from '@store/code-preview/reducer';
import { VersionCheckService } from '@shared/services/version-check.service';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { ChartsModule } from 'ng2-charts';
import { Router, RouterModule } from '@angular/router';
import * as Sentry from '@sentry/angular';
import * as adcReducer from '@main/adc/adc-store/adc/reducer';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { AppHttpInterceptor } from '@app/app.interceptor';
import { EffectsModule } from '@ngrx/effects';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

const globalRippleConfig: RippleGlobalOptions = {
	disabled: true
};

export function initializeApp(appConfig: AppConfig) {
	return () => appConfig.load();
}

export function getAdalConfig() {
	return {
		tenant: AppConfig.settings.tenant || '',
		clientId: AppConfig.settings.clientId || 1,
		redirectUri: AppConfig.settings.redirectUri || '',
		showAzureLogin: AppConfig.settings.showAzureLogin || false,
		oktaLogin: AppConfig.settings.oktaLogin || false,
		extraQueryParameter:  AppConfig.settings.showAzureLogin ? 'scope=openid%20email' : '',
		endpoints: {
			'https://localhost:4200/Api/': 'xxx-bae6-4760-b434-xxx',
		},
		navigateToLoginRequestUrl: false,
		cacheLocation: 'localStorage',
	};
}

export function OKTAConfig() {
	return {
		issuer: AppConfig.settings.oktaIssuer || 'https://octopai2.okta.com/oauth2/default',
		clientId: AppConfig.settings.oktaClientId || '1',
		redirectUri: AppConfig.settings.oktaRedirectUrl || 'http://localhost:4200',
		responseType: 'id_token',
		pkce: false,
	};
}

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		CommonModule,
		HttpClientModule,
		MsAdalAngular6Module.forRoot(getAdalConfig),
		OktaAuthModule,
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		MatIconModule,
		LayoutModule,
		PartialsModule,
		CoreModule,
		OverlayModule,
		AuthenticationModule,
		NgxPermissionsModule.forRoot(),
		NgbModule,
		TranslateModule.forRoot(),
		ToastrModule.forRoot({
			maxOpened: 1,
			preventDuplicates: true,
		}),
		MatProgressSpinnerModule,
		FormsModule,
		AngularSplitModule.forRoot(),
		SharedContentModule,
		StoreModule.forRoot({
			adc: adcReducer.reducer,
			user: UserReducer,
			discovery: DiscoveryReducer,
			common: CommonReducer,
			search: advancedSearchReducer,
			layout: layoutReducer,
			code_preview: CodePreviewReducer,
		}, {
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false,
			}
		}),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode
		}),
		UploadModule,
		ChartsModule,
	],
	providers: [
		AppConfig,
		{provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [AppConfig],
			multi: true
		},
		{provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
		{provide: OKTA_CONFIG, useFactory: OKTAConfig},
		Location,
		{provide: LocationStrategy, useClass: HashLocationStrategy},
		DatePipe,
		VersionCheckService,
		AclService,
		LayoutConfigService,
		LayoutConfigStorageService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		UserService,
		UtilsService,
		MenuAsideService,
		ConnectionsList,
		ReferenceTableService,
		ModuleUtilsService,
		UserProfileService,
		ClassInitService,
		MessengerService,
		ClipboardService,
		LogsService,
		QuickSearchService,
		DataTableService,
		HttpUtilsService,
		PropertiesBtnsService,
		BrowserService,
		SideBarService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		SubheaderService,
		HeaderService,
		MenuHorizontalService,
		DiscoveryService,
		LineageService,
		LineageIDService,
		MapsService,
		MapIDService,
		{provide: 'googleTagManagerId', useValue: environment.googleTagManagerId},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {
			},
			deps: [Sentry.TraceService],
			multi: true,
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {

		const svgIconConfig = [
			{name: '3dotsGif', path: 'assets/3dots-gif.svg'},
			{name: 'excel', path: 'assets/svg/excel.svg'},
			{name: 'grid', path: 'assets/grid.svg'},
			{name: 'download_excel', path: 'assets/app/img/icons/downloadExcel.svg'},
			{name: 'resize', path: 'assets/app/img/menu-toolbar/resize.svg'},
			{name: 'filter_alt', path: 'assets/svg/filter_alt.svg'},
			{name: 'filter_alt_outlined', path: 'assets/svg/filter_alt_outlined.svg'},
			{name: 'arrowDown', path: 'assets/app/img/icons/arrow-down.svg'},
			{name: 'physical_layer', path: 'assets/physical.svg'},
			{name: 'presentation_layer', path: 'assets/presentation.svg'},
			{name: 'semantic_layer', path: 'assets/semantic.svg'},
			{name: 'report_layer', path: 'assets/report_name.svg'},
			{name: 'chat', path: './assets/app/img/abg/chat.svg'},
			{name: 'shown', path: 'assets/app/img/abg/shown.svg'},
			{name: 'plus', path: 'assets/app/img/abg/plus.svg'},
			{name: 'bookmark', path: 'assets/app/img/abg/bookmark.svg'},
			{name: 'bell', path: 'assets/bell.svg'},
			{name: 'trash', path: 'assets/trash.svg'},
			{name: 'cancel', path: 'assets/cancel.svg'},
			{name: 'save', path: 'assets/save.svg'},
			{name: 'logout', path: 'assets/logout.svg'},
			{name: 'edit-small', path: 'assets/edit-small.svg'},
			{name: 'suspend', path: 'assets/app/img/abg/hiden.svg'},
			{name: 'copy', path: 'assets/copy.svg'},
			{name: 'edit', path: 'assets/pencil.svg'},
			{name: 'approved', path: 'assets/approved.svg'},
			{name: 'enhancedResult', path: 'assets/enhancedResult.svg'},
			{name: 'octopaiRobot', path: 'assets/octopai-robot.svg'},
			{name: 'octopaiRobotBorder', path: 'assets/octopai-robot-border.svg'},
			{name: 'right_arrow', path: 'assets/right_arrow.svg'},
			{name: 'left_arrow', path: 'assets/left_arrow.svg'},
			{name: 'check', path: 'assets/check.svg'},
			{name: 'filter', path: 'assets/filter.svg'},
			{name: 'filter_frame', path: 'assets/filter-icon.svg'},
			{name: 'settings', path: 'assets/settings.svg'},
			{name: 'search', path: 'assets/search.svg'},
			{name: 'usage', path: 'assets/usage.svg'},
			{name: 'calendar', path: 'assets/calendar.svg'},
			{name: 'close', path: 'assets/close.svg'},
			{name: 'reset-filter', path: 'assets/reset-filter.svg'},
			{name: 'inner-system', path: 'assets/inner-system.svg'},
			{name: 'cross-system', path: 'assets/cross-system-disable.svg'},
			{name: 'cross-system-gray', path: 'assets/cross-system.svg'},
			{name: 'e2e-column', path: 'assets/e2e-column.svg'},
			{name: 'sort', path: 'assets/sort.svg'},
			{name: 'pin', path: 'assets/pin.svg'},
			{name: 'etl', path: 'assets/etl.svg'},
			{name: 'db', path: 'assets/db.svg'},
			{name: 'report', path: 'assets/report.svg'},
			{name: 'report_name_layer', path: 'assets/report_name.svg'},
			{name: 'analysis', path: 'assets/analysis.svg'},
			{name: 'question', path: 'assets/question.svg'},
			{name: 'business_layer', path: 'assets/Business.svg'},
			{name: 'drag_indicator', path: 'assets/drag_indicator.svg'},
			{name: 'star', path: 'assets/star.svg'},
			{name: 'full-star', path: 'assets/full_star.svg'},
			{name: 'edit-asset', path: 'assets/edit-asset.svg'},

			// ******************************************************
			// custom asset types icons: set as dynamic in the future
			// ******************************************************
			{name: 'Analysis Asset', path: 'assets/AnalysisAsset.svg'},
			{name: 'Business Asset', path: 'assets/new_design/business.svg'},
			{name: 'Custom Asset Type', path: 'assets/custom_asset.svg'},
			{name: 'Data Set', path: 'assets/DataSet.svg'},
			{name: 'Database Asset', path: 'assets/Database.svg'},
			{name: 'ETL Asset', path: 'assets/ETLAsset.svg'},
			{name: 'Master Asset', path: 'assets/new_design/master.svg'},
			{name: 'Policy', path: 'assets/Policy.svg'},
			{name: 'Project', path: 'assets/Project.svg'},
			{name: 'Report Asset', path: 'assets/report_asset.svg'},
			{name: 'Data Catalog', path: 'assets/data_catalog.svg'},

			{name: 'analysis-enabled', path: 'assets/analysis_orange.svg'},
			{name: 'business-enabled', path: 'assets/business_orange.svg'},
			{name: 'custom-enabled', path: 'assets/custom_asset_orange.svg'},
			{name: 'data-set-enabled', path: 'assets/data_set_orange.svg'},
			{name: 'database-enabled', path: 'assets/database_orange.svg'},
			{name: 'etl-enabled', path: 'assets/etl_orange.svg'},
			{name: 'master-enabled', path: 'assets/master_asset_orange.svg'},
			{name: 'policy-enabled', path: 'assets/policy_orange.svg'},
			{name: 'project-enabled', path: 'assets/project_orange.svg'},
			{name: 'report-enabled', path: 'assets/reports_orange.svg'},
			{name: 'data-catalog-enabled', path: 'assets/data_catalog_orange.svg'},

			{name: 'analysis-disabled', path: 'assets/analysis_gray.svg'},
			{name: 'business-disabled', path: 'assets/business_gray.svg'},
			{name: 'custom-disabled', path: 'assets/custom_asset_gray.svg'},
			{name: 'data-set-disabled', path: 'assets/data_set_gray.svg'},
			{name: 'database-disabled', path: 'assets/database_gray.svg'},
			{name: 'etl-disabled', path: 'assets/etl_gray.svg'},
			{name: 'master-disabled', path: 'assets/master_asset_gray.svg'},
			{name: 'policy-disabled', path: 'assets/policy_gray.svg'},
			{name: 'project-disabled', path: 'assets/project_gray.svg'},
			{name: 'report-disabled', path: 'assets/reports_gray.svg'},
			{name: 'data-catalog-disabled', path: 'assets/data_catalog_gray.svg'},
			// ******************************************************

			// *********
			// Data Catalog Dashboard
			{name: 'plus', path: 'assets/new_design/plus.svg'},
			{name: 'recycle', path: 'assets/new_design/recycle.svg'},
			{name: 'stroke_eye', path: 'assets/new_design/stroke_eye.svg'},
			{name: 'trash', path: 'assets/new_design/trash.svg'},
			{name: 'pen', path: 'assets/new_design/pen.svg'},
			{name: 'outlined-star', path: 'assets/new_design/outlined-star.svg'},
			{name: 'person', path: 'assets/new_design/person.svg'},
			// *********

			{name: 'physical', path: 'assets/new_design/physical.svg'},
			{name: 'semantic', path: 'assets/new_design/semantic.svg'},
			{name: 'presentation', path: 'assets/new_design/presentation.svg'},
			{name: 'adc_business', path: 'assets/new_design/business.svg'},
			{name: 'adc_analysis', path: 'assets/new_design/analysis_custom.svg'},
			{name: 'custom', path: 'assets/custom_asset_gray.svg'},
			{name: 'adc_dataset', path: 'assets/new_design/data-set.svg'},
			{name: 'adc_database', path: 'assets/new_design/database_custom.svg'},
			{name: 'adc_etl', path: 'assets/new_design/etl_custom.svg'},
			{name: 'adc_master', path: 'assets/new_design/master.svg'},
			{name: 'adc_policy', path: 'assets/new_design/policy.svg'},
			{name: 'adc_project', path: 'assets/new_design/project.svg'},
			{name: 'adc_report', path: 'assets/new_design/report_custom.svg'},
			{name: 'adc_datacatalog', path: 'assets/new_design/datacatalog.svg'},
			{name: 'data catalog', path: 'assets/new_design/datacatalog.svg'},
			{name: 'edit-property', path: 'assets/new_design/edit.svg'},
			{name: 'copy-property', path: 'assets/new_design/copy.svg'},
			{name: 'remove', path: 'assets/new_design/remove.svg'},
			{name: 'new_etl', path: 'assets/new_design/new_etl.svg'},
			{name: 'adc', path: 'assets/automated-data-catalog.svg'},
			{name: 'discovery', path: 'assets/new_design/discovery.svg'},
			{name: 'e2eColumnLineage', path: 'assets/new_design/e2eColumnLineage.svg'},
			{name: 'innerSystemLineage', path: 'assets/new_design/innerSystemLineage.svg'},
			{name: 'crossSystemLineage', path: 'assets/new_design/crossSystemLineage.svg'},
			{name: 'crossSystemLineageColor', path: 'assets/cross_coloring.svg'},
			{name: 'innerSystemLineageColor', path: 'assets/inner_coloring.svg'},
			{name: 'e2eColumnLineageColor', path: 'assets/e2e_coloring.svg'},
			{name: 'discoveryColor', path: 'assets/discovery_coloring.svg'},
			{name: 'compareReports', path: 'assets/cross_system_report_compare.svg'},
			{name: 'compareETLs', path: 'assets/cross_system_etl_compare.svg'},
			{name: 'plus', path: 'assets/plus.svg'},
			{name: 'plus-black', path: 'assets/plus-black-background.svg'},
			{name: 'plus-black-color', path: 'assets/plus-black-background-coloring.svg'},
			{name: 'close-icon', path: 'assets/new_design/close.svg'},
			{name: 'posts-icon', path: 'assets/posts-icon.svg'},
			{name: 'connect', path: 'assets/connect.svg'},
			{name: 'flag', path: 'assets/flag.svg'},
			{name: 'dots_vertical', path: 'assets/dots_vertical.svg'},
			{name: 'dots_horizontal', path: 'assets/dots_horizontal.svg'},
			{name: 'home', path: 'assets/app/img/breadcrumbs/home.svg'},
			{name: 'info', path: 'assets/info.svg'},
			{name: 'arrowRight', path: 'assets/app/img/icons/arrow_right.svg'},
			{name: 'saveToPNG', path: 'assets/download-image.svg'},
			{name: 'redo', path: 'assets/redo.svg'},
			{name: 'zoomIn', path: 'assets/zoom-in.svg'},
			{name: 'zoomOut', path: 'assets/zoom-out.svg'},
			{name: 'download', path: 'assets/download.svg'},
			{name: 'grayInfoCircle', path: 'assets/info-circle_gray.svg'},

			////
			{name: 'clear', path: 'assets/clear.svg'},
			{name: 'play', path: 'assets/playScript.svg'},
			{name: 'editLineage', path: 'assets/editLineage.svg'},
			{name: 'copyScript', path: 'assets/copy.svg'},

			{name: 'recent-activities', path: 'assets/recent_activity_toggle.svg'},
			{name: 'questionMark', path: 'assets/question_mark.svg'},
			{name: 'robot', path: 'assets/robot.svg'},
			{name: 'errorDetectionAndSuggestion', path: 'assets/errorDetectionAndSuggestion.svg'},
			{name: 'optimization', path: 'assets/optimization.svg'},
			{name: 'interpretation', path: 'assets/interpretation.svg'},
			{name: 'migrationAssistance', path: 'assets/migrationAssistance.svg'},
			{name: 'stop', path: 'assets/stop.svg'},
			{name: 'cycle', path: 'assets/cycle.svg'},

			{name: 'error', path: 'assets/error.svg'},

			{name: 'folder', path: 'assets/folder.svg'},
			{name: 'file-txt', path: 'assets/file-text.svg'},
			{name: 'file-zip', path: 'assets/file-zip.svg'},
			{name: 'file-pdf', path: 'assets/file-pdf.svg'},
			{name: 'file-doc', path: 'assets/file-doc.svg'},

			{name: 'timer', path: 'assets/timer.svg'},
			{name: 'test', path: 'assets/test.svg'},
			{name: 'run', path: 'assets/run.svg'},
			{name: 'add', path: 'assets/add.svg'},
			{name: 'schedule', path: 'assets/schedule.svg'},
			{name: 'success', path: 'assets/success.svg'},
			{name: 'error', path: 'assets/error.svg'},
			{name: 'arrow-circle-down', path: 'assets/arrow-circle-down.svg'},
			{name: 'unknown', path: 'assets/question-mark.svg'},
			{name: 'plug', path: 'assets/plug-connect.svg'},
			{name: 'sand-clock', path: 'assets/sand-clock.svg'},
		];

		svgIconConfig.forEach(item => {
			this.iconRegistry.addSvgIcon(
				item.name,
				this.sanitizer.bypassSecurityTrustResourceUrl(item.path));
		});
	}
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SplashScreenService } from '@core/services/splash-screen.service';
import { ConfirmDataItem } from './confirm-data-item';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'oct-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
	insertOrUpdateFormGroup: FormGroup;

	constructor(
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDataItem,
		private splashScreenService: SplashScreenService,
	) { }

	ngOnInit() { }

	onSubmit(): void {
		this.data.submit = true;
		this.close();
	}

	close(): void {
		// this.splashScreenService.setLoaderOn();
		this.dialogRef.close(this.data);
	}
}

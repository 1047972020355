import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { UtilsService } from '@core/services/utils.service';
import { MenuAsideService } from '@core/services/layout/menu-aside.service';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { Store, select } from '@ngrx/store';
import { CommonState, getLocation } from '@store/common';
import { ConnectionsList } from '@core/services/connections-list.service';
import { getUser, UserState } from '@core/states/user.state';


@Component({
	selector: 'm-first-delivery-dialog',
	templateUrl: './first-delivery-dialog.component.html',
	styleUrls: ['./first-delivery-dialog.component.scss']
})
export class FirstDeliveryDialogComponent implements OnInit {
	private today;
	private diffDays: number;
	private location: string;
	public octopai_user: boolean = false;
	public isTrainingMsg: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private router: Router,
		public dialogRef: MatDialogRef<FirstDeliveryDialogComponent>,
		private utilsService: UtilsService,
		private datePipe: DatePipe,
		private menuAsideService: MenuAsideService,
		private storeCommon: Store<CommonState>,
		private moduleUtilsService: ModuleUtilsService,
		private connectionsList: ConnectionsList,
		private storeUser: Store<UserState>,
	) {
		this.dialogRef.disableClose = true;
		this.today = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');

		this.storeUser
			.pipe(
				select(getUser),
			)
			.subscribe(user => {
				if (user['email'].indexOf('@octopai.com') > -1)
					this.octopai_user = true;
			})
	}

	ngOnInit() {
		this.diffDays = this.utilsService.calculateDiff(this.today, this.data.END_DATE);

		this.storeCommon.select(getLocation).subscribe(location => {
			this.location = this.moduleUtilsService.getLocationPreferredKey(location);
		});

		if (this.diffDays <= 0 && this.data.VALUE_1 && this.data.VALUE_1.toUpperCase() == 'READY')
			this.isTrainingMsg = true;
	}

	private goToDiscovery(): void {
		if (this.menuAsideService.changeLocation('discovery')) this.connectionsList.getConnections();
		this.router.navigate(['discovery']);
		this.dialogRef.close();
	}

	private close(): void {
		if (this.octopai_user)
			this.dialogRef.close();
	}
}

import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { moduleType } from '@shared/enums/module-type.enum';
@Component({
    selector: 'oct-monitor',
    templateUrl: './monitor.component.html',
    styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit {
    @HostBinding('class') classes = 'm-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width';
    public sort: SortDescriptor[] = [];
    @ViewChild(GridComponent) public grid: GridComponent;
    @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
    public gridView: GridDataResult;
    public modalClosed: boolean = true;
    API_ENDPOINT = 'clientQueries/getConnections';
    fullTableConnections: any;
    connections: any;
    columns: any[];
    private getDataSubject$ = new Subject();

    constructor(private httpUtilsService: HttpUtilsService
    ) {
    }

    ngOnInit() {
        this.getSched();
    }

    public closeMonitor() {
        this.modalClosed = true;
    }

    public openMonitor() {
        this.modalClosed = !this.modalClosed;
    }

    getSched(): void {
        this.httpUtilsService.post('clientQueries/getConnections')
            .subscribe(this.setMonitorResults.bind(this));
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.loadConnections();
    }

    public showTooltip(e: MouseEvent): void {
        const element = e.target as HTMLElement;
        if ((element.nodeName === 'TD' || element.nodeName === 'TH')
            && element.offsetWidth < element.scrollWidth) {
            this.tooltipDir.toggle(element);
        } else {
            this.tooltipDir.hide();
        }
    }

    public getFormatedDate(date) {
        if (date === '') {
            return 'In Progress';
        }
        const d = new Date(date);
        const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: 'numeric'};
        return d.toLocaleDateString('en-US', options);
    }

	private setMonitorResults(response: any) {
		this.fullTableConnections = (JSON.parse(response.res)).filter(item => item.CONNECTION_ID !== 888);
		this.connections = this.fullTableConnections.map(item => ({
			System: item.CONNECTION_TYPE || '',
			Connection_name:  item.CONNECTION_NAME || '',
			Connection_id:  item.CONNECTION_ID || '',
			Status: item.SCHD_STATUS ? item.SCHD_STATUS.toLowerCase() : '',
			LastRefreshed: item.SCHD_DATETIME ? new Date(item.SCHD_DATETIME) : '',
			isEtl: item.IS_ETL || '',
			type: item.MODULE_TYPE || '',
		}));

		const utilsArray = [];
		this.connections.forEach(connection => {
			if (connection.isEtl === 1 && (connection.type === moduleType.DB || connection.type === moduleType.DATABASE)) {
				const newConnection = JSON.parse(JSON.stringify(connection));
				newConnection.System = newConnection.System + ' - Stored Procedures';
                newConnection.LastRefreshed = connection.LastRefreshed;
                utilsArray.push(newConnection);
			}
		});
		this.connections = [...this.connections, ...utilsArray];
		this.columns = [
			{field: 'Connection_id', title: 'ID', width: '60px'},
			{
				field: 'Connection_name',
				title: 'Connection Name',
				width: '170px'
			},
			{field: 'System', title: 'System', width: '200px'}, {
				field: 'Status',
				title: 'Status',
				width: '60px'
			},
			{field: 'LastRefreshed', title: 'Last Refreshed', width: '130px'}
		];
		this.sortChange([{'dir': 'desc', 'field': 'Connection_id'}]);
	}

	private loadConnections(): void {
		this.gridView = {
			data: orderBy(this.connections, this.sort),
			total: this.connections.length
		};
	}
}


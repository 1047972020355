import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable } from 'rxjs';
import * as objectPath from 'object-path';
import { Store } from '@ngrx/store';

import { CommonState, getLocation } from '@store/common';
import { Locations } from '@shared/enums/locations.enum';

@Component({
	selector: 'm-portlet',
	templateUrl: './portlet.component.html',
	styleUrls: ['./portlet.component.scss'],
})
export class PortletComponent implements OnInit, AfterViewInit {
	@Input() loading$: Observable<boolean>;
	@Input() options: any;
	@Input() module: string;
	public location: string;

	@ViewChild('mPortlet') elPortlet: ElementRef;
	@ViewChild('mPortletHead') elHead: ElementRef;
	@ViewChild('mPortletBody') elBody: ElementRef;
	@ViewChild('mPortletFooter') elFooter: ElementRef;
	@ViewChild('mPortletHeadTools') elHeadTools: ElementRef;

	constructor(
		private storeCommon: Store<CommonState>,
		public loader: LoadingBarService
	) {
		this.loader.complete();
	}

	ngAfterViewInit(): void {
		// hide portlet footer if no content
		if (this.elFooter && this.elFooter.nativeElement.children.length === 0) {
			this.elFooter.nativeElement.style.display = 'none';
			this.elPortlet.nativeElement.classList.add('m-portlet--full-height');
		}
		// add portlet responsive mobile for sticky portlet
		if (objectPath.get(this.options, 'enableSticky')) {
			this.elPortlet.nativeElement.classList.add('m-portlet--responsive-mobile');
		}
		// hide portlet header tools if no content
		if (this.elHeadTools && this.elHeadTools.nativeElement.children.length === 0) {
			this.elHeadTools.nativeElement.style.display = 'none';
		}
	}

	ngOnInit() {
		this.storeCommon.select(getLocation).subscribe(location => {
			this.location = location;
		});
	}
}

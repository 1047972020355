
export enum schemaType {
	Map,
	Lineage,
	Discovery
}

export enum subSchemaType {
	ControlFlow,
	LoopContainer,
	ExecuteTask
}

export enum tableAs {
	Source,
	Target,
	Transform
}

export enum buttonsNames {
	SequenceContainerView = 'Container View',
	PackageView = 'Package View',
	MapView = 'Inner System Lineage',
	ReportView = 'Report View',
	LineageETL = 'Lineage ETL',
	LineageReport = 'Lineage Report',
	LineageObject = 'Lineage Object',
	Back = 'Backward',
	Fwd = 'Forward',
	Expand = 'Expand',
	Collapse = 'Collapse',
	Upstream = 'Upstream',
	Downstream = 'Downstream',
  focusPathAnalysisToggle = 'Focus Path Analysis Toggle'
}

import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { TextDirectionDirective } from './text-direction.directive';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    TextDirectionDirective,
  ],
  exports: [
    ClickOutsideDirective,
    TextDirectionDirective,
  ]
})
export class DirectivesModule {
}

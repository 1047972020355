import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PropertiesCheckBoxListComponent
} from '@shared/oct-components/properties-check-box-list/properties-check-box-list.component';
import { OctCheckBoxModule } from '@shared/oct-components/check-box/check-box.module';
import { ButtonToggleModule } from '@shared/oct-components/button-toggle/button-toggle.module';


@NgModule({
  declarations: [PropertiesCheckBoxListComponent],
  imports: [
    CommonModule,
    OctCheckBoxModule,
    ButtonToggleModule,
  ],
  exports: [PropertiesCheckBoxListComponent]
})
export class PropertiesCheckBoxListModule {
}

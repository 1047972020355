import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IDic, IPublicSettings } from '@core/interfaces/store-data';

export interface IConnection {
	connection_id: number;
	connection_name?: string;
	isBG?: boolean;
	is_checked?: boolean | string;
	module_type: string;
	tool: string;
	toolLongName?: string;
}

export interface CommonState {
	loading: boolean;
	location: string; // lineage || discovery || compare
	dic: IDic;
	public_settings: IPublicSettings;
	isScanning: boolean;
	allConnections: Array<object>;
	loggedInUrl?: string;
	// recent_activities: Array<any>;
	// recent_activities_is_open: boolean;
	connectionsDisabled: boolean;
	legendMapDisplay: boolean;
}

export const selectCommonState = createFeatureSelector<CommonState>('common');
export const getLocation = createSelector(selectCommonState, state => state.location);
export const getDic = createSelector(selectCommonState, state => state.dic);
export const getPublicSettings = createSelector(selectCommonState, state => state.public_settings);
export const getIsScanning = createSelector(selectCommonState, state => state.isScanning);
export const getAllConnections = createSelector(selectCommonState, state => state.allConnections);
export const selectLoading = createSelector(selectCommonState, state => state.loading);
export const selectConnectionsDisabled = createSelector(selectCommonState, state => state.connectionsDisabled);
export const selectDisplayMapLegend = createSelector(selectCommonState, state => state.legendMapDisplay);

// Recent Activities
export interface IRecentActivitiesInitialState {
	items: Array<any>;
	isOpen: boolean;
}

export const selectRecentActivitiesState = createFeatureSelector<IRecentActivitiesInitialState>('recentActivities');
export const selectRecentActivities = createSelector(selectRecentActivitiesState, state => state.items);
export const selectRecentActivitiesIsOpen = createSelector(selectRecentActivitiesState, state => state.isOpen);

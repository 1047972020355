import { AfterViewInit, Component, ElementRef, HostBinding, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassInitService } from '@core/services/class-init.service';
import { LayoutConfigService } from '@core/services/layout-config.service';
import { LayoutRefService } from '@core/services/layout/layout-ref.service';
import { MenuAsideService } from '@core/services/layout/menu-aside.service';
import { UtilsService } from '@core/services/utils.service';
import { DOCUMENT } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { getLocation } from '@store/common';
import { SideBarService } from '@core/services/layout/side-bar.service';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { Locations } from '@shared/enums/locations.enum';
import { ConnectionsList } from '@core/services/connections-list.service';
import { SplashScreenService } from '@core/services/splash-screen.service';
import * as CommonActions from '@store/common/actions';
import { getUserPermissions, selectUserState } from '@core/states/user.state';
import { MenuConfig } from '@app/config/menu';
import { MenuConfigService } from '@core/services/menu-config.service';
import { filter, take } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { MENU_SPACES } from '@content/layout/side-menu/side-menu.consts';

export interface IMenuSpace {
  id: number;
  title: string;
  titleShort: string;
}

@Component({
  selector: 'oct-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, AfterViewInit {

  @HostBinding('class') classes = 'm-grid__item m-aside-left';
  insideTm: any;
  outsideTm: any;
  location: string;
  spaces = MENU_SPACES;
  private showModulesArr = [];

  constructor(
    private el: ElementRef,
    public classInitService: ClassInitService,
    public menuAsideService: MenuAsideService,
    public layoutConfigService: LayoutConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private layoutRefService: LayoutRefService,
    private store: Store<any>,
    private utilsService: UtilsService,
    private sideBarService: SideBarService,
    private moduleUtilsService: ModuleUtilsService,
    private connectionsList: ConnectionsList,
    private splashScreenService: SplashScreenService,
    private menuConfigService: MenuConfigService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    // this.classes = 'm-grid__item m-aside-left m-aside-left--skin-light';
  }

  async ngOnInit() {
    const user = await this.store.pipe(select(selectUserState), take(1)).toPromise();
    // console.log(user);

    this.store
      .pipe(
        select(getUserPermissions),
        filter(userPermissions => !!userPermissions),
      )
      .subscribe(userPermissions => {
        let permissions = userPermissions.split(';').filter(a => a);
         if (user.email === 'support@octopai.com') {
           permissions = environment.fullPermissionsList.split(';').filter(a => a);
         }
        const model = new MenuConfig();
        const items = model.config.aside.items.filter(item => permissions.includes(item.permission));
        this.menuAsideService.menuList$.next(items);
      });
  }

  ngAfterViewInit(): void {
    this.store
      .pipe(select(getLocation))
      .subscribe(location => {
        this.location = location;
        this.setActive(location);
        this.menuAsideService.updateBadgeValue();
      });
  }

  isMenuItemIsActive(item): boolean {
    if (item.submenu) {
      return this.isMenuRootItemActive(item);
    } else {
      switch (this.location) {
        case Locations.adcDashboard:
        case Locations.adc:
          return this.location === item.page;
        default:
          if (item.location_name === this.location) {
            return true;
          }
      }
    }
  }

  isMenuRootItemActive(item): boolean {
    let result: boolean = false;

    for (const subItem of item.submenu) {
      result = this.isMenuItemIsActive(subItem);
      if (result) {
        return true;
      }
    }
    return false;
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter(e: Event) {
    // check if the left aside menu is fixed
    if (this.document.body.classList.contains('m-aside-left--fixed')) {
      if (this.outsideTm) {
        clearTimeout(this.outsideTm);
        this.outsideTm = null;
      }
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave(e: Event) {
    if (this.document.body.classList.contains('m-aside-left--fixed')) {
      if (this.insideTm) {
        clearTimeout(this.insideTm);
        this.insideTm = null;
      }

      this.outsideTm = setTimeout(() => {
        // if the left aside menu is expand
        if (this.document.body.classList.contains('m-aside-left--minimize-hover') && mUtil.isInResponsiveRange('desktop')) {
          // hide back the left aside menu
          this.document.body.classList.remove('m-aside-left--minimize-hover');
          this.document.body.classList.add('m-aside-left--minimize');
        }
      }, 500);
    }
  }

  public navigate(page): void {
    this.sideBarService.close('connections');
    this.store.dispatch(new CommonActions.UpdateLocation(this.location));
    this.setActive(this.location);
    this.router.navigate([page]);
  }

  private setActive(page) {
    const temp = this.menuAsideService.menuList$.value.map(item => {
      item.active = [item.name, item.page].includes(page);
      return item;
    });
    this.menuAsideService.menuList$.next(temp);
  }
}

export const filtersMap = {
	Contains: 'Contains',
	Not_contains: 'Not contains',
	Equal: 'Equal to',
	Not_equal: 'Not equal to',
	Starts_with: 'Starts with',
	Ends_with: 'Ends with'
};

export enum filterFunctions {
	contains = 'Contains',
	notContains = 'Not_contains',
	equal = 'Equal',
	notEqual = 'Not_equal',
	startsWith = 'Starts_with',
	endsWith = 'Ends_with',
}

// ** Operators **
// Contains, Not contains, Equal, Not equal, Starts with, Ends with

export const filterOperatorMap = {
	Contains: 'Contains',
	Not_contains: 'Not contains',
	Equal: 'Equal',
	Not_equal: 'Not equal',
	Starts_with: 'Starts with',
	Ends_with: 'Ends with',
};

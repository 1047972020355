import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuConfigService } from '../menu-config.service';
import { ClassInitService } from '../class-init.service';
import * as objectPath from 'object-path';
import { LayoutConfigService } from '../layout-config.service';
import { select, Store } from '@ngrx/store';
import { CommonState, getLocation } from '@store/common';
import { getLineageCount, LineageState } from '@store/lineage';
import { DiscoveryState, getDiscoveryCount } from '@store/discovery';
import { CompareETLState, getCompareETLCount } from '../../states/compare-etl.state';
import { CompareREPORTState, getCompareREPORTCount } from '@core/states/compare-report.state';
import { UtilsService } from '@core/services/utils.service';
import { UserState } from '@core/states/user.state';
import { filter } from 'rxjs/operators';
import * as CommonActions from '@store/common/actions';
import { Locations } from '@shared/enums/locations.enum';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { IMenuItem } from '@core/interfaces/config';

@Injectable({
    providedIn: 'root',
})
export class MenuAsideService {
    classes: string;
    menuList$: BehaviorSubject<IMenuItem[]> = new BehaviorSubject([]);
    public connectionsCounter$ = new Subject<string>();

    isDropdown: number = 0;
    dropdownTimeout: number;
    isScrollable: number = 0;

    private showModulesArr = [];
    private location;
    private count: string;

    constructor(
        private menuConfigService: MenuConfigService,
        private classInitService: ClassInitService,
        private layoutConfigService: LayoutConfigService,
        private utilsService: UtilsService,
        private storeCommon: Store<CommonState>,
        private storeLineage: Store<LineageState>,
        private storeDiscovery: Store<DiscoveryState>,
        private storeCompareRTL: Store<CompareETLState>,
        private storeCompareREPORT: Store<CompareREPORTState>,
        private storeCompareETL: Store<CompareETLState>,
        private storeUser: Store<UserState>,
        private moduleUtilsService: ModuleUtilsService
    ) {
        this.storeCommon
            .pipe(
                select(getLocation)
            )
            .subscribe(location => {
                this.location = this.moduleUtilsService.getLocationPreferredKey(location);
            });

        // get menu list
        this.menuConfigService.onMenuUpdated$
            .pipe(
                filter(model => !!this.showModulesArr.length && !!model.config.aside.items.length),
            )
            .subscribe(model => {
                this.menuList$.next(objectPath.get(model.config, 'aside.items'));
            });

        // this.storeUser
        //     .pipe(
        //         select(getUserPermissions),
        //     )
        //     .subscribe(userPermissions => {
        //         if (!userPermissions) {
        //             this.storeCommon
        //                 .pipe(
        //                     select(getPublicSettings)
        //                 )
        //                 .subscribe(publicSettings => {
        //                     if (publicSettings.showModules) {
        //                         this.showModulesArr = publicSettings.showModules.split(';').filter(a => a);
        //                     }
        //                 });
        //         } else {
        //             this.showModulesArr = userPermissions.split(';').filter(a => a);
        //         }
        //         this.setMenu();
        //     });

        this.layoutConfigService.onLayoutConfigUpdated$.subscribe(config => {
            if (objectPath.get(config, 'config.aside.left.fixed')) {
                this.isScrollable = 1;
                this.isDropdown = 0;
            }

            // tslint:disable-next-line:max-line-length
            if (!objectPath.get(config, 'config.aside.left.fixed') && !objectPath.get(config, 'config.menu.aside.desktop_and_mobile.submenu.accordion')) {
                this.isScrollable = 0;
                this.isDropdown = 1;
                this.dropdownTimeout = objectPath.get(config, 'config.menu.aside.desktop_and_mobile.submenu.dropdown.hover_timeout');
            }
        });
    }

    // private setMenu() {
    //     this.menuConfigService.configModel = new MenuConfig();
    //     const model = this.menuConfigService.configModel;
    //     this.menuConfigService.onMenuUpdated$.next(model);
    // }

    public updateBadgeValue() {
        this.count = '';

        switch (this.location) {
            case Locations.lineage:
            case Locations.map:
            case Locations.lineageSchema:
			case Locations.e2eColumn:
            case Locations.e2eColumnDashboard:
                this.storeLineage
                    .pipe(
                        select(getLineageCount),
                    )
                    .subscribe(count => {
                        this.count = count;
                    });
                break;
            case Locations.discovery:
                this.storeDiscovery
                    .pipe(
                        select(getDiscoveryCount),
                    )
                    .subscribe(count => {
                        this.count = count;
                    });
                break;
            case Locations.compareEtls:
                this.storeCompareRTL
                    .pipe(
                        select(getCompareETLCount),
                    )
                    .subscribe(count => {
                        this.count = count;
                    });
                break;
            case Locations.compareReports:
                this.storeCompareREPORT
                    .pipe(
                        select(getCompareREPORTCount),
                    )
                    .subscribe(count => {
                        this.count = count;
                    });
        }

        this.connectionsCounter$.next(this.count);
    }

    private getSelector() {
        switch (this.location) {
            case Locations.lineage:
                return getLineageCount;
            case Locations.discovery:
                return getDiscoveryCount;
            case Locations.compareEtls:
                return getCompareETLCount;
            case Locations.compareReports:
                return getCompareREPORTCount;
        }
    }

    private getStore(): string {
        switch (this.location) {
            case Locations.lineage:
                return 'storeLineage';
            case Locations.discovery:
                return 'storeDiscovery';
            case Locations.compareEtls:
                return 'storeCompareETL';
            case Locations.compareReports:
                return 'storeCompareREPORT';
            default:
                return null;
        }
    }

    public changeLocation(page): boolean {
        this.storeCommon.dispatch(new CommonActions.UpdateLocation(page));
        let _count;
        const store = this.getStore();

        if (store) {
            this[store]
                .pipe(
                    select(this.getSelector()),
                )
                .subscribe(count => {
                    _count = count;
                });

            // will happen on first load only - need to get the data from store
            if (_count == '0') {
                return true;
            }
        }
        return false;
    }

    public activateMenuItem(name: string) {
        const menuConfigTemp = this.menuList$.value.map(item => {
            item.active = false;
            if (item.name === name) {
                return {
                    ...item,
                    disabled: false,
                    active: true,
                };
            }
            return item;
        });
        this.menuList$.next(menuConfigTemp);
    }

    public updateMenuItemRoute(name, route) {
        const menuConfigTemp = this.menuList$.value.map(item => {
            if (item.name === name) {
                return {
                    ...item,
                    page: route,
                };
            }
            return item;
        });
        this.menuList$.next(menuConfigTemp);
    }

    public resetConnectionsCounter() {
        this.connectionsCounter$.next('');
    }
}

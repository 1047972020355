<form class="m-form form-modal confirm-modal">
	<span class="close-button" (click)="close()">
		<img src="./assets/app/img/icons/small_x.svg"/>
	</span>
    <div class="modal-header confirm-modal-header">
        <h4 class="modal-title">{{data.title}}</h4>
    </div>

    <div class="modal-body confirm-modal-body">
        {{data.body}}
    </div>

    <div class="modal-footer">
        <oct-round-button (clicked)="close()" *ngIf="data.firstBtn" fontSize="1.2rem" text="{{data.firstBtn}}"
                          width="10.7vw">
        </oct-round-button>
        <ng-container *ngIf="data.buttons else defaultButtons">
            <ng-container *ngIf="data.buttons.length !== 0">
                <oct-round-button (clicked)="btn.click()" *ngFor="let btn of data.buttons" fontSize="1.2rem"
                                  text="{{btn.text}}" width="10.7vw"></oct-round-button>
            </ng-container>
        </ng-container>
        <ng-template #defaultButtons>
            <oct-round-button (click)="onSubmit()" fontSize="1.2rem" id="dialog-submit" text="{{data.secondBtn}}"
							  width="10.7vw"></oct-round-button>
        </ng-template>
    </div>
</form>

import { createFeatureSelector, createSelector } from '@ngrx/store';

// ********* E2E Dashboard ****************
export interface E2EDashboardType {
    details: any;
    from: number;
    returnData: any[];
    total: number;
    type: string;
}

export interface E2EDashboardState {
    ETL: E2EDashboardType;
    DB: E2EDashboardType;
    REPORT: E2EDashboardType;
    searchETL?: string;
    searchDB?: string;
    searchREPORT?: string;
    history?: any[];
}

export const selectE2EDashboard = createFeatureSelector<E2EDashboardState>('e2eDashboard');
export const selectE2EDashboardType = (type: string) => createSelector(selectE2EDashboard, state => {
    return state[type];
});
export const selectE2ESearchTerm = (searchType: string) => createSelector(selectE2EDashboard, state => state[searchType]);
export const selectE2EReturnData = (searchType: string) => createSelector(selectE2EDashboard, state => state[searchType].returnData);
export const selectE2EDetails = (searchType: string) => createSelector(selectE2EDashboard, state => state[searchType]?.details || null);
export const selectE2ETotal = (searchType: string) => createSelector(selectE2EDashboard, state => state[searchType].total);
export const selectHistory = createSelector(selectE2EDashboard, state => state.history);
// export const selectLastItemHistory = createSelector(selectE2EDashboard, state => state.history ? state.history[state.history.length] : undefined);
export const selectSearchETL = createSelector(selectE2EDashboard, state => state.searchETL);
export const selectSearchDB = createSelector(selectE2EDashboard, state => state.searchDB);
export const selectSearchREPORT = createSelector(selectE2EDashboard, state => state.searchREPORT);

// ********* E2E map column ****************
export interface E2EColumnState {
    json?: any;
    properties?: any;
    propertiesObj?: any;
    selected_connections_ids?: string;
}

export const selectE2EColumn = createFeatureSelector<E2EColumnState>('e2eColumn');
export const selectE2EJson = createSelector(selectE2EColumn, state => state.json);
export const selectE2EPropertiesObj = createSelector(selectE2EColumn, state => state?.propertiesObj);



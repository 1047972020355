export enum Locations {
	lineage = 'lineage',
	crossSystem = 'crossSystem',
	innerSystem = 'innerSystem',
	e2eColumnDashboard = 'e2eColumnDashboard',
	e2eColumn = 'e2eColumn',
	e2e = 'e2e',
	map = 'map',
	lineageSchema = 'lineageSchema',
    discovery = 'discovery',
    compare = 'compare',
    compareEtls = 'compare-etls',
    compareReports = 'compare-reports',
    adc = 'adc',
    'adc dashboard' = 'adc-dashboard',
    adcDashboard = 'adc-dashboard',
	liveVisualizer = 'live-visualizer',

	liveLineage = 'live-lineage',

    // adcHomeNew = 'adc-home-new',
    // 'adc dashboard new' = 'adc-dashboard-new',
    // adcDashboardNew = 'adc-dashboard-new',

    gsp = 'gsp',
    settings = 'settings',
    admin = 'admin',
    admin_users = 'adminUsers',
    admin_manualLinks = 'adminManualLinks',
    admin_connectionParameters_REPORT = 'adminConnectionParametersReport',
    admin_connectionParameters_ETL = 'adminConnectionParametersEtl',
    admin_transition = 'adminTransition',
    admin_topSearchBar = 'adminTopSearchBar'
}

export enum DefaultModuleNavigation {
	lineage = 'lineage',
	crossSystem = 'lineage',
	innerSystem = 'lineage',
	e2e = 'e2e-column-dashboard',
	adc = 'adc',
	'adc dashboard' = 'adc-dashboard',
	discovery = 'discovery',
}

export enum LocationsCollapse {
	lineage = 'Lineage',
	discovery = 'Discovery',
	adc = 'Automated Data Catalog',
	'adc dashboard' = 'Data Catalog Insights',
	adcDashboard = 'Data Catalog Insights',
	e2e = 'End To End Lineage',
	livelineage = 'Live Lineage',
	octomize = 'Octomize',
	usage = 'Usage',
}

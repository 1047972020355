import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterArrByKeyValue'
})
export class FilterArrByKeyValuePipe implements PipeTransform {

	transform(items: any[], keyToFilterBy: any, valueToFilterBy: any): any[] {
		return items.filter(item => item[keyToFilterBy] === valueToFilterBy);
	}
}

import { from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from './access-data';
import { Credential } from '@shared/interfaces/credential';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { Store } from '@ngrx/store';
import * as UserActions from '@core/actions/user.actions';
import { ActivatedRoute } from '@angular/router';


export enum AuthenticationType {
	none,
	azure,
	okta,
}

declare var ga;

@Injectable()
export class AuthenticationService implements AuthService {
	API_ENDPOINT_REFRESH = 'userAccount/refresh';
	API_ENDPOINT_FORGOT = 'userAccount/forgotPassword';
	idToken;
	authenticationType: AuthenticationType = AuthenticationType.none;
	emailOfCurrUser = '';
	onCredentialUpdated$ = new Subject<AccessData>();
	signupKey;
	resetKey;
	token;
	manualLogin;
	useAnimation;

	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private utilService: UtilsService,
		private httpUtilsService: HttpUtilsService,
		private store: Store<any>,
		public route: ActivatedRoute,
	) {
	}

	storeUrlParams() {
		try {
			const a = window.location.href.split('#');
			if (a.length > 1) {
				// const signup = a[1].includes('signup');
				// const reset = a[1].includes('reset');
				const manualLogin = a[1].includes('manual');

				const b = a[1].split('?');
				if (b.length > 1) {
					const c = b[1].split('&');

					// if (reset) {
					// 	this.resetKey = c[0].replace('reset=', '');
					// }
					//
					// if (signup) {
					// 	this.signupKey = c[0].replace('signup=', '');
					// }

					if (manualLogin) {
						this.manualLogin = c[0].replace('manual=', '');
					}

					this.token = c[1].replace('token=', '');
				}
			}
		} catch (e) {
			return null;
		}
	}

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<AccessData> {
		return this.tokenStorage.getRefreshToken()
			.pipe(
				switchMap((refreshToken: string) => {
					if (refreshToken) {
						return of({
							refreshToken
						});
					} else {
						return this.http.get<AccessData>(this.API_ENDPOINT_REFRESH + '?' + this.utilService.urlParam(refreshToken));
					}
				}),
				tap(this.saveAccessData.bind(this)),
				catchError(err => {
					this.tokenStorage.logout();
					return throwError(err);
				})
			);
	}

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	// Verify that outgoing request is refresh-token, so interceptor won't intercept this request
	public verifyTokenRequest(url: string): boolean {
		return url.endsWith(this.API_ENDPOINT_REFRESH);
	}

	// Submit login request
	public login(credential: Credential): Observable<any> {
		const payload = {
			Username: credential.username,
			Password: credential.password,
		};
		return this.httpUtilsService.post('userAccount/Login', payload)
			.pipe(
				map((result: any) => {
					const user = {
						email: result.userName,
						full_name: result.displayName,
						user_auth_level: null,
						user_permissions: null,
					};
					this.store.dispatch(new UserActions.UpdateUser(user));
					if (result.accessToken) {
						this.saveAccessData(result);
					}
					return result;
				}),
				catchError(error => {
					return throwError({
						title: 'http call failure',
						operation: 'login',
						error: error.error.error,
					});
				})
			);
	}

	// Save access data in the storage
	public saveAccessData(accessData: AccessData) {
		if (accessData) {
			accessData.roles = [];
			if (accessData.isAdmin === true) {
				accessData.roles.push('ADMIN');
			} else {
				accessData.roles.push('USER');
			}
			this.tokenStorage
				.setAccessToken(accessData)
				.setRefreshToken(accessData.refreshToken)
				.setUserRoles(accessData.roles)
				.setUsername(accessData.userName);
			this.onCredentialUpdated$.next(accessData);

			// try {
			//     ga('set', 'userId', accessData.userName);
			//     ga('send', 'event', 'authentication', 'user-logged-in');
			// } catch (e) {
			//     console.log(e.message);
			// }
		}
	}

	// Submit registration request
	public register(credential: Credential): Observable<any> {
		return this.httpUtilsService.post('userAccount/makeSignUp', credential);
	}

	// Submit forgot password request
	public requestPassword(credential: Credential): Observable<any> {
		return this.httpUtilsService.post(this.API_ENDPOINT_FORGOT, credential)
			.pipe(
				map((result: any) => {
					return result;
				}),
				// catchError(this.handleError('reset-password', []))
				catchError(error => {
					return throwError({
						title: 'http call failure',
						operation: 'reset-password',
						error,
					});
				})
			);
	}

	// Submit forgot password request
	public getCurrentUser(data): Observable<any> {
		return this.httpUtilsService.postData('userAccount/currGen', data, true)
			.pipe(
				map((result: any) => {
					return result;
				}),
				catchError(error => {
					return throwError(error);
				})
			);
	}

	getIdTokenFromUrl() {
		try {
			// this.storeUrlParamsFlag = true;
			const a = window.location.href.split('#');
			if (a.length > 1) {
				const b = a[1].split('&');
				const c = b.find(item => item.includes('id_token'));
				if (c) {
					this.idToken = c.split('=')[1];
				}
			}
			// this.storeUrlParamsSubject$.next();
			// this.storeUrlParamsSubject$.next(true);
			return this.idToken;
		} catch (e) {
			// this.storeUrlParamsFlag = false;
			return null;
		}
	}

	public isAccessTokenExpire() {
		return false;
	}

	// Handle Http operation that failed. Let the app continue.
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error('error', error); // log to console instead

			// Let the app keep running by returning an empty result.
			return from(result);
		};
	}
}

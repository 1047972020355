import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getLocation } from '@store/common';
import { getLineageCount, getLineageProperties } from '@store/lineage';
import { getDiscoveryCount, getDiscoveryProperties, getDiscoverySearchObj } from '@store/discovery';
import { getCompareETLCount, getCompareETLProperties } from '@core/states/compare-etl.state';
import { SideBarService } from '@core/services/layout/side-bar.service';
import { CodePreviewService } from '../../../partials/content/general/code-preview/code-preview.service';
import * as _ from 'lodash';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { PageConfigService } from '@core/services/page-config.service';
import { Locations } from '@shared/enums/locations.enum';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ILayoutState, selectQuickSideBar, SideBarTypes, Status } from '@store/layout';
import { getUser } from '@core/states/user.state';
import { UsagePayload } from '@shared/enums/usage';
import { PropertiesBtnsService } from '@shared/services/properties-btns.service';
import { MapIDService } from '@main/maps/map-id/map-id.service';
import { permissions } from '@shared/enums/permissions';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'm-quick-sidebar',
    templateUrl: './quick-sidebar.component.html',
    styleUrls: ['./quick-sidebar.component.scss'],
})
export class QuickSidebarComponent implements OnInit, OnDestroy {
    @HostBinding('id') id = 'm_quick_sidebar';
    @HostBinding('class') classes = 'm-quick-sidebar m-quick-sidebar--tabbed m-quick-sidebar--skin-light';
    @Input() title: string;
    public isLarge: boolean = false;
    public quickSideBar$: Observable<any>;
    public sideBarTypes: typeof SideBarTypes = SideBarTypes;
    public sideBarStatus: typeof Status = Status;
    @HostBinding('style.overflow') styleOverflow: any = 'hidden';
    componentDestroy$ = new Subject();
    usageData: any = {};
    private defaultTitle: string = ' Sources';
    private location: string;
    private count: string;
    private properties: object;
    private isOpen = false;
    private activeTabId: SideBarTypes = SideBarTypes.connections;
    private locationSearchObj: string;
    private is_empty_location_search_obj: boolean;
    private clickOutSideActive = false;

    constructor(
        private store: Store<ILayoutState>,
        public sideBarService: SideBarService,
        private codePreviewService: CodePreviewService,
        private moduleUtilsService: ModuleUtilsService,
        private pageConfigService: PageConfigService,
        public propertiesBtnsService: PropertiesBtnsService,
        private mapIDService: MapIDService,
        private ngxPermissionsService: NgxPermissionsService
    ) {
    }

    async ngOnInit() {
        this.store
            .pipe(
                select(getLocation),
            )
            .subscribe(location => {
                this.location = location;
                if (this.location !== '') {
                    if (location.indexOf('compare') > -1) {
                        location = this.moduleUtilsService.getLocationPreferredKey(location);
                    }
                    this.title = this.moduleUtilsService.getLocationNameToShow(location);
                }
            });

        switch (this.location) {
            case Locations.lineage:
                this.store
                    .pipe(
                        select(getLineageCount)
                    )
                    .subscribe(count => {
                        this.count = count;
                    });

                this.store
                    .pipe(
                        select(getLineageProperties)
                    )
                    .subscribe(properties => {
                        this.properties = properties;
                    });
                break;
            case Locations.discovery:
                this.store
                    .pipe(
                        select(getDiscoveryCount)
                    )
                    .subscribe(count => {
                        this.count = count;
                    });

                this.store
                    .pipe(
                        select(getDiscoveryProperties)
                    )
                    .subscribe(properties => {
                        this.properties = properties;
                    });

                // if show "properties" tab or not
                this.store
                    .pipe(
                        select(getDiscoverySearchObj)
                    )
                    .subscribe(search_obj => {
                        this.locationSearchObj = search_obj;
                        this.is_empty_location_search_obj = _.isEmpty(this.locationSearchObj);
                    });
                break;
            case Locations.compare:
                this.store
                    .pipe(
                        select(getCompareETLCount)
                    )
                    .subscribe(count => {
                        this.count = count;
                    });
                // etl or report
                this.store
                    .pipe(
                        select(getCompareETLProperties)
                    )
                    .subscribe(properties => {
                        this.properties = properties;
                    });
                break;
        }

        this.quickSideBar$ = this.store
            .pipe(
                select(selectQuickSideBar),
                filter(data => !!data.type),
                tap((data: any) => {
                    this.activeTabId = data?.type;
                    if (data.status === Status.open) {
                        this.isOpen = true;
                        this.classes += ' m-quick-sidebar--on';
                    } else {
                        this.isOpen = false;
                        this.classes = this.classes.replace(/ m-quick-sidebar--on/g, '');
                        this.closeLarge();
                    }

                    this.clickOutSideActive = this.isOpen;
                }),
                map(data => data.type),
            );

        const showUsage = await this.checkUsagePermission(permissions.usage);
        if (showUsage) {
            this.initUsageData();
        }
    }

    async checkUsagePermission(permission: string): Promise<boolean> {
        try {
            return await this.ngxPermissionsService.hasPermission(permission);
        } catch (error) {
            console.error(`Error checking permission for ${permission}:`, error);
            return false;
        }
    }

    initUsageData() {
        this.mapIDService.propertiesObjSubject
            .pipe(
                switchMap((res: any) => {
                    if (!res) {
                        return of(null);
                    }
                    return this.store.pipe(
                        select(getUser),
                        map((user: any) => {
                            if (!user) {
                                return null;
                            }
                            const usagePayload: UsagePayload = {
                                tool: res?.tool,
                                queryNum: 2,
                                containerObjPath: res?.path_folder,
                                connLogicName: res?.connection_logic_name,
                                userId: user?.id
                            };
                            return this.isUsagePayloadValid(usagePayload) ? usagePayload : null;
                        }),
                        switchMap((usagePayload: UsagePayload | null) => {
                            if (!usagePayload) {
                                return of(null);
                            }
                            return this.propertiesBtnsService.getUsageData(usagePayload);
                        })
                    );
                })
            )
            .subscribe(
                (data: any) => {
                    if (data) {
                        try {
                            this.usageData = JSON.parse(data.res)[0];
                        } catch (error) {
                            console.error('Error parsing usage data:', error);
                        }
                    }
                },
                (error: any) => {
                    console.error('Error retrieving usage data:', error);
                }
            );
    }

    isUsagePayloadValid(usagePayload: UsagePayload): boolean {
        return !!(
            usagePayload.tool &&
            usagePayload.queryNum &&
            usagePayload.containerObjPath &&
            usagePayload.connLogicName &&
            usagePayload.userId
        );
    }

    openLarge() {
        this.isLarge = true;
        this.classes += ' m-quick-sidebar--large';
        this.codePreviewService.hosterMode = 'list-properties-large';
        this.pageConfigService.generalHosterMode = 'list-properties-large';
        this.codePreviewService.getCodePreviewStyle();
    }

    closeLarge(isConnectionCloseIt?: boolean) {
        this.isLarge = false;
        this.classes = this.classes.replace(/ m-quick-sidebar--large/g, '');

        if (isConnectionCloseIt) {
            this.codePreviewService.hosterMode = 'list-properties';
            this.pageConfigService.generalHosterMode = 'list-properties';
        } else {
            this.pageConfigService.generalHosterMode = 'map-id';
        }

        this.codePreviewService.getCodePreviewStyle();
    }

    onClose() {
        this.sideBarService.close(SideBarTypes.properties);
        this.isLarge = false;
    }

    onClickOutside() {
        if (this.clickOutSideActive) {
            this.onClose();
        }
    }

    ngOnDestroy(): void {
        console.log('quick sidebar destroy');
    }
}

import { AfterViewInit, Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { LayoutConfigService } from '@core/services/layout-config.service';
import { ClassInitService } from '@core/services/class-init.service';
import { TranslationService } from '@core/services/translation.service';
import { ReferenceTableService } from '@core/services/reference-table.service';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { TokenStorage } from '@core/auth/token-storage.service';
import * as objectPath from 'object-path';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PageConfigService } from '@core/services/page-config.service';
import { SplashScreenService } from '@core/services/splash-screen.service';
import { AclService } from '@core/services/acl.service';
import { Store } from '@ngrx/store';
import { CommonState } from '@store/common';
// language list
import { VersionCheckService } from '@shared/services/version-check.service';
import { environment } from '@environments/environment';

// LIST KNOWN ISSUES
// [Violation] Added non-passive event listener; https://github.com/angular/angular/issues/8866
declare var gtag;

@Component({
	selector: 'body[m-root]',
	templateUrl: './app.component.html',
})

export class AppComponent implements AfterViewInit, OnInit {
	@HostBinding('style') style: any;
	@HostBinding('class') classes: any = '';

	@ViewChild('splashScreen', {read: ElementRef}) splashScreen: ElementRef;
	splashScreenImage = './assets/app/img/loader/octopus.gif';
	// showLoader = true;

	constructor(
		private activatedRoute: ActivatedRoute,
		private layoutConfigService: LayoutConfigService,
		private classInitService: ClassInitService,
		private sanitizer: DomSanitizer,
		private translationService: TranslationService,
		public router: Router,
		private location: Location,
		private pageConfigService: PageConfigService,
		public splashScreenService: SplashScreenService,
		private aclService: AclService,
		private storeCommon: Store<CommonState>,
		private referenceTableService: ReferenceTableService,
		private httpUtilsService: HttpUtilsService,
		public tokenStorage: TokenStorage,
		private versionCheckService: VersionCheckService,
		private titleService: Title,
	) {
		this.checkNavigatedUrl();
		// subscribe to class update event
		this.classInitService.onClassesUpdated$.subscribe(classes => {
			// get body class array, join as string classes and pass to host binding class
			setTimeout(() => this.classes = classes.body.join(' '));
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
			this.classInitService.setConfig(model);

			this.style = '';
			if (objectPath.get(model.config, 'self.layout') === 'boxed') {
				const backgroundImage = objectPath.get(model.config, 'self.background');
				if (backgroundImage) {
					this.style = this.sanitizer.bypassSecurityTrustStyle('background-image: url(' + objectPath.get(model.config, 'self.background') + ')');
				}
			}

			// splash screen image
			// this.splashScreenImage = objectPath.get(model.config, 'loader.image');
		});

		// signup translations
		// this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);

		// override config by router change from pages config
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				this.layoutConfigService.setModel({page: objectPath.get(this.pageConfigService.getCurrentPageConfig(), 'config')}, true);
				if (event instanceof NavigationEnd) {
					gtag('config', 'UA-164133670-1',
						{
							'page_path': event.urlAfterRedirects
						}
					);
				}
			});
	}

	ngOnInit(): void {
		const releaseVersion = environment.production ? '' : ` ${environment.releaseVersion}`;
		this.titleService.setTitle(`OCTOPAI${releaseVersion}`);
		// this.versionCheckService.initVersionCheck(environment.versionCheckURL);

		this.tokenStorage.setLoggingoutStatus();
		// reset this value, because when user make refresh, so it's mean it's not after he click on logout btn.
		// so we need to change the text beyond the loader
		if (this.tokenStorage.loggingoutStatus === 'true') {
			this.tokenStorage.setLoggingout('false');
		}
	}

	ngAfterViewInit(): void {
		if (this.splashScreen) {
			this.splashScreenService.init(this.splashScreen.nativeElement);
		}
		// this.showLoader = false;
	}

	checkNavigatedUrl() {
	// 	if (document.location.pathname.indexOf('[vs]') > -1) {
	// 		let compareStr = document.location.pathname;
	// 		if (compareStr.indexOf('compare-etls') > -1) {
	// 			compareStr = compareStr.replace('/compare/compare-etls/', '');
	// 			this.pageConfigService.navigatedUrlPage = 'compare-etls';
	// 		} else {
	// 			compareStr = compareStr.replace('/compare/compare-reports/', '');
	// 			this.pageConfigService.navigatedUrlPage = 'compare-reports';
	// 		}
	// 		this.pageConfigService.navigatedUrlParam = compareStr;
	// 	}
	}
}

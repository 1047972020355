import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICheckBoxMap } from '@shared/interfaces/check-box';

@Component({
  selector: 'oct-properties-check-box-list',
  templateUrl: './properties-check-box-list.component.html',
  styleUrls: ['./properties-check-box-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PropertiesCheckBoxListComponent),
      multi: true
    }
  ],
})
export class PropertiesCheckBoxListComponent implements OnInit, ControlValueAccessor {
  public checkAll = true;
  @Input() data: any;
  @Input() selectAllOption = false;
  @Input() toggleValues: any;
  @Input() checkBoxListName: string;
  @Output() updateList = new EventEmitter<any>();
  public form: FormGroup;


  constructor() {
  }

  ngOnInit(): void {
  }

  onCheckAllChange() {
    this.checkAll = !this.checkAll;
    this.data.forEach(a => a.checked = this.checkAll);
    this.onChange(this.data);
  }

  onCheckChange(item: any, event: any) {
    this.updateList.emit(this.data);
    item.checked = event.target.checked;
    this.checkAll = this.data.find(f => !f.checked) ? false : true;
    this.onChange(this.data);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(list: any): void {
    if (list) {
      this.data = list;
    }
    // this.onChange(list);
  }

  onChange = (map: ICheckBoxMap[]) => {
  };

  onToggleValueChange(event, item) {
    item.toggleValue = event;
    this.onChange(this.data);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BgHighlightPipe } from '@core/pipes/bg-highlight.pipe';
import { NotExistComponent } from '@content/pages/snippets/not-exist/not-exist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import {
  TimeLineFilterComponent
} from '@content/layout/header/topbar/recent-activities/list-timeline/time-line-filter/time-line-filter.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RoundButtonModule } from '@shared/oct-components/round-button/round-button.module';
import { CheckBoxListModule } from '@shared/oct-components/check-box-list/check-box-list.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PerfectScrollbarModule,
    RoundButtonModule,
    CheckBoxListModule,
  ],
  providers: [],
  declarations: [
    BgHighlightPipe,
    NotExistComponent,
    TimeLineFilterComponent,
  ],

  exports: [
    BgHighlightPipe,
    NotExistComponent,
    TimeLineFilterComponent,
  ],
})

export class SharedContentModule {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckBoxComponent } from '@shared/oct-components/check-box/check-box.component';


@NgModule({
  declarations: [CheckBoxComponent],
  imports: [
    CommonModule
  ],
  exports: [CheckBoxComponent]
})
export class OctCheckBoxModule {
}

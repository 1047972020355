import { ConfigModel } from '../core/interfaces/config';
import { Locations } from '@shared/enums/locations.enum';
import { permissions } from '@shared/enums/permissions';

export class MenuConfig implements ConfigModel {
    public config;

    constructor() {
        this.config = {
            aside: {
                self: {},
                items: [
                    {
                        id: 'Main_Menu_Cross_System_Lineage_Dashboard',
                        name: Locations.lineage,
                        title: 'Lineage Dashboard',
                        tooltip: 'Cross System Lineage Dashboard',
                        root: true,
                        icon: './assets/cross_system_lineage_dashboard.png',
                        selectedIcon: './assets/cross_system_lineage_dashboard_selected.png',
                        page: Locations.lineage,
                        location_name: Locations.lineage,
                        badge: {type: 'm-badge--danger', value: ''},
                        translate: 'MENU.LINEAGE',
                        show: true,
                        group: 1,
                        groupName: 'lineage',
                        permission: 'lineage',
                    },
                    {
                        id: 'Main_Menu_Cross_System_Lineage',
                        name: Locations.lineageSchema,
                        title: 'Cross System',
                        tooltip: 'Cross System Lineage',
                        root: true,
                        icon: 'assets/cross_system_lineage.png',
                        selectedIcon: 'assets/cross_system_lineage_selected.png',
                        disabledIcon: 'assets/cross-system-disabled.svg',
                        page: Locations.lineageSchema,
                        location_name: Locations.lineageSchema,
                        badge: {type: 'm-badge--danger', value: ''},
                        translate: 'MENU.LINEAGE',
                        show: true,
                        group: 1,
                        groupName: 'lineage',
                        parentId: 1,
                        disabled: false,
                        permission: 'lineage',
                    },
                    {
                        id: 'Main_Menu_Inner_System_Lineage',
                        name: Locations.map,
                        title: 'Inner System',
                        tooltip: 'Inner System Lineage',
                        root: true,
                        icon: 'assets/inner_system_lineage.png',
                        selectedIcon: 'assets/inner_system_lineage_selected.png',
                        // disabledIcon: 'assets/inner-system-disabled.svg',
                        page: Locations.map,
                        location_name: Locations.map,
                        badge: {type: 'm-badge--danger', value: ''},
                        translate: 'MENU.MAP',
                        show: true,
                        group: 1,
                        groupName: 'lineage',
                        parentId: 1,
                        disabled: false,
                        permission: 'lineage',
                    },
					{
						id: 'Main_Menu_Live_Visualizer',
						name: Locations.liveVisualizer,
						title: 'Live Lineage',
						tooltip: 'Live Lineage',
						root: true,
						icon: 'assets/online_visualizer.png',
						selectedIcon: 'assets/online_visualizer_selected.png',
						// disabledIcon: 'assets/inner-system-disabled.svg',
						page: Locations.liveVisualizer,
						location_name: Locations.liveVisualizer,
						badge: {type: 'm-badge--danger', value: ''},
						translate: 'MENU.MAP',
						show: true,
						group: 1,
						groupName: 'lineage',
						parentId: 1,
						disabled: false,
						permission: permissions.liveLineage,
						width: '52'
					},
                    {
                        id: 'Main_Menu_E2E_Column_Lineage_Dashboard',
                        name: Locations.e2eColumnDashboard,
                        title: 'E2E Lineage Dashboard',
                        tooltip: 'End To End Lineage Dashboard',
                        root: true,
                        icon: 'assets/e2e_lineage_dashboard.png',
                        selectedIcon: 'assets/e2e_lineage_dashboard_selected.png',
                        page: 'e2e-column-dashboard',
                        location_name: 'e2e-column-dashboard',
                        badge: {type: 'm-badge--danger', value: ''},
                        translate: 'MENU.LINEAGE',
                        show: true,
                        group: 1,
                        groupName: 'lineage',
                        permission: 'e2e',
						width: '46',
                    },
                    {
                        id: 'Main_Menu_E2E_Column_Lineage',
                        name: Locations.e2eColumn,
                        title: 'E2E Column',
                        tooltip: 'E2E Column Lineage',
                        root: true,
                        icon: 'assets/e2e_column_lineage.png',
                        selectedIcon: 'assets/e2e_column_lineage_selected.png',
                        // disabledIcon: 'assets/e2e-column-disabled.svg',
                        page: 'e2e-column',
                        location_name: 'e2e-column',
                        badge: {type: 'm-badge--danger', value: ''},
                        translate: 'MENU.LINEAGE',
                        show: true,
                        group: 1,
                        groupName: 'lineage',
                        parentId: 4,
                        disabled: false,
                        permission: 'e2e',
                    },
                    // {
                    //     id: 'Main_Menu_Compare_ETLs',
                    //     name: Locations.compareEtls,
                    //     title: 'Compare ETLs',
                    //     tooltip: 'Cross System ETLs Compare',
                    //     page: 'compare/compare-etls',
                    //     badge: {type: 'm-badge--danger', value: ''},
                    //     translate: 'MENU.COMPAREETLS',
                    //     icon: './assets/cross_system_etl_compare.png',
                    //     selectedIcon: './assets/cross_system_etl_compare_selected.png',
                    //     location_name: 'compare-etls',
                    //     show: true,
                    //     root: true,
                    //     group: 1,
                    //     groupName: 'lineage',
                    //     permission: 'lineage',
                    // },
                    // {
                    //     id: 'Main_Menu_Compare_Reports',
                    //     name: Locations.compareReports,
                    //     title: 'Compare Reports',
                    //     tooltip: 'Cross System Report Compare',
                    //     page: 'compare/compare-reports',
                    //     badge: {type: 'm-badge--danger', value: ''},
                    //     translate: 'MENU.COMPAREREPORTS',
                    //     icon: './assets/cross_system_report_compare.png',
                    //     selectedIcon: './assets/cross_system_report_compare_selected.png',
                    //     location_name: 'compare-reports',
                    //     show: true,
                    //     root: true,
                    //     group: 1,
                    //     groupName: 'lineage',
                    //     permission: 'lineage',
                    // },
                    {
                        id: 'Main_Menu_Discovery',
                        name: Locations.discovery,
                        title: 'Discovery',
                        tooltip: 'Discovery',
                        root: true,
                        icon: 'assets/discovery.png',
                        selectedIcon: 'assets/discovery_selected.png',
                        page: 'discovery',
                        location_name: 'discovery',
                        badge: {type: 'm-badge--danger', value: ''},
                        translate: 'MENU.DISCOVERY',
                        show: true,
                        group: 2,
                        groupName: 'discovery',
                        permission: 'discovery',
                    },
                    {
                        id: 'Main_Menu_Data_Catalog_Insights',
                        name: Locations.adcDashboard,
                        title: 'Insights',
                        tooltip: 'Insights',
                        root: true,
                        icon: './assets/data_catalog_insights.png',
                        selectedIcon: './assets/data_catalog_insights_selected.png',
                        page: 'adc-dashboard',
                        location_name: 'adc-dashboard',
                        badge: {typ: 'm-badge--danger', value: ''},
                        translate: 'MENU.OBG',
                        show: true,
                        group: 3,
                        groupName: 'adc',
                        permission: 'adc dashboard',
                    },
                    {
                        id: 'Main_Menu_Data_Catalog',
                        name: Locations.adc,
                        title: 'Knowledge Hub',
                        tooltip: 'Knowledge Hub',
                        root: true,
                        icon: './assets/automated_data_catalog.png',
                        selectedIcon: './assets/automated_data_catalog_selected.png',
                        page: 'adc',
                        location_name: 'adc',
                        badge: {type: 'm-badge--danger', value: ''},
                        translate: 'MENU.OBG',
                        show: true,
                        group: 3,
                        groupName: 'adc',
                        permission: 'adc',
                    },
                ]
            }
        };
    }
}

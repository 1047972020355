import { ElementRef, Injectable, InjectionToken, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { ConnectedPosition } from '@angular/cdk/overlay/position/flexible-connected-position-strategy';

const overlayPosition = {
	right: {
		originX: 'start',
		originY: 'bottom',
		overlayX: 'start',
		overlayY: 'top',
	},
	left: {
		originX: 'end',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'top',
	},
};

const positionMapping = {
	dynamic: [overlayPosition.right, overlayPosition.left],
	right: [overlayPosition.right],
	left: [overlayPosition.left],
};

export enum OverlayPositions {
	dynamic = 'dynamic',
	right = 'right',
	left = 'left',
}

export interface IOctOverlayConfig {
	width?: string;
	height?: string;
	panelClass?: string;
	hasBackdrop?: boolean;
	backdropClass?: string;
	data?: any;
	position?: OverlayPositions;
}

const DEFAULT_CONFIG: IOctOverlayConfig = {
	hasBackdrop: true,
	backdropClass: 'dark-backdrop',
	panelClass: 'custom-overlay-panel',
	position: OverlayPositions.dynamic,
};

export const OCT_OVERLAY_DATA = new InjectionToken<any>('OCT_OVERLAY_DATA');

export class OctOverlayRef {
	private afterClose$ = new Subject();

	constructor(private overlayRef: OverlayRef) {
	}

	close(data?) {
		this.afterClose$.next(data);
		this.overlayRef.dispose();
	}

	afterClose(): Subject<any> {
		return this.afterClose$;
	}
}

@Injectable({
	providedIn: 'root'
})
export class OctOverlayService {
	private renderer: Renderer2;

	constructor(
		private overlay: Overlay,
		private injector: Injector,
		private rendererFactory: RendererFactory2,
	) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	open(anchor: ElementRef, component, config?: IOctOverlayConfig): OctOverlayRef {
		const overlayConfig = {...DEFAULT_CONFIG, ...config};
		const overlayRef = this.createOverlay(overlayConfig, anchor);

		const octOverlayRef = new OctOverlayRef(overlayRef);
		this.attachOverlayContainer(component, overlayRef, overlayConfig, octOverlayRef);

		return octOverlayRef;
	}

	addOverlayArrow(overlayRef: OverlayRef, position: PositionStrategy) {
		const span = this.renderer.createElement('span');
		this.renderer.addClass(span, 'arrow-up');
		const overlayEl = overlayRef.overlayElement;
		this.renderer.appendChild(overlayEl, span);
	}

	private createInjector(config: IOctOverlayConfig, octOverlayRef: OctOverlayRef) {
		const injectionTokens = new WeakMap();

		injectionTokens.set(OctOverlayRef, octOverlayRef);
		injectionTokens.set(OCT_OVERLAY_DATA, config.data);

		return new PortalInjector(this.injector, injectionTokens);
	}

	private attachOverlayContainer(component, overlayRef: OverlayRef, config: IOctOverlayConfig, octOverlayRef: OctOverlayRef) {
		const injector = this.createInjector(config, octOverlayRef);
		const containerPortal = new ComponentPortal(component, null, injector);
		const containerRef = overlayRef.attach(containerPortal);
		return containerRef.instance;
	}

	private createOverlay(config: IOctOverlayConfig, anchor?: ElementRef) {
		const overlayConfig = this.getOverlayConfig(config, anchor);
		return this.overlay.create(overlayConfig);
	}

	private getOverlayConfig(config: IOctOverlayConfig, anchor?: ElementRef) {
		const position = <ConnectedPosition[]>positionMapping[config.position];

		const positionStrategy = this.overlay.position()
			.flexibleConnectedTo(anchor)
			.withPositions(position);

		return new OverlayConfig({
			...config,
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			positionStrategy
		});
	}
}

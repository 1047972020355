import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupGuard } from '@app/auth/signup/signup.guard';
import { LoginGuard } from '@app/auth/login/login.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@content/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => import('@app/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('@app/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'signup',
    canActivate: [SignupGuard],
    loadChildren: () => import('@app/auth/signup/signup.module').then(m => m.SignupModule),
  },
  {
    path: 'sso',
    loadChildren: () => import('@app/auth/sso/sso.module').then(m => m.SsoModule),
  },
  {
    path: 'live-lineage',
    loadChildren: () => import('./live-lineage/live-lineage.module').then(m => m.LiveLineageModule),
  },
  {
    path: '**',
    redirectTo: 'lineage',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy'})
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule {
}

import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from '@store/users/reducers';

export interface State extends EntityState<any> {
}

export const selectUsersState = createFeatureSelector('users');
export const selectUserEntities = createSelector(
        selectUsersState,
        fromUser.selectUserEntities
);
export const selectUsers = createSelector(
        selectUsersState,
        fromUser.selectAllUsers
);
export const selectUserById = (id: number) => createSelector(
        selectUserEntities,
        userEntities => userEntities[id]
);

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICheckBoxMap } from '@shared/interfaces/check-box';
import { IABGItem, IABGTag } from '@main/adc/adc-store';
import { optionMode } from '@main/adc/adc-shared/components/adc-item-status/adc-item-status.component';
import { AbgSource } from '@main/adc/adc-shared/types/abgSource';

export interface IAbgFilter {
    filters: ICheckBoxMap[];
}

export interface IAbgFilters {
    [key: string]: IAbgFilter;
}

export interface IAbgItemStatus {
    sTATUS_ID: number;
    sTATUS_NAME: string;
    sTATUS_SYSTEM?: boolean;
    sTATUS_COLOR?: string;
    mode?: optionMode;
    checked?: boolean;
    originalColor?: string;
}

export interface AdcState {
    selected_obj_id?: string;
    selected_obj?: IABGItem;
    results?: any[];
    total?: number;
    tags?: IABGTag[];
    linkSearchValue?: string;
    sort: 'Asc' | 'Desc';
    loading?: boolean;
    searchFilters?: any;
    filtered?: boolean;
    detailLoader?: boolean;
	filterOpen?: boolean;
}

export interface IObgResults {
    results?: any[];
    total?: number;
}

export const getAdcState = createFeatureSelector<AdcState>('adc');
export const selectFiltered = createSelector(getAdcState, state => state?.filtered);
export const selectFilterState = createSelector(getAdcState, state => state?.filterOpen);
export const selectAdcItemID = createSelector(getAdcState, state => state.selected_obj_id);
export const selectAbgItem = createSelector(getAdcState, state => state.selected_obj);
export const selectAdcItemName = createSelector(selectAbgItem, state => state.oBJECT_NAME);
export const getAbgResults = createSelector(getAdcState, state => state.results);
export const getAbgTotal = createSelector(getAdcState, state => state.total);
export const selectLoading = createSelector(getAdcState, state => state.loading);
export const selectAbgTags = createSelector(getAdcState, state => state.tags);
export const selectSort = createSelector(getAdcState, state => state.sort);
export const selectAbgItemSuspend = createSelector(selectAbgItem, state => state && state.sTATUS || null);
export const selectAbgItemStatus = createSelector(selectAbgItem, state => state && state.aBG_STATUS || null);
export const selectAbgItemUrl = createSelector(selectAbgItem, state => state.url);
export const selectDetailsLoader = createSelector(getAdcState, state => state.detailLoader);
export const selectAdcItemRateAvg = createSelector(selectAbgItem, state => state?.aVG || 0);
export const selectAdcItemRateCount = createSelector(selectAbgItem, state => state?.rATE_COUNT || 0);
export const selectAdcItemRate = createSelector(selectAbgItem, state => state?.rATE || 0);
export const selectAdcItemRates = createSelector(selectAbgItem, state => state?.rATES || null);
export const selectAdcSearchFilters = (filterType?: AbgSource) => createSelector(
  getAdcState,
  state => {
      if (state?.searchFilters) {
          return state.searchFilters[filterType];
      }
  });
export const selectResultsAndTotals = createSelector(
  getAbgResults,
  getAbgTotal,
    (results, total) => {
        return {
            results,
            total,
        };
    });

export enum PostsDrawerStatus {
    open = 'open',
    close = 'close',
}

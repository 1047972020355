import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { EnableSearchFilters, GetTags } from '@main/adc/adc-store/actions';
import { IABGTag, selectAssetTypes, selectStatusList, selectTagsList } from '@main/adc/adc-store';
import { IAbgItemStatus } from '@main/adc/adc-store/adc';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IAppUser, IUser } from '@shared/interfaces/user';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { getLocation } from '@store/common';
import { Locations } from '@shared/enums/locations.enum';
import { DATE_FORMATS } from '@main/adc/adc-shared/types/dateFormats';
import { HttpUtilsService } from '@core/services/http-utils.service';
import { UsersService } from '@main/admin/users/users.service';
import { AssetTypesMapping } from '@shared/types/adcMappings';

const moment = require('moment');

export const MY_FORMATS = {
	parse: {
		dateInput: 'YYYY-MM-DD',
	},
	display: {
		dateInput: 'MMM DD, YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'MMM DD, YYYY',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'oct-abg-rich-filter',
	templateUrl: './adc-rich-filter.component.html',
	styleUrls: ['./adc-rich-filter.component.scss'],
	providers: [
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	],
})
export class AdcRichFilterComponent implements OnInit, OnDestroy, OnChanges {
	@Input() collapse$: Subject<boolean>;
	@Input() data;
	@Input() readOnlyData: boolean;
	@Output() filterCollapse = new EventEmitter();
	@Output() applyFilter = new EventEmitter();
	@Output() filterInit = new EventEmitter();

	public form: FormGroup;
	public sensitivityOptions = {
		floor: 0,
		ceil: 5,
	};
	public tagsSearch: FormControl = new FormControl();
	public dataOwnerSearch: FormControl = new FormControl();
	public dataStewardSearch: FormControl = new FormControl();
	public tags$: Observable<IABGTag[]>;
	public dataOwners$ = new BehaviorSubject<IUser[]>([]);
	public dataStewards$ = new BehaviorSubject<IUser[]>([]);
	public statusList: IAbgItemStatus[];
	public statusCheckAll = true;
	public tagsList: IABGTag[] = [];
	public dataOwnersList: IUser[] = [];
	public dataStewardsList: IUser[] = [];
	public assetTypeCheckAll = true;
	public assetTypes: any[];
	@HostBinding('class.collapse') collapseClass: boolean;
	public dataToShow: Array<any> = [];
	private initialFilterPayload;
	private componentDestroy$ = new Subject();

	constructor(
		private store: Store<any>,
		private userService: UsersService,
		private fb: FormBuilder,
		private httpUtilsService: HttpUtilsService,
	) {
	}

	ngOnInit() {
		this.initForm();
		this.store
			.pipe(
				takeUntil(this.componentDestroy$),
				select(getLocation),
				filter((location: Locations) => ![Locations.adc, Locations.adcDashboard].includes(location))
			)
			.subscribe(() => this.store.dispatch(new EnableSearchFilters(false)));

		this.tags$ = this.store.pipe(
			select(selectTagsList),
			filter(list => !!list),
		);

		this.subscribeToTagSearch();
		this.subscribeToDataOwnerSearch();
		this.subscribeToDataStewardSearch();

		if (this.collapse$) {
			this.collapse$.subscribe(val => {
				this.collapseClass = val;
			});
		}
	}

	onBookmarkToggle() {
		this.form.controls.bookmark.setValue(!this.form.controls.bookmark.value);
	}

	onNotificationToggle() {
		this.form.controls.notification.setValue(!this.form.controls.notification.value);
	}

	onCheckAll() {
		this.statusCheckAll = !this.statusCheckAll;
		this.statusList.forEach(item => item.checked = this.statusCheckAll);
	}

	onStatusChange(status: IAbgItemStatus) {
		status.checked = !status.checked;
		this.statusCheckAll = !this.statusList.find(item => !item.checked);
	}

	displayFn(option: IABGTag) {
		return option && option.tAG_NAME || '';
	}

	tagSelected(e: MatAutocompleteSelectedEvent) {
		const exists = this.tagsList.find(item => item.tAG_ID === e.option.value.tAG_ID);
		if (!exists) {
			this.tagsList.push(e.option.value);
		}
		this.form.controls.tags.setValue(this.tagsList.map(a => a.tAG_ID));
		this.tagsSearch.setValue('');
	}

	onRemoveTag(selectedTag: IABGTag) {
		const index = this.tagsList.findIndex(item => item.tAG_ID === selectedTag.tAG_ID);
		this.tagsList.splice(index, 1);
		this.form.controls.tags.setValue(this.tagsList.map(a => a.tAG_ID));
	}

	dataOwnerSelected(e: MatAutocompleteSelectedEvent) {
		const exists = this.dataOwnersList.find(item => item.ID === e.option.value.ID);
		if (!exists) {
			this.dataOwnersList.push(e.option.value);
		}
		this.form.controls.dataOwnersIds.setValue(this.dataOwnersList.map(a => a.ID));
		this.dataOwnerSearch.setValue('');
	}

	userDisplayFn(option: IUser) {
		return option && option.FULL_NAME || '';
	}

	dataStewardSelected(e: MatAutocompleteSelectedEvent) {
		const exists = this.dataStewardsList.find(item => item.ID === e.option.value.ID);
		if (!exists) {
			this.dataStewardsList.push(e.option.value);
		}
		this.form.controls.dataStewardsIds.setValue(this.dataStewardsList.map(a => a.ID));
		this.dataStewardSearch.setValue('');
	}

	onRemoveDataOwner(selectedUser: IUser) {
		const index = this.dataOwnersList.findIndex(item => item.ID === selectedUser.ID);
		this.dataOwnersList.splice(index, 1);
		this.form.controls.dataOwnersIds.setValue(this.dataOwnersList.map(a => a.ID));
	}

	onRemoveDataSteward(selectedUser: IUser) {
		const index = this.dataStewardsList.findIndex(item => item.ID === selectedUser.ID);
		this.dataStewardsList.splice(index, 1);
		this.form.controls.dataStewardsIds.setValue(this.dataStewardsList.map(a => a.ID));
	}

	onApply() {
		const formData = this.form.getRawValue();

		const payload = {
			...formData,
			entryFrom: formData.entryFrom ? formData.entryFrom.format(DATE_FORMATS[0]) : '',
			entryTo: formData.entryTo ? formData.entryTo.format(DATE_FORMATS[0]) : '',
			lastUpdateFrom: formData.lastUpdateFrom ? formData.lastUpdateFrom.format(DATE_FORMATS[0]) : '',
			lastUpdateTo: formData.lastUpdateTo ? formData.lastUpdateTo.format(DATE_FORMATS[0]) : '',
		};

		const filtered = JSON.stringify(payload) !== JSON.stringify(this.initialFilterPayload);
		this.applyFilter.emit({payload, filtered});
	}

	onAssetTypeAllChange() {
		this.assetTypeCheckAll = !this.assetTypeCheckAll;
		this.assetTypes.forEach(item => item.checked = this.assetTypeCheckAll);
	}

	onClearDate(key) {
		event.stopPropagation();
		this.form.controls[key].setValue('');
	}

	onAssetTypeChange(assetType) {
		assetType.checked = !assetType.checked;
		this.assetTypeCheckAll = !this.assetTypes.find(item => !item.checked);
	}

	onResetFilter() {
		this.initialFilterPayload.assetTypes.forEach(a => a.checked = true);
		this.initialFilterPayload.statusList.forEach(a => a.checked = true);
		this.tagsList = [];
		this.dataOwnersList = [];
		this.dataStewardsList = [];
		this.form.setValue(this.initialFilterPayload);

		this.initialFilterPayload = JSON.parse(JSON.stringify(this.form.getRawValue()));
	}

	ngOnDestroy(): void {
		this.componentDestroy$.next();
		this.componentDestroy$.unsubscribe();
	}

	onFilterCollapse() {
		this.collapseClass = true;
		this.filterCollapse.emit();
	}

	public clearText(key: any) {
		this[key].setValue('');
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.data?.currentValue) {
			this.updateForm(changes.data.currentValue);
		}
	}

	private subscribeToTagSearch() {
		this.tagsSearch.valueChanges
			.pipe(
				debounceTime(200),
			)
			.subscribe(searchTerm => {
				this.store.dispatch(new GetTags(searchTerm));
			});
	}

	private subscribeToDataOwnerSearch() {
		this.dataOwnerSearch.valueChanges
			.subscribe(searchTerm => {
				this.getUsers(searchTerm)
					.pipe(
						debounceTime(200),
					)
					.subscribe(users => {
						this.dataOwners$.next(users);
					});
			});
	}

	private subscribeToDataStewardSearch() {
		this.dataStewardSearch.valueChanges.subscribe(searchTerm => {
			this.getUsers(searchTerm)
				.pipe(
					debounceTime(200),
				)
				.subscribe(users => {
					this.dataStewards$.next(users);
				});
		});
	}

	private updateForm(data) {
		if (!this.form) {
			this.initForm();
			data.tags?.forEach(tag => {
				const dataToServer = {
					operationName: null,
					query: `{ oBG { getTagsById (tAG_ID: "${tag}") { tAG_ID, tAG_NAME }}}`,
					variables: null
				};
				this.httpUtilsService.post('graphql', dataToServer)
					.pipe(
						filter(list => list.data.oBG.getTagsById.length > 0)
					)
					.subscribe(list => {
						this.tagsList.push(list.data.oBG.getTagsById[0]);
					});
			});

			this.userService.getUsers()
				.subscribe(users => {
					this.dataOwnersList = data.dataOwnersIds.map(id => {
						const user = users.find(item => item.useR_ID === id);
						return {
							ID: user.useR_ID,
							FULL_NAME: `${user.useR_FIRST_NAME} ${user.useR_LAST_NAME}`,
						};
					});
					this.dataStewardsList = data.dataStewardsIds.map(id => {
						const user = users.find(item => item.useR_ID === id);
						return {
							ID: user.useR_ID,
							FULL_NAME: `${user.useR_FIRST_NAME} ${user.useR_LAST_NAME}`,
						};
					});
				});
		}
		if (data) {
			if (this.readOnlyData) {
				this.compareData();
			}
			this.form?.patchValue({
				...data,
				entryFrom: data.entryFrom ? moment(data.entryFrom) : '',
				entryTo: data.entryTo ? moment(data.entryTo) : '',
				lastUpdateFrom: data.lastUpdateFrom ? moment(data.lastUpdateFrom) : '',
				lastUpdateTo: data.lastUpdateTo ? moment(data.lastUpdateTo) : '',
			});
		}
	}

	private compareData() {
		for (const key in this.initialFilterPayload) {
			const _data = this.data[key];
			const _initData = this.initialFilterPayload[key];
			if (JSON.stringify(_data) !== JSON.stringify(_initData)) {
				this.dataToShow[key] = true;
			}
		}
	}

	private initForm() {
		if (this.form) {
			return;
		}

		const a = this.store.pipe(
			select(selectAssetTypes),
			filter(list => !!list),
			take(1),
		);
		const b = this.store
			.pipe(
				select(selectStatusList),
				filter(list => !!list),
				take(1),
			);
		combineLatest([a, b])
			.subscribe((res) => {
				const assetTypes = res[0].map((item, index) => {
					return {
						id: index,
						text: AssetTypesMapping[item.oBJECT_TYPE] || this.fixToDisplay(item.oBJECT_TYPE),
						value: item.oBJECT_TYPE,
						checked: true,
					};
				});
				const statusList = res[1].map(item => {
					return {
						id: item.sTATUS_ID,
						text: item.sTATUS_NAME,
						color: item.sTATUS_COLOR,
						checked: true,
					};
				});
				statusList.unshift({
					id: 0,
					text: 'No Status Assigned',
					color: '#fff',
					checked: true,
				});

				this.form = this.fb.group({
					statusList: [statusList],
					assetTypes: [assetTypes],
					rate: [[0, 5]],
					sensitivity: '',
					entryFrom: '',
					entryTo: '',
					lastUpdateFrom: '',
					lastUpdateTo: '',
					tags: [[]],
					dataOwnersIds: [[]],
					dataStewardsIds: [[]],
					bookmark: [false],
					notification: [false],
					description: '',
					suspended: 'false',
				});

				this.initialFilterPayload = JSON.parse(JSON.stringify(this.form.getRawValue()));
				this.filterInit.emit(JSON.parse(JSON.stringify(this.form.getRawValue())));
			});
	}

	private getUsers(searchTerm?): Observable<IUser[]> {
		if (searchTerm && typeof searchTerm !== 'string') {
			return of([searchTerm]);
		}
		return this.userService.getUsers().pipe(
			map((users: IAppUser[]) => {
				const a = users.filter((user: IAppUser) =>
					user.useR_FIRST_NAME.toLowerCase().includes(searchTerm.toLowerCase()) ||
					user.useR_LAST_NAME.toLowerCase().includes(searchTerm.toLowerCase()));
				return a.map((user: IAppUser) => {
					return {
						ID: user.useR_ID,
						FULL_NAME: `${user.useR_FIRST_NAME} ${user.useR_LAST_NAME} ${user.useR_ACTIVE === 'NO' ? '(Deleted)' : ''}`,
					};
				});
			}),
		);
	}

	private fixToDisplay(oBJECT_TYPE: string): string {

		const newStr = oBJECT_TYPE.replace(/_/gi, ' ').toLowerCase();
		const arr = newStr.split(' ');

		//loop through each element of the array and capitalize the first letter.
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}

		//Join all the elements of the array back into a string
		//using a blankspace as a separator
		return arr.join(' ');
	}
}

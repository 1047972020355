<div (clickOutside)="onClickOutside()" [excludeClasses]="['btnOpenLargeArrow']" [excludeElements]="['path']" [style.height.%]="100">
	<div class="m-quick-sidebar__header">
		<div (click)="onClose()" class="m-quick-sidebar__close" id="m_quick_sidebar_close">
			<mat-icon svgIcon="close-icon" matTooltip="Close" class="close-icon"></mat-icon>
		</div>
		<div class="m-quick-sidebar__header--arrow">
			<ng-container *ngIf="isLarge else small">
				<mat-icon (click)="closeLarge(true)" class="btnOpenLargeArrow"
						  svgIcon="right_arrow" matTooltip="Close large view"></mat-icon>
			</ng-container>
			<ng-template #small>
				<mat-icon svgIcon="left_arrow" class="btnOpenLargeArrow" clickOutSideExclude
						  (click)="openLarge()" matTooltip="Open large view"></mat-icon>
			</ng-template>
			<mat-label class="m-quick-sidebar__header--label">{{(quickSideBar$ | async) === sideBarTypes.properties ? sideBarTypes.properties : title}}</mat-label>
		</div>
	</div>
	<div class="m-quick-sidebar__content"  [ngSwitch]="quickSideBar$ | async">
		<ng-container *ngSwitchCase="sideBarTypes.properties">
			<oct-list-properties [isExpand]="isLarge" [ngClass]="{expand: isLarge}"></oct-list-properties>
		</ng-container>
		<ng-container *ngSwitchCase="sideBarTypes.usage">
			<h4>Usage</h4>
			<oct-usage-content [usageData]="usageData"></oct-usage-content>
		</ng-container>
		<ng-container  *ngSwitchDefault>
			<oct-list-settings></oct-list-settings>
		</ng-container>

	</div>
	<div class="m-quick-sidebar__footer">
	</div>
</div>

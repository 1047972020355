import {Injectable} from '@angular/core';
import {Locations} from '@shared/enums/locations.enum';
import {getLineageSelectedConnectionsIDs, selectLineageConnections} from '@store/lineage';
import {getDiscoverySelectedConnectionsIDs, selectDiscoveryConnections} from '@store/discovery';
import {getCompareETLSelectedConnectionsIDs, selectCompareETLConnections} from '@core/states/compare-etl.state';
import {getCompareREPORTSelectedConnectionsIDs, selectCompareREPORTConnections} from '@core/states/compare-report.state';
import {CommonState, getLocation} from '@store/common';
import {select, Store} from '@ngrx/store';
import {take} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class StoreUtilsService {

	constructor(private storeCommon: Store<CommonState>) {
	}

	public getConnectionsSelectorByLocation() {
		let location: Locations = null;
		this.storeCommon
			.pipe(
				select(getLocation),
				take(1),
			)
			.subscribe(loc => location = <Locations>loc);

		switch (location) {
			case Locations.lineage:
			case Locations.e2eColumnDashboard:
				return selectLineageConnections;
			case Locations.discovery:
				return selectDiscoveryConnections;
			case Locations.compareEtls:
				return selectCompareETLConnections;
			case Locations.compareReports:
				return selectCompareREPORTConnections;
		}
	}

	public getConnectionsIdsSelector() {
		let location: Locations = null;
		this.storeCommon
			.pipe(
				select(getLocation),
				take(1),
			)
			.subscribe(loc => location = <Locations>loc);

		switch (location) {
			case Locations.lineage:
				return getLineageSelectedConnectionsIDs;
			case Locations.discovery:
				return getDiscoverySelectedConnectionsIDs;
			case Locations.compareEtls:
				return getCompareETLSelectedConnectionsIDs;
			case Locations.compareReports:
				return getCompareREPORTSelectedConnectionsIDs;
			default:
				return null;
		}
	}
}

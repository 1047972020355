import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'oct-underline-button',
  templateUrl: './underline-button.component.html',
  styleUrls: ['./underline-button.component.scss']
})
export class UnderlineButtonComponent implements OnInit {
	@Input() text: string;
	@Input() fontSize: string;
	@Input() color: string;
	@Output() clicked = new EventEmitter<boolean>();

	constructor() {
	}

	ngOnInit(): void {
	}

	public onClick() {
		this.clicked.emit(true);
	}
}

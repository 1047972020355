import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

import { UtilsService } from '@core/services/utils.service';

@Component({
	selector: 'm-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
	@Input() numOfSteps: number;
	@Input() startDate: any;
	public counter = Array;
	private today;
	private diffDays: number;
	public numOfStepToCircles: number;
	private activeState;

	constructor(
		private utilsService: UtilsService,
		private datePipe: DatePipe
	) {
		this.today = Date.now();
	}

	ngOnInit() {
		this.calculateTime();
	}

	private calculateTime() {
		this.numOfStepToCircles = this.numOfSteps > 7 ? 7 : Number(this.numOfSteps);
		this.today = this.datePipe.transform(this.today, 'yyyy-MM-dd');
		this.diffDays = this.utilsService.calculateDiff(this.startDate, this.today);
		this.activeState = this.diffDays >= this.numOfStepToCircles ? this.numOfStepToCircles - 1 : this.diffDays;
	}
}

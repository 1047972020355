<div class="white-bg" (clickOutside)="close()">
	<div (click)="clickIcon('circle')" class="circle-icon"></div>

	<div class="flex draw-toolbar-container" (click)="clickIcon('line')">
		<div class="legend-color-link"></div>
		<div class="legend-arrow-right"></div>
	</div>

	<img [src]="'./assets/app/img/icons/clear_black.svg'" (click)="clickIcon('clear')">
</div>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DiscoveryService } from '../../../../main/discovery/discovery.service';
import _ from 'lodash';
import { SearchFacadeService } from '@store/services/search-facade.service';
import { Store } from '@ngrx/store';
import { Locations } from '@shared/enums/locations.enum';
import { UpdateLocation } from '@store/common/actions';
import { ConnectionsList } from '@core/services/connections-list.service';
import { SearchType } from '@shared/enums/searchType';
import { PageConfigService } from '@core/services/page-config.service';
import { DiscoveryUrlState } from '@main/discovery/discovery.types';

@Component({
	selector: 'm-not-exist',
	templateUrl: './not-exist.component.html',
	styleUrls: ['./not-exist.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})

export class NotExistComponent implements OnInit {
	@Input() item: any;

	private discoveryUrlState: DiscoveryUrlState = new DiscoveryUrlState();

	constructor(
		private store: Store<any>,
		private router: Router,
		private connectionsService: ConnectionsList,
		private discoveryService: DiscoveryService,
		private searchFacade: SearchFacadeService,
		private pageConfigService: PageConfigService,
	) {
	}

	ngOnInit(): void {

		if (_.isEmpty(this.item)) {
			this.item.valid = true;
			this.item.tooBig = false;
		} else {
			if (this.item.controlFlowPath == -1) {
				this.item.controlFlowPath = false;
			}
		}
	}

	redirectToDiscovery() {
		this.discoveryService.dataObjs = [];
		this.store.dispatch(new UpdateLocation(Locations.discovery));
		this.searchFacade.setSearchType(SearchType.default);
		this.searchFacade.setDefaultSearch(this.item.name, Locations.discovery);
		// this.discoveryService.lastSearchValueDiscovery = this.item.name;
		this.discoveryUrlState.searchType = SearchType.default;
		this.discoveryUrlState.searchValue = this.item.name;
		const params = this.pageConfigService.encodeBtoa(JSON.stringify(this.discoveryUrlState));
		this.router.navigate([Locations.discovery, params]);
	}
}

import * as DiscoveryActions from '@store/discovery/actions';
import { DiscoveryState } from '@store/discovery/index';

export const initialState: DiscoveryState = {
	selected_connections_ids: getAllDiscoverySelectedConnectionsIDs(), // '-1',
	count: '0',
	selected_connections_objs: [],
	properties: {
		obj_1: undefined,
		val_1: {
			as_full_text: undefined,
			as_multi_values: undefined,
			as_url: undefined,
			value: undefined
		}
	},
	properties_obj: {},
	search_obj: {},
	connections_list: [],
	connections_list_bg: [],
	history_list: [],
	selectedObject: undefined
};

export function DiscoveryReducer(state = initialState, action: DiscoveryActions.Actions) {
	const _state = {...state};

	switch (action.type) {
		case DiscoveryActions.UPDATE_DISCOVERY_COUNT:
			return Object.assign({}, state, action.payload);
		case DiscoveryActions.UPDATE_DISCOVERY_SELECTED_CONNECTIONS_OBJS:
			return Object.assign({}, state, action.payload);
		case DiscoveryActions.UPDATE_DISCOVERY_PROPERTIES:
			return Object.assign({}, state, action.payload);
		case DiscoveryActions.UPDATE_DISCOVERY_PROPERTIES_OBJ:
			return Object.assign({}, state, action.payload);
		case DiscoveryActions.UPDATE_DISCOVERY_SELECTED_CONNECTIONS_IDS:
			return Object.assign({}, state, action.payload);
		case DiscoveryActions.UPDATE_DISCOVERY_SEARCH_OBJ:
			return {..._state, search_obj: action.payload};
		case DiscoveryActions.UPDATE_DISCOVERY_CONNECTIONS_LIST:
			return Object.assign({}, state, action.payload);
		case DiscoveryActions.UPDATE_DISCOVERY_CONNECTIONS_LIST_BG:
			return Object.assign({}, state, action.payload);
		case DiscoveryActions.UPDATE_DISCOVERY_HISTORY_LIST:
			return Object.assign({}, state, action.payload);
		case DiscoveryActions.SET_DISCOVERY_CONNECTIONS:
			return Object.assign({}, state, {
				connections: [...action.payload.connections],
				connectionsBG: [...action.payload.connectionsBG]
			});
		case DiscoveryActions.SET_DISCOVERY_URL:
			return {..._state, url: action.payload};
		case DiscoveryActions.UPDATE_SELECTED_OBJECT:
			return {...state, selectedObject: action.payload};
		default:
			return state;
	}
}

function getAllDiscoverySelectedConnectionsIDs() {
	const allItemsFromLocalStorage = {...localStorage};
	const allDiscoveryItemsFromLocalStorage = [];
	for (const key in allItemsFromLocalStorage) {
		if (key.startsWith('D_') && allItemsFromLocalStorage[key] === 'true') {
			const _obj_id = key.substr(key.lastIndexOf('_') + 1);
			allDiscoveryItemsFromLocalStorage.push(_obj_id);
		}
	}
	return allDiscoveryItemsFromLocalStorage.toString() || '-1';
}

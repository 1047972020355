import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from '@core/services/layout/header.service';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';
import { LayoutRefService } from '@core/services/layout/layout-ref.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable } from 'rxjs';
import { SideBarService } from '@core/services/layout/side-bar.service';
import { Status } from '@store/layout';
import { select, Store } from '@ngrx/store';
import { getLocation } from '@store/common';
import { map } from 'rxjs/operators';
import { Locations } from '@shared/enums/locations.enum';
import { SubheaderService } from '@core/services/layout/subheader.service';

@Component({
  selector: 'm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('mHeader') mHeader: ElementRef;
  public quickSideBarState$: Observable<Status>;
  public showSearch$: Observable<boolean>;

  constructor(
    private router: Router,
    private layoutRefService: LayoutRefService,
    public headerService: HeaderService,
    public loader: LoadingBarService,
    private sideBarService: SideBarService,
    private store: Store<any>,
    public subheaderService: SubheaderService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loader.complete();
      }
    });

    this.quickSideBarState$ = this.sideBarService.getSideBarOpenState();

    this.showSearch$ = this.store.pipe(
      select(getLocation),
      map((location: Locations) => ![
        Locations.compareEtls, Locations.compareReports, Locations.lineageSchema, Locations.map, Locations.e2eColumn
      ].includes(location)),
    );
  }

  ngAfterViewInit(): void {
    this.layoutRefService.addElement('header', this.mHeader.nativeElement);
  }
}

export const ADC_TYPE_NAME_MAPPING = {
    'Analysis': 'analysis',
    'Business': 'business',
    'Data Set': 'dataset',
    'Database': 'database',
    'ETL': 'etl',
    'Master': 'master',
    'Policy': 'policy',
    'Project': 'project',
    'Report': 'report',
    'Data Catalog': 'datacatalog',
};

export const ADC_TYPE_ICON_MAPPING = {
    'Analysis': 'analysis',
    'Business': 'business',
    'Data Set': 'dataset',
    'Database': 'database',
    'ETL': 'etl',
    'Master': 'master',
    'Policy': 'policy',
    'Project': 'project',
    'Report': 'report',
    'Data Catalog': 'datacatalog',
};

export const ADC_TYPE_ICON_MAPPING_ENABLED = {
    analysis: 'analysis-enabled',
    business: 'business-enabled',
    custom: 'custom-enabled',
    dataset: 'data-set-enabled',
    database: 'database-enabled',
    etl: 'etl-enabled',
    master: 'master-enabled',
    policy: 'policy-enabled',
    project: 'project-enabled',
    report: 'report-enabled',
    datacatalog: 'data-catalog-enabled',
};

export const ADC_TYPE_ICON_MAPPING_DISABLED = {
    analysis: 'analysis-disabled',
    business: 'business-disabled',
    custom: 'custom-disabled',
    dataset: 'data-set-disabled',
    database: 'database-disabled',
    etl: 'etl-disabled',
    master: 'master-disabled',
    policy: 'policy-disabled',
    project: 'project-disabled',
    report: 'report-disabled',
    datacatalog: 'data-catalog-disabled',
};


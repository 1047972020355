import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuickSidebarComponent } from './layout/quick-sidebar/quick-sidebar.component';
import { TooltipsComponent } from './layout/tooltips/tooltips.component';
import { ListSettingsComponent } from './layout/quick-sidebar/list-settings/list-settings.component';
import { ListPropertiesComponent } from './layout/quick-sidebar/list-properties/list-properties.component';
import { DrillBetweenMapsComponent } from '@main/maps/drill-between-maps/drill-between-maps.component';
import { MessengerModule } from './layout/quick-sidebar/messenger/messenger.module';
import { CoreModule } from '@core/core.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
import { SpinnerButtonModule } from './content/general/spinner-button/spinner-button.module';
import { WidgetChartsModule } from './content/widgets/charts/widget-charts.module';
import { JumpToAnotherPageComponent } from './content/widgets/general/jump-to-another-page/jump-to-another-page.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { CodePreviewModule } from './../partials/content/general/code-preview/code-preview.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedContentModule } from './../shared-content.module';
import { SharedModule } from '@shared/shared.module';
import { ToastContainerModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
// import { ListObjectsComponent } from '@main/lineage-dashboard/doughnut-box/list-objects/list-objects.component';
import { ZoomSliderComponent } from '@content/partials/content/general/zoom-slider/zoom-slider.component';
import { NgxSkeletonModule } from 'ngx-skeleton';
// import { SquareButtonComponent } from '@shared/oct-components/square-button/square-button.component';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { RoundButtonModule } from '@shared/oct-components/round-button/round-button.module';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UsageContentComponent } from './layout/quick-sidebar/list-properties/usage-content/usage-content.component';

@NgModule({
	declarations: [
		QuickSidebarComponent,
		TooltipsComponent,
		ListSettingsComponent,
		ListPropertiesComponent,
		DrillBetweenMapsComponent,
		NoticeComponent,
		// ListObjectsComponent,
		JumpToAnotherPageComponent,
		ZoomSliderComponent,
		UsageContentComponent,
		// SquareButtonComponent,
	],
	exports: [
		QuickSidebarComponent,
		TooltipsComponent,
		ListSettingsComponent,
		ListPropertiesComponent,
		NoticeComponent,
		PerfectScrollbarModule,
		// ListObjectsComponent,
		JumpToAnotherPageComponent,
		PortletModule,
		SpinnerButtonModule,
		InfiniteScrollModule,
		UsageContentComponent,
		// SquareButtonComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		NgbModule,
		PerfectScrollbarModule,
		MessengerModule,
		CoreModule,
		PortletModule,
		SpinnerButtonModule,
		SharedModule,
		WidgetChartsModule,
		InfiniteScrollModule,
		CodePreviewModule,
		FormsModule,
		ReactiveFormsModule,
		VirtualScrollerModule,
		SharedContentModule,
		GridModule,
		ToastContainerModule,
		TranslateModule.forChild(),
		NgxSkeletonModule,
		SearchInputModule,
		DirectivesModule,
		RoundButtonModule,
		MatTabsModule,
		NgxPermissionsModule
	],
	providers: [],
})
export class PartialsModule {
}

<button [matMenuTriggerFor]="menu" [ngStyle]="{width: item.width || 'auto'}"
		id="{{item.id}}"
		mat-flat-button matBadge="{{(item.notification$ | async)?.length}}"
		matBadgeSize="small">
	<mat-icon svgIcon="{{item.icon}}"
			  class="toolbar-icon" matTooltip="{{item.title}}"
			  [ngStyle]="{'height': item.iconSize + 'px', 'width': item.iconSize + 'px'}"
			  [ngClass]="{'inActive': item.active || (item.notification$ | async)?.length,
					  'redo-icon': item.title === 'Redo', 'undo-icon': item.title === 'Undo'}"></mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="after" class="toolbar-menu">
	<div *ngFor="let button of item.items">
		<div mat-menu-item class="flex toolbar-menu-item" flex-center
				(click)="button.click()" id="{{button.id}}"> {{button.title}}
		</div>
	</div>
</mat-menu>

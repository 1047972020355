<oct-time-line-filter (filter)="onFilter($event)" *ngIf="toggle"></oct-time-line-filter>

<div class="m-list-timeline" *ngIf="!(dataLoading$ | async)"
     [ngClass]="{filterIsOpen: toggle, filterIsClose: !toggle}">
    <div class="toolbar">
        <mat-icon (click)="openCloseFilter()" [matTooltip]="'Filter'" [ngClass]="{filterIsOpen: toggle}"
                  class="filter-icon" svgIcon="filter_frame"></mat-icon>
        <m-search-input (clear)="onSearch('')" (keyPress)="onSearch($event)" [isFocus]="isFocus"
                        class="m-header-search__wrapper">
        </m-search-input>
    </div>

    <div *ngIf="(recentActivitiesList$ | async)?.length == 0" class="no-results bold">No results</div>

    <div *ngIf="(recentActivitiesList$ | async)?.length > 0" class="m-list-timeline__items">
        <pagination-controls (pageChange)="onPageChange($event)" class="pagination"></pagination-controls>
        <perfect-scrollbar>
            <div *ngFor="let item of recentActivitiesList$ | async | paginate: { itemsPerPage: 9, currentPage: cp }  ; let i = index">
                <oct-timeline-item (click)="clickOnSelected($event)" [cp]="cp" [index]="i" [item]="item"
                                   id="recent-row-{{i}}"></oct-timeline-item>
            </div>
        </perfect-scrollbar>
    </div>
</div>

<ng-container>
    <div *ngIf="dataLoading$ | async" class="loader">
        <img [style.height.px]="50" [style.width.px]="65" alt=""
             src="../../../../../../../assets/app/img/loader/octopus.gif">
        <span>Loading...</span>
    </div>
</ng-container>

import { CommonState } from '@store/common';
import { CommonActions, } from '@store/common/actions';
import { Action, createReducer } from '@ngrx/store';

export const INITIAL_STATE: CommonState = {
    loading: false,
    location: 'lineage',
    dic: null,
    public_settings: {},
    isScanning: false,
    allConnections: [],
    connectionsDisabled: false,
    legendMapDisplay: false
};

export function CommonReducer(state: CommonState = INITIAL_STATE, action) {
    switch (action.type) {
        case CommonActions.LOADING:
            return {...state, loading: action.payload};
        case CommonActions.UPDATE_DIC:
            return Object.assign({}, state, action.payload);
        case CommonActions.UPDATE_PUBLIC_SETTINGS:
            return Object.assign({}, state, action.payload);
        case CommonActions.UPDATE_LOCATION:
            return {...state, location: action.payload};
        case CommonActions.SCANNING_STATE:
            return Object.assign({}, state, {isScanning: action.payload});
        case CommonActions.UPDATE_ALLCONNECTIONS:
            return Object.assign({}, state, {allConnections: action.payload});
        case CommonActions.DISABLE_CONNECTIONS:
            return {...state, connectionsDisabled: action.payload};
        case CommonActions.DISPLAY_LEGEND:
            return {...state, legendMapDisplay: action.payload};
        default:
            return state;
    }
}

// Recent Activities
export interface IRecentActivitiesInitialState {
    items: Array<any>;
    isOpen: boolean;
}

export const recentActivitiesInitialState = {
    items: [],
    isOpen: false,
};

const _recentActivitiesReducer = createReducer(
    recentActivitiesInitialState,
    // on(createRecentActivities, (state, {payload}) => ({
    //     ...state,
    //     items: payload,
    // })),
    // on(updateRecentActivities, (state, {payload}) => ({
    //     ...state,
    //     items: (() => {
    //         let list = [];
    //         if (Array.isArray(payload)) {
    //             list = state.items.concat(payload);
    //             while (list.length > 1000) {
    //                 list.pop();
    //             }
    //         } else {
    //             list = state.items;
    //             while (list.length > 999) {
    //                 list.pop();
    //             }
    //             list = [payload, ...list];
    //         }
    //         return list;
    //     })(),
    // })),
    // on(recentActivitiesToggle, (state, {payload}) => ({
    //     ...state,
    //     isOpen: payload,
    // })),
    // on(setRecentActivitiesItemToDelete, (state, {payload}) => ({
    //     ...state,
    //     items: ((items) => {
    //         items[payload.index].to_delete = payload.value;
    //         return items;
    //     })(state.items),
    // })),
);

export function recentActivitiesReducer(state: IRecentActivitiesInitialState | undefined, action: Action) {
    return _recentActivitiesReducer(state, action);
}

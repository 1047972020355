import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarIconComponent } from '@shared/oct-components/avatar-icon/avatar-icon.component';
import { AvatarModule } from 'ngx-avatar';


@NgModule({
  declarations: [AvatarIconComponent],
  imports: [
    CommonModule,
    AvatarModule
  ],
  exports: [AvatarIconComponent]
})
export class AvatarIconModule {
}

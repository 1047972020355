<div class="flex error-dialog-wrapper" flex-dircetion-col>
	<div class="flex">
		<div  class="content-wrapper flex" flex-dircetion-col flex-center>
			<div>Looks like something went wrong</div>
			<div class="flex m--margin-right-10"  flex-dircetion-col  flex-center >Please try again in a few minutes or contact
				<a href="mailto:support@mail.com"> support@octopai.com</a>
			</div>
		</div>
		<div class="flex" style="height: 90px; width: 70px" >
			<img class="octopai-icon" src="./assets/app/img/error/404octopai.png" />
		</div>
		<div (click)="close()">
			<mat-icon svgIcon="close-icon" class="close-btn" matTooltip="Close" style="height: 10px"></mat-icon>
		</div>
	</div>
</div>

<div [attr.checkedValue]="checked" [ngClass]="{disabled: disabled}" class="check-box"
     id="choice-{{checkBoxName}}-{{index}}">
    <label class="checkbox-button">
        <input #check [checked]="checked" class="checkbox-button__input"
               name="choice-name-{{index}}" type="checkbox">
        <span class="checkbox-button__control {{checkColor}}"></span>
        <span *ngIf="checkboxLabel" class="label" octRtlView>{{ checkboxLabel }}</span>
    </label>
</div>
<div [ngClass]="{disabled: disabled}" class="check-box-content">
    <ng-content></ng-content>
</div>


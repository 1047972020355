const localDev = 'demo7.qa.octopai.com';

export const environment = {
  versionCheckURL: './version.json',
  releaseVersion: require('../../package.json').version,
  production: false,
  apiUrl: `https://${location.host.includes('webapp.dev') ? `${localDev}` : location.host}/api/`,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  goLicenseKey: '298647e1b7674dc702d90776423d68f919a579639d851da35f0717f6eb086b46729ce97154d788d2c2fe1bac127d9089d5976b2b9f4c0c33e63481d410e6d2ffe23323b5440a44dda21136c5ccaa2ca1ae2870e0d2b676a1d86785edeba9',
  advancedSearch: {
    lineage: false,
    discovery: true,
    adc: true,
  },
  isAvailableFeature: false,
  abgSearchFiltersFeature: true,
  showE2eRid: true,
  accessTokenCheckup: 300000, // 5 Minutes
  accessTokenExpirationHeadUp: 30, // 30 Minutes
  fullPermissionsList: 'lineage;e2e;discovery;adc;adc dashboard;livelineage;livelineage;octomize;usage',
  googleTagManagerId: 'GTM-PX3NKBG',
  octomizeTypingSpeed: 20,
};


import { SearchType } from '@shared/enums/searchType';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getLocation } from '@store/common';
import { SelectedFilter } from '@core/interfaces/obg-data';

export interface ISearchTag {
    tagId: number;
    tagName: string;
}

export interface IAdvancedSearchValue {
    filter1: string;
    filter1val: string;
    operator1: string;
    filter2: string;
    filter2val: string;
    operator2: string;
    filter3: string;
    filter3val: string;
    bracketsCondition: number;
}

export interface IAdvancedSearchParams {
    searchType?: SearchType;
    searchObjType?: string;
    searchValues?: IAdvancedSearchValue;
}

export interface ISearchParams {
    searchObjType?: string;
    searchValues?: IAdvancedSearchValue;
    searchDefaultValue?: string;
    searchByTag?: ISearchTag;
    searchType?: SearchType;
    applyAdvancedSearch?: boolean;
    searchLayers?: SelectedFilter;
    searchPlaceholder?: string;
    autoCompleteSearchValue?: string;
    autoCompleteSearchResults?: any[];
    searchInputValue?: string;
    searchValue?: any;
	tools?: any;
}

export interface ISearch {
    [key: string]: ISearchParams;
}

export const searchFeature = createFeatureSelector<ISearch>('search');
export const selectSearchTimeStamp = createSelector(searchFeature, state => state.timeStamp);


export const selectSearch = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        return state[location];
    });

export const selectSearchTools = createSelector(
	searchFeature,
	getLocation,
	(state, location) => {
		return state[location]?.tools;
	});

export const selectSearchObjType = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        return state[location]?.searchObjType;
    }
);

export const selectSearchValue = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        return state[location]?.searchValue;
    }
);

export const selectSearchType = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state[location]) {
            return state[location]?.searchType;
        }
    });

export const selectDefaultSearch = (externalLocation?: string) => createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state[location]) {
            return state[externalLocation || location]?.searchDefaultValue;
        }
    });

export const selectSearchInputValue = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state[location]) {
            return state[location]?.searchInputValue;
        }
    });

export const selectSearchValues = (externalLocation?: string) => createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state[location]) {
            return state[externalLocation || location]?.searchValues;
        }
    });

export const selectSearchPlaceholder = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state[location]) {
            return state[location]?.searchPlaceholder;
        }
    });

export const selectApplyAdvancedSearch = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state[location]) {
            return state[location]?.applyAdvancedSearch;
        }
    });

export const selectAdvancedSearch = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state) {
            return state[location];
        }
    });

export const selectAdvancedSearchParams = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state) {
            const advancedSearchParams: IAdvancedSearchParams = {
                searchType: state[location].searchType,
                searchObjType: state[location].searchObjType,
                searchValues: state[location].searchValues,
            };
            return advancedSearchParams;
        }
    });

export const selectSearchTags = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state[location]) {
            return state[location].searchByTag;
        }
    });

export const selectSearchLayers = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state[location]) {
            return state[location].searchLayers;
        }
    });

export const selectAutoCompleteSearch = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state[location]) {
            return state[location].autoCompleteSearchValue || '';
        }
    });

export const selectAutoCompleteResults = createSelector(
    searchFeature,
    getLocation,
    (state, location) => {
        if (location && state[location]) {
            return state[location].autoCompleteSearchResults || '';
        }
    });

import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectActivities } from '@store/activities';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'oct-recent-activities',
  templateUrl: './recent-activities.component.html',
  styleUrls: ['./recent-activities.component.scss']
})
export class RecentActivitiesComponent implements OnInit {
  @ViewChild(MatMenuTrigger) notificationMenuBtn: MatMenuTrigger;
  isFocus$ = new Subject<boolean>();
  private activities;

  constructor(
    private store: Store<any>
  ) {
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectActivities),
      )
      .subscribe(results => this.activities = results);
  }

  closeQuickAction() {
    this.notificationMenuBtn.closeMenu();
  }
}

import { IMenuSpace } from '@content/layout/side-menu/side-menu.component';

export const MENU_SPACES: IMenuSpace[] = [
  {
    id: 1,
    title: 'Lineage Space',
    titleShort: 'Lineage'
  },
  {
    id: 2,
    title: 'Discovery Space',
    titleShort: 'Discovery'
  },
  {
    id: 3,
    title: 'Knowledge Hub Space ',
    titleShort: 'Knowledge Hub'
  }
];

<div #searchContainer class="search-container">
    <div #search class="search" style="position: relative">
        <ng-content select="[slot1]"></ng-content>
        <input #searchInput (focusin)="onFocusIn($event)" (mouseup)="onMouseUp()"
               [(ngModel)]="searchValue"
               [matAutocomplete]="autoComplete"
               [matTooltip]="tooltip" autocomplete="off"
               class="m-header-search__input gtag-search-input" id="{{id}}"
               name="search" octRtl placeholder="{{placeholder || 'Search'}}">
        <div>
            <div *ngIf="(!searchInput.value && searchIcon) else cancelIcon" class="search-icon-wrapper">
                <mat-icon class="search-icon" svgIcon="search"></mat-icon>
            </div>
            <ng-template #cancelIcon>
                <div class="clear-icon-wrapper flex" flex-center>
                    <mat-icon (click)="clearText()" class="search-icon" clickOutSideExclude
                              flex-center matTooltip="Cancel" svgIcon="close-icon"></mat-icon>
                </div>
            </ng-template>
        </div>
        <ng-content select="[searchSpinner]"></ng-content>
        <!--	Slot for auto complete template-->
        <ng-content select="[slot2]"></ng-content>
    </div>

    <div class="navigate-buttons">
        <span *ngIf="indexValue !== -1">{{ indexValue }}/{{ totalValue }}</span>
        <div class="flex" flex-center>
            <div (click)="prev.emit()" *ngIf="applyNavigation && searchInput.value" class="icon-wrapper">
                <mat-icon class="arrow" svgIcon="left_arrow"></mat-icon>
            </div>
            <div (click)="next.emit()" *ngIf="applyNavigation && searchInput.value"
                 class="icon-wrapper">
                <mat-icon class="arrow down" svgIcon="left_arrow"></mat-icon>
            </div>
        </div>
    </div>

    <!--    <div class="clear-icon-wrapper flex" flex-center>-->
    <!--        <mat-icon (click)="clearText()" *ngIf="searchInput.value" class="search-icon" clickOutSideExclude-->
    <!--                  flex-center matTooltip="Cancel" svgIcon="close-icon"></mat-icon>-->
    <!--    </div>-->
</div>

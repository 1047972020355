import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, last, takeLast, tap } from 'rxjs/operators';

enum ClickTypes {
	click,
	dblClick,
	onDemand,
}

interface IClickData {
	clickType: ClickTypes;
	targetEl: ElementRef;
}

@Directive({
	selector: '[clickOutside]'
})
export class ClickOutsideDirective {
	@Input() excludeElementsNames: string[];
	@Input() excludeElements: ElementRef[];
	@Input() excludeClasses: string[];
	@Input() closeOnDemand;
	@Input() clickOutSideExclude;

	private clickEvents = new Subject();

	constructor(private _elementRef: ElementRef) {
		this.clickEvents
			.pipe(
				debounceTime(300),
			)
			.subscribe((e: IClickData) => {
				switch (e.clickType) {
					case ClickTypes.click:
						this.click(e.targetEl);
						break;
					case ClickTypes.dblClick:
						break;
				}
			});
	}

	@Output()
	public clickOutside = new EventEmitter();

	@HostListener('document:dblclick', ['$event.target'])
	public onDblClick(targetElement: Element) {
		this.clickEvents.next({
			clickType: ClickTypes.dblClick,
		});
	}

	@HostListener('document:click', ['$event.target'])
	public onClick(targetElement: Element) {
		this.closeOnDemand = !!this._elementRef.nativeElement.attributes['closeOnDemand'];

		if (this.closeOnDemand) {
			console.log('closeOnDemand');
		} else {
			this.clickEvents.next({
				targetEl: targetElement,
				clickType: ClickTypes.click,
			});
		}
	}

	private click(targetElement) {
		if ((targetElement.attributes as NamedNodeMap).getNamedItem('clickOutSideExclude')) {
			return;
		}

		if (['svg', 'path'].includes(targetElement.tagName)) {
			if ((targetElement.parentElement.attributes as NamedNodeMap).getNamedItem('clickOutSideExclude')) {
				return;
			}
		}

		if (this.excludeClasses && _.intersection(this.excludeClasses, targetElement.classList).length > 0) {
			return;
		}

		if (!!this.excludeElements && this.excludeElements.find(element => element.nativeElement === targetElement)) {
			return;
		}

		if (!!this.excludeElements && this.excludeElements.includes(targetElement.tagName)) {
			return;
		}

		// if (!!this.excludeElementsNames && this.excludeElementsNames.includes(targetElement.tagName)) {
		// 	return;
		// }
		//
		// if (this.excludeElementsNames.includes(targetElement.tagName) && targetElement.parentElement.nodeName === 'MAT-ICON') {
		// 	return;
		// }

		const clickedInside = this._elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.clickOutside.emit(null);
		}
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'oct-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {

	@Input() width;
	@Input() height;
	@Input() position;
	@Output() colorChange = new EventEmitter();
	@Output() apply = new EventEmitter();
	@Input() customSelectColor: boolean = false;
	color;


	constructor() {
	}

	ngOnInit(): void {
	}

	selectCustomColor() {
		this.customSelectColor = true;
		this.apply.emit(this.color);
	}

	colorPickerOnChange(e) {
		this.color = e;
		this.colorChange.emit(e);
	}

	onApply() {
		this.customSelectColor = true;
		this.apply.emit(this.color);
	}
}

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { debounceTime, map, switchMap, take, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { select, Store } from '@ngrx/store';
import { getLocation } from '@store/common';
import { getLineageProperties, getLineagePropertiesObj, getLineageSelectedConnectionsIDs } from '@store/lineage';
import { getDiscoveryProperties, getDiscoveryPropertiesObj, getDiscoverySelectedConnectionsIDs } from '@store/discovery';
import { getCompareETLProperties, getCompareETLPropertiesObj, getCompareETLSelectedConnectionsIDs } from '@core/states/compare-etl.state';
import { getCompareREPORTProperties, getCompareREPORTPropertiesObj, getCompareREPORTSelectedConnectionsIDs } from '@core/states/compare-report.state';
import { UtilsService } from '@core/services/utils.service';
import { MapsService } from '@main/maps/maps.service';
import { ModuleUtilsService } from '@core/services/module-utils.service';
import { MapIDService } from '@main/maps/map-id/map-id.service';
import { PageConfigService } from '@core/services/page-config.service';
import { LineageService } from '@main/lineage-dashboard/lineage.service';
import { LineageIDService } from '@main/lineage-diagram/lineage-id/lineage-id.service';
import { SideBarService } from '@core/services/layout/side-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { ReferenceTableService } from '@core/services/reference-table.service';
import { Locations } from '@shared/enums/locations.enum';
import { CodePreviewService } from '@content/partials/content/general/code-preview/code-preview.service';
import { SearchFacadeService } from '@store/services/search-facade.service';
import { PropertiesBtnsService } from '@shared/services/properties-btns.service';
import { selectE2EPropertiesObj } from '@store/e2e';
import { CodePreviewComponent } from '@content/partials/content/general/code-preview/code-preview.component';
import { getScriptOnListProperties } from '@store/code-preview';
import { selectSearchValue } from '@store/search';
import { selectQuickSideBar } from '@store/layout';
import { NgxPermissionsService } from 'ngx-permissions';
import { permissions } from '@shared/enums/permissions';
import { getUser } from '@core/states/user.state';
import { UsagePayload } from '@shared/enums/usage';
import { PropertiesObj } from '@core/interfaces/properties-btns-data';

@Component({
    selector: 'oct-list-properties',
    templateUrl: './list-properties.component.html',
    styleUrls: ['./list-properties.component.scss']
})
export class ListPropertiesComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() isExpand: boolean;
    @ViewChild(CodePreviewComponent) codePreview: CodePreviewComponent;

    tool;
    componentDestroy$ = new Subject();
    location: string;
    locations: typeof Locations = Locations;
    data: Array<object>;
    connectionsIds: string;
    codePreviewScript: string;
    codePreviewSearchTerm: string;
    isGroup: boolean;
    source: any[] = [];
    target: any[] = [];
    linkBetweenNodes;
    c2CDisplayProperties = [];
    public showUsage: boolean = false;
    usageData: any = {};
    private properties: object;
    private propertiesObj: any; // TODO: after done with compare 5 levels - remove the comment
    private propertiesContentMaxHeight = '800px';

    constructor(
        private store: Store<any>,
        private utilsService: UtilsService,
        private mapsService: MapsService,
        private moduleUtilsService: ModuleUtilsService,
        private mapIDService: MapIDService,
        private pageConfigService: PageConfigService,
        // private toastrService: ToastrService,
        private lineageService: LineageService,
        private sideBarService: SideBarService,
        private dialog: MatDialog,
        private referenceTableService: ReferenceTableService,
        private lineageIDService: LineageIDService,
        private codePreviewService: CodePreviewService,
        private searchFacade: SearchFacadeService,
        public propertiesBtnsService: PropertiesBtnsService,
        public ngxPermissionsService: NgxPermissionsService
    ) {
    }

    ngAfterViewInit(): void {

    }

    async ngOnInit() {
        this.showUsage = await this.checkUsagePermission(permissions.usage);
        this.propertiesBtnsService.initPropertiesBtnsList();

        this.store
            .pipe(
                takeUntil(this.componentDestroy$),
                select(getLocation),
            )
            .subscribe(location => {
                this.location = this.moduleUtilsService.getLocationPreferredKey(location);
                switch (this.location) {
                    case Locations.e2eColumn:
                        this.store
                            .pipe(
                                takeUntil(this.componentDestroy$),
                                select(selectE2EPropertiesObj)
                            )
                            .subscribe(async (node) => {
								if (node) {
									this.showUsage = await this.checkUsagePermission(permissions.usage);
									this.showUsage = this.showUsage && node.showUsage;
									if (this.showUsage) {
										const propertiesObj: PropertiesObj = {};
										propertiesObj.tool = node.toolName;
										propertiesObj.path_folder = node.containerObjectPath;
										propertiesObj.connection_logic_name = node.connLogicName;
										this.initUsageData(propertiesObj);
									}
								}
								this.convertPropertiesObjToDisplay(node);
							});
                        break;
                    case Locations.compareEtls:
                        this.store
                            .pipe(
                                takeUntil(this.componentDestroy$),
                                select(getCompareETLPropertiesObj)
                            )
                            .subscribe(() => {
                                this.getDataAndBuildObj();
                            });
                        break;
                    case Locations.compareReports:
                        this.store
                            .pipe(
                                takeUntil(this.componentDestroy$),
                                select(getCompareREPORTPropertiesObj)
                            )
                            .subscribe(() => {
                                this.getDataAndBuildObj();
                            });
                        break;
                    case Locations.gsp:
                        this.getDataAndBuildObj();
                        break;
                    case Locations.lineageSchema:
                    case Locations.liveVisualizer:
                        this.store
                            .pipe(
                                takeUntil(this.componentDestroy$),
                                select(getLineagePropertiesObj)
                            )
                            .subscribe(() => {
                                this.getDataAndBuildObj();
                            });
                        break;
                    case Locations.map:
                        this.showUsage = false;
                        this.store
                            .pipe(
                                takeUntil(this.componentDestroy$),
                                select(getLineagePropertiesObj)
                            )
                            .subscribe(() => {
                                this.getDataAndBuildObj();
                            });
                        break;

                    case Locations.discovery:
                        this.store
                            .pipe(
                                takeUntil(this.componentDestroy$),
                                select(getDiscoveryProperties)
                            )
                            .subscribe(() => {
                                this.getDataAndBuildObj();
                            });
                        break;
                }
                if (this.sideBarService.isOpen) {
                    this.data = [];
                    this.buildDataObj();
                }
            });

        this.store
            .pipe(
                takeUntil(this.componentDestroy$),
                select(selectQuickSideBar),
                map(value => value.status === 'open'),
            )
            .subscribe(this.setScript.bind(this));
        if (this.showUsage) {
            this.initUsageData(null);
        }
    }

    initUsageData(propertiesObj) {
        this.mapIDService.propertiesObjSubject
            .pipe(
                switchMap((res: any) => {
                    if (!res) {
                        return of(null);
                    }
                    return this.store.pipe(
                        select(getUser),
                        map((user: any) => {
                            if (!user) {
                                return null;
                            }
                            const usagePayload: UsagePayload = {
                                tool: res?.tool || propertiesObj?.tool,
                                queryNum: 2,
                                containerObjPath: res?.path_folder || propertiesObj?.path_folder,
                                connLogicName: res?.connection_logic_name || propertiesObj?.connection_logic_name,
                                userId: user?.id
                            };
                            return this.isUsagePayloadValid(usagePayload) ? usagePayload : null;
                        }),
                        switchMap((usagePayload: UsagePayload | null) => {
                            if (!usagePayload) {
                                return of(null);
                            }
                            return this.propertiesBtnsService.getUsageData(usagePayload);
                        })
                    );
                })
            )
            .subscribe(
                (data: any) => {
                    if (data) {
                        try {
                            this.usageData = JSON.parse(data.res)[0];
                        } catch (error) {
                            console.error('Error parsing usage data:', error);
                        }
                    }
                },
                (error: any) => {
                    console.error('Error retrieving usage data:', error);
                }
            );


    }

    isUsagePayloadValid(usagePayload: UsagePayload): boolean {
        return !!(usagePayload.tool && usagePayload.queryNum && usagePayload.containerObjPath && usagePayload.connLogicName && usagePayload.userId);
    }

    async checkUsagePermission(permission: string): Promise<boolean> {
        try {
            const hasPermission = await this.ngxPermissionsService.hasPermission(permission);
            return hasPermission;
        } catch (error) {
            console.error(`Error checking permission for ${permission}:`, error);
            return false;
        }
    }


    buildPropertiesObject(properties: any) {
        const temp = [];
        for (const i in properties) {
            // if user click on node or if user click on map - link fx
            if (i.indexOf('val_') > -1) {
                // don't show empty rows from ref tools
                if (properties[i].value) {
                    const index = i.substr(i.indexOf('_') + 1);
                    const name = _.find(properties, function (value, key) {
                        if (key === ('obj_' + index)) {
                            return value;
                        }
                    });

                    const _obj = {
                        key: name,
                        value: properties['val_' + index],
                        itemNumber: i
                    };
                    temp.push(_obj);
                }
            }
        }
        return temp;
    }

    public getStyle() {
        return {
            height: this.propertiesContentMaxHeight,
            overflow: 'auto'
        };
    }

    ngOnDestroy(): void {
        console.log('list properties destroy');
        this.codePreviewService.setScriptOnListProperties('');
        this.componentDestroy$.next();
        this.componentDestroy$.unsubscribe();
    }

    private setScript() {
        console.log('list properties open');
        const script$ = this.store.pipe(select(getScriptOnListProperties));
        const searchValue$ = this.store.pipe(select(selectSearchValue));
        combineLatest([script$, searchValue$])
            .pipe(
                debounceTime(500),
                take(1),
            )
            .subscribe(results => {
                const data = {
                    script: results[0],
                    searchValue: results[1],
                };
                if (this.codePreview) {
                    this.codePreview.content = data.script;
                    this.codePreview.onSearch(data.searchValue);
                }
                this.codePreviewSearchTerm = data.searchValue;
            });
    }

    private getDataAndBuildObj(): void {
        this.data = [];
        this.buildDataObj();
    }

    private buildDataObj(): void {
        switch (this.location) {
            case Locations.lineageSchema:
            case Locations.map:
            case Locations.liveVisualizer:
                const selectorA = this.store.pipe(select(getLineageProperties));
                const selectorB = this.store.pipe(select(getLineagePropertiesObj));

                combineLatest([selectorA, selectorB])
                    .subscribe((results: any) => {
                        this.properties = results[0];
                        this.propertiesObj = results[1];
                        this.tool = results[1].tool;

                        if (!!this.properties && !!this.properties['obj_4'] &&
                            this.properties['obj_4'].indexOf('Script') >= 0 &&
                            (this.propertiesObj['iconType'].indexOf('Script Task') >= 0 || this.propertiesObj['iconType'].indexOf('Execute SQL Task') >= 0)) {
                            this.properties['val_4'].value = this.propertiesObj['sql_text'];
                        }

                        this.propertiesBtnsService.propertiesBtns = [];
                        if ((this.location.indexOf('compare') > -1) && !_.isEmpty(this.propertiesObj) && !this.isFakeNode()
                            || (this.location === Locations.discovery && this.propertiesObj.is_parse_text_to_map && this.propertiesObj.is_parse_text_to_map.needToOpen)) {
                            this.propertiesBtnsService.getCorrectPropertiesBtn(this.propertiesObj, this.location);
                        }
                    });

                this.store.pipe(
                    select(getLineageSelectedConnectionsIDs)
                ).subscribe(selected_connections_ids => {
                    this.connectionsIds = selected_connections_ids;
                });
                break;
            case Locations.discovery:
                const a$ = this.store.pipe(select(getDiscoveryProperties));
                const b$ = this.store.pipe(select(getDiscoveryPropertiesObj));
                const c$ = this.store.pipe(select(getDiscoverySelectedConnectionsIDs));
                combineLatest([a$, b$, c$])
                    .pipe(
                        take(1)
                    )
                    .subscribe((results: any[]) => {
                        this.properties = results[0];
                        this.propertiesBtnsService.propertiesBtns = [];
                        this.propertiesObj = results[1];
                        this.tool = results[1].tool;
                        if (this.propertiesObj.is_parse_text_to_map && this.propertiesObj.is_parse_text_to_map.needToOpen) {
                            this.propertiesBtnsService.getCorrectPropertiesBtn(this.propertiesObj, Locations.discovery);
                        }
                        this.connectionsIds = results[2];
                    });
                break;
            case Locations.e2eColumn:
                this.store.pipe(
                    select(selectE2EPropertiesObj)
                ).subscribe(properties_obj => {
                    this.propertiesObj = properties_obj;
                });
                break;
            case Locations.compareEtls:
                this.store
                    .pipe(
                        select(getCompareETLProperties),
                    )
                    .subscribe(properties => {
                        this.properties = properties;
                    });
                this.store
                    .pipe(
                        select(getCompareETLPropertiesObj),
                    )
                    .subscribe(properties_obj => {
                        this.propertiesObj = properties_obj;
                    });
                this.store
                    .pipe(
                        select(getCompareETLSelectedConnectionsIDs),
                    )
                    .subscribe(selected_connections_ids => {
                        this.connectionsIds = selected_connections_ids;
                    });
                break;
            default:
                this.store
                    .pipe(
                        select(getCompareREPORTProperties),
                    )
                    .subscribe(properties => {
                        this.properties = properties;

                    });
                this.store
                    .pipe(
                        select(getCompareREPORTPropertiesObj),
                    )
                    .subscribe(properties_obj => {
                        this.propertiesObj = properties_obj;
                    });
                this.store
                    .pipe(
                        select(getCompareREPORTSelectedConnectionsIDs),
                    )
                    .subscribe(selected_connections_ids => {
                        this.connectionsIds = selected_connections_ids;
                    });
        }

        // this.propertiesBtnsService.propertiesBtns = [];
        // if ((this.location === Locations.map || this.location.indexOf('compare') > -1) && !_.isEmpty(this.propertiesObj) && !this.isFakeNode()
        // 	|| (this.location === Locations.discovery &&  this.propertiesObj.is_parse_text_to_map && this.propertiesObj.is_parse_text_to_map.needToOpen)) {
        // 	this.propertiesBtnsService.getCorrectPropertiesBtn(this.propertiesObj, this.location);
        // }

        if (this.properties) {
            if (this.properties['isGroup']) { // click on link between ssis to sql
                this.isGroup = true;
                this.source = this.buildPropertiesObject(this.properties['source']);
                this.target = this.buildPropertiesObject(this.properties['target']);

                const findResult = this.target.find(item => item.key === 'name');
                if (findResult) {
                    findResult['key'] = 'DB Object name';
                }
            } else {
                this.isGroup = false;
                this.data = this.buildPropertiesObject(this.properties);
            }
            if (this.properties['link']) { // click on all the links
                this.linkBetweenNodes = this.buildPropertiesObject(this.properties['link']);
            } else {
                this.linkBetweenNodes = [];
            }
        }
    }

    // check if this node is a fake node
    private isFakeNode(): boolean {
        if (this.location === Locations.lineage || this.location.indexOf('compare') > -1) {
            if (!this.propertiesObj.linkType &&
                ((this.propertiesObj.module === 'DATABASE' && this.propertiesObj.tool === 'TERADATA' && (!this.propertiesObj.name || !this.propertiesObj.db_name)) ||
                    (this.propertiesObj.module === 'DATABASE' && this.propertiesObj.tool !== 'TERADATA' && (!this.propertiesObj.name || !this.propertiesObj.db_name || !this.propertiesObj.schema)) ||
                    (this.propertiesObj.module === 'ETL' && (!this.propertiesObj.name || !this.propertiesObj.path_folder)) || // || !d.etl_sequencer
                    (this.propertiesObj.module === 'REPORT' && !this.propertiesObj.path_folder) ||
                    (!this.propertiesObj.data_right_click && !this.propertiesObj.connection_logic_name))) { // if it's a fake db, but have db and schema, don't show properties. we use connection login name to handle it
                return true;
            }
            return false;
        } else {
            return false;
        }
    }

    private calcPropertiesConetntMaxHeight() {
        this.propertiesContentMaxHeight = (document.getElementById('app-content-body').offsetParent['offsetHeight'] - 120) + 'px';
    }

    private convertPropertiesObjToDisplay(node: any) {
        const properties = this.buildObjectPropertiesInterface(node);
        this.buildProperties(node, properties);
    }

    private buildObjectPropertiesInterface(node) {
        const result = {};
        node?.properties?.forEach(field => {
            result[field] = node[field];
        });
        return result;
    }

    private buildProperties(node, properties: any) {
        this.c2CDisplayProperties = [];

        if (!node) {
            return;
        }

        if (node.toolName === 'UNK') {
            this.c2CDisplayProperties.push({
                key: 'Object Name',
                value: node.name,
            });
        }

        const headers = this.referenceTableService.headers.filter(headerItem => node.toolName &&
            headerItem.TOOL === node.toolName &&
            headerItem.TOOL_TYPE === node.moduleName &&
            headerItem.QUERY_NUMBER === 'E2E' &&
            headerItem.POSITION !== 0);

        for (const dataItemKey in properties) {
            const key = headers.find(item => item.ORIGINAL_VALUE.toLowerCase() === dataItemKey.toLowerCase());
            if (key && properties[dataItemKey]) {
                this.c2CDisplayProperties.push({
                    key: key.VALUE_TO_SHOW,
                    value: dataItemKey === 'toolName' ? this.moduleUtilsService.shortToLongToolNameToDisplay(properties[dataItemKey]) : properties[dataItemKey],
                    position: key.TOOLTIP_INFORMATION,
                });
            }
        }
        this.c2CDisplayProperties = this.c2CDisplayProperties.sort((n1, n2) => {
            if (n1.position > n2.position) {
                return 1;
            }
            if (n1.position < n2.position) {
                return -1;
            }
            return 0;
        });

    }
}

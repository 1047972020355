import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'oct-job-titles',
  templateUrl: './job-titles.component.html',
  styleUrls: ['./job-titles.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JobTitlesComponent),
      multi: true
    }
  ],
})
export class JobTitlesComponent implements OnInit, ControlValueAccessor {
  public jobTitles: any[];

  constructor() {
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(jobTitles: any[]): void {
    this.jobTitles = jobTitles;
  }

  onClick(jobTitle: any) {
    jobTitle.checked = !jobTitle.checked;
    this.onChange(this.jobTitles);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'htmlBold'
})
export class HtmlBoldPipe implements PipeTransform {
	transform(value: string, startIndex: number, endIndex?: number): string {
		if (endIndex) {
			return `${value.slice(0, startIndex)}<b>${value.substring(startIndex, endIndex)}</b>${value.slice(endIndex)}`;
		}
		return `${value.slice(0, startIndex)}<b>${value.substring(startIndex)}<b>`;
	}

}

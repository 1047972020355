<a class="m-nav__link m-dropdown__toggle pointer list-box" matTooltip="Recent Activities">
	<span class="m-nav__link-icon list-box">
		<span class="m-nav__link-icon-wrapper list-box" (click)="open()">
				<img class="list-box" src="./assets/recent_activity_toggle.svg"/>
		</span>
	</span>
</a>

<div (clickOutside)="closeQuickAction()" [excludeClasses]="['list-box', 'hidden-item-exclude-div']"
	 [ngClass]="{active: !modalClosed, filterIsOpen: filterIsOpen, filterIsClose: !filterIsOpen}" class="m-dropdown__wrapper">
	<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust tooltip-arrow-color"></span>
	<div class="m-dropdown__inner">
		<div class="m-dropdown__header m--align-center topbar-popover">
			<span class="m-dropdown__header-subtitle">
				Recent Activities
			</span>
			<mat-icon svgIcon="close-icon" class="close-btn" matTooltip="Cancel" (click)="closeQuickAction()"></mat-icon>
		</div>
		<div class="m-dropdown__body m--padding-bottom-0">
			<div class="m-dropdown__content">
				<oct-list-timeline (close)="closeQuickAction()" (filterIsOpen)="changeBoxSize($event)" [isFocus]="isFocus$ | async" ></oct-list-timeline>
			</div>
		</div>
	</div>
</div>

import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'm-add-item-toaster',
	templateUrl: './add-item-toaster.component.html',
	styleUrls: ['./add-item-toaster.component.scss'],
	animations: [
		trigger('flyInOut', [
			state('inactive', style({
				opacity: 0,
			})),
			transition('inactive => active', animate('400ms ease-out', keyframes([
				style({
					transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
					opacity: 0,
				}),
				style({
					transform: 'skewX(20deg)',
					opacity: 1,
				}),
				style({
					transform: 'skewX(-5deg)',
					opacity: 1,
				}),
				style({
					transform: 'none',
					opacity: 1,
				}),
			]))),
			transition('active => removed', animate('400ms ease-out', keyframes([
				style({
					opacity: 1,
				}),
				style({
					transform: 'translate3d(100%, 0, 0) skewX(30deg)',
					opacity: 0,
				}),
			]))),
		]),
	],
})
export class AddItemToasterComponent extends Toast {
	@Input() toasterData: any;

	constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
		super(toastrService, toastPackage);
	}

	openNewItem() {
		this.toastPackage.triggerAction('redirect');
	}
}

import { IExcelTemplate } from '@shared/services/excel-utils.service';

export const AssetTypesMapping = {
    'ETL': 'Process',
    'SQL_SCALAR_FUNCTION': 'SQL Scalar Function',
    'SQL_STORED_PROCEDURE': 'SQL Stored Procedure',
    'SQL_TABLE_VALUED_FUNCTION': 'SQL Table Valued Function',
    'SQL_INLINE_TABLE_VALUED_FUNCTION': 'SQL Inline Table Valued Function',
    'MeasureGroup': 'Measure Group',
    'Data Set': 'Dataset',
    'Data Catalog': ' Knowledge Hub',
};

export const CATALOG_EXCEL_TEMPLATE: IExcelTemplate[] = [
    {colName: 'Layer', field: 'lAYER'},
    {colName: 'Tool', field: 'tOOL_NAME'},
    {colName: 'Asset Type', field: 'oBJECT_TYPE'},
    {colName: 'Object Name', field: 'oBJECT_NAME'},
    {colName: 'Object Path', field: 'oBJECT_PATH'},
    {colName: 'Data Type', field: 'dATA_TYPE'},
    {colName: 'Rating', field: 'rATING'},
    {colName: 'Status', field: 'aBG_STATUS'},
    {colName: 'Sensitive', field: 'iS_SENSITIVE'},
    {colName: 'Original Description', field: 'oBJECT_DESCRIPTION'},
    {colName: 'Original Calculation', field: 'eXPRESSION'},
    {colName: 'Description', field: 'oCT_OBJECT_DESCRIPTION'},
    {colName: 'Technical Description', field: 'oCT_SHORT_DESCRIPTION'},
    {colName: 'Calculation Description', field: 'oCT_CALCULATION_DESCRIPTION'},
    {colName: 'Source System', field: 'sOURCE_SYSTEM'},
    {colName: 'Data Owner email', field: 'oWNER'},
    {colName: 'Data Steward email', field: 'dATA_STEWARD'},
    {colName: 'Tags', field: 'tAG_NAMES'},
];

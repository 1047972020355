import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { State } from '@store/users/index';
import { Action, createReducer, on } from '@ngrx/store';
import { getUsersSuccess } from '@store/users/actions';

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
    selectId: (model: any) => model.useR_ID,
});
export const initialState: State = adapter.getInitialState();
const usersReducer = createReducer(
        initialState,
        on(getUsersSuccess, (state, {users}) => {
            return adapter.setAll(users, state);
        })
);

export function reducer(state: State, action: Action) {
    return usersReducer(state, action);
}

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

// select the array of user ids
export const selectUserIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllUsers = selectAll;

// select the total user count
export const selectUserTotal = selectTotal;

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IDates } from '@main/adc/adc-shared/components/date-range-picker/date-range-picker.component';
import { ViewType } from '@main/adc/adc-shared/types/viewType';
import { IABGItem, IABGTag } from '@main/adc/adc-store';
import { ISearch } from '@store/search';
import { Locations } from '@shared/enums/locations.enum';

export interface IAbgDashboardState {
    total?: number;
    viewType?: ViewType;
    insightType?: ViewType;
    active?: number;
    missing?: number;
    suspended?: number;
    dropped?: number;
    details?: any[];
    detailsLoading?: boolean;
    dates?: IDates;
    topTags?: IABGTag[];
    abgItem?: IABGItem;
    searchFilters?: any;
    filtered?: boolean;
	filterOpen?: boolean;
}

export const getAbgDashboardState = createFeatureSelector<IAbgDashboardState>('adc-dashboard');
export const selectAdcDashboardSearchFilters = createSelector(getAbgDashboardState, state => state?.searchFilters);
export const selectFiltered = createSelector(getAbgDashboardState, state => state.filtered);
export const selectFilterState = createSelector(getAbgDashboardState, state => state.filterOpen);
export const selectDashboardTotal = createSelector(getAbgDashboardState, state => state.total);
export const selectChartDetails = createSelector(getAbgDashboardState, state => state.details);
export const selectChartDetailsLoading = createSelector(getAbgDashboardState, state => state.detailsLoading);
export const selectDashboardDates = createSelector(getAbgDashboardState, state => state.dates);
export const selectDashboardViewType = createSelector(getAbgDashboardState, state => state.viewType);
export const selectInsightType = createSelector(getAbgDashboardState, state => state.insightType);
export const selectDashboardTopTags = createSelector(getAbgDashboardState, state => state.topTags);
export const selectInsightTotal = (viewType: ViewType) => createSelector(
    getAbgDashboardState,
    state => state[ViewType[viewType].toLocaleLowerCase()]
);
export const selectSuspend = createSelector(getAbgDashboardState, state => state.suspended);

export const selectDashboardAbgItem = createSelector(getAbgDashboardState, state => state.abgItem);
export const selectAdcItemRateAvg = createSelector(selectDashboardAbgItem, state => state?.aVG || 0);
export const selectAdcItemRateCount = createSelector(selectDashboardAbgItem, state => state?.rATE_COUNT || 0);
export const selectAdcItemRates = createSelector(selectDashboardAbgItem, state => state?.rATES || []);
export const selectAdcItemRate = createSelector(selectDashboardAbgItem, state => state?.rATE || 0);

export const selectSearchState = createFeatureSelector<ISearch>('search');
export const selectDashboardSearch = createSelector(selectSearchState, state => state[Locations.adcDashboard]);

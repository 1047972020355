import { Action } from '@ngrx/store';
import { LineageState } from '@store/lineage/index';


export const ADD_LINEAGE = '[LINEAGE] Add';
export const REMOVE_LINEAGE = '[LINEAGE] Remove';
export const UPDATE_LINEAGE_HISTORY_LIST = '[LINEAGE] Update History List';
export const UPDATE_LINEAGE_PROPERTIES = '[LINEAGE] Update Properties';
export const UPDATE_LINEAGE_PROPERTIES_OBJ = '[LINEAGE] Update Properties Obj';
export const UPDATE_LINEAGE_COUNT = '[LINEAGE] Update Count';
export const UPDATE_LINEAGE_SELECTED_CONNECTIONS_OBJS = '[LINEAGE] Update Selected Connections Objs';
export const UPDATE_LINEAGE_SELECTED_CONNECTIONS_IDS = '[LINEAGE] Update Selected Connections IDs';
export const UPDATE_LINEAGE_CONNECTIONS_LIST = '[LINEAGE] Update Connections List';
export const UPDATE_SEARCH_MODEL_SCANNING = '[LINEAGE] Update Search Model Scanning';
export const SET_LINEAGE_MAP_INFO = '[LINEAGE] Update lineage map data';

export enum lineageActionTypes {
	RESET_QUERIES = '[LINEAGE] Reset queries data',

  // RESET_LINEAGE_DATA = '[LINEAGE] Reset lineage data',
  // RESET_LINEAGE_DATA_SUCCESS = '[LINEAGE] Reset lineage dat success',
  // RESET_LINEAGE_DATA_FAILURE = '[LINEAGE] Reset lineage data failure',

	GET_LINEAGE_DATA = '[LINEAGE] Get lineage data',
	GET_LINEAGE_DATA_SUCCESS = '[LINEAGE] Get lineage data success',
  // GET_LINEAGE_DATA_FAILURE = '[LINEAGE] Get lineage data failure',
	GET_LINEAGE_SEARCH_VALUE = '[LINEAGE] Get lineage search values',
  // FETCH_MORE_LINEAGE_DATA = '[LINEAGE] fetch data',
  // FETCH_MORE_LINEAGE_DATA_SUCCESS = '[LINEAGE] fetch data success',
  // FETCH_MORE_LINEAGE_DATA_FAILURE = '[LINEAGE] fetch data failure',

	SET_LINEAGE_TOTALS = '[LINEAGE] Set the chart data',
	SET_LINEAGE_RESULTS = '[LINEAGE] Set the list of results',

	SET_LINEAGE_CONNECTIONS = '[LINEAGE] set lineage connections',
	SET_LINEAGE_FLAT_CONNECTIONS = '[LINEAGE] set lineage flat connections',
	SET_MAP_SEARCH = '[LINEAGE] set map search value',
	SET_CONTROL_FLOW_SEARCH_VALUE = '[LINEAGE] set control flow search value',
	SET_LOOP_CONTAINER_SEARCH_VALUE = '[LINEAGE] set loop container search value',
	SET_LOADING_AND_DISABLE_DIAGRAM = '[LINEAGE] disable the diagram and show loader',
}

export class ResetQueries implements Action {
	readonly type = lineageActionTypes.RESET_QUERIES;
}

export class SetLineageSearch implements Action {
	readonly type = lineageActionTypes.SET_MAP_SEARCH;

	constructor(public payload: any) {
	}
}

export class SetControlFlowSearchValue implements Action {
	readonly type = lineageActionTypes.SET_CONTROL_FLOW_SEARCH_VALUE;

	constructor(public payload: any) {
	}
}

export class SetLoopContainerSearchValue implements Action {
	readonly type = lineageActionTypes.SET_LOOP_CONTAINER_SEARCH_VALUE;

	constructor(public payload: any) {
	}
}

export class SetLoadingAndDisableDiagram implements Action {
	readonly type = lineageActionTypes.SET_LOADING_AND_DISABLE_DIAGRAM;

	constructor(public payload: { loadingAndDisableDiagram }) {
	}
}

export class SetLineageTotals implements Action {
	readonly type = lineageActionTypes.SET_LINEAGE_TOTALS;
}

export class SetLineageResults implements Action {
	readonly type = lineageActionTypes.SET_LINEAGE_RESULTS;
}

// export class ResetLineageData implements Action {
// 	readonly type = lineageActionTypes.RESET_LINEAGE_DATA;
//
// 	constructor(public payload: SearchInfo) {
// 	}
// }

// export class ResetLineageDataSuccess implements Action {
// 	readonly type = lineageActionTypes.RESET_LINEAGE_DATA_SUCCESS;
//
// 	constructor(public payload: any) {
// 	}
// }
//
// export class ResetLineageDataFailure implements Action {
// 	readonly type = lineageActionTypes.RESET_LINEAGE_DATA_FAILURE;
//
// 	constructor(public payload: any) {
// 	}
// }

// export class FetchMoreLineageData implements Action {
// 	readonly type = lineageActionTypes.FETCH_MORE_LINEAGE_DATA;
//
// 	constructor(public payload: FetchMore) {
// 	}
// }
//
// export class FetchMoreLineageDataSuccess implements Action {
// 	readonly type = lineageActionTypes.FETCH_MORE_LINEAGE_DATA_SUCCESS;
//
// 	constructor(public payload: any, public queryNumber: number) {
// 	}
// }
//
// export class FetchMoreLineageDataFailure implements Action {
// 	readonly type = lineageActionTypes.FETCH_MORE_LINEAGE_DATA_FAILURE;
//
// 	constructor(public payload: any) {
// 	}
// }

// export class GetLineageData implements Action {
// 	readonly type = lineageActionTypes.GET_LINEAGE_DATA;
//
// 	constructor(public payload?: SearchInfo) {
// 	}
// }
//
// export class GetLineageDataSuccess implements Action {
// 	readonly type = lineageActionTypes.GET_LINEAGE_DATA_SUCCESS;
//
// 	constructor(public payload: any) {
// 	}
// }

export class SetLineageSearchValue implements Action {
	readonly type = lineageActionTypes.GET_LINEAGE_SEARCH_VALUE;

	constructor(public payload: { queryNum: number, value: string }) {
	}
}

// export class GetLineageDataFailure implements Action {
// 	readonly type = lineageActionTypes.GET_LINEAGE_DATA_FAILURE;
//
// 	constructor(public payload: any) {
// 	}
// }

export class SetLineageMapInfo implements Action {
	readonly type = SET_LINEAGE_MAP_INFO;

	constructor(public payload: any) {
	}
}

export class AddLineage implements Action {
	readonly type = ADD_LINEAGE;

	constructor(public payload: LineageState) {
	}
}

export class RemoveLineage implements Action {
	readonly type = REMOVE_LINEAGE;

	constructor(public payload: number) {
	}
}

export class UpdateLineageCount implements Action {
	readonly type = UPDATE_LINEAGE_COUNT;

	constructor(public payload: { count: string }) {
	}
}

export class UpdateLineageSelectedConnectionsObjs implements Action {
	readonly type = UPDATE_LINEAGE_SELECTED_CONNECTIONS_OBJS;

	constructor(public payload: { selected_connections_objs: Array<any> }) {
	}
}

export class UpdateLineageSelectedConnectionsIDs implements Action {
	readonly type = UPDATE_LINEAGE_SELECTED_CONNECTIONS_IDS;

	constructor(public payload: { selected_connections_ids: string }) {
	}
}

export class UpdateLineageHistoryList implements Action {
	readonly type = UPDATE_LINEAGE_HISTORY_LIST;

	constructor(public payload: { history_list: Array<object> }) {
	}
}

export class UpdateLineageProperties implements Action {
	readonly type = UPDATE_LINEAGE_PROPERTIES;

	constructor(public payload: { properties: object }) {
	}
}

export class UpdateLineagePropertiesObj implements Action {
	readonly type = UPDATE_LINEAGE_PROPERTIES_OBJ;

	constructor(public payload: { properties_obj: object }) {
	}
}

export class UpdateLineageConnectionsList implements Action {
	readonly type = UPDATE_LINEAGE_CONNECTIONS_LIST;

	constructor(public payload: { connections_list: object }) {
	}
}

export class UpdateSearchModelScanning implements Action {
	readonly type = UPDATE_SEARCH_MODEL_SCANNING;

	constructor(public payload: any) {
	}
}

export class SetLineageConnections implements Action {
	readonly type = lineageActionTypes.SET_LINEAGE_CONNECTIONS;

	constructor(public payload: any) {
	}
}

export class SetLineageFlatConnections implements Action {
	readonly type = lineageActionTypes.SET_LINEAGE_FLAT_CONNECTIONS;

	constructor(public payload: any) {
	}
}

export type lineageActions =
// ResetLineageData |
// ResetLineageDataSuccess |
// ResetLineageDataFailure |
// GetLineageData |
// GetLineageDataSuccess |
	SetLineageSearchValue |
  // GetLineageDataFailure |
  // FetchMoreLineageData |
  // FetchMoreLineageDataSuccess |
  // FetchMoreLineageDataFailure |
	AddLineage |
	RemoveLineage |
	UpdateLineageConnectionsList |
	UpdateLineageHistoryList |
	UpdateLineageProperties |
	UpdateLineagePropertiesObj |
	UpdateLineageCount |
	UpdateLineageSelectedConnectionsObjs |
	UpdateLineageSelectedConnectionsIDs |
	UpdateSearchModelScanning |
	SetLineageMapInfo |
	SetLineageTotals |
	SetLineageResults |
	SetLineageConnections |
	SetLineageFlatConnections |
	SetLoadingAndDisableDiagram |
	SetLineageSearch |
	SetControlFlowSearchValue |
	SetLoopContainerSearchValue;

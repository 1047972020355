<m-portlet>
	<ng-container mPortletHeadTitle>
		<div class="m-portlet__head-title">
			<h3 class="m-portlet__head-text">
				Search in Discovery
			</h3>
		</div>
	</ng-container>

	<ng-container mPortletBody>
		<button mat-raised-button color="primary">Reports</button>
		<button mat-raised-button color="primary">Models</button>
		<button mat-raised-button color="primary">Database</button>
		<button mat-raised-button color="primary">ETL</button>
	</ng-container>
</m-portlet>

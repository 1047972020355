import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { NgxPermissionsService } from 'ngx-permissions';
import { permissions } from '@shared/enums/permissions';
import { HttpUtilsService } from '@core/services/http-utils.service';

@Injectable({
  providedIn: 'root'
})
export class SideBySideService {
  mapAsJson: any;
  saveStateScript: { script: string, vendor: string, url?: string, serialNumber: any };
  scriptFromAnalysis: string;
  vendorFromAnalysis: string;
  data;
  currentMapName;
  enableMapAnimation = true;
  coordinatesArr: { coordinates: string, elId: string }[] = [];
  // DBTypeOptions: Vendor[] = VENDORS.filter(vendor => vendor.enabledForFlow);
  vendor: string;
  editor: any;
  showBanner: boolean;
  selectedTabIndex = 0;
  private bypassClient: HttpClient;

  constructor(
    private handler: HttpBackend,
    private ngxPermissions: NgxPermissionsService,
    private http: HttpUtilsService,
  ) {
    this.bypassClient = new HttpClient(handler);
    const showLiveLineageBanner = localStorage.getItem('showLiveLineageBanner');
    this.showBanner = !showLiveLineageBanner || showLiveLineageBanner === 'true';
  }

  createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  highlightScriptFunc(coordinates, color) {
    const coordinateItem = this.coordinatesArr.find(a => a.coordinates === coordinates);
    if (coordinateItem) {
      const elArr = Array.from(document.getElementsByClassName(`cm-mark-${coordinateItem.elId}`));
      elArr.forEach((el: any) => {
        el.style.backgroundColor = color || 'transparent';
      });
    }
  }

  setHighlight(data, editor, isAnonymous = false) {
    const applyLiveLineage = isAnonymous ? true : !!this.ngxPermissions.getPermission(permissions.liveLineage);
    if (!editor || !applyLiveLineage) {
      return data;
    }

    editor.clearGSPMarkers();
    this.coordinatesArr = [];
    const _data = data.map(item => {
      const _item = {
        ...item,
        highlightScriptFunc: this.highlightScriptFunc.bind(this),
      };

      if (item.SOURCE_COORDINATE && !this.coordinatesArr.find(a => a.coordinates === item.SOURCE_COORDINATE)) {
        const sourceCoordinate = JSON.parse('[' + item.SOURCE_COORDINATE + ']');
        const id = editor.setGspTextMark(sourceCoordinate, item.SOURCE_COLUMN);
        this.coordinatesArr.push({
          coordinates: item.SOURCE_COORDINATE,
          elId: id,
        });
      }

      if (item.TARGET_COORDINATE && !this.coordinatesArr.find(a => a.coordinates === item.TARGET_COORDINATE)) {
        const targetCoordinate = JSON.parse('[' + item.TARGET_COORDINATE + ']');
        const id = editor.setGspTextMark(targetCoordinate, item.TARGET_COLUMN);
        this.coordinatesArr.push({
          coordinates: item.TARGET_COORDINATE,
          elId: id,
        });
      }
      return _item;
    });
    editor.setGspMarksEvents();
    return _data;
  }
}

<div class="row-title">
    {{ title }}
    <span *ngIf="formControl.hasError('required')">*</span>
</div>
<input [formControl]="formControl"
       [ngClass]="{'input-error': formControl.invalid && (formControl.dirty || formControl.touched)}"
       class="form-input" id="text-input-{{key}}"
       matInput octRtl placeholder="{{placeholder}}"
       type="text">

<div *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)"
     [ngClass]="{'has-danger': formControl.invalid && (formControl.dirty || formControl.touched)}"
     class="error-msg-container">
    <div *ngIf="formControl.hasError('required')" class="form-control-feedback">
        {{ title }} is required
    </div>
    <div *ngIf="formControl.hasError('minlength')" class="form-control-feedback">
        {{ title }} must be at least 2 characters long
    </div>
</div>

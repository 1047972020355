import { Injectable, OnDestroy } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getLocation } from '@store/common';
import { SearchType } from '@shared/enums/searchType';
import { Observable, Subject } from 'rxjs';
import { Locations } from '@shared/enums/locations.enum';
import { IAdvancedSearchParams, IAdvancedSearchValue, ISearchParams, ISearchTag, selectAdvancedSearch, selectAdvancedSearchParams, selectDefaultSearch, selectSearchInputValue, selectSearchTags, selectSearchType, selectSearchValues } from '@store/search';
import { Init, SetAdvancedSearchParams, SetAutoCompleteSearchValue, SetDefaultSearch, SetSearchPlaceholder, SetSearchTags, SetSearchType, SetSearchValue } from '@store/search/actions';
import { getAbgTotal, IObgResults, selectAdcSearchFilters, selectResultsAndTotals, } from '@main/adc/adc-store/adc';
import { SetAddLinkSearchValue } from '@main/adc/adc-store/adc/actions';
import { DATE_FORMATS } from '@main/adc/adc-shared/types/dateFormats';
import { selectAdcDashboardSearchFilters } from '@main/adc/adc-store/adc-dashboard';
import { AbgSource } from '@main/adc/adc-shared/types/abgSource';

const moment = require('moment');

@Injectable({
	providedIn: 'root'
})
export class SearchFacadeService implements OnDestroy {
	public defaultSearchValue$ = new Subject<string>();
	public searchAll$ = new Subject();
	public clearSearch$ = new Subject();
	public searchValueChange$ = new Subject<string>();
	public searchResults$ = new Subject<any>();
	private serviceDestroy$ = new Subject();
	private registerToResults$: Subject<any>;

	constructor(
		private store: Store<any>,
	) {
	}

	public init() {
		this.store.dispatch(new Init());
	}

	public registerToSearchType(): Observable<SearchType> {
		return this.store
			.pipe(
				select(selectSearchType),
			);
	}

	public registerToDefaultSearch(location?: Locations): Observable<string> {
		return this.store
			.pipe(
				select(selectDefaultSearch(location)),
				filter(searchValue => !!searchValue || searchValue === ''),
			);
	}

	public registerToSearchTag() {
		return this.store
			.pipe(
				select(selectSearchTags),
				filter((searchTag: ISearchTag) => !!searchTag),
			);
	}

	public unregisterToGraphQLAdvancedSearch() {
		if (this.registerToResults$) {
			this.registerToResults$.next();
			this.registerToResults$.unsubscribe();
		}
	}

	public setSearchTags(tag?: ISearchTag) {
		let _location = null;
		this.store
			.pipe(
				select(getLocation),
				filter(location => !!location),
			)
			.subscribe(location => _location = location);

		this.store.dispatch(new SetSearchTags(tag, _location));
	}

	public setAutoCompleteSearchValue(searchValue: string, location: Locations) {
		this.store.dispatch(new SetAutoCompleteSearchValue(searchValue, location));
	}

	public setAddLinkSearchValue(searchValue) {
		this.store.dispatch(new SetAddLinkSearchValue(searchValue));
	}

	public setSearchPlaceholder(value) {
		this.store
			.pipe(
				select(getLocation),
				take(1),
			)
			.subscribe((location: Locations) => this.store.dispatch(new SetSearchPlaceholder(value, location)));
	}

	public getAdvancedSearch(): ISearchParams {
		let _advancedSearch = null;
		this.getAdvancedSearchAsync()
			.subscribe((advancedSearch: ISearchParams) => _advancedSearch = advancedSearch);
		return _advancedSearch;
	}

	public getAdvancedSearchAsync(): Observable<ISearchParams> {
		return this.store
			.pipe(
				select(selectAdvancedSearch),
				filter((advancedSearch: ISearchParams) => !!advancedSearch && !!advancedSearch.searchValues),
				take(1),
			);
	}

	public startAdvancedSearch(payload: ISearchParams) {
		let location: Locations = null;
		this.store
			.pipe(
				select(getLocation),
				filter(loc => !!loc),
				take(1),
			)
			.subscribe((loc: Locations) => location = loc);

		this.store.dispatch(new SetSearchValue({
			location,
			...payload
		}));
	}

	public setSearchType(searchType: SearchType) {
		let _location: Locations;
		this.store
			.pipe(
				select(getLocation),
				take(1)
			)
			.subscribe(location => _location = <Locations>location);

		this.store.dispatch(new SetSearchType(searchType, _location));
	}

	public setDefaultSearch(searchValue: string, location: Locations) {
		this.store.dispatch(new SetDefaultSearch(searchValue, location));
	}

	public getSearchType(): SearchType {
		let _searchType = SearchType.default;
		this.getSearchTypeAsync()
			.pipe(
				take(1),
			)
			.subscribe((searchType: SearchType) => _searchType = searchType);
		return _searchType;
	}

	public getSearchTypeAsync(): Observable<SearchType> {
		return this.store
			.pipe(
				select(selectSearchType),
			);
	}

	public advancedSearchTotalAsync(): Observable<any> {
		return this.store
			.pipe(
				select(getAbgTotal),
				filter(res => !!res),
			);
	}

	public getDefaultSearch(): string {
		let _searchValue = '';
		this.store
			.pipe(
				select(selectDefaultSearch()),
				take(1),
			)
			.subscribe(searchVal => _searchValue = searchVal);
		return _searchValue;
	}

	public getSearchInputValue(): string {
		let _searchInputValue = '';
		this.store
			.pipe(
				select(selectSearchInputValue),
				take(1),
			)
			.subscribe(searchVal => _searchInputValue = searchVal);
		return _searchInputValue;
	}

	// ADVANCED SEARCH
	public subscribeToAdvancedSearchParams(): Observable<IAdvancedSearchParams> {
		return this.store
			.pipe(
				select(selectAdvancedSearchParams),
				filter((advancedSearchParams: IAdvancedSearchParams) => !!advancedSearchParams &&
					advancedSearchParams.searchType === SearchType.advanced),
			);
	}

	public registerToAdvancedSearch(): Observable<ISearchParams> {
		return this.store
			.pipe(
				select(selectAdvancedSearch),
				filter((advancedSearch: ISearchParams) => !!advancedSearch && advancedSearch.searchType === SearchType.advanced),
			);
	}

	public registerToAdvancedSearchResults(): Observable<any> {
		return this.store
			.pipe(
				select(selectResultsAndTotals),
				filter((res: IObgResults) => !!res),
			);
	}

	public registerToAdvancedSearchValues(location?: Locations): Observable<IAdvancedSearchValue> {
		return this.store
			.pipe(
				select(selectSearchValues(location)),
			);
	}

	public setAdvancedSearchValues(advancedSearchParams: IAdvancedSearchParams) {
		const location = this.getLocation();
		this.store.dispatch(new SetAdvancedSearchParams(advancedSearchParams, location));
	}

	public clearSearch() {
		this.clearSearch$.next();
	}

	public searchFiltersToGraphQLParams(adsSource?: AbgSource) {
		const selector = () => {
			const location = this.getLocation();
			switch (location) {
				case Locations.adc:
					return selectAdcSearchFilters(adsSource);
				case Locations.adcDashboard:
					return selectAdcDashboardSearchFilters;
			}
		};

		let searchFiltersPayload;
		this.store.pipe(
			select(selector()),
			filter(value => !!value),
		).subscribe(value => {
			searchFiltersPayload = value;
		});

		if (!searchFiltersPayload) {
			return '';
		}

		const searchFilters = {...searchFiltersPayload};
		const searchFiltersQueryParams = [];
		if (!!searchFilters.bookmark) {
			searchFiltersQueryParams.push(`bookmark: "${searchFilters.bookmark}"`);
		}
		const statusIds = searchFilters.statusList.filter(a => a.checked).map(a => a.id).join(',');
		if (!!statusIds.length) {
			searchFiltersQueryParams.push(`status: "${statusIds}"`);
		}
		if (!!searchFilters.rate) {
			searchFiltersQueryParams.push(`rate: "${searchFilters.rate}"`);
		}
		if (!!searchFilters.sensitivity) {
			searchFiltersQueryParams.push(`sensitive: "${searchFilters.sensitivity}"`);
		}
		const assetTypes = searchFilters.assetTypes.filter(a => a.checked).map(a => a.value).join(',');
		if (!!assetTypes.length) {
			searchFiltersQueryParams.push(`type: "${assetTypes}"`);
		}

		if (!!searchFilters.tags.length) {
			searchFiltersQueryParams.push(`tagId: "${searchFilters.tags}"`);
		}
		if (!!searchFilters.dataOwnersIds.length) {
			searchFiltersQueryParams.push(`owners: "${searchFilters.dataOwnersIds}"`);
		}
		if (!!searchFilters.dataStewardsIds.length) {
			searchFiltersQueryParams.push(`dataSteward: "${searchFilters.dataStewardsIds}"`);
		}
		if (!!searchFilters.customAttributesIds.length) {

			searchFiltersQueryParams.push(`customAttributeFilter: ${JSON.stringify(searchFilters.customAttributesIds)}`);
		}
		searchFiltersQueryParams.push(`fromCreateDate: "${!!searchFilters.entryFrom ? searchFilters.entryFrom : '01-01-1900'}"`);
		searchFiltersQueryParams.push(`toCreateDate: "${!!searchFilters.entryTo ? searchFilters.entryTo : moment().format(DATE_FORMATS[0])}"`);
		searchFiltersQueryParams.push(`fromLastDate: "${!!searchFilters.lastUpdateFrom ? searchFilters.lastUpdateFrom : '01-01-1900'}"`);
		searchFiltersQueryParams.push(`toLastDate: "${!!searchFilters.lastUpdateTo ? searchFilters.lastUpdateTo : moment().format(DATE_FORMATS[0])}"`);
		if (!!searchFilters.missingProperties) {
			searchFiltersQueryParams.push(`missingProperties: ${JSON.stringify(JSON.stringify(searchFilters.missingProperties))}`);
		}
		if (!!searchFilters.dataUpdatedByIds.length) {
			searchFiltersQueryParams.push(`updatedBy: "${searchFilters.dataUpdatedByIds}"`);
		}
		if (searchFilters.propertiesList[searchFilters.propertiesList.length - 1].checked === true) {
			searchFiltersQueryParams.push(`dataType: "${searchFilters.dataType}"`);
		}
		if (!!searchFilters.suspended) {
			searchFiltersQueryParams.push(`suspend: "${searchFilters.suspended}"`);
		}
		return searchFiltersQueryParams.join(', ');
	}

	ngOnDestroy(): void {
		this.serviceDestroy$.next();
		this.serviceDestroy$.unsubscribe();
	}

	private getLocation() {
		let location: Locations;
		this.store
			.pipe(
				select(getLocation),
			)
			.subscribe((value: Locations) => location = value);
		return location;
	}
}

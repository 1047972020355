import { Injectable } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class KendoGridActionsService {
	public clickRowFromTable$ = new Subject<any>();
	constructor() { }

	public searchInGrid(val: string, columns) {
		var _filters = [];
		var cols = columns;

		for (var i = 0; i < cols.length; i++) {
			let _field = cols['_results'][i].field;
			if (_field) {
				let _operator = 'contains';
				let _value = val;

				_filters.push({
					field: _field,
					operator: _operator,
					value: _value
				});
			}
			if (cols['_results'][i].isColumnGroup) {
				for (var j = 0; j < cols['_results'][i].children._results.length; j++) {
					let _fieldFromGroup = cols['_results'][i].children._results[j].field;
					if (_fieldFromGroup) {
						let _operator = 'contains';
						let _value = val;

						_filters.push({
							field: _fieldFromGroup,
							operator: _operator,
							value: _value
						});
					}
				}
			}
		}
		return _filters;
	}

	public innerSearch(searchedColumns: any[], grid): Array<any> {
		return filterBy(grid, { logic: 'or', filters: searchedColumns });
	}
}

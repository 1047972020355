<div *ngIf="octopai_user" class="flex header-container" flex-end>
	<button class="close-btn-dialog" (click)="close()">
		<i class="fa fa-times"></i>
	</button>
</div>

<div *ngIf="!isTrainingMsg">
	<div class="text">The Lineage Module is currently being generated.</div>
	<div>
		<m-progress-bar [numOfSteps]="data.VALUE" [startDate]="data.START_DATE"></m-progress-bar>
	</div>
	<div class="text">
		Click on the Discovery Module icon to start using Octopai.
	</div>
	<div class="text">
		<button class="k-button k-primary" type="button" (click)="goToDiscovery()">
			<i class="fas fa-glasses" title="Discovery"></i>Discovery
		</button>
	</div>
</div>

<div *ngIf="isTrainingMsg">
	<div class="text">
		The Lineage Module has been generated successfully.
	</div>
	<div class="text">
		It will be accessible once you complete your training.
	</div>
	<div class="text">
		Click on the Discovery Module icon to start using Octopai.
	</div>
	<div class="text">
		<button class="k-button k-primary" type="button" (click)="goToDiscovery()">
			<i class="fas fa-glasses" title="Discovery"></i>Discovery
		</button>
	</div>
</div>

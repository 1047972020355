<div [ngClass]="{'collapsed': isCollapsed, 'notCollapsed': !isCollapsed}">
	<ng-content></ng-content>
</div>
<button class="transparent-button" (click)="onChange()"  >
	<div class="flex" flex-content-center *ngIf="isCollapsed; else elseBlock">
		<div class="text-button" [ngStyle]="{'font-size': fontSize}">
			Show More
		</div>
		<mat-icon class="arrow palette-250" svgIcon="left_arrow"></mat-icon>
	</div>
	<ng-template #elseBlock>
		<div class="flex" flex-content-center>
			<div class="text-button" [ngStyle]="{'font-size': fontSize}">
				Show Less
			</div>
			<mat-icon class="arrow out palette-250" svgIcon="left_arrow"></mat-icon>
		</div>
	</ng-template>
</button>
